import sobrinhoFrente1g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-1g-frente.png";
import sobrinhoFrente2g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-2g-frente.png";
import sobrinhoFrente5g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-5g-frente.png";
import sobrinhoFrente10g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-10g-frente.png";
import sobrinhoFrente25g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-25g-frente.png";
import sobrinhoFrente31g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-31g-frente.png";
import sobrinhoFrente50g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-50g-frente.png";
import sobrinhoVerso1g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-1g-verso.png";
import sobrinhoVerso2g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-2g-verso.png";
import sobrinhoVerso5g from "../../../assets/newImages/mockups/familia/sobrinho/sobrinho-ouro-5g-verso.png";

export const  sobrinhoMockups = {
    nome: "Sobrinho de Ouro",
    frente1g: sobrinhoFrente1g,
  
    frente2g: sobrinhoFrente2g,
  
    frente5g: sobrinhoFrente5g, 
    frente10g: sobrinhoFrente10g,
    frente25g:sobrinhoFrente25g,
    frente31g: sobrinhoFrente31g,
    frente50g: sobrinhoFrente50g, 
  
    verso1g: sobrinhoVerso1g,
    verso2g: sobrinhoVerso2g,
  
    verso5g: sobrinhoVerso5g,
    verso10g: sobrinhoVerso5g,
    verso25g: sobrinhoVerso5g,
    verso31g: sobrinhoVerso5g,
    verso50g: sobrinhoVerso5g,
  };