import vendedoraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-1g-frente.png";
import vendedoraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-2g-frente.png";
import vendedoraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-5g-frente.png";
import vendedoraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-10g-frente.png";
import vendedoraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-25g-frente.png";
import vendedoraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-31g-frente.png";
import vendedoraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-50g-frente.png";
import vendedoraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-1g-verso.png";
import vendedoraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-2g-verso.png";
import vendedoraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/vendedora/vendedora-ouro-5g-verso.png";

export const  vendedoraMockups = {
    nome: "Vendedora",
    frente1g: vendedoraFrente1g,
  
    frente2g: vendedoraFrente2g,
  
    frente5g: vendedoraFrente5g, 
    frente10g: vendedoraFrente10g,
    frente25g:vendedoraFrente25g,
    frente31g: vendedoraFrente31g,
    frente50g: vendedoraFrente50g, 
  
    verso1g: vendedoraVerso1g,
    verso2g: vendedoraVerso2g,
  
    verso5g: vendedoraVerso5g
  };