import operadorFrente1g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-1g-frente.png";
import operadorFrente2g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-2g-frente.png";
import operadorFrente5g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-5g-frente.png";
import operadorFrente10g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-10g-frente.png";
import operadorFrente25g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-25g-frente.png";
import operadorFrente31g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-31g-frente.png";
import operadorFrente50g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-50g-frente.png";
import operadorVerso1g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-1g-verso.png";
import operadorVerso2g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-2g-verso.png";
import operadorVerso5g from "../../../assets/newImages/mockups/empresa-ouro/operador/operador-ouro-5g-verso.png";

export const  operadorMockups = {
    nome: "Operador",
    frente1g: operadorFrente1g,
  
    frente2g: operadorFrente2g,
  
    frente5g: operadorFrente5g, 
    frente10g: operadorFrente10g,
    frente25g:operadorFrente25g,
    frente31g: operadorFrente31g,
    frente50g: operadorFrente50g, 
  
    verso1g: operadorVerso1g,
    verso2g: operadorVerso2g,
  
    verso5g: operadorVerso5g
  };