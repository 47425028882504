import engenheiraFrente1g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-1g-frente.png";
import engenheiraFrente2g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-2g-frente.jpg";
import engenheiraFrente5g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-5g-frente.png";
import engenheiraFrente10g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-10g-frente.png";
import engenheiraFrente25g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-25g-frente.png";
import engenheiraFrente31g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-31g-frente.png";
import engenheiraFrente50g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-50g-frente.png";
import engenheiraVerso1g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-1g-verso.png";
import engenheiraVerso2g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-2g-verso.png";
import engenheiraVerso5g from "../../../assets/newImages/mockups/profissoes/engenheira/engenheira-ouro-5g-verso.png";

export const  engenheiraMockups = {
    nome: "Engenheira de Ouro",
    frente1g: engenheiraFrente1g,
  
    frente2g: engenheiraFrente2g,
  
    frente5g: engenheiraFrente5g,
  
    frente10g: engenheiraFrente10g,
  
    frente25g: engenheiraFrente25g,
  
    frente31g: engenheiraFrente31g,
  
    frente50g: engenheiraFrente50g,
  
    verso1g: engenheiraVerso1g,
    verso2g: engenheiraVerso2g,
  
    verso5g: engenheiraVerso5g,
    verso10g: engenheiraVerso5g,
    verso25g: engenheiraVerso5g,
    verso31g: engenheiraVerso5g,
    verso50g: engenheiraVerso5g,
  };

 