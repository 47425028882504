import React from 'react';
import img from '../../../assets/newImages/grupo-om.png';
import './grupoOM.css';
import '../../pages.css';
import linha from '../../../assets/linha.png';
export default function GrupoOM() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">GRUPO OM</p>
            <p>
              Empresas parceiras completam e agregam. Formar parcerias com
              outras empresas é uma ótima maneira de expandir o negócio e
              oferecer ao cliente mais opções de produtos e serviços
              relacionados ao ouro e câmbio, além de poder compartilhar alguns
              processos de produção e oferecer melhores condições de compra para
              clientes, através de uma parceria, as empresas cooperam uma com a
              outra de forma a obter crescimento e satisfação do cliente.
            </p>
            <p>
              As parcerias empresariais, o processo de produção e gestão é
              totalmente compartilhado, o que traz uma maior facilidade para os
              clientes e o processo de vendas também se torna muito mais
              facilitado.
            </p>
            <p>
              A parceria é realizada com empresas que são fortes na área e que
              possuem um ótimo produto ou presta um excelente serviço. Um estudo
              interno é feito para a escolha do parceiro. É realizada uma
              análise de todas as possibilidades, para que a empresa parceira
              supra as necessidades do cliente. 
            </p>
            <p>
              As parcerias não só complementam o negócio, como também acabam
              suprindo outras necessidades dos clientes e são estabelecidas
              somente parcerias com empresas que possuam um pensamento alinhado
              com o da Ourominas (OM) e que investem em um constante treinamento
              e aperfeiçoamento para atender os clientes OM de uma forma
              bastante satisfatória.
            </p>
            <p>Conheça abaixo todas as empresas parceiras da OM.</p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="c-init__image col-6">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          AUTRAN – RECUPERADORA DE METAIS PRECIOSOS
        </h5>
        <p>
          A AUTRAN é uma recuperadora de metais com 20 anos de experiência no
          mercado, proporciona serviços com alto padrão de ética, qualidade e
          confiança. A empresa possui uma estrutura física altamente
          tecnológica, onde são constantemente monitorados a fim de garantir a
          plena eficiência dos seus serviços de análises.
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          ÂNCORA CONSÓRCIOS – CONSÓRCIO EM BARRAS DE OURO: OURO STAR
        </h5>
        <p>
          A Âncora Consórcios foi criada em 1989. Sua sua atuação é nacional,
          devido à proporção alcançada pelas parcerias comerciais em todo país e
          a ampla atuação nos diversos segmentos. Empresa consolidada como uma
          das melhores administradoras do mercado, principalmente através do
          atendimento diferenciado que oferecem para desenvolver soluções aos
          clientes.
        </p>
        <p>
          A Âncora é a administradora oficial dos consórcios: Kawasaki,
          OuroStar, NOMA, Loja do Mecânico e Meu Patrimônio Imobiliário.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://www.ourostar.com/"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          BANCO BS2 - CONTA INTERNACIONAL GLOBAL
        </h5>
        <p>
          O Banco BS2 é um banco digital exclusivo para empresas, que une
          experiência, tecnologia e atendimento consultivo. Com mais de 30 anos
          de história, iniciou como Banco Bonsucesso e se consolidou em 2020
          como o único banco dedicado totalmente às empresas. Oferece soluções
          financeiras inovadoras e personalizadas, visando gerar resultados para
          todos.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://www.bancobs2.com.br/"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          BRASIL PRÉ-PAGOS (BPP) - CARTÃO PRÉ-PAGO
        </h5>
        <p>
          A BPP é uma plataforma financeira que simplifica a relação das pessoas
          e empresas com finanças e pagamentos, ou seja, empresa de meios de
          pagamento via cartões e parceiros da VISA, o que permite oferecer a
          cobertura de uma das maiores emissoras de cartão do mundo.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://bpp.com.br/"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          DHL - LOGÍSTICA INTERNACIONAL E CORREIO EXPRESSO
        </h5>
        <p>
          A DHL Express é uma divisão da Deutsche Post do setor de logística
          internacional e correio expresso. Tem sede na cidade alemã de Bona.
          Fundada em 1969, a DHL é um acrónimo do nome dos seus criadores
          norte-americanos, Adrian Dalsey, Larry Hillblom e Robert Lynn.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://www.dhl.com/br-pt/home.html"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          GLOBAL TRAVEL ASSISTANCE (GTA) – SEGURO VIAGEM
        </h5>
        <p>
          A GTA, é uma empresa especializa no segmento de assistência para
          viagens internacionais e nacionais, conta com 40 centros operacionais,
          300 postos de atendimento, 3.000 funcionários devidamente treinados e
          em conformidade com as normas da ISO 9001.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://www.segurogta.com.br/2020/home/"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          MONEYGRAM - TRANSFERÊNCIA INTERNACIONAL DE DINHEIRO
        </h5>
        <p>
          A MoneyGram é uma empresa de transferência de dinheiro sediada nos
          Estados Unidos, com sede em Dallas, Texas. Possui um centro de
          operações em St. Louis Park, Minnesota e escritórios regionais e
          locais em todo o mundo.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://moneygram.com.br/"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          MORE PAYMENT EVOLUTION - EVOLUÇÃO DE PAGAMENTOS
        </h5>
        <p>
          Fundada em 2003, por necessidade devido à falta de alternativas
          presentes no mercado. O principal objetivo é acompanhar o processo de
          evolução dos pagamentos para facilitar a vida dos clientes em mais de
          120 países.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="http://www.morepaymentevolution.com"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">MY SIM TRAVEL – CHIP INTERNACIONAL</h5>
        <p>
          A My Sim Travel é uma empresa de chip de internet global que utiliza a
          mais avançada tecnologia GSM de Roaming Internacional, interligando
          uma rede global de operadoras de telefonia celular e em +200 países de
          todos os continentes do planeta.
        </p>
        <p>
          Para conhecê-la melhor clique{' '}
          <a
            href="https://mysimtravel.com.br/"
            rel="noopene noreferrer"
            target="_blank"
            className="text-decoration-none"
          >
            AQUI.
          </a>
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">OM EXPRESS – PACOTES AÉREOS</h5>
        <p>
          A OM Express conta com um experiente time com mais de 10 anos de
          jornada no ramo do turismo, traz consigo o dever de entregar aos seus
          clientes uma gama de facilidades e bons preços quando o assunto é
          viagens, nacionais ou internacionais.
        </p>
      </section>
    </article>
  );
}
