import React, { useEffect, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import { getLancamentos } from "../../../../services/admin";
import { toDate } from "../../../../utils/regex";

export default function FiquePorDentroADM() {
  const [data, setData] = useState();
  const [err, setErr] = useState();
  const header = [
    "ID",
    "NOME",
    "EMAIL",
    "TELEFONE",
    "DATA",
    "STATUS",
  ];
  useEffect(() => {
    getLancamentos()
      .then(setData)
      .catch((resp) => setErr(resp.response.status));
  }, []);

  function onDownload() {
    downloadExcel({
      fileName: "Users table",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body: data,
      },
    });
  }
  return (
    <section>
      <Container>
        <Title>Fique Por Dentro</Title>
        {err && (
          <p className="text-center">
            Dados não disponíveis!
            <br />
            {err}
          </p>
        )}
        {data && (
          <>
          <div className="d-flex justify-content-end mb-2">
              {" "}
              <button
                className="btn btn-success text-align-center"
                onClick={() => onDownload()}
              >
                {" "}
                Exportar
              </button>{" "}
            </div>
          
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <Th scope="col">Nome</Th>
                <Th className="text-center" scope="col">
                  Email
                </Th>
                <Th className="text-center" scope="col">
                  Telefone
                </Th>
                <Th className="text-center" scope="col">
                  Data
                </Th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                return (
                  <tr key={i++}>
                    <Td>{item?.nome}</Td>
                    <Td>{item?.email}</Td>
                    <Td>{item?.telefone}</Td>
                    <Td>{toDate(item?.dataCadastro)}</Td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </>
        )}
      </Container>
    </section>
  );
}

const Th = styled.th`
  width: 20%;
`;

const Td = styled.td`
  width: 20%;
`;

const Container = styled.div`
  width: 100%;
  padding: 60px 100px;
`;
const Title = styled.h1`
  text-align: center;
  margin-bottom: 50px;
`;
