import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useGoldStore, useStoresCoinStore } from "../../services/useStore/useCoinStore";
import { ReturnName } from "../../utils/coins";
import CountryFlags from "../../utils/countryFlags";
import spin from "../../assets/spinner.gif"
import "./vitrine.css";
import logo from "../../assets/logo-large.png"

export default function VitrineBras() {
  const { data: dataForeign, getData: getDataForeign, isLoading, } = useStoresCoinStore();
  const { data: dataGold, getData: getDataGold} = useGoldStore();

  const [filteredCoins, setFilteredCoins] = useState([])
  const [dolar, setDolar] = useState([])
  const [moneygram, setMoneygram] = useState([])
  const [gold, setGold] = useState()

  useEffect(() => {
    const fetchData = async () => {
      await getDataForeign();
      await getDataGold();
    };

    fetchData();
  }, [getDataForeign, getDataGold]);

  useEffect(() => {
    if (dataGold.length > 0) {
      let filter = dataGold.filter((item) =>
      item.codIso.includes("OURO BARRA (1g)")
      );
      setGold(filter[0]);
    }
  }, [dataGold]);

  useEffect(() => {


    function fetchCoins(){
      if (dataForeign.length > 0) {
        const filter = dataForeign.filter((coin) => {
          return coin.id.includes("deea88c2-1039-4482-9cd9-08dbaa2796b5")  || coin.codIso.includes("EUR") || coin.codIso.includes("GBP")
        }
        );
        const filterDolar = dataForeign.filter((coin) => {
          return coin.id.includes("deea88c2-1039-4482-9cd9-08dbaa2796b5")
        }
        );
        const filterMOneygram = dataForeign.filter((coin) => {
          return coin.id.includes("0bd0b068-4ec1-4d92-9cdc-08dbaa2796b5")
        }
        );
        setFilteredCoins(filter);
        setDolar(filterDolar);
        setMoneygram(filterMOneygram)
      }
    }
    fetchCoins()

  }, [dataForeign])

  return (
    <Container>
      <BoxLogo>
        <img src={logo} alt="" />

      </BoxLogo>
      <Title>COTAÇÃO AGORA / QUOTE NOW</Title>
      {isLoading && (
        <div className="col-12 d-flex justify-content-center ">
          <Spin alt="imagem de load" src={spin} />
        </div>
      )}

      {dolar.length > 0 && (

        <Box>
          <table className="table table-hover table-bordered cTable mb-2">

            <thead>
              <tr>
                <th scope="col">COMERCIAL / MERCHANT</th>
                <th scope="col" className="th">Compra / Buy</th>
                <th scope="col" className="th">Venda / Sell</th>
              </tr>
            </thead>
            <tbody>
              {dolar?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <CountryFlags cod={item?.codIso} />
                      {ReturnName(item?.codIso)} {" - "}
                      {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.ovd).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(item?.ocp).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        </Box>
      )}

      {filteredCoins.length > 0 && (

        <Box>
          <table className="table table-hover table-bordered cTable mb-2">

            <thead>
              <tr>
                <th scope="col">TURISMO / TOURISM</th>
                <th scope="col" className="th">Você Compra / You Buy</th>
                <th scope="col" className="th">Você Vende / You Sell</th>
              </tr>
            </thead>
            <tbody>
              {filteredCoins?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <CountryFlags cod={item?.codIso} />
                      {ReturnName(item?.codIso)} {" - "}
                      {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.calculoIOFVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(item?.calculoIOFCompra).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        </Box>
      )}


      <Box>
        <table className="table table-hover table-bordered cTable mb-2">

          <thead>
            <tr>
              <th scope="col">MONEYGRAM</th>
              <th scope="col" className="th">Recebimento / Receivement</th>
              <th scope="col" className="th">Envio / Sent</th>
            </tr>
          </thead>
          <tbody>

          {moneygram?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td><CountryFlags cod="MONEYGRAM" />
                      MONEYGRAM
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.calculoIOFCompra).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(item?.calculoIOFVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}

          </tbody>
        </table>

      </Box>

      {gold && (

        <Box>
          <table className="table table-hover table-bordered cTable mb-2">

            <thead>
              <tr>
                <th scope="col">Barra de Ouro - Gramatura / Gold Bar - Grammage</th>
                <th scope="col" className="th" >Você Compra / You Buy</th>
                <th scope="col" className="th">Você Vende / You Sell</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td><CountryFlags cod="OURO" />Cotação da Grama do Ouro 999 Mercantil / Gold Bar Quotation 999 Mercantil</td>
                <td>
                  {parseFloat(gold?.calculoVenda).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                </td>
                <td>
                  {parseFloat(gold?.calculoCompra).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                </td>
              </tr>
            </tbody>
          </table>

        </Box>
      )}


    </Container>
  )
}

const Container = styled.div`
margin: 0 auto;
width: 80vw;
height: 80vh
`

const Title = styled.h2`
color: #004032;
text-align: center;
margin-bottom: 20px;
`

const Box = styled.div`

background-color: #F7F7F7;
`


const Spin = styled.img`
width: 20px;
margin: 5px 0 !important;
`

const BoxLogo = styled.div`
display: flex;
justify-content: center;
margin: 20px 0 10px 0;
&& > img {
  width: 140px;

}


`