import budistaFrente1g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-1g-frente.png";
import budistaFrente2g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-2g-frente.png";
import budistaFrente5g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-5g-frente.png";
import budistaFrente10g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-10g-frente.png";
import budistaFrente25g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-25g-frente.png";
import budistaFrente31g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-31g-frente.png";
import budistaFrente50g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-50g-frente.png";
import budistaVerso1g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-1g-verso.png";
import budistaVerso2g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-2g-verso.png";
import budistaVerso5g from "../../../assets/newImages/mockups/religiao/budista/budista-ouro-5g-verso.png";

export const  budistaMockups = {
    nome: "Budista",
    frente1g: budistaFrente1g,
  
    frente2g: budistaFrente2g,
  
    frente5g: budistaFrente5g, 
    frente10g: budistaFrente10g,
    frente25g:budistaFrente25g,
    frente31g: budistaFrente31g,
    frente50g: budistaFrente50g, 
  
    verso1g: budistaVerso1g,
    verso2g: budistaVerso2g,
  
    verso5g: budistaVerso5g,
    verso10g: budistaVerso5g,
    verso25g: budistaVerso5g,
    verso31g: budistaVerso5g,
    verso50g: budistaVerso5g,
  };