import gerenteFrente1g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-1g-frente.png";
import gerenteFrente2g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-2g-frente.png";
import gerenteFrente5g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-5g-frente.png";
import gerenteFrente10g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-10g-frente.png";
import gerenteFrente25g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-25g-frente.png";
import gerenteFrente31g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-31g-frente.png";
import gerenteFrente50g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-50g-frente.png";
import gerenteVerso1g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-1g-verso.png";
import gerenteVerso2g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-2g-verso.png";
import gerenteVerso5g from "../../../assets/newImages/mockups/empresa-ouro/gerente/gerente-ouro-5g-verso.png";

export const  gerenteMockups = {
    nome: "Gerente",
    frente1g: gerenteFrente1g,
  
    frente2g: gerenteFrente2g,
  
    frente5g: gerenteFrente5g, 
    frente10g: gerenteFrente10g,
    frente25g:gerenteFrente25g,
    frente31g: gerenteFrente31g,
    frente50g: gerenteFrente50g, 
  
    verso1g: gerenteVerso1g,
    verso2g: gerenteVerso2g,
  
    verso5g: gerenteVerso5g
  };