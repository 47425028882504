import psicologaFrente1g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-1g-frente.png";
import psicologaFrente2g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-2g-frente.png";
import psicologaFrente5g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-5g-frente.png";
import psicologaFrente10g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-10g-frente.png";
import psicologaFrente25g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-25g-frente.png";
import psicologaFrente31g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-31g-frente.png";
import psicologaFrente50g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-50g-frente.png";
import psicologaVerso1g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-1g-verso.png";
import psicologaVerso2g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-2g-verso.png";
import psicologaVerso5g from "../../../assets/newImages/mockups/profissoes/psicologa/psicologa-ouro-5g-verso.png";

export const  psicologaMockups = {
    nome: "Psicóloga de Ouro",
    frente1g: psicologaFrente1g,
  
    frente2g: psicologaFrente2g,
  
    frente5g: psicologaFrente5g,
  
    frente10g: psicologaFrente10g,
  
    frente25g: psicologaFrente25g,
  
    frente31g: psicologaFrente31g,
  
    frente50g: psicologaFrente50g,
  
    verso1g: psicologaVerso1g,
    verso2g: psicologaVerso2g,
  
    verso5g: psicologaVerso5g,
    verso10g: psicologaVerso5g,
    verso25g: psicologaVerso5g,
    verso31g: psicologaVerso5g,
    verso50g: psicologaVerso5g,
  };

 