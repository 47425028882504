import React from "react";
import img from "../../../assets/cartao-ouro-facil.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";
export default function ProcessoSeletivo() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
            ETAPAS OBRIGATÓRIAS
            </p>
            <ol>
            <li>Envio de ficha cadastral preenchida;</li>
            <li>Contato telefônico;</li>
            <li>Envio de documentos solicitados;</li>
            <li>
              Visita in loco para conhecimento do ponto e análise da região;
            </li>
            <li>Aprovação de parceria pelo comitê;</li>
            <li>Treinamentos necessários;</li>
            <li>Assinatura do contrato;</li>
            <li>Início das operações.</li>
          </ol>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

     
      </section>
    </article>
  );
}
