import React from 'react';
import '../../pages.css';
import img from '../../../assets/newImages/politicaSgi.png';
import organograma from '../../../assets/images/organograma.png';

export default function PoliticaSgi() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              POLÍTICA DE SISTEMA DE GESTÃO INTEGRADA (SGI)
            </p>
            <p>
              Política elaborada por Marco Dulgheroff Novais (Jurídico) e
              autorizada a publicação pelo Diretor Mauriciano Vital Gomes
              Cavalcante, destinada a institucionalização de diretrizes de
              gestão operacional da <strong>OM DTVM</strong>.
            </p>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONFORMIDADE E ÂMBITO DE APLICAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  Esta Política está em conformidade com as normativas ISO
                  9001/2015, ISO 14001/2015 e ISO 45001/2018, as quais
                  determinam a instituição de sistema de gestão integrada em
                  pessoas jurídicas de direito privado.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                VALIDADE E DISPONIBILIZAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  O conteúdo desta política é de responsabilidade do Diretor
                  Mauriciano Vital Gomes Cavalcante da <strong>OM DTVM</strong>,
                  sendo este responsável pelo seu cumprimento. A gestão desta
                  Política (elaboração, conformidade, guarda, divulgação,
                  controle de atualizações e adesão) faz parte do Programa de
                  Compliance da <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Esta Política de Sistema de Gestão Integrada é reavaliada e
                  republicada a cada 2 (dois) anos, quando houver alterações na{' '}
                  <strong>OM DTVM</strong> ou em razão de requerimentos legais
                  e/ou administrativos que justifiquem a sua atualização
                  antecipada. A sua existência, assim como suas novas versões,
                  são aprovadas e disponibilizadas na rede pública interna
                  (Intranet ou Teams) e/ou sítio da <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> oferece somente serviços permitidos
                  às Distribuidoras de Títulos e Valores Mobiliários e, no
                  evento de vir a oferecer demais produtos e serviços
                  suscetíveis à alterações institucionais, também será
                  antecipadamente atualizada.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO E FUNÇÃO DA POLÍTICA DE SISTEMA DE GESTÃO INTEGRADA
              </li>
              <li className="list-unstyled">
                <p>
                  Divulgar os princípios e diretrizes que norteiam as ações de
                  Política de Sistema de Gestão Integrada desta{' '}
                  <strong>OM DTVM</strong>, a qual, em consonância ao Segmento
                  (S4) da regulamentação prudencial do Banco Central do Brasil
                  (Supervisor), abarcada pelo Conselho Monetário Nacional (Órgão
                  Normativo), engendra e viabiliza a implementação de requisitos
                  objetivos para a estratificação de determinativos de
                  administração interna e externa da <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Diante disso e considerando que a <strong>OM DTVM</strong>{' '}
                  promove serviços nos setores de (i) compra e venda de moeda
                  estrangeira e (ii) compra de ouro como ativo financeiro, sendo
                  esta Política necessária para viabilizar o desenvolvimento
                  sustentável de suas atividades.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PRINCÍPIO QUE LASTREIA ESTA POLÍTICA DE SGI
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> adota como princípio basilar a
                  condução de seus operação de forma consistente aos mais
                  elevados padrões éticos e em conformidade com todas as leis e
                  regulamentos aplicáveis e vigentes, de modo que a instituição
                  desta Política visa exatamente promover o verdadeiro arrimo da
                  atuação da <strong>OM DTVM</strong> às normativas instituídas
                  pelas retromencionadas ISO 9001/2015, ISO 14001/2015 e ISO
                  45001/2018.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Além disso, a <strong>OM DTVM</strong> reforça seu compromisso
                  de inexoravelmente desenvolver sua atividade operacional em
                  consonância à ética e apreço pela legislação aplicável.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ESTRUTURA DE GOVERNANÇA (ORGANOGRAMA INSTITUCIONAL)
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong>, para fins de cumprimento desta
                  Política de Sistema de Gestão Integrada, dispõe de estrutura
                  organizacional precisamente desenhada para atender todas as
                  nuances inerentes à implementação das normativas insculpidas
                  na presente Políticas:
                </p>
              </li>
              <div className="organograma">
                <img
                  src={organograma}
                  className="image"
                  alt="politica-privacidade"
                ></img>
              </div>
              <li className="list-unstyled">
                <p>
                  Como posto acima, a <strong>OM DTVM</strong>, por meio de seu
                  Corpo Diretivo (Diretores de Ouro, Câmbio e Compliance),
                  instituiu os Compromissos e Ferramentas para implementação
                  desta Política de SGI.{' '}
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O Corpo de Supervisores, por sua vez, em consonância às
                  orientações do Corpo Diretivo, promove a verificação da
                  observância pelos demais setores da <strong>OM DTVM</strong>{' '}
                  das diretrizes impostas nesta Política de SGI.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O Corpo Operacional (Operacional de Ouro e Câmbio e Setores de
                  PI e de Cadastro & Onboarding), tem a inexorável
                  responsabilidade de aplicar as normas instituídas nesta
                  Política de SGI quando do cumprimento de suas funções.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Por fim, o Corpo de Apoio (Departamentos de TI e RH e
                  Auditoria Interna), tem por responsabilidade prestar suporte à
                  quaisquer situações apresentadas pelos Colaboradores que
                  impeçam o regular cumprimento desta Política de SGI.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMPROMISSOS DA OM DTVM
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong>, por meio desta Política de SGI,
                  instituiu termos e condições para viabilizar o desenvolvimento
                  de suas operações de forma a estritamente observar a
                  necessária sustentabilidade e legalidade integral de sua
                  operação:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Buscar melhoria contínua nos processos, produtos e
                      serviços oferecidos pela <strong>OM DTVM</strong>, com
                      foco em sustentabilidade econômica, ambiental e social, a
                      fim de expandir seu renome no mercado de câmbio e ouro,
                      sem prejuízo da qualidade e inovação em sua operação;
                    </li>
                    <li>
                      Superar as expectativas de nossos clientes e
                      colaboradores.
                    </li>
                    <li>
                      Atuar na proteção do meio ambiente, prevenindo a poluição
                      em nossas atividades e da cadeia de valor.
                    </li>
                    <li>
                      Atender os requisitos legais e regulatórios impostos pelo
                      ramo negocial exercido pela <strong>OM DTVM</strong>;
                    </li>
                    <li>
                      Manter um ambiente de trabalho saudável e seguro,
                      prevenindo lesões e doenças ocupacionais, além de
                      incentivar o cuidado com a saúde mental de seus
                      Colaboradores;
                    </li>
                    <li>
                      Garantir a consulta e a participação dos trabalhadores.{' '}
                    </li>
                    <li>
                      Continuamente melhorar o Sistema de Gestão de Qualidade,
                      Ambiental e de Saúde e Segurança Ocupacional.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SANÇÕES ADMINISTRATIVAS
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong>, com o escopo de preservar a
                  legalidade e assertividade de suas operações, desenvolveu um
                  protocolo de sanções e penalidades a serem aplicadas em face
                  de qualquer participante de sua operação, em caso de
                  descumprimento das regulações do Banco Central do Brasil ou de
                  qualquer outra legislação aplicável.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nessa toada, no evento de ser cometida alguma irregularidade,
                  ao tomar ciência, a <strong>OM DTVM</strong> instaurará um
                  procedimento interno para apuração dos fatos, do que decorrerá
                  a constatação de eventual ocorrência e gravidade da conduta.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Verificada a ocorrência e apurada a gravidade, a{' '}
                  <strong>OM DTVM</strong> aplicará as sanções
                  proporcionalmente, as quais são constituídas por advertência
                  por ofício, advertência formal, suspensão temporária do
                  contrato ou rescisão contratual motivada, sem prejuízo do
                  eventual ingresso de demanda judicial por tal razão.
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONCLUSÃO
              </li>
              <li className="list-unstyled">
                <p>
                  Em arremate, a Política de Sistema de Gestão Integrada da{' '}
                  <strong>OM DTVM</strong> reflete o compromisso da instituição
                  em alinhar suas operações aos mais altos padrões de qualidade,
                  sustentabilidade e conformidade legal e regulatória.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Nesse diapasão, resta cristalino que o compromisso com a
                  melhoria contínua, a ética e a segurança dos Colaboradores,
                  não apenas reforça a reputação da <strong>OM DTVM</strong> em
                  seu ramo negocial, mas também contribui para um ambiente de
                  negócios mais responsável e sustentável.
                </p>
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
