import avoFrente1g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-1g-frente.png";
import avoFrente2g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-2g-frente.png";
import avoFrente5g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-5g-frente.png";
import avoFrente10g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-10g-frente.png";
import avoFrente25g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-25g-frente.png";
import avoFrente31g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-31g-frente.png";
import avoFrente50g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-50g-frente.png";
import avoVerso1g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-1g-verso.png";
import avoVerso2g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-2g-verso.png";
import avoVerso5g from "../../../assets/newImages/mockups/familia/avo/avo-ouro-5g-verso.png";

export const  avoMockups = {
    nome: "Avô de Ouro",
    frente1g: avoFrente1g,
  
    frente2g: avoFrente2g,
  
    frente5g: avoFrente5g, 
    frente10g: avoFrente10g,
    frente25g:avoFrente25g,
    frente31g: avoFrente31g,
    frente50g: avoFrente50g, 
  
    verso1g: avoVerso1g,
    verso2g: avoVerso2g,
  
    verso5g: avoVerso5g,
    verso10g: avoVerso5g,
    verso25g: avoVerso5g,
    verso31g: avoVerso5g,
    verso50g: avoVerso5g,
  };