import empresariaFrente1g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-1g-frente.png";
import empresariaFrente2g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-2g-frente.png";
import empresariaFrente5g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-5g-frente.png";
import empresariaFrente10g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-10g-frente.png";
import empresariaFrente25g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-25g-frente.png";
import empresariaFrente31g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-31g-frente.png";
import empresariaFrente50g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-50g-frente.png";
import empresariaVerso1g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-1g-verso.png";
import empresariaVerso2g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-2g-verso.png";
import empresariaVerso5g from "../../../assets/newImages/mockups/profissoes/empresaria/empresaria-ouro-5g-verso.png";

export const  empresariaMockups = {
    nome: "Empresária de Ouro",
    frente1g: empresariaFrente1g,
  
    frente2g: empresariaFrente2g,
  
    frente5g: empresariaFrente5g,
  
    frente10g: empresariaFrente10g,
  
    frente25g: empresariaFrente25g,
  
    frente31g: empresariaFrente31g,
  
    frente50g: empresariaFrente50g,
  
    verso1g: empresariaVerso1g,
    verso2g: empresariaVerso2g,
  
    verso5g: empresariaVerso5g,
    verso10g: empresariaVerso5g,
    verso25g: empresariaVerso5g,
    verso31g: empresariaVerso5g,
    verso50g: empresariaVerso5g,
  };

 