import primoFrente1g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-1g-frente.png";
import primoFrente2g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-2g-frente.png";
import primoFrente5g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-5g-frente.png";
import primoFrente10g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-10g-frente.png";
import primoFrente25g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-25g-frente.png";
import primoFrente31g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-31g-frente.png";
import primoFrente50g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-50g-frente.png";
import primoVerso1g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-1g-verso.png";
import primoVerso2g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-2g-verso.png";
import primoVerso5g from "../../../assets/newImages/mockups/familia/primo/primo-ouro-5g-verso.png";

export const  primoMockups = {
    nome: "Primo de Ouro",
    frente1g: primoFrente1g,
  
    frente2g: primoFrente2g,
  
    frente5g: primoFrente5g, 
    frente10g: primoFrente10g,
    frente25g:primoFrente25g,
    frente31g: primoFrente31g,
    frente50g: primoFrente50g, 
  
    verso1g: primoVerso1g,
    verso2g: primoVerso2g,
  
    verso5g: primoVerso5g,
    verso10g: primoVerso5g,
    verso25g: primoVerso5g,
    verso31g: primoVerso5g,
    verso50g: primoVerso5g,
  };