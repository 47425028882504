
export interface Endereco {
    cep: string;
    logradouro: string;
    numeroLogradouro: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
}

export interface EnderecoItem {
    name: string,
    endereco: Endereco;
}
export default function createEnderecoCEP(numero: string, complemento: string, cep: string, obj: any){
    const endereco : Endereco = {
        cep: cep,
        logradouro: obj.logradouro,
        numeroLogradouro: numero,
        complemento: complemento,
        bairro: obj.bairro,
        cidade: obj.cidade,
        uf: obj.uf
    }

    return endereco;

}


export  function createEndereco(cep: string, logradouro: string, numero: string, complemento: string, bairro: string, cidade: string, localidade: string, uf: string){
    const endereco : Endereco = {
        cep: cep,
        logradouro: logradouro,
        numeroLogradouro: numero,
        complemento: complemento,
        bairro: bairro,
        cidade: localidade,
        uf: uf
    }

    return endereco;

}

export function returnEndereco(data: Endereco): string{
    return `${data.logradouro}, ${data.numeroLogradouro} ${data.complemento}, ${data.cidade}`;
}


export function returnEnderecoOperacao(tipoEndereco): string{
    switch (tipoEndereco) {
      case "enderecoResidencial":
        return "DELIVERY";
      case "enderecoAlternativo":
        return  "DELIVERYT";
      case "enderecoComercial":
        return"DELIVERYE";
      default:
        return null;
    }
  }
