import React, { useEffect } from 'react';

import relatorio1sem2017 from '../../assets/docs/relatorios/1-sem-2017.pdf';
import relatorio2sem2017 from '../../assets/docs/relatorios/2-sem-2017.pdf';
import relatorio1sem2018 from '../../assets/docs/relatorios/1-sem-2018.pdf';
import relatorio2sem2018 from '../../assets/docs/relatorios/2-sem-2018.pdf';
import relatorio1sem2019 from '../../assets/docs/relatorios/1-sem-2019.pdf';
import relatorio2sem2019 from '../../assets/docs/relatorios/2-sem-2019.pdf';
import relatorio1sem2020 from '../../assets/docs/relatorios/1-sem-2020.pdf';
import relatorio2sem2020 from '../../assets/docs/relatorios/2-sem-2020.pdf';
import relatorio1sem2021 from '../../assets/docs/relatorios/1-sem-2021.pdf';
import relatorio1_1sem2021 from '../../assets/docs/relatorios/1-sem-2021.1.pdf';
import relatorio2semEstatistico2022 from '../../assets/docs/relatorios/2-sem-2022-estatistico.pdf';
import relatorio1semEstatistico2022 from '../../assets/docs/relatorios/1-sem-2022-estatistico.pdf';
import relatorio1semEstatistico2023 from '../../assets/docs/relatorios/1-sem-2023-estatistico.pdf';
import relatorio2semEstatistico2023 from '../../assets/docs/relatorios/OM_ESTATISTICO_2.SEM2023.pdf';
import relatorio1semEstatistico2024 from '../../assets/docs/relatorios/1-sem-2024.pdf';

import '../pages.css';
import './atendimento.css';
import { useParams } from 'react-router-dom';
import FormTratment from '../../components/forms/formButton/FormTratment';

export default function Atendimento() {
  const { id } = useParams();
  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
      element.open = true;
    }
  }, [id]);
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit mt-2">
        <h1 className="mt-2 mb-5 fw-bolder text-decoration-underline">
          Atendimento
        </h1>
        <section>
          <details id="central">
            <summary>CENTRAL DE ATENDIMENTO / MESA DE OPERAÇÕES CÂMBIO</summary>
            <p>
              Canal criado para a compra e venda de ouro, câmbio e outros
              serviços. Utilizado também para informações referente aos produtos
              e serviços da Ourominas e para sanar dúvidas referente a uma
              compra já realizada.
            </p>
            <p>
              Produtos e serviços que o cliente encontra através deste canal:
            </p>
            <ul>
              <li>Moedas estrangeiras em espécie;</li>
              <li>Cartão pré-pago internacional;</li>
              <li>Remessas ou transferências internacionais;</li>
              <li>Sistema de remessa via MoneyGram;</li>
              <li>Seguro viagem internacional;</li>
              <li>Chip internacional de celular;</li>
              <li>DHL.</li>
            </ul>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b> Telefone: </b>{" "}
              <a
                className=""
                href={`tel:+551131163663`}
                rel="noopene noreferrer"
                target="_blank"
              >
                (11) 3116-3663
              </a>
            </p>

            <p>
              <b>Horário de Atendimento: </b>08h40 às 18h00 dias úteis (exceto
              feriados)
            </p>
            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={1} />
          </details>
          <hr></hr>
        </section>

        <section>
          <details id="central">
            <summary>CENTRAL DE ATENDIMENTO / MESA DE OPERAÇÕES OURO</summary>
            <p>
              Canal criado para a compra e venda de ouro. Utilizado também para
              informações referente aos produtos e serviços da Ourominas e para
              sanar dúvidas referente a uma compra já realizada.
            </p>
            <p>
              Produtos e serviços que o cliente encontra através deste canal:
            </p>
            <ul>
              <li>Ouro em barras;</li>
            </ul>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b> Telefone: </b>{" "}
              <a
                className=""
                href={`tel:+551131163663`}
                rel="noopene noreferrer"
                target="_blank"
              >
                (11) 3116-3663
              </a>
            </p>

            <p>
              <b>Horário de Atendimento: </b>08h40 às 18h00 dias úteis (exceto
              feriados)
            </p>
            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={1} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="app">
            <summary>OUROMINAS APP</summary>
            <p>
              Canal criado para informações e dúvidas referente a uma
              compra/venda realizada online no Aplicativo da Ourominas.
              Utilizado também para sanar dúvidas sobre como efetuar o cadastro
              e navegar pelo aplicativo.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
              Acesse através do menu principal a opção "OURO" e, posteriormente
              a opção "OURO DIGITAL APP"
            </p>
            <p>
              <b>Horário de Atendimento: </b>09h00 às 18h00 dias úteis (exceto
              feriados)
            </p>
            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={3} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="ouro">
            <summary>OURO JOALHEIROS</summary>
            <p>
              Canal destinado à venda de ouro para pessoa jurídica tais como,
              joalherias, ourives, fabricantes de joias e designer de jóias.
              Utilizado também para informações e dúvidas referente a uma compra
              já realizada.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b> Telefone: </b>{' '}
              <a
                className=""
                href={`tel:+551132180988`}
                rel="noopene noreferrer"
                target="_blank"
              >
                (11) 3218-0988
              </a>
            </p>
            <p>
              <b>Horário de Atendimento: </b>09h às 18h dias úteis (exceto
              feriados)
            </p>

            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={1} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="parceiro">
            <summary>SEJA NOSSO PARCEIRO</summary>
            <p>
              Canal destinado a pessoas ou empresas que desejam realizar uma
              parceria com a Ourominas, abrir um novo negócio ou expandir sua
              cartela de produtos e serviços e se tornar um autorizado.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b>Horário de Atendimento: </b>09h às 18h dias úteis (exceto
              feriados){' '}
            </p>
            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={11} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="assessoria">
            <summary> ASSESSORIA DE IMPRENSA E LIVES</summary>
            <p>
              Canal destinado aos profissionais de veículos influentes e aos
              jornalistas que procuram entrevistas ou informações sobre a
              Ourominas e o mercado de câmbio e ouro. Utilizado também para
              influenciadores digitais que desejam fazer lives ou gravação de
              Pod Casts.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=5511998648524&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 9 9864-8524
              </a>{' '}
            </p>

            <p>
              <b>Horário de Atendimento: </b>09h às 18h dias úteis (exceto
              feriados)
            </p>
            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={4} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="marketing">
            <summary>MARKETING</summary>
            <p>
              Canal destinado aos profissionais de marketing, publicidade e
              propaganda que procuram parcerias ou informações sobre a
              Ourominas.
            </p>

            <p>
              <b>Email: </b>
              <a
                className=""
                href={`mailto:${'marketing@ourominas.com'}`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                marketing@ourominas.com
              </a>
            </p>
            <FormTratment destination={5} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="trabalhe-conosco">
            <summary>TRABALHE CONOSCO</summary>
            <p>
              Canal destinado a pessoas que desejam fazer parte da equipe de
              colaboradores da Ourominas.
            </p>

            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={6} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="sugestoes">
            <summary>SUGESTÕES</summary>
            <p>
              Canal criado exclusivamente para as sugestões de clientes que
              acreditam que algum produto ou serviço podem ser reavaliados e
              melhorados. Ao entrar em contato com este canal o cliente contará
              com um atendimento eficaz, onde a sugestão será apresentada aos
              gestores e diretores da Ourominas. O retorno através deste contato
              tem prazo estabelecido de até 3 (três) dias úteis.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b>Horário de Atendimento: </b>08h40 às 17h40 dias úteis (exceto
              feriados)
            </p>
            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={7} />
          </details>

          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="denuncia">
            <summary>DENÚNCIA</summary>
            <p>
              Canal criado exclusivamente para os indícios de ilicitude
              relacionados às atividades da instituição. O principal objetivo
              deste canal é identificar fraudes ou desvios de conduta que possam
              ocorrer com a empresa e seus parceiros autorizados, e se preparar
              ou amenizar, eventuais perdas em decorrência a estes atos
              acometidos.
            </p>
            <p>
              De acordo com a Resolução 4.567/17 o prazo estipulado pelo Banco
              Central é de até 10 (dez) dias úteis, no entanto nossos esforços
              estão direcionados para que a demanda seja atendida o quanto
              antes.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b>Horário de Atendimento: </b>09h00 às 18h00 dias úteis (exceto
              feriados)
            </p>

            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={8} isAnonymous={true} />
          </details>
          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="reclamacao">
            <summary>RECLAME AQUI</summary>
            <p>
              Canal criado exclusivamente para as reclamações de clientes que se
              encontram insatisfeitos com um fato ocorrido junto à empresa ou
              junto a um parceiro autorizado Ourominas. Ao entrar em contato com
              este canal o cliente receberá um número de protocolo e contará com
              uma solução eficaz e satisfatória. O retorno através deste contato
              tem prazo estabelecido de até 3 (três) dias úteis.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>

            <p>
              <b>Horário de Atendimento: </b>08h40 às 17h40 dias úteis (exceto
              feriados)
            </p>
            <p>
              <b>Email:</b>
            </p>

            <FormTratment destination={9} />
          </details>

          <hr></hr>
        </section>

        <section className="mt-3">
          <details id="ouvidoria">
            <summary>OUVIDORIA</summary>

            <p>
              Canal criado exclusivamente para as reclamações de clientes que
              não foram resolvidas através do Canal Reclame Aqui Ourominas e que
              ainda se encontram insatisfeitos com um fato ocorrido junto à
              empresa ou a um parceiro autorizado. Ao entrar em contato com este
              canal o cliente deverá informar o número de protocolo gerado no
              Canal Reclame Aqui Ourominas. O retorno através deste contato tem
              prazo estabelecido de até 5 (cinco) dias úteis.
            </p>

            <p>
              <b>WhatsApp: </b>{' '}
              <a
                className=""
                href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
                rel="noopene noreferrer"
                target="_blank"
              >
                {' '}
                (11) 2050-1646
              </a>{' '}
            </p>
            <p>
              <b> Telefone: </b>{' '}
              <a
                className=""
                href={`tel:+08007705422`}
                rel="noopene noreferrer"
                target="_blank"
              >
                0800 770-5422
              </a>{' '}
              (somente chamadas realizadas no Brasil)
            </p>
            <p>
              <b>Horário de Atendimento: </b>09h às 16h40 dias úteis (exceto
              feriados)
            </p>

            <p>
              <b>Email:</b>
            </p>
            <FormTratment destination={10} />

            <section className="mt-4">
              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2017</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1sem2017}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={relatorio2sem2017}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2018</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1sem2018}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={relatorio2sem2018}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2019</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1sem2019}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={relatorio2sem2019}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2020</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1sem2020}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={relatorio2sem2020}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2021</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1sem2021}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1_1sem2021}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2022</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1semEstatistico2022}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>

                  <li>
                    <a
                      className="text-decoratio-none"
                      href={relatorio2semEstatistico2022}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Relatórios Ouvidoria 2023</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1semEstatistico2023}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>

                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio2semEstatistico2023}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="">
                <summary>Relatórios Ouvidoria 2024</summary>
                <ul>
                  <li>
                    {' '}
                    <a
                      className="text-decoratio-none"
                      href={relatorio1semEstatistico2024}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                </ul>
              </details>
            </section>
          </details>

          <hr></hr>
        </section>
      </section>
    </article>
  );
}
