import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import FormButton from "../../forms/formButton/formButton";
import { CartContext } from "./cartShoppService";
import Clear from "@mui/icons-material/HighlightOff";
import TextButton from "../../buttons/textButton";
import { NavLink, useParams } from "react-router-dom";
import { ReturnName } from "../../../utils/coins";

export default function CartShopp() {
  const {
    productsCart,
    removeProducts,
    sendWhatsapp,
    toogleCArt,
  } = useContext(CartContext);
  const [soma, setSoma] = useState();
  let array = [productsCart];
  const { name } = useParams();
  useEffect(() => {
    let value = 0;
    array[0].map((item) => {
      return value += item.valor * item.quantidade;
    });
    setSoma(value);
    sendWhatsapp();
  }, [productsCart]);

  return (
    <Container id="shopContainer" className="invisible">
      <Section>
        <Header>
          <h5 className="m-0">Informações sobre o pedido</h5>
          <Button variant="outline-danger" onClick={toogleCArt}>
            X
          </Button>
        </Header>
        <hr></hr>
        {array[0]?.map((item, i) => (
          <>
            <TotalItems key={i}>
              <LineItem>
                <BtnIcon
                  onClick={() => {
                    removeProducts(item);
                  }}
                >
                  <Clear />
                </BtnIcon>
                <p className="m-0 fw-bold">
                  ({item?.quantidade}x) {ReturnName(item?.nome) || item?.nome}
                </p>
              </LineItem>
              <ColumnOffer>
                <p>
                  {parseFloat(item?.valor * item?.quantidade).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </p>
              </ColumnOffer>
            </TotalItems>
            <hr></hr>
          </>
        ))}
        <TotalItems>
          <p>Total</p>
          <p value>
            {parseFloat(soma).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </p>
        </TotalItems>
        <hr></hr>
        <ButtonRow className="d-flex justify-content-center">
          <TextButton onClick={toogleCArt}>Adicionar mais produtos</TextButton>
        </ButtonRow>
        {array[0].length > 0 && (
          <NavLink to={`/om/servicos-cambio/${name}/finalizar-pedido`} onClick={toogleCArt}>
            <ButtonRow>
              <FormButton className="btnAdd" variant="secondary" size="lg" >
                Finalizar Pedido
              </FormButton>
            </ButtonRow>
          </NavLink>
        )}
      </Section>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  z-index: 2000;
`;

const Section = styled.div`
  width: 400px;
  transform: translateX(-50%);
  top: 10%;
  position: fixed;
  left: 85%;
  background-color: #fff;
  box-shadow: 0 0 2em rgba(63, 61, 61, 0.11);
  border-radius: 5px;
  @media (min-width: 320px) {
    left: 50%;
    width: 300px;
  }
  @media (min-width: 375px) {
    left: 50%;
    width: 360px;
  }
  @media (min-width: 660px) {
    width: 400px;
    left: 70%;
  }
  @media (min-width: 800px) {
    left: 75%;
  }
  @media (min-width: 1200px) {
    left: 83%;
  }
  @media (min-width: 1360px) {
    left: 85%;
  }
`;

const Header = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0 10px;
  align-items: center;
`;
const ButtonRow = styled.div`
  margin: 20px 15px;
`;
const TotalItems = styled.div`
  margin: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    margin: 0;
  }
`;
const LineItem = styled.div`
  display: flex;
  align-items: center;
`;
const BtnIcon = styled.button`
  border: 0;
  color: red;
  background: none;
  padding-right: 5px;
`;
const ColumnOffer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > p {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  > span {
    font-size: 12px;
  }
`;
