import React, { useState } from "react";
import { EmailModel } from "../../../models/Email";
import { postSendEmail } from "../../../services/api";
import spinner from "../../../assets/spinner.gif"
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField } from "@mui/material";
import styled from "styled-components";
import { maskDDD, maskOnlyLetters, maskPhoneNotDDD, validEmail } from "../../../utils/regex";;

export default function FormTratment({ destination, isAnonymous }) {
    const [name, setName] = useState('');
    const [errName, setErrName] = useState('');
    const [email, setEmail] = useState('');
    const [errMail, setErrEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errPhone, setErrPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errMessage, setErrMessage] = useState('');

    const [ddd, setDDD] = useState('');
    const [errDdd, setErrDDD] = useState('');
    const [check, setCheck] = useState(false);
    const [errCheck, setErrCheck] = useState();
    const [checkAnonymous, setCheckAnonymous] = useState(false);

    const [load, setLoad] = useState(false);
    const [success, setSuccess] = useState('');
    const [err, setErr] = useState('');

    function validateForm() {
        setSuccess('')
        setErr('');
        setErrName('');
        setErrEmail('');
        setErrPhone('');
        setErrDDD();
        setErrCheck('');
        setErrMessage('');
        let valid = true;


        if (!checkAnonymous) {
            if (name == null || name === '') {
                setErrName("Informe seu nome!");
                valid = false;
            }
            if (email == null || email === '') {
                setErrEmail("Informe seu email!");
                valid = false;
            } else if (!validEmail.test(email)) {
                setErrEmail("Informe um email válido!");
                valid = false;
            }

            if (ddd == null || ddd === '') {
                setErrDDD("Informe seu DDD!");
                valid = false;
            } else if (ddd.length !== 2) {
                setErrDDD("Informe um DDD válido!");
                valid = false;
            }

            if (phone == null || phone === '') {
                setErrPhone("Informe seu telefone!");
                valid = false;
            } else if (phone.length !== 10) {
                setErrPhone("Informe um número válido!");
                valid = false;
            }
        }


        if (message == null || message === '') {
            setErrMessage("Informe a mensagem!");
            valid = false;
        }
        if (check == null || check === false) {
            setErrCheck("Marque o aceite!");
            valid = false;
        }

        return valid;
    }

    function send() {
        if (validateForm()) {
            setLoad(true)
            const model = EmailModel;
            if (checkAnonymous) {
                model.nome = 'Anônimo';
                model.email = 'Anônimo';
                model.telefone = "Anônimo";
                model.destinatario = destination
                model.mensagem = message
            } else {
                model.nome = name;
                model.email = email;
                model.telefone = ddd + phone;
                model.destinatario = destination
                model.mensagem = message
            }


            postSendEmail(model).then(() => {
                clean();
                setSuccess("Dados enviados com sucesso!")
            }).catch(() => {

            }).finally(() => setLoad(false));

        } else {

        }
    }
    function clean() {

        setName('');
        setEmail('');
        setDDD('');
        setPhone('');
        setCheck(false);
        setMessage('');

    }
    return (
        <Container>
            <BoxInputs>

                <BoxName>
                    <TextField
                        helperText={errName}
                        className="bg-white w-100"
                        placeholder="Digite seu nome"
                        label="Nome *"
                        value={name}
                        onChange={(e) => setName(maskOnlyLetters(e.target.value))}
                        disabled={checkAnonymous ? true: false}
                    />
                </BoxName>

                <BoxEmail>
                    <TextField
                        helperText={errMail}
                        className="bg-white w-100"
                        placeholder="Digite seu email"
                        label="Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={checkAnonymous ? true: false}
                    />

                </BoxEmail>

                <BoxTelephone>
                    <BoxDDD>

                        <TextField
                            helperText={errDdd}
                            className="bg-white w-100"
                            placeholder="Digite seu DDD"
                            label="DDD *"
                            value={ddd}
                            onChange={(e) => setDDD(maskDDD(e.target.value))}

                            disabled={checkAnonymous ? true: false}
                        />
                    </BoxDDD>
                    <BoxPhone>

                        <TextField
                            helperText={errPhone}
                            className="bg-white w-100"
                            placeholder="Digite seu telefone"
                            label="Telefone (WhatsApp) *"
                            value={phone}
                            onChange={(e) => setPhone(maskPhoneNotDDD(e.target.value))
                            }
                            disabled={checkAnonymous ? true: false}

                        />
                    </BoxPhone>

                </BoxTelephone>
            </BoxInputs>

            <BoxMessage>
                <TextField
                    helperText={errMessage}
                    className="bg-white w-100"
                    placeholder="Digite a mensagem"
                    label="Mensagem *"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)
                    }
                    multiline
                    max Rows={4}

                />
            </BoxMessage>
            {isAnonymous && (
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={checkAnonymous} onChange={() => setCheckAnonymous(!checkAnonymous)} />} label="Não quero me identificar." />

                </FormGroup>
            )}
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={check} onChange={() => setCheck(!check)} />} label="Autorizo a OM entrar em contato comigo e salvar minhas informações." />
                <FormHelperText>{errCheck}</FormHelperText>
            </FormGroup>
            <BoxButton>
                <Button onClick={send} >Enviar</Button>
            </BoxButton>

            {load && (

                <LineLoading>
                    <Loading src={spinner} />
                </LineLoading>
            )}

            <Success>{success}</Success>
        </Container>
    )
}

const Container = styled.div`
background-color: #f7f7f7;
padding: 20px 15px 10px 15px;
border: 1px dotted grey;
@media(min-width: 320px){
    width: 100%;
}
@media(min-width:520px){

    width: 80%;
    margin: 0 auto;
}

@media(min-width:630px){

    width: 60%;
    margin: 0 ;
}
@media(min-width:768px){

    width: 100%;

}



`
const BoxInputs = styled.div`
display: flex;
flex-direction: column;

&& > div {
    margin-bottom: 10px;
}

@media(min-width:768px){
    flex-direction: inherit;

    && > div {
        width: 232px;
        margin-left: 10px;
    }

    > div:nth-child(1) {
       margin: 0
    }
}
`

const BoxName = styled.div`

@media(min-width: 768px){

}
`

const BoxEmail = styled.div``

const BoxTelephone = styled.div`
display: flex;

@media(min-width:1024px){
    width: 300px !important;
}


`
const BoxPhone = styled.div`
@media(min-width:320px){
   width: -webkit-fill-available;
}

`
const BoxDDD = styled.div`
@media(min-width:320px){
    width: 30%;
    margin-right: 10px;
}

@media(min-width:768px){
    width: 50%;
}

`

const BoxButton = styled.div`
width:100%;
margin-top: 5px;
text-align: center;

`
const Button = styled.button`
background-color: var(--green);
    color: #fff;
    width: 260px;
    height: 40px;
    font-family: "Poppins", sans-serif;
    border: 1px solid var(--green);
    border-radius: 4px;
    font-size: 12px;
    justify-self: center;
    margin: 0 auto;
    &&:hover{
        background-color: #002c23;
    }
`

const Loading = styled.img`
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto;
`;

const LineLoading = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%
`;

const Success = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: green;
  text-align: center;
`;

const BoxMessage = styled.div`
@media(min-width: 768px){
   // margin-left: 10px

}
`