import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';

export default function Seguranca() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [step, setStep] = useState('confirmCode');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleContinuarClick = () => {
    if (step === 'confirmCode') {
      setStep('changePassword');
    } else if (step === 'changePassword') {
      if (password === confirmPassword) {
        if (password.length < 6) {
          setErrorMessage('A senha deve ter pelo menos 6 caracteres.');
          return;
        }
        setAlertSeverity('success');
        setAlertMessage('Senha alterada com sucesso!');
        setShowAlert(true);
        setOpen(false);
      } else {
        setAlertSeverity('error');
        setAlertMessage('As senhas não coincidem. Por favor, tente novamente.');
        setShowAlert(true);
      }
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <MenuPrincipal>
        <SectionContainer>
          <SectionIntroContainer>
            <h2 className="display-4">Segurança</h2>
          </SectionIntroContainer>
          <Details>
            <summary>Alterar Senha</summary>
            <Section>
              <p>
                Para alterar a senha, precisamos confirmar que é você mesmo.
              </p>
              <p>
                Iremos enviar um código de validação, no seu email cadastrado.
              </p>
              <div>
                <Button onClick={handleOpen}>Continuar</Button>
              </div>
            </Section>
          </Details>
        </SectionContainer>
      </MenuPrincipal>
      <Modal open={open} onClose={handleClose}>
        <SectionContainerModal>
          <SectionModal>
            {step === 'confirmCode' && (
              <>
                <h5>Alterar Senha</h5>
                <p>
                  Informe o código que enviamos para o seu email cadastrado.
                </p>
                <Input type="text" placeholder="Código" maxLength={6} />
              </>
            )}
            {step === 'changePassword' && (
              <>
                <h5>Alterar Senha</h5>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <p>Informe a nova senha.</p>
                <Input
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  type="password"
                  placeholder="Confirmar Nova Senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </>
            )}
            <div>
              <Button onClick={handleContinuarClick}>
                {step === 'confirmCode' ? 'Confirmar' : 'Confirmar'}
              </Button>
            </div>
          </SectionModal>
        </SectionContainerModal>
      </Modal>

    </>
  );
}

const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;
`;

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
`;

const SectionContainerModal = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 80px 15px;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;

  h2 {
    text-transform: uppercase;
  }
`;

const Details = styled.details`
  border: 1px solid;
  border-radius: 8px;
  padding: 20px;
  margin-top: 25px;
  color: #a9a9a9;
`

const Section = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #a9a9a9;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SectionModal = styled.div`
  background-color: #d8d8d8;
  border: 1px solid;
  border-radius: 8px;
  padding: 30px 20px;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
  max-width: 700px;
  min-width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 580px;
  }
`;

const Button = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 45px;
  margin: 30px 10px 10px;
`;

const Input = styled.input`
  width: 90%;
  height: 30px;
  border-radius: 8px;
  border: 1px solid var(--light);
  background-color: #d8d8d8;
  margin-top: 4%;
  text-align: center;
  font-size: 16px;

  ::placeholder {
    text-align: center;
  }

  &:focus {
    outline: none;
  }
`;

const ErrorMessage = styled.p`
  color: var(--primary-red);
  margin-bottom: 10px;
`;
