import madrinhaFrente1g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-1g-frente.png";
import madrinhaFrente2g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-2g-frente.png";
import madrinhaFrente5g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-5g-frente.png";
import madrinhaFrente10g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-10g-frente.png";
import madrinhaFrente25g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-25g-frente.png";
import madrinhaFrente31g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-31g-frente.png";
import madrinhaFrente50g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-50g-frente.png";
import madrinhaVerso1g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-1g-verso.png";
import madrinhaVerso2g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-2g-verso.png";
import madrinhaVerso5g from "../../../assets/newImages/mockups/familia/madrinha/madrinha-ouro-5g-verso.png";

export const  madrinhaMockups = {
    nome: "Madrinha de Ouro",
    frente1g: madrinhaFrente1g,
  
    frente2g: madrinhaFrente2g,
  
    frente5g: madrinhaFrente5g, 
    frente10g: madrinhaFrente10g,
    frente25g:madrinhaFrente25g,
    frente31g: madrinhaFrente31g,
    frente50g: madrinhaFrente50g, 
  
    verso1g: madrinhaVerso1g,
    verso2g: madrinhaVerso2g,
  
    verso5g: madrinhaVerso5g,
    verso10g: madrinhaVerso5g,
    verso25g: madrinhaVerso5g,
    verso31g: madrinhaVerso5g,
    verso50g: madrinhaVerso5g,
  };