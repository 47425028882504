import React from "react";
import img from "../../../assets/newImages/Certificado Origem do ouro1.png";
import "../../seguranca/politicas/politicas.css";
import linha from "../../../assets/linha.png";

export default function OrigemOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              Certificado de Origem do Ouro
            </p>
            <p>
              O material vendido pela OM é adquirido no mercado Balcão e no
              mercado primário, extraído de área de produção mineral localizada
              no Brasil, oriundo de Permissões de Lavras Garimpeiras (PLG)
              autorizadas e conferidas pela Agência Nacional de Mineração (ANM).
            </p>
            <p>
              Todo ouro comercializado na OM possui teor de pureza 999 - 24k,
              são lacrados individualmente com selos holográficos
              tridimensionais que garantem a segurança e a qualidade do produto,
              acompanha Nota de Negociação com Ouro Ativo Financeiro (NNO),
              Garantia de Recompra e Certificado de Origem do Metal.
            </p>
            <p>
              A OM é uma Instituição Financeira (IF) e Distribuidora de Títulos
              e Valores Mobiliários (DTVM) que opera sob regras estabelecidas
              pelo Conselho Monetário Nacional (CMN), autorizada pelo Banco
              Central (BACEN 27930), credenciada na Comissão de Valores
              Mobiliário (CVM), associada na Associação Brasileira de Câmbio
              (ABRACAM) e certificada na Americas Gold Manufacturers Association
              (AMAGOLD), na ABS Quality Evaluations (ABS QE) com os selos ISO
              45001, IS0 9001 e ISO 14001, na Amiga da Floresta com o selo Plante
              Árvore, no Instituto Brasileiro de Floresta (IBF) e no Great Place
              to Work (GPTW).
            </p>
            <p>
              A OM possui parte de seus colaboradores e parceiros certificados
              na ABRACAM: ABT1 ABT2 e na ANBIMA: CPA-10 e CPA-20.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="grupo-om"></img>
          </div>
        </div>
      </section>
    </article>
  );
}
