import React from "react";
import styled from "styled-components";
import { ReactComponent as MySvg } from "../../assets/spinner.svg";
export const PageLoading = () => {
  return (
    <BoxPageLoading> <MySvg />
    </BoxPageLoading>
  );
};

const BoxPageLoading = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #0000001f;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  align-items: center;

  > svg {
    height: 100px;
    width: 157px;
  }
`;