import React from "react";
import "../../pages.css";
import img from "../../../assets/cartao-pre-pago.png";
import linha from "../../../assets/linha.png";

export default function CartaoPrePago() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">CARTÃO PRÉ-PAGO</p>
            <p>
            Modernize a sua forma de viajar, a Ourominas (OM) possui o cartão
            Ourominas Pré-Pago multimoedas internacional, a maneira mais prática
            e segura de levar dinheiro em sua viagem internacional, com ele você
            poderá fazer saques, compras e o melhor de tudo, não tem anuidade,
            cobrança de juros e estará isento da variação cambial em gastos fora
            do país.
          </p>
          <p>
            O cartão é ótimo para complementar a quantia de moeda em espécie
            planejada para a viagem, os cartões operam em 6 moedas diferentes:
            dólar americano, euro, dólar australiano, libra esterlina, dólar
            canadense e dólar neozelandês – o que garante a facilidade de
            utilizar todas as funções do Ourominas Pré-Pago em diversos países,
            sem dor de cabeça e com acesso a saque 24 horas.
          </p>
          <p>
            As movimentações acontecem em tempo real, você pode conferir o saldo
            e gerenciar as transações a qualquer hora e de qualquer lugar do
            mundo através do aplicativo da BPP (Brasil Pré-Pagos), nosso
            parceiro.
          </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section className="mb-4 sect">
        <p>
        Os cartões operam em 6 moedas diferentes: dólar americano, euro, dólar australiano, libra esterlina, dólar canadense e dólar neozelandês – o que garante a facilidade de utilizar todas as funções do BPP Travel Money em diversos países, sem dor de cabeça.
        </p>
      </section>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            COMO ADQUIRIR O CARTÃO PRÉ-PAGO?
          </summary>
          <p className="mt-4">Através do site ou lojas próprias ou autorizadas OM.</p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EXISTE TARIFA PARA AQUISIÇÃO DO CARTÃO?
          </summary>
          <ol className="mt-4">
            <li>Não, para aquisição do cartão não são cobradas tarifas.</li>
          </ol>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">COMO RECEBER O CARTÃO PRÉ-PAGO?</summary>
          <p className="mt-4">
            Nas compras feitas em uma de nossas lojas, com pagamento em
            dinheiro, débito ou crédito, o cartão pode ser retirado na hora;
            pagamentos em DOC/TED e Pix poderão ser retirados a partir da compensação
            em nossa conta corrente.
          </p>
          <p>
            Se a compra for realizada pelo site ou pelo telefone, você poderá
            agendar a retirada em uma de nossas lojas ou utilizar o serviço
            Delivery que faz a entrega no endereço de sua preferência.
          </p>
          <p>Consulte a disponibilidade deste serviço em sua região.</p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            ESQUECI A SENHA DO CARTÃO, E AGORA?
          </summary>
          <p className="mt-4">
          Em caso de esquecimento ou problemas com sua senha acesse o link <a href="https://meu.brasilprepagos.com.br/bpp">
              (https://meu.brasilprepagos.com.br/bpp)
            </a> ou entre em contato com nossa central de atendimento.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EM CASO DE PERDA, ROUBO OU FURTO, COMO PROCEDER?
          </summary>
          <p className="mt-4">Você pode bloquear o seu cartão a partir dos seguintes canais:</p>
          <ul>
            <li>Ligue para a OM, através de seus canais de atendimento;</li>
            <li>
              Acesse este link (LINK), depois acesse o item “Dados do Cartão”
              localizado no menu superior a direita e clique em “BLOQUEAR
              CARTÃO”.
            </li>
          </ul>
          <p>Importante:</p>
          <p>
            Até que o bloqueio seja feito, todas as transações efetuadas são de
            sua responsabilidade. Lembre-se de manter a sua senha separada do
            cartão, para evitar o uso indevido.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            APÓS O BLOQUEIO DO CARTÃO, O QUE DEVO FAZER PARA SOLICITAR UMA
            SUBSTITUIÇÃO?
          </summary>
          <p className="mt-4">Ligue para os números abaixo:</p>
          <ul>
            <li>Brasil: +55 (11) 3218-0993;</li>
            <li>
              Do Exterior: +55 11 3588-4848 (a ligação pode ser feita a cobrar,
              exceto de celular).
            </li>
          </ul>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAL O PRAZO DE ENTREGA DO CARTÃO DE SUBSTITUIÇÃO EM CASA DE PERDA,
            ROUBO OU FURTO?
          </summary>
          <p className="mt-4">
            O prazo de entrega pode variar de acordo com as normas alfandegárias
            de cada país.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EXISTE TARIFA DE ENVIO PARA REPOSIÇÃO DE CARTÃO EM CASO DE PERDA,
            ROUBO OU FURTO?
          </summary>
          <p className="mt-4">
            A primeira reposição é gratuita. A partir da segunda, haverá
            cobrança debitada diretamente do saldo disponível do cartão. O valor
            poderá ser consultado na seção Tarifas Bancárias no site do BPP.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS SÃO OS VALORES MÍNIMOS PARA CARGA E RECARGA DO CARTÃO
            PRÉ-PAGO?
          </summary>
          <p className="mt-4">Lojas e Central de Vendas:</p>
          <ul>
            <li>Carga: USD/EUR/GBP/AUD/CAD/NZD 000</li>
            <li>Recarga: USD/EUR/GBP/AUD/CAD/NZD 30.000</li>
          </ul>
          <p>
            Obs: Operações com valores acima de R$ 10.000,00 estão sujeitas à
            análise de Compliance.
          </p>
        </details>        
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EXISTE UM LIMITE MÍNIMO E MÁXIMO PARA SAQUES E COMPRAS?
          </summary>
          <p className="mt-4">
            Sim. Por questões de segurança, estabelece limites de compras,
            saques e quantidade de transações, sendo o máximo 30.000 para
            compras e 4 (quatro) saques por dia de 500.00, totalizando
            2.000/dia.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            ONDE POSSO CONSULTAR AS LOCALIDADES DOS CAIXAS ELETRÔNICOS?
          </summary>
          <ul className="mt-4">
            <li>Bandeira Visa – Visa Plus clicando neste link.</li>{" "}
            <a href="https://www.visa.com/atmlocator/">
              (https://www.visa.com/atmlocator/)
            </a>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            COMO SABER O PRAZO DE VALIDADE DO CARTÃO?
          </summary>
          <p className="mt-4">
            O prazo de validade está impresso na frente do cartão (MM/AA).
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            DIGITEI A SENHA DO CARTÃO ERRADA 3 VEZES. O QUE FAZER?
          </summary>
          <p className="mt-4">
            A primeira reposição é gratuita. A partir da segunda, haverá
            cobrança debitada diretamente do saldo disponível do cartão. O valor
            poderá ser consultado na seção Tarifas Bancárias no site do BPP.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EXISTE TARIFA DE ENVIO PARA REPOSIÇÃO DE CARTÃO EM CASO DE PERDA,
            ROUBO OU FURTO?
          </summary>
          <p className="mt-4">
            Por questões de segurança, caso você erre a senha do cartão por 3
            vezes consecutivas, a senha ficará bloqueada por 24 horas para
            compras e saques que utilizam senha. Após este período, o cartão
            estará automaticamente liberado.
          </p>
          <p>
            Estando o seu cartão bloqueado, mesmo que você altere a senha do
            cartão (4 dígitos), será necessário aguardar 24 horas após a última
            tentativa de senha incorreta.
          </p>
          <p>
            Enquanto a senha estiver bloqueada, você poderá realizar compras em
            estabelecimentos que não solicitem senha para as transações.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            NÃO RECONHEÇO A TRANSAÇÃO EM MEU EXTRATO. O QUE DEVO FAZER?
          </summary>
          <p className="mt-4">
            Verifique se o valor não foi realmente utilizado por meio dos
            comprovantes de compras e saques.
          </p>
          <p>
            Acesse o extrato do seu cartão, vá até a caixa de seleção, ao lado
            da operação que você não reconhece, e clique no botão “Contestar
            Transações Selecionas”.
          </p>
        </details>
        </section>
      </section>
    </article>
  );
}
