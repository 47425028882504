import { Documento } from "./Documento";
import { Endereco } from "./Endereco";

export interface Cliente {
    id: string;
    codigoCliente: string;
    tipoPessoa: string;
    cpfCnpj: string;
    nome: string;
    rgIe: string;
    dtEmissaoDocumento: Date;
    ufEmissaoDocumento: string;
    nacionalidade: string;
    dddCelular: string;
    celular: string;
    email: string;
    emailSecundario: string;
    enderecoResidencial: Endereco;
    enderecoComercial: Endereco;
    enderecoAlternativo: Endereco;
    indicacao: string;
    profissao: string;
    rendaFaturamento: number;
    pep: string;
    descricaoPEP: string;
    comoConheceu: string;
    kycInfo: string;
    dtNascimento: Date;
    localNascimento: string;
    filiacao: string;
    estadoCivil: string;
    conjuge: string;
    sexo: string;
    dddTelefoneEndereco: string;
    telefoneEndereco: string;
    dataCadastro: Date;
    dataUltimaConsulta: Date;
    dataUltimoAcesso: Date;
    dataUltimaQuitacao: Date;
    documentos: Documento[];
}

interface ClientePOST {
    cpfCnpj: string;
    codigoCliente: string;
    nome: string;
    dddCelular: string;
    celular: string;
    email: string;
    rgIe: string;
    tipoPessoa: string;
    dtNascimento: Date;
    //senha

    enderecoResidencial: Endereco;

    enderecoAlternativo: Endereco;
}

export default function createCliente(nome: string, cpf:string, email: string, rg: string, dataNascimento: Date, ddd: string, celular: string, enderecoResidencial: Endereco, enderecoAlternativo: Endereco){
    const cliente : ClientePOST = {
        nome: nome,
        cpfCnpj: cpf,
        codigoCliente: cpf,
        email: email,
        rgIe: rg,
        dtNascimento: dataNascimento,
        dddCelular: ddd,
        celular: celular,
        tipoPessoa: "F",
        enderecoResidencial: enderecoResidencial,
        enderecoAlternativo: enderecoAlternativo
    }

    return cliente;

}
