import sograFrente1g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-1g-frente.png";
import sograFrente2g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-2g-frente.png";
import sograFrente5g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-5g-frente.png";
import sograFrente10g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-10g-frente.png";
import sograFrente25g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-25g-frente.png";
import sograFrente31g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-31g-frente.png";
import sograFrente50g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-50g-frente.png";
import sograVerso1g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-1g-verso.png";
import sograVerso2g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-2g-verso.png";
import sograVerso5g from "../../../assets/newImages/mockups/familia/sogra/sogra-ouro-5g-verso.png";

export const  sograMockups = {
    nome: "Sogra de Ouro",
    frente1g: sograFrente1g,
  
    frente2g: sograFrente2g,
  
    frente5g: sograFrente5g, 
    frente10g: sograFrente10g,
    frente25g:sograFrente25g,
    frente31g: sograFrente31g,
    frente50g: sograFrente50g, 
  
    verso1g: sograVerso1g,
    verso2g: sograVerso2g,
  
    verso5g: sograVerso5g,
    verso10g: sograVerso5g,
    verso25g: sograVerso5g,
    verso31g: sograVerso5g,
    verso50g: sograVerso5g,
  };