import React from "react";
import img from "../../../assets/newImages/vender-minha-moeda.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";

export default function VenderMinhaMoeda() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              VENDER MINHA MOEDA
            </p>
            <p>
              Escolher o melhor local para fazer a venda de suas moedas
              estrangeiras é, certamente, uma decisão importante e que pode
              fazer toda a diferença para os que estão buscando custo-benefício.
            </p>
            <p>
              Por isso, contar com um serviço rápido, prático e seguro como o da
              Ourominas (OM) é essencial para que tudo ocorra com simplicidade e
              tranquilidade.
            </p>
            <p>
              Na OM você conta com a máxima eficiência na venda de suas moedas
              estrangeiras. Comprarmos todos os tipos de moedas estrangeiras em
              espécie, que possuem circulação no mercado.
            </p>
            <p>
              Em operações que o cliente vende moedas para a OM, o cálculo é
              feito com base no valor de compra do câmbio turismo e deve ser
              descontado o IOF (Imposto sobre Operações Financeiras) de 0,38%.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              POR QUE A OUROMINAS É A MELHOR CASA DE CÂMBIO?
            </summary>
            <p className="mt-4">
              Sabemos que a maioria das pessoas fazem pesquisa de preço e
              cotação entre diferentes casas de câmbio. Agora, imagina só a
              vantagem de ver qualquer valor cobrado sendo coberto pela OM?
              Cobrimos o preço da concorrência através de documento
              comprobatório. Oferecemos o custo-benefício mais positivo.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              POR QUE VENDER PARA A OUROMINAS?
            </summary>
            <ol className="mt-4">
              <li>
                A empresa negocia o câmbio com spreads baixos e seguindo
                parâmetros de cotação tanto do mercado interno quanto do
                internacional, de forma a propiciar a possibilidade de o cliente
                vender a moeda estrangeira que deseja sempre no melhor preço do
                mercado. Na OM o cliente encontra uma excelente cotação.
              </li>
              <li>
                As cotações são transparentes e o cliente tem vantagens mesmo
                vendendo uma pequena quantidade de moeda estrangeira, sendo nota
                ou níquel que normalmente não são aceitas em outras empresas.
              </li>
              <li>
                A OM é uma empresa de tradição, possui mais de 40 anos de
                experiência no setor, conta com uma equipe bastante eficiente e
                profissional.
              </li>
              <li>
                A OM possui autorização do Banco Central e é credenciada na
                Comissão de Valores Mobiliários (CVM) para operar com câmbio,
                como toda DTVM.
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">COMO VENDER?</summary>
            <p className="mt-4">
              Para efetuarmos a compra de suas moedas estrangeiras é preciso
              fazer uma análise para comprovar a veracidade das notas ou das
              moedas.
            </p>
            <p>
              O cliente precisa ir até uma loja física própria ou autorizada,
              dessa forma a analise pode ser feita de forma imediata.
            </p>
            <p>
              Após acordo de valores, realize o seu cadastro com a OM. Você
              poderá realizar suas operações com um cadastro simplificado (para
              operações de pequeno valor) ou com um cadastro completo (para os
              demais casos).
            </p>
            <p>
              O pagamento será através de transferência bancária (Pix, TED ou DOC)
              após a confirmação da veracidade das notas ou das moedas e os
              dados da conta bancária devem ser do titular do contrato de
              câmbio.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">CADASTRO.</summary>
            <p className="mt-4">
              A OM, como instituição financeira e com fins de prevenção contra a
              lavagem de dinheiro e financiamento ao terrorismo (PLD/FT) é
              obrigada a seguir certos procedimentos cadastrais. A empresa
              protege as informações de seus clientes e não repassa a terceiros,
              salvo sob determinação do Banco Central do Brasil.
            </p>
            <ol>
              <li>
                Cadastro Simplificado: consiste no preenchimento da Ficha
                Cadastral com os dados básicos necessários (inclusos dados
                bancários) e cópia simples dos documentos: RG / CPF /
                Comprovante de Endereço.
              </li>
              <li>
                Cadastro Completo: é o procedimento padrão de cadastro da
                instituição. Consiste no preenchimento da Ficha Cadastral, cópia
                simples dos documentos: RG / CPF / Comprovante de Endereço e
                documentação e apresentar um comprovante de origem da moeda
                estrangeira.
              </li>
            </ol>
          </details>

          
        </section>
      </section>
    </article>
  );
}
