import brasiliaFrente1g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-1g-frente.png";
import brasiliaFrente2g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-2g-frente.png";
import brasiliaFrente5g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-5g-frente.png";
import brasiliaFrente10g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-10g-frente.png";
import brasiliaFrente25g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-25g-frente.png";
import brasiliaFrente31g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-31g-frente.png";
import brasiliaFrente50g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-50g-frente.png";
import brasiliaVerso1g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-1g-verso.png";
import brasiliaVerso2g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-2g-verso.png";
import brasiliaVerso5g from "../../../assets/newImages/mockups/brasil-turistico/brasilia/brasilia-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  brasiliaMockups = {
    nome: "Brasília",
    frente1g: brasiliaFrente1g,

    frente2g: brasiliaFrente2g,

    frente5g: brasiliaFrente5g,

    frente10g: brasiliaFrente10g,

    frente25g: brasiliaFrente25g,

    frente31g: brasiliaFrente31g,

    frente50g: brasiliaFrente50g,

    verso1g: brasiliaVerso1g,
    verso2g: brasiliaVerso2g,

    verso5g: brasiliaVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const brasiliaBR = [
    {
      name: "Brasília",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]