import enteadaFrente1g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-1g-frente.png";
import enteadaFrente2g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-2g-frente.png";
import enteadaFrente5g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-5g-frente.png";
import enteadaFrente10g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-10g-frente.png";
import enteadaFrente25g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-25g-frente.png";
import enteadaFrente31g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-31g-frente.png";
import enteadaFrente50g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-50g-frente.png";
import enteadaVerso1g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-1g-verso.png";
import enteadaVerso2g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-2g-verso.png";
import enteadaVerso5g from "../../../assets/newImages/mockups/familia/enteada/enteada-ouro-5g-verso.png";

export const  enteadaMockups = {
    nome: "Enteada de Ouro",
    frente1g: enteadaFrente1g,
  
    frente2g: enteadaFrente2g,
  
    frente5g: enteadaFrente5g, 
    frente10g: enteadaFrente10g,
    frente25g:enteadaFrente25g,
    frente31g: enteadaFrente31g,
    frente50g: enteadaFrente50g, 
  
    verso1g: enteadaVerso1g,
    verso2g: enteadaVerso2g,
  
    verso5g: enteadaVerso5g,
    verso10g: enteadaVerso5g,
    verso25g: enteadaVerso5g,
    verso31g: enteadaVerso5g,
    verso50g: enteadaVerso5g,
  };