import alemanhaFrente1g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-1g-frente.png";
import alemanhaFrente2g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-2g-frente.png";
import alemanhaFrente5g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-5g-frente.png";

import alemanhaVerso1g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-1g-verso.png";
import alemanhaVerso2g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-2g-verso.png";
import alemanhaVerso5g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-5g-verso.png";
import alemanhaVerso10g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-10g-verso.png";
import alemanhaVerso25g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-25g-verso.png";
import alemanhaVerso31g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-31g-verso.png";
import alemanhaVerso50g from "../../../assets/newImages/mockups/golden-cup/alemanha/alemanha-ouro-50g-verso.png";

export const  alemanhaMockups = {
    nome: "Alemanha",
    frente1g: alemanhaFrente1g,
  
    frente2g: alemanhaFrente2g,
  
    frente5g: alemanhaFrente5g,  

    frente10g: alemanhaFrente5g,
    frente25g:alemanhaFrente5g,
    frente31g: alemanhaFrente5g,
    frente50g: alemanhaFrente5g,
  
    verso1g: alemanhaVerso1g,
    verso2g: alemanhaVerso2g,
  
    verso5g: alemanhaVerso5g,

    verso10g: alemanhaVerso10g,
    verso25g: alemanhaVerso25g,
    verso31g: alemanhaVerso31g,
    verso50g: alemanhaVerso50g
  };

  