/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { getOuro } from "../../../services/api";
import {
  getCartaoPrePago,
  getMoedasEstrangeira,
  getRemessas,
} from "../../../services/cotacoes";
import { ReturnName } from "../../../utils/coins";

export const OuroContext = createContext();

export default function CotacoesProvider({ children }) {
  const [productsOuro, setProductsOuro] = useState([]);
  const [moedaEstrangeira, setMoedaEstrangeira] = useState([]);
  const [cartaoPrePago, setCartaoPrePago] = useState([]);
  const [remessas, setRemessas] = useState([]);
  const [error, setError] = useState();
  let errorCount = 0;
  useEffect(() => {
    setInterval(() => {
      addOuro();
    }, 100000);
  }, []);

  function addOuro() {
    if(errorCount === 3){
      return []
    }
    return getOuro()
      .then((items) => {
        setProductsOuro(
          items.filter(
            (item) =>
              item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (7g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)") ||
              item.codIso.includes("OURO BARRA (100g)") ||
              item.codIso.includes("OURO BARRA (250g)") ||
              item.codIso.includes("OURO BARRA (500g)") ||
              item.codIso.includes("OURO BARRA (1000g)")
          )
        );
      })
      .catch((err) => {
        errorCount += 1;
        console.log(errorCount)
        setError(err);
      });
  }
  function addMoedaEstrangeira() {
    return getMoedasEstrangeira()
      .then((resp) => {
        resp.forEach((item) => {
          item["nome"] = ReturnName(item.codIso);
        });
        setMoedaEstrangeira(resp);
      })
      .catch((err) => {
        return err;
      });
  }
  function addCartaoPrePago() {
    return getCartaoPrePago().then((resp) => {
      return setCartaoPrePago(() => {
        resp.forEach((item) => {
          item["nome"] = ReturnName(item.codIso);
        });
      });
    });
  }

  function addRemessas() {
    return getRemessas().then((resp) => {
      resp.forEach((item) => {
        item["nome"] = ReturnName(item.codIso);
      });
      setRemessas(resp);
    });
  }

  return (
    <OuroContext.Provider
      value={{
        productsOuro,
        addOuro,
        error,
        moedaEstrangeira,
        addMoedaEstrangeira,
        cartaoPrePago,
        addCartaoPrePago,
        remessas,
        addRemessas,
      }}
    >
      {children}
    </OuroContext.Provider>
  );
}
