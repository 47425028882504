import atenasFrente1g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-1g-frente.png";
import atenasFrente2g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-2g-frente.png";
import atenasFrente5g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-5g-frente.png";
import atenasFrente10g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-10g-frente.png";
import atenasFrente25g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-25g-frente.png";
import atenasFrente31g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-31g-frente.png";
import atenasFrente50g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-50g-frente.png";
import atenasVerso1g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-1g-verso.png";
import atenasVerso2g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-2g-verso.png";
import atenasVerso5g from "../../../assets/newImages/mockups/mundos/atenas/atenas-ouro-5g-verso.png";

export const  atenasMockups = {
    nome: "Atenas",
    frente1g: atenasFrente1g,
  
    frente2g: atenasFrente2g,
  
    frente5g: atenasFrente5g,
  
    frente10g: atenasFrente10g,
  
    frente25g: atenasFrente25g,
  
    frente31g: atenasFrente31g,
  
    frente50g: atenasFrente50g,
  
    verso1g: atenasVerso1g,
    verso2g: atenasVerso2g,
  
    verso5g: atenasVerso5g,
    verso10g: atenasVerso5g,
    verso25g: atenasVerso5g,
    verso31g: atenasVerso5g,
    verso50g: atenasVerso5g,
  };

 