import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import styled from "styled-components";

const SubSectionTitle = styled.h4`
  text-align: left;
  margin: 50px 0 20px 0;
`;

function UsageTermsAndPrivacyPolicyPage() {
  return (
    <section>
      <Container style={{ marginTop: "90px" }}>
        <div className="row justify-content-center text-center section-intro">
          <div className="col-12 col-md-9 col-lg-8">
            <h2 className="display-4" data-aos="fade-up" data-aos-delay="100">
              TERMOS DE USO E POLÍTICA DE PRIVACIDADE
            </h2>
          </div>
        </div>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col className="mb-30">
            <h3 className="small-title" style={{ textAlign: "center" }}>
              TERMOS DE USO E POLÍTICA DE PRIVACIDADE – OM DTVM Ltda.
              <span className="st-point">.</span>
            </h3>
            <div
              className="section-line mb-60 mb-xxs-30"
              style={{ textAlign: "left" }}
            >
              &nbsp;
            </div>

            <Container>
              <Col className="mb-30">
                <div className="section-text">
                  <p style={{ textAlign: "left" }}>
                    OM DTVM Ltda., com sede na Avenida Cidade Jardim, nº 400, 17
                    Andar – Jardim Paulistano, cidade de São Paulo, Estado de
                    São Paulo, CEP: 01454-000, inscrito no CNPJ/MF sob o
                    nº11.495.073/0001-18 (“OUROMINAS”), entendemos como sensível
                    e relevante os registros eletrônicos e dados pessoais
                    deixados por você (“Usuário”) na utilização dos diversos
                    sites e serviços (“Páginas”) da OUROMINAS, servindo a
                    presente Política de Privacidade (“Política”) para regular,
                    de forma simples, transparente e objetiva, quais dados e
                    informações serão obtidos, assim como quando os mesmos
                    poderão ser utilizados.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A presente Política se aplica às Páginas relacionadas a
                    quaisquer das marcas da OUROMINAS, entendendo como tal todas
                    aquelas elencadas no site oficial da OUROMINAS, no endereço
                    https://www.ourominas.com, englobando todos os seus
                    produtos.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    ATENÇÃO: O USUÁRIO, AO ACESSAR OS SERVIÇOS E CONTEÚDOS DO
                    SITE E/OU SE CADASTRAR NO APLICATIVO, ESTARÁ ADERINDO E
                    ACEITANDO AUTOMÁTICA E INTEGRALMENTE OS TERMOS E CONDIÇÕES
                    DE USO E POLÍTICA DE PRIVACIDADE ABAIXO DESCRITOS. A
                    OUROMINAS SE RESERVA AO DIREITO DE ALTERAR, A QUALQUER
                    MOMENTO, OS TERMOS E CONDIÇÕES DE USO, SEM QUALQUER AVISO,
                    DEVENDO PARA TANTO INCLUIR A NOVA VERSÃO NO APLICATIVO OU NO
                    SITE WWW.OUROMINAS.COM EM CASO DE DISCORDÂNCIA, FICA
                    RESERVADO AO USUÁRIO O CANCELAMENTO DO SERVIÇO. .
                  </p>
                  <p style={{ textAlign: "left" }}>
                    AS ALTERAÇÕES SERÃO APLICÁVEIS A PARTIR DA DATA DE
                    VEICULAÇÃO DA NOVA VERSÃO NO SITE OUROMINAS E/OU NO
                    APLICATIVO. O USO CONTÍNUO DO(S) SITE(S) E APLICATIVO(S)
                    PELO USUÁRIO, APÓS A ALTERAÇÃO DO TERMO E CONDIÇÕES DE USO,
                    SIGNIFICARÁ A SUA CONCORDÂNCIA COM TAIS MODIFICAÇÕES.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    RECOMENDAMOS A LEITURA ATENTA DESTES TERMOS E CONDIÇÕES DE
                    USO E POLÍTICA DE PRIVACIDADE E TODOS OS OUTROS AVISOS QUE
                    POSSAM APARECER DURANTE A NAVEGAÇÃO NO SITE E/OU APLICATIVO
                    OU COMUNICAÇÕES ENVIADAS AO ENDEREÇO ELETRÔNICO CADASTRADO.
                  </p>
                  <p style={{ textAlign: "left" }}></p>
                  <p style={{ textAlign: "left" }}></p>
                  <p className="MsoNormal" style={{ textAlign: "left" }}>
                    Para melhor ilustrar a forma como realizamos o tratamento de
                    dados, apresentamos um resumo de nossa Política de
                    Privacidade e Proteção de Dados Pessoais (“Política”):
                  </p>

                  <SubSectionTitle>1 - QUADRO DE RESUMO</SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    <strong>Agente de tratamento</strong> - OUROMINAS Ltda.
                    CNPJ: 11.495.073/0001-18 Endereço: Avenida Cidade Jardim, nº
                    400, 17 Andar – Jardim Paulistano, cidade de São Paulo,
                    Estado de São Paulo, CEP: 01454-000
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Papel no tratamento</strong> - Predominantemente
                    controladora
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Natureza dos dados tratados</strong> - Dados
                    pessoais fornecidos pelo usuário e/ou coletados
                    automaticamente.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Finalidade como controladora</strong> -&nbsp; •
                    Utilizar dados pessoais para fins publicitários, como para o
                    envio de informações de marcas, produtos, promoções e
                    descontos da OUROMINAS, para divulgação de eventos e de
                    programas de fidelidade ou para a realização de pesquisa de
                    satisfação de compra. Base legal: • Consentimento do
                    titular.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Compartilhamento</strong> -&nbsp; Operadores e
                    fornecedores de serviços essenciais para nossas atividades.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Proteção de Dados</strong> -&nbsp; Medidas de
                    segurança, técnicas e administrativas adequadas.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Seus direitos</strong> - Confirmação da existência
                    de tratamento, acesso, correção, etc
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>
                  <p style={{ textAlign: "left" }}>
                    Esta Política poderá ser atualizada, a qualquer tempo, pela
                    OUROMINAS, mediante aviso no site e/ou por e-mail, se o
                    Usuário tiver optado por receber comunicações da OUROMINAS.
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>

                  <SubSectionTitle>2 - QUAIS DADOS UTILIZAMOS</SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS poderá coletar as informações inseridas
                    ativamente pelo Usuário no momento do cadastro e, ainda,
                    informações coletadas automaticamente quando da utilização
                    das páginas e da rede, como, por exemplo, identificação do
                    estabelecimento comercial utilizado, IP com data e hora da
                    conexão, entre outras.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Há, assim, o tratamento de dois tipos de dados
                    pessoais:&nbsp;
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (i) aqueles fornecidos pelo próprio Usuário; e&nbsp;
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (ii) aqueles coletados automaticamente.&nbsp;
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (i) Informações fornecidas pelo Usuário: A OUROMINAS coleta
                    todas as informações inseridas ativamente pelo Usuário nas
                    Páginas, tais como RG, CPF, nome completo, e-mail, telefone,
                    gênero, data de nascimento, cidade e estado, quando do
                    preenchimento de formulários nas Páginas pelo Usuário. A
                    OUROMINAS fará uso dessas informações para promover a
                    efetivação de operações contratadas e a divulgação de
                    material publicitário, bem como para realização de pesquisa
                    de satisfação.
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (ii) Dados coletados automaticamente:
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS também coleta uma série de informações de modo
                    automático, tais como: características do dispositivo de
                    acesso, do navegador, IP (com data e hora), origem do IP,
                    informações sobre cliques, páginas acessadas, as páginas
                    seguintes acessadas após a saída das Páginas, ou qualquer
                    termo de procura digitado nos sites ou em referência a
                    estes, dentre outros. Para tal coleta, a OUROMINAS fará uso
                    de algumas tecnologias padrões, como cookies, API e ID da
                    sessão que são utilizadas com o propósito de melhorar a
                    experiência de navegação do Usuário nas Páginas, de acordo
                    com seus hábitos e suas preferências.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    É possível desabilitar, por meio das configurações de seu
                    navegador de internet, a coleta automática de informações
                    por meio de algumas tecnologias, como cookies e caches, bem
                    como em nosso próprio website, especificamente quanto aos
                    cookies. No entanto, o Usuário deve estar&nbsp; ciente de
                    que, se desabilitadas estas tecnologias, alguns recursos
                    oferecidos pelo site, que dependem do tratamento dos
                    referidos dados, poderão não funcionar corretamente.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Além disso, a OUROMINAS poderá promover pesquisas de
                    satisfação e de preferência durante sua utilização da Rede
                    Wi-Fi, coletando estas informações para melhorar cada dia
                    mais nossos serviços, para compreender melhor as suas
                    preferências e avaliar a qualidade e suas impressões sobre
                    nossos produtos, bem como para fins estatísticos e
                    publicitários. Para esta última hipótese, a OUROMINAS
                    disponibiliza funcionalidades para que o Usuário autorize
                    (ou não) o tratamento de seus dados, sendo certa que tal
                    aceitação não é condição para cadastramento do Usuário.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    As informações coletadas poderão ser compartilhadas pela
                    OUROMINAS com:&nbsp;
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (i) Empresas do Grupo Ourominas, quando forem necessárias
                    para a adequada prestação dos serviços objeto de suas
                    atividades;
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (ii) para proteção dos interesses da OUROMINAS em qualquer
                    tipo de conflito;
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    (iii)&nbsp;mediante decisão judicial ou requisição de
                    autoridade competente.
                  </p>
                  <p style={{ paddingLeft: "30px", textAlign: "left" }}>
                    &nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Ainda, esclarecemos que suas informações também poderão ser
                    compartilhadas com empresas provedoras de infraestrutura
                    tecnológica e operacional necessária para as atividades da
                    OUROMINAS, como intermediadoras de pagamento e provedores de
                    serviço de armazenamento de informações.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    O Usuário poderá acessar, atualizar e adicionar seus dados,
                    bem como poderá solicitar a exclusão dos seus dados
                    coletados pela OUROMINAS (Item 9), Iremos nos esforçar para
                    respondê-lo no menor tempo possível, respeitando-se os
                    prazos de guarda estabelecidos pela legislação.
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>

                  <SubSectionTitle>
                    3 - COMO UTILIZAMOS OS DADOS
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    As informações coletadas pela OUROMINAS têm como finalidade
                    o estabelecimento de vínculo contratual ou a gestão,
                    administração, prestação, ampliação e melhoramento das
                    Páginas ao Usuário, adequando-as às suas preferências e aos
                    seus gostos, bem como a criação de novos serviços e produtos
                    a serem oferecidos aos Usuários.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    As informações coletadas poderão, ainda, ser utilizadas para
                    fins publicitários, como para o envio de informações de
                    marcas, produtos, promoções e descontos da OUROMINAS, bem
                    como a divulgação de eventos e de programas de fidelidade,
                    ou para a realização de pesquisa de satisfação de compra,
                    mediante o consentimento do titular para tal.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS poderá centralizar as informações coletadas nas
                    Páginas, as quais poderão ser utilizadas em Páginas e
                    serviços relacionados a todas as marcas da OUROMINAS,
                    respeitadas as finalidades ora dispostas e o consentimento
                    do Usuário.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Caso não deseje mais receber informativos publicitários da
                    OUROMINAS, a qualquer momento o Usuário pode contatar a
                    OUROMINAS (Item 9)
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Base Legal</strong>: Nestes casos, o tratamento de
                    dados é autorizado pelo inciso I do artigo 7º da Lei nº
                    13.709/2018, a Lei Geral de Proteção de Dados (“Lei Geral de
                    Proteção de Dados – LGPD”).&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>

                  <SubSectionTitle>
                    4 - COMO UTILIZAMOS OS&nbsp;COOKIES
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    Cookies são arquivos ou informações que podem ser
                    armazenadas em seus dispositivos, quando você visita as
                    Páginas da OUROMINAS. Geralmente, um cookie contém o nome do
                    site que o originou, seu tempo de vida e um valor, que é
                    gerado aleatoriamente.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS utiliza cookies para facilitar o uso e melhor
                    adaptar as Páginas aos seus interesses e necessidades, bem
                    como para compilarmos informações sobre a utilização de
                    nossos sites e serviços, auxiliando a melhorar suas
                    estruturas e seus conteúdos. Os cookies também podem ser
                    utilizados para acelerar suas atividades e experiências
                    futuras nas Páginas.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Após o Usuário consentir para a utilização de cookies,
                    quando do uso das Páginas da OUROMINAS, a OUROMINAS
                    armazenará um cookie em seu dispositivo para lembrar-se
                    disso na próxima sessão.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A qualquer momento, o Usuário poderá revogar seu
                    consentimento quanto aos cookies, pelo que deverá apagar os
                    cookies das Páginas da OUROMINAS utilizando as configurações
                    de seu navegador de preferência. Para mais informações sobre
                    como proceder em relação à gestão dos cookies nos
                    navegadores:&nbsp;
                  </p>
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      Internet Explorer:&nbsp;
                      <a href="https://support.microsoft.com/pt-br/help/260971/description-of-cookies">
                        https://support.microsoft.com/pt-br/help/260971/description-of-cookies&nbsp;
                      </a>
                    </li>
                    <li>
                      Mozilla Firefox:&nbsp;
                      <a href="https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sitesarmazenam-no-computador">
                        https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sitesarmazenam-no-computador&nbsp;
                      </a>
                    </li>
                    <li>
                      Google Chrome:&nbsp;
                      <a href="https://policies.google.com/technologies/managing?hl=pt-BR">
                        https://policies.google.com/technologies/managing?hl=pt-BR&nbsp;
                      </a>
                    </li>
                    <li>
                      Safari:{" "}
                      <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
                        https://support.apple.com/pt-br/guide/safari/sfri11471/mac
                      </a>
                    </li>
                  </ul>
                  <p style={{ textAlign: "left" }}>
                    Por fim, lembramos que, caso o Usuário não aceite alguns
                    cookies das Páginas da OUROMINAS, certos serviços poderão
                    não funcionar de maneira ideal.
                  </p>

                  <SubSectionTitle>
                    1.1 5 - COM QUEM COMPARTILHAREMOS OS DADOS
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS poderá compartilhar as informações coletadas por
                    meio das Páginas, nas seguintes hipóteses:
                  </p>
                  <p style={{ textAlign: "left" }}>
                    i. Com outras empresas do Grupo OUROMINAS , constituídas ou
                    atuantes em qualquer país, que se comprometem a utilizar as
                    informações para os mesmos fins indicados na presente
                    Política;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    ii. Com empresas parceiras, no desenvolvimento das
                    atividades comerciais da OUROMINAS; 3
                  </p>
                  <p style={{ textAlign: "left" }}>
                    iii. Para proteção dos interesses da OUROMINAS em qualquer
                    tipo de conflito, incluindo ações judiciais;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    iv.. Mediante ordem judicial ou pelo requerimento de
                    autoridades administrativas que detenham competência legal
                    para sua requisição.
                  </p>

                  <SubSectionTitle>
                    6 - COMO MANTEMOS OS DADOS SEGUROS
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS armazenará as informações coletadas em suas
                    Páginas em servidores próprios ou por ela contratados.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS utiliza os meios razoáveis de mercado e
                    legalmente requeridos para preservar a privacidade dos dados
                    coletados em suas Páginas. Desta forma, adota as seguintes
                    precauções, em observância às diretrizes sobre padrões de
                    segurança estabelecidas no Decreto nº 8.771/2016, tais como:
                  </p>
                  <p style={{ textAlign: "left" }}>
                    i. A OUROMINAS utiliza os métodos padrão e de mercado para
                    criptografar e anonimizar os dados coletados;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    ii. A OUROMINAS possui proteção contra acesso não autorizado
                    a seus sistemas;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    iii. A OUROMINAS somente autoriza o acesso de pessoas
                    previamente estabelecidas ao local onde são armazenadas as
                    informações coletadas;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    iv. Aqueles que entrarem em contato com as informações
                    deverão se comprometer a manter sigilo absoluto. A quebra do
                    sigilo acarretará responsabilidade civil e o responsável
                    será responsabilizado nos moldes da legislação brasileira;
                    e&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    v. Manutenção do inventário indicando momento, duração,
                    identidade do funcionário, ou do responsável pelo acesso e o
                    arquivo objeto, com base nos registros de conexão e de
                    acesso a aplicações, conforme determinado no artigo 13 do
                    Decreto nº 8.771/2016.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS adota os melhores esforços, no sentido de
                    preservar a privacidade dos dados dos Usuários.{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Entretanto, nenhum site é totalmente seguro e a OUROMINAS
                      não pode garantir integralmente que todas as informações
                      que trafegam nas Páginas não sejam alvo de acessos não
                      autorizados perpetrados por meio de métodos desenvolvidos
                      para obter informações de forma indevida. Por esse motivo,
                      nós incentivamos os Usuários a tomar as medidas
                      apropriadas para se proteger, como, por exemplo, mantendo
                      confidenciais todos os nomes de usuário e senhas.
                    </span>
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>
                  <p style={{ textAlign: "left" }}>
                    <strong>
                      Cuidados que o usuário deve tomar. É muito importante que
                      você proteja seus Dados contra acesso não autorizado ao
                      seu computador, conta ou senha, além de se certificar de
                      sempre clicar em “sair” ao encerrar sua navegação em um
                      computador compartilhado. Também é muito importante que
                      você saiba que nós nunca enviaremos mensagens eletrônicas
                      solicitando confirmação de dados ou com anexos que possam
                      ser executados (extensões: .exe, .com, entre outros) ou
                      ainda links para eventuais downloads.
                    </strong>
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>

                  <SubSectionTitle>
                    7 - RETENÇÃO DAS INFORMAÇÕES COLETADAS&nbsp;
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    As informações coletadas pela OUROMINAS por meio das Páginas
                    serão automaticamente excluídas de seus servidores quando
                    deixarem de ser úteis para os fins para os quais foram
                    coletadas, ou quando o usuário solicitar a eliminação de
                    seus dados pessoais.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sem prejuízo, as informações poderão ser conservadas para
                    cumprimento de obrigação legal ou regulatória, transferência
                    à terceiro – desde que respeitados os requisitos de
                    tratamento de dados – e uso exclusivo da OUROMINAS, vedado
                    seu acesso por terceiro, desde que anonimizadas.
                  </p>

                  <SubSectionTitle>8 - SEUS DIREITOS&nbsp;</SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    Em cumprimento à regulamentação aplicável, no que diz
                    respeito ao tratamento de dados pessoais, a OUROMINAS
                    respeita e garante ao Usuário, a possibilidade de
                    apresentação de solicitações baseadas nos seguintes
                    direitos:&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    i) a confirmação da existência de tratamento;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>ii) o acesso aos dados;</p>
                  <p style={{ textAlign: "left" }}>
                    iii) a correção de dados incompletos, inexatos ou
                    desatualizados;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    iv) a anonimização, bloqueio ou eliminação de dados
                    desnecessários, excessivos ou tratados em
                    desconformidade;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    v) a portabilidade de seus dados a outro fornecedor de
                    serviço ou produto, mediante requisição expressa pelo
                    Usuário;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    vi) a eliminação dos dados tratados com consentimento do
                    Usuário;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    vii) a obtenção de informações sobre as entidades públicas
                    ou privadas com as quais a OUROMINAS compartilhou seus
                    dados;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    viii) a informação sobre a possibilidade de não fornecer o
                    seu consentimento, bem como de ser informado sobre as
                    consequências, em caso de negativa;&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    ix) a revogação do consentimento.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Parte destes direitos poderá ser exercida diretamente pelo
                    Usuário, a partir da gestão de informações sobre a conta, na
                    página seus dados. As demais alterações dependerão do envio
                    de solicitação para posterior avaliação e adoção de demais
                    providências pela OUROMINAS.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Caso necessite de qualquer auxílio para exercer seus
                    direitos, o Usuário pode entrar em contato com a OUROMINAS
                    (Item 9) , conforme orientações nesta Política.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>
                    <span style={{ textDecoration: "underline" }}>
                      O Usuário fica ciente de que a exclusão das informações
                      essenciais para gestão de sua conta junto à OUROMINAS
                      implicará no término de seu cadastro, com consequente
                      cancelamento dos serviços então prestados.
                    </span>
                  </p>
                  <p style={{ textAlign: "left" }}>
                    A OUROMINAS empreenderá todos os esforços para atender tais
                    pedidos no menor espaço de tempo possível. No entanto, mesmo
                    em caso de requisição de exclusão, será respeitado o prazo
                    de armazenamento mínimo de informações de usuários de
                    aplicações de Internet, determinado pela legislação
                    brasileira.
                  </p>

                  <SubSectionTitle>
                    9 - ENCARREGADO PELO TRATAMENTO DE DADOS (DPO)&nbsp;
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    Se o USUÁRIO em caso de qualquer dúvida em relação às
                    disposições desta Política e de qualquer outro documento que
                    verse sobre a sua Privacidade nas relações estabelecidas com
                    a OUROMINAS, entre em contato com nosso Departamento de
                    Proteção de Dados e fale com nosso encarregado de dados
                    (DPO) através do e-mail dpo@ourominas.com. As respostas ao
                    contato serão enviadas entre segunda e sexta-feira (exceto
                    feriados), no horário comercial bancário.
                  </p>

                  <SubSectionTitle>
                    10 - LEGISLAÇÃO E FORO&nbsp;
                  </SubSectionTitle>
                  <p style={{ textAlign: "left" }}>
                    Esta Política será regida, interpretada e executada de
                    acordo com as Leis da República Federativa do Brasil,
                    especialmente a Lei nº 13.709/2018, independentemente das
                    Leis de outros estados ou Países, sendo competente o foro de
                    domicílio do Usuário para dirimir qualquer dúvida decorrente
                    deste documento.&nbsp;
                  </p>
                  <p style={{ textAlign: "left" }}>OM D.T.V.M. Ltda.&nbsp;</p>
                  <p style={{ textAlign: "left" }}>
                    <strong>Horário de atendimento</strong>: Segunda a Sexta das
                    09:00 as 18:00
                  </p>
                  <p style={{ textAlign: "left" }}>&nbsp;</p>
                </div>
              </Col>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default UsageTermsAndPrivacyPolicyPage;
