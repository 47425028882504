import diamanteFrente1g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-1g-frente.png";
import diamanteFrente2g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-2g-frente.png";
import diamanteFrente5g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-5g-frente.png";
import diamanteFrente10g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-10g-frente.png";
import diamanteFrente25g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-25g-frente.png";
import diamanteFrente31g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-31g-frente.png";
import diamanteFrente50g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-50g-frente.png";
import diamanteVerso1g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-1g-verso.png";
import diamanteVerso2g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-2g-verso.png";
import diamanteVerso5g from "../../../assets/newImages/mockups/pedras-preciosas/diamante/diamante-ouro-5g-verso.png";

export const  diamanteMockups = {
    nome: "Diamante",
    frente1g: diamanteFrente1g,
  
    frente2g: diamanteFrente2g,
  
    frente5g: diamanteFrente5g,
  
    frente10g: diamanteFrente10g,
  
    frente25g: diamanteFrente25g,
  
    frente31g: diamanteFrente31g,
  
    frente50g: diamanteFrente50g,
  
    verso1g: diamanteVerso1g,
    verso2g: diamanteVerso2g,
  
    verso5g: diamanteVerso5g,
    verso10g: diamanteVerso5g,
    verso25g: diamanteVerso5g,
    verso31g: diamanteVerso5g,
    verso50g: diamanteVerso5g,
  };

 