import genroFrente1g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-1g-frente.png";
import genroFrente2g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-2g-frente.png";
import genroFrente5g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-5g-frente.png";
import genroFrente10g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-10g-frente.png";
import genroFrente25g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-25g-frente.png";
import genroFrente31g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-31g-frente.png";
import genroFrente50g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-50g-frente.png";
import genroVerso1g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-1g-verso.png";
import genroVerso2g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-2g-verso.png";
import genroVerso5g from "../../../assets/newImages/mockups/familia/genro/genro-ouro-5g-verso.png";

export const  genroMockups = {
    nome: "Genro de Ouro",
    frente1g: genroFrente1g,
  
    frente2g: genroFrente2g,
  
    frente5g: genroFrente5g, 
    frente10g: genroFrente10g,
    frente25g:genroFrente25g,
    frente31g: genroFrente31g,
    frente50g: genroFrente50g, 
  
    verso1g: genroVerso1g,
    verso2g: genroVerso2g,
  
    verso5g: genroVerso5g,
    verso10g: genroVerso5g,
    verso25g: genroVerso5g,
    verso31g: genroVerso5g,
    verso50g: genroVerso5g,
  };