import React from 'react';
import styled from 'styled-components';
import '../../components/section/cards/cards.css';
import icoCurrencies from '../../assets/images/professional/ico-currencies.png';
import icoPrepaidCards from '../../assets/images/professional/ico-prepaid-cards.png';
import icoMoneygram from '../../assets/images/professional/ico-moneygram.png';
import icoGlobal from '../../assets/images/professional/ico-global.png';
import icoContaGlobal from '../../assets/images/professional/ico-global.png';
import { CardContaGlobal } from '../../components/section/cards/cardsContaGlobal';

export default function SectionCambio() {
  const itemsDescription = [
    {
      productName: 'Moedas Estrangeiras',
      src: icoCurrencies,
      href: 'moedas-estrangeiras',
    },
    {
      productName: 'Conta Global Internacional',
      src: icoContaGlobal,
      href: 'https://om.ourominas.com/lp-ourominas-nova',
      external: true,
    },
    {
      productName: 'Cartão Pré-pago',
      src: icoPrepaidCards,
      href: 'cartao-pre-pago',
    },
    {
      productName: 'Remessa Internacional',
      src: icoGlobal,
      href: 'encaminhamento/remessa',
    },
    {
      productName: 'Moneygram',
      src: icoMoneygram,
      href: 'encaminhamento/moneygram',
    },
  ];

  return (
    <SectionContainer>
      <SectionIntroContainer>
        <h2 className="display-4">
          O QUE VOCÊ PRECISA <br /> HOJE?
        </h2>
      </SectionIntroContainer>

      <ul className="feature-list feature-list-sm row wv-100">
        {itemsDescription.map(({ href, src, productName, external }) => (
          <li
            className="col-md-4 col-sm-6 col-12 card-li mt-4"
            key={productName}
          >
            {external ? (
              <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
                className="aCard card text-center"
              >
                <div className="card-body">
                  <img
                    src={src}
                    style={{ height: 'auto', width: 100 }}
                    alt={`ícone do ${productName}`}
                  />
                  <h6 className="text-decoration-none title-decorative mt-2 text-muted text-transform-uppercase">
                    {productName}
                  </h6>
                </div>
              </a>
            ) : (
              <CardContaGlobal
                href={href}
                src={src}
                productName={productName}
              />
            )}
          </li>
        ))}
      </ul>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-color: #f8f9fa;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 50px;
`;
