import outonoFrente1g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-1g-frente.png";
import outonoFrente2g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-2g-frente.png";
import outonoFrente5g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-5g-frente.png";
import outonoVerso1g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-1g-verso.png";
import outonoVerso2g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-2g-verso.png";
import outonoVerso5g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-5g-verso.png";
import outonoVerso10g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-10g-verso.png";
import outonoVerso25g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-25g-verso.png";
import outonoVerso31g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-31g-verso.png";

import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g-outono.png";
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g-outono.png";
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g-outono.png";

import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g-outono.png";
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g-outono.png";

import outonoVerso50g from "../../../assets/newImages/mockups/estacoes-ano/outono/outono-50g-verso.png";

export const  outonoMockups = {
    nome: "Outono",
    frente1g: outonoFrente1g,
  
    frente2g: outonoFrente2g,
  
    frente5g: outonoFrente5g,  
  
    verso1g: outonoVerso1g,
    verso2g: outonoVerso2g,
  
    verso5g: outonoVerso5g,
    verso10g: outonoVerso10g,
    verso25g: outonoVerso25g,
    verso31g: outonoVerso31g,
    verso50g: outonoVerso50g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g
  };

  export const outono = [
    {
      name: "Outono",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]