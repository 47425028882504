import styled from "@emotion/styled";

export const ButtonMain = styled.button`
    color: var(--primary-white);
    background-color: var(--green);
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 45px;

      `