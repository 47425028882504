import artistaFrente1g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-1g-frente.png";
import artistaFrente2g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-2g-frente.png";
import artistaFrente5g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-5g-frente.png";
import artistaFrente10g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-10g-frente.png";
import artistaFrente25g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-25g-frente.png";
import artistaFrente31g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-31g-frente.png";
import artistaFrente50g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-50g-frente.png";
import artistaVerso1g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-1g-verso.png";
import artistaVerso2g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-2g-verso.png";
import artistaVerso5g from "../../../assets/newImages/mockups/profissoes/artista/artista-ouro-5g-verso.png";

export const  artistaMockups = {
    nome: "Artista de Ouro",
    frente1g: artistaFrente1g,
  
    frente2g: artistaFrente2g,
  
    frente5g: artistaFrente5g,
  
    frente10g: artistaFrente10g,
  
    frente25g: artistaFrente25g,
  
    frente31g: artistaFrente31g,
  
    frente50g: artistaFrente50g,
  
    verso1g: artistaVerso1g,
    verso2g: artistaVerso2g,
  
    verso5g: artistaVerso5g,
    verso10g: artistaVerso5g,
    verso25g: artistaVerso5g,
    verso31g: artistaVerso5g,
    verso50g: artistaVerso5g,
  };

 