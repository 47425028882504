
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { Cliente } from "../../../models/modules/Cliente/Cliente";
import { apiTravel } from "../travel";
import { setClienteCodigo } from "../../../utils/localStorage";
import { CEP } from "../../../models/modules/Cliente/Cep";
import { setClienteCPF } from "./clienteObservable";
  export function postCliente(data: Cliente): Promise<any>{
    return apiTravel.post("/cliente/inserir", data).then((resp) =>  resp.data).catch(e => {
      AlertNotify({message: 'Cliente já cadastrado!', type:'warning'})
      return Promise.reject(e);
    });
  }

  export function putCliente(data: Cliente): Promise<any>{
    return apiTravel.post("/cliente/editar", data).then((resp) =>  resp.data).catch(e => {
      console.log(e)
      AlertNotify({message: 'Ocorreu algum erro ao atualizar as informações.', type:'warning'})
      return Promise.reject(e);
    });
  }

  export function getClienteByCPF(cpf: string): Promise<Cliente>{
    return apiTravel.get(`/cliente/obter-por-cpf-cnpj/${cpf}`).then((resp) =>  {
      if(resp.data.data){
        return resp.data.data;
      }
      return Promise.reject();
    }).catch(e => e);
  }

  export function getConsultaClienteByCPF(cpf: string): Promise<Cliente>{
    return apiTravel.get(`/cliente/consultar-situacao-novo-registro/${cpf}`).then((resp) =>  {
      if(resp.data.data){
        return resp.data.data;
      }
      return Promise.reject();
    });
  }

  export function getClienteByCodigo(codigo: string): Promise<Cliente>{
    return apiTravel.get(`/cliente/obter/${codigo}`).then((resp): Cliente =>  resp.data.data);
  }

  export function putClienteSenha(cpf: string, senha: string): Promise<any>{
    return apiTravel.put(`/cliente/atualiza-senha/${cpf}/${senha}`).then((resp) =>  resp.data);
  }

  export function loginCliente(cpf, senha): Promise<Cliente>{
    return apiTravel
      .get(`/cliente/login-cliente/${cpf}/${senha}`)
      .then((resp): Cliente  => {
        const data = resp.data.data as Cliente;
        setClienteCodigo(data.codigoCliente);
        setClienteCPF(data.cpfCnpj);
        return resp.data;
      })
      .catch(e => {
        if(e.response.status === 404){
          AlertNotify({message: 'Login ou senha inválidos!', type:'warning'})
        }
        return Promise.reject(e);
      });
  }

  export function getCEP(cep: string): Promise<CEP> {
    return apiTravel.get(`/cliente/obter-endereco-cep/${cep}`).then((resp) => {
      const data = resp.data.data;
      if(data){
        return data;
      }
      AlertNotify({message: 'CEP não encontrado!', type: 'warning'})
      return Promise.reject('CEP não encontrado!');

    });
  }
