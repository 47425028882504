/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import NumberUtility from "../../../../utils/NumberUtility";
import CurrencyInput from "../../../inputs/CurrencyInputs";
import FormButton from "../../../forms/formButton/formButton";
import { QuoteInfoItemOuroDigital } from "../../../quoteInfo/quoteInfo";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../../context/shopp/cartShoppService";
import { maskNumberInt } from "../../../../utils/regex";
import "./simulator.css";
import { Colections, returnModel, ViewCarrouselGold } from "./utils";
import load from "../../../../assets/spinner.gif";
import { ReturnGrammageGold } from "../../../../utils/coins";
import { LinkClean } from "../../../styled/links";

export default function GoldSimulator({ data, error }) {
  const navigate = useNavigate();
  const [coin, setCoin] = useState();
  const [quant, setquant] = useState(1);
  const [valueRoyal, setValueRoyal] = useState(null);
  const [modelList, setModelList] = useState([]);
  const [colection, setColection] = useState();
  const { name } = useParams();
  const [model, setModel] = useState();

  const { toogleCArt, addProducts } = useContext(CartContext);
  const [type, setType] = useState("Comprar");
  const [modelUnique, setModelUnique] = useState("");
  let cod = useRef(null);
  const text1g = {
    obsCompra:
      "Esta cotação é válida para compras online no site da Ourominas. Central de Vendas, Lojas Próprias e Correspondentes autorizados, podem praticar outros valores.",
    obsCompra1g:
      " A cotação das barras de 1 e 2 gramas possuem o valor mais elevado que as demais devido ser um produto exclusivo Ourominas, sendo a única DTVM a comercializar barras de 1 e 2g no formato de barra padrão, outras empresas comercializam estas gramaturas em formato laminado.",
    obsVenda:
      "Nesta página você conseguirá simular a venda de suas barras de ouro, porém não conseguirá finalizar a venda. Para efetuar a venda é necessária uma análise do ouro para comprovar a veracidade, por favor, se dirija a uma loja física, própria ou autorizada, para concluir a sua venda de forma presencial. Esta cotação pode sofrer alteração nas lojas físicas, sendo elas, próprias ou correspondentes autorizados.",
      obsDesconto: "Campanha Black Week: desconto de 5% para barras de ouro a partir de 1 grama até 1 quilo de ouro. Válido pelo período do dia 20/11/2023 até o dia 24/11/2023."
  };
  const [infoItems, setInfoItems] = useState({});

  useEffect(() => {
    setquant(1);
  }, [name]);
  useEffect(() => {
    function setCoin(){
      if (coin) {
        selectCoin();
      }
      setValueRoyal(quant*coin);

    }
    setCoin();
   }, [quant, coin, valueRoyal]);

  useEffect(() => {
    setInfoItems({});
    setCoin(0);
  }, [type]);
  useEffect(() => {
    setModelList(() => returnModel(colection));
    if (colection === null) {
    }
  }, [colection]);

  useEffect(() => {
    if (modelUnique) {
      const selectedIndex = document.getElementById("selectG");
      cod.current = selectedIndex
    }
  }, [modelUnique, data]);
  async function selectCoin() {
    cod = document.getElementById("selectG");
    cod = cod.options[cod.selectedIndex].id;
    data.forEach((item) => {
      if (type === "Comprar") {
        if (item.codIso === cod) {
          setInfoItems(item);
        }
      }
      if (type === "Vender") {
        if (item.codIso === cod) {
          setInfoItems(item);
        }
      }
    });
  }

  async function AddItemsCoin() {
    let product = [];
    product = infoItems;
    product["tipo"] = name;
    product["opcao"] = type;
    product["quantidade"] = quant;
    product["nome"] = infoItems.codIso;
    product["valor"] = infoItems.calculoGramaturaVenda;
    if (coin) {
      await addProducts(product);
      toogleCArt("open");
    }
  }
  return (
    <Container>
      <ShopDescription>Loja Online</ShopDescription>
      <ContainerInputs>

        <ContainerCoinContent>
          <SelectCoin value="existentes">
            <LineLoading>
              {data?.length === 0 && !error && <Loading src={load} />}
              {error && <Error>Dados não disponíveis no momento. </Error>}
            </LineLoading>
            <ContentRow className="d-flex flex-column">
              {name === "existentes" && (
                <>
                  <InputContainer type="mb">
                    <InputLabel>Selecione a Coleção</InputLabel>
                    <GrammageSelect
                      onChange={(e) => setColection(e.target.value)}
                    >
                      <option value={0}>Selecione</option>

                      {Colections.map((item, i) => {
                        return (
                          <option key={i++} value={item.value}>
                            {item.name}
                          </option>
                        );
                      })}
                    </GrammageSelect>
                  </InputContainer>
                  <InputContainer type="mb">
                    <InputLabel>Selecione o Modelo</InputLabel>
                    <GrammageSelect
                      onChange={(e) => setModelUnique(e.target.value)}
                    >
                      <option value={0}>Selecione</option>
                      {modelList &&
                        modelList?.map((item, i) => {
                          return (
                            <option key={i++} value={item.value}>
                              {item.name}
                            </option>
                          );
                        })}
                    </GrammageSelect>
                  </InputContainer>
                </>
              )}
              {name.includes("namorados") && (
                <InputContainer type="mb">
                  <InputLabel>Selecione o Modelo</InputLabel>
                  <GrammageSelect
                    onChange={(e) => {
                      setModel(e.target.value);
                    }}
                    defaultValue={"Namorado"}
                  >
                    <option value={0}>Selecione</option>
                    <option value={"Namorado"}>Namorado</option>
                    <option value={"Namorada"}>Namorada</option>
                  </GrammageSelect>
                </InputContainer>
              )}
              <InputContainer type="mb">
                <InputLabel>Selecione a g</InputLabel>
                <GrammageSelect
                  onChange={(e) => {
                    setCoin(e.target.value);
                  }}
                  id="selectG"
                >
                  <option id="selectGold" value={0}>
                    Selecione
                  </option>

                  {type === "Comprar" &&
                    data?.map((item, i) => (
                      <option
                        id={item?.codIso}
                        key={i++}
                        value={item?.calculoGramaturaVenda}
                      >
                        {ReturnGrammageGold(item?.codIso)}
                      </option>
                    ))}
                  {type === "Vender" &&
                    data?.map((item, i) => (
                      <option
                        id={item?.codIso}
                        key={i++}
                        value={item?.calculoGramaturaCompra}
                      >
                        {ReturnGrammageGold(item?.codIso)}
                      </option>
                    ))}
                </GrammageSelect>
              </InputContainer>

              <InputContainerType>
                <GrammageSelect onChange={(e) => setType(e.target.value)}>
                  <option value={"Comprar"}>Comprar</option>
                  <option value={"Vender"}>Vender</option>
                </GrammageSelect>
              </InputContainerType>
              <InfoObs></InfoObs>
            </ContentRow>
          </SelectCoin>
          <ContentQuant>
            <Block>
              <ContentRow className="vw-20">
                <InputContainer>
                  <InputLabel>Quantidade de barras</InputLabel>
                  <InputQuant
                    value={quant}
                    onChange={(e) => setquant(maskNumberInt(e.target.value))}
                  />
                </InputContainer>
              </ContentRow>

              {infoItems && (
                <QuoteInfoItemOuroDigital item={infoItems} type={type}/>
              )}

              <ContentRow className="vw-20">
                <InputContainer>
                  <InputLabel>Valor em reais</InputLabel>

                  <CurrencyInput
                    type="tel"
                    disabled
                    id="inpValue"
                    value={"R$ " + NumberUtility.displayCurrencyValue(valueRoyal)}
                    maskOptions={{
                      prefix: "R$ ",
                      includeThousandsSeparator: true,
                      allowDecimal: true,
                      requireDecimal: true,
                    }}
                  />
                </InputContainer>
              </ContentRow>
              <InfoCompra>
                {type === "Comprar" && <span>{text1g.obsCompra}</span>}
                {type === "Comprar" && name === "1g-2g" && <span>{text1g.obsCompra1g}</span>}
                {type === "Vender" && <span>{text1g.obsVenda}</span>}
              </InfoCompra>
            </Block>
          </ContentQuant>

        </ContainerCoinContent>

        {infoItems && (
          <ViewCarrouselGold
            item={infoItems}
            name={name}
            type={type}
            model={model}
          />
        )}
      </ContainerInputs>
      <ContainerButton>
        <ButtonRow>
          {type === "Comprar" ? (
            <>
              <FormButton
                className="btnAdd"
                variant="secondary"
                size="lg"
                onClick={() => {
                  AddItemsCoin();
                }}
              >
                {type}
              </FormButton>
              <ContainerTextButton>
                <LinkClean
                  className="text-center w-100 mt-3"
                  onClick={() => navigate("/om/servicos-ouro/existentes")}
                >
                  Ver mais modelos de cartão
                </LinkClean>
              </ContainerTextButton>
            </>
          ) : type === "Vender" ? (
            <FormButton
              className="btnAdd"
              variant="secondary"
              size="lg"
              onClick={() => navigate("/lojas")}
            >
              Loja mais próxima
            </FormButton>
          ) : null}
        </ButtonRow>
      </ContainerButton>
    </Container>
  );
}
const Container = styled.section`
  background-color: white;
  flex-direction: column;
  @media (min-width: 320px) {
    padding: 20px 5px;
  }
  @media (min-width: 1000px) {
    border: 1px solid #e9ecef;
    padding: 50px 20px;
  }
  @media(min-width:1200px){
    padding: 50px 90px;
  }
`;
const ContainerInputs = styled.div`
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    gap: 5%;
  }
  @media(min-width:1400px){
    justify-content: space-between;
  }
`;

const ContainerCoinContent = styled.div`
  @media(min-width: 320px){
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 10%;
  }
`

const ShopDescription = styled.div`
  display: flex;
  color: #677a8d;
  font-weight: bold;
`;

const ContentRow = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
  @media (min-width: 769px) {
    width: 250px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  padding: 10px 15px;
  height: 70px;
  width: 100%;
  margin-bottom: ${(props) => (props.type === "mb" ? " 10px" : null)};

  input[type="tel"] {
    border: none;
    border-radius: 6px;

    &:disabled {
      color: unset;
      background-color: unset;
    }
  }
`;
const InputContainerType = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #e9ecef;
  padding: 10px 15px;
  width: 100%;

  input[type="tel"] {
    border: none;
    border-radius: 6px;

    &:disabled {
      color: unset;
      background-color: unset;
    }
  }
`;
const ContentQuant = styled.div`
  width: 320px;
  @media (max-width: 768px) {
    width: auto;
  }
  @media (min-width: 1024px) {
    max-width: 620px;
  }
`;
const InputQuant = styled.input`
  border: 0 solid;
  width: 100%;
`;

const InputLabel = styled.div`
  font-weight: bold;
  color: #677a8d;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ButtonRow = styled.div`
  margin-top: 20px;
`;

const GrammageSelect = styled.select`
  border: none;
  background-color: white;

  border-radius: 0px;
`;
const ContainerButton = styled.div`
  padding: 0 10px;
  @media (min-width: 768px) {
    width: 516px;
    margin: 0 auto;
  }
`;
const InfoCompra = styled.span`
text-align: justify;
font-size: 12px;
color: #677a8d;
margin: 0;
padding: 0;
display: flex;
  flex-direction: column;
`;
const InfoObs = styled.span`
  text-align: start;
  font-size: 12px;
  color: #677a8d;
`;
const SelectCoin = styled.div`
  @media (min-width: 769px) {
    width: 250px;
  }
`;
const Block = styled.div`
  line-height: normal;
`;
export const LineLoading = styled.div`
  display: flex;
  justify-content: center;
`;
const Loading = styled.img`
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0 auto;
`;

export const Error = styled.span`
  font-size: 10px;
`;

const ContainerTextButton = styled.div`
  margin-top: 10px;
  width: 100%;
  text-align: center;
`;
