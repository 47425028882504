import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/logo.png";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "../styles/ouroninas.css";
import { Link, useNavigate } from "react-router-dom";
import  iconThreads from "../assets/newImages/threads.png"
import { Pinterest } from "@mui/icons-material";

function Section5() {
  const navigate = useNavigate();
  const linkClick = (link) => {
    window.scrollTo(0, 0);
    navigate(link);

  };
  return (
    <section className="section5">
      <Container>
        <img src={logo} alt="logo" className="my-4" loading="lazy" />
        <Row className="d-flex justify-content-center">
          <Col xs={12} sm={4} md={2} className="col-5">
            <div>
              <h1>SOBRE A OM</h1>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/quem-somos")}
                >
                  Quem Somos
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/credibilidade")}
                >
                  Credibilidade
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/grupo-om")}
                >
                  Grupo OM
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/projetos-sociais")}
                >
                  Projetos Sociais
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/planta-arvore")}
                >
                  Programa Plante Árvore
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/compliance")}
                >
                  Compliance
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/demonstracoes-contabeis")}
                >
                  Demonstrações Contábeis
                </button>
              </li>
            </div>
            {/* <div>
              <h1>ATENDIMENTO</h1>
              <li>
                <a
                  href="https://web.whatsapp.com/send?phone=551120501646"
                  rel="noopene noreferrer"
                  target="_blank"
                  className="btnFooter aLink"
                >
                  WhatsApp
                </a>
              </li>
              <li>
                <a
                  href="mailto:atendimento@ourominas.com"
                  rel="noopene noreferrer"
                  target="_blank"
                  className="btnFooter aLink"
                >
                  E-mail
                </a>
              </li>
              <li>
                <a
                  href="tel:1131163663"
                  rel="noopene noreferrer"
                  target="_blank"
                  className="btnFooter aLink"
                >
                  Telefone
                </a>
              </li>
            </div> */}
          </Col>
          <Col xs={12} sm={4} md={2} className="col-5">
            <div>
              <h1>OURO</h1>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/ouro-para-voce")}
                >
                  Ouro Para Você
                </button>
              </li>
              <li>
                <a
                  href="https://www.ourominasapp.com/"
                  rel="noopene noreferrer"
                  target="_blank"
                  className="btnFooter aLink"
                >
                  Ouro Digital (App)
                </a>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/sua-empresa")}
                >
                  Ouro Para Sua Empresa
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/cartao-ouro")}
                >
                  Cartão Ouro Fácil
                </button>
              </li>
              <li>
                <a
                  href="https://www.ourostar.com/"
                  rel="noopene noreferrer"
                  target="_blank"
                  className="btnFooter aLink"
                >
                  Consórcio
                </a>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/piramide-de-ouro")}
                >
                  Pirâmide de Ouro
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/vender-meu-ouro")}
                >
                  Vender meu Ouro
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/premiacao-barra-ouro")}
                >
                  Premiação com Certificado Barra de Ouro
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/exportacao")}
                >
                  Exportação
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/export")}
                >
                  Export
                </button>
              </li>
              <li>
                <button
                  className="btnFooter "
                  onClick={() => linkClick("/om/ouro/curiosidades-ouro")}
                >
                  Curiosidade Sobre o Ouro
                </button>
              </li>
            </div>
          </Col>

          <Col xs={12} sm={4} md={2} className="col-5">
            <div>
              <h1>CÂMBIO EXCHANGE</h1>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/moedas-estrangeiras")}
                >
                  Moeda Estrangeira
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/cartao-pre-pago")}
                >
                  Cartão Pré-Pago
                </button>
              </li>
              <li>
                <a
                  href="https://meu.brasilprepagos.com.br/bpp"
                  rel="noopene noreferrer"
                  target="_blank"
                  className="btnFooter aLink"
                >
                  Cartão Pré-pago (saldo e extrato)
                </a>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/remessa-internacional")}
                >
                  Remessa Internacional
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/moneyGram")}
                >
                  MoneyGram
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/more-payment")}
                >
                  More Payment
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/vender-minha-moeda")}
                >
                  Vender Minha Moeda Estrangeira
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/cambio/curiosidades-cambio")}
                >
                  Curiosidades Sobre o Câmbio
                </button>
              </li>
            </div>
            <div>
              <h1>SEGURANÇA</h1>
              {/* <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/politica-seguranca-cibernetica")}
                >
                  Política de Segurança Cibernética
                </button>
              </li> */}
              <li>
                  <button
                    className="btnFooter"
                    onClick={() => linkClick("/om/seguranca/politicas")}
                  >
                    Políticas
                  </button>
                </li>
              <li>
                  <button
                    className="btnFooter"
                    onClick={() => linkClick("/om/seguranca/fraude")}
                  >
                    Fraude
                  </button>
                </li>
            </div>
          </Col>
          <Col xs={12} sm={4} md={2} className="col-5">
            <div>
              <h1 onClick={() => linkClick("/om/lojas")} className="c-pointer">
                LOJAS
              </h1>
              <h2>AUTORIZADO</h2>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/autorizado/modelo-negocio/correspondente-cambial")}
                >
                  Seja um Autorizado
                </button>
              </li>

              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/autorizado/modelo-negocio/correspondente-cambial")}
                >
                  Correspondente Cambial
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/autorizado/modelo-negocio/indicador-cambial")}
                >
                  Indicador Cambial
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/autorizado/modelo-negocio/indicador-digital")}
                >
                  Indicador Digital
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/autorizado/modelo-negocio/indicador-financeiro")}
                >
                  Indicador Ativo Financeiro
                </button>
              </li>

            </div>
            {/* <div>
              <h1>ACESSO INTERNO</h1>
              <li>
                <button
                  className="btnFooter"
                  onClick={() => linkClick("/om/admin/login")}
                >
                  ADM
                </button>
              </li>
              <li>Portal Marketing - SEM LINK</li>
            </div> */}
          </Col>
          <Col xs={12} sm={4} md={2} className="col-5 width-30">
            <div>
              <h1
                onClick={() => linkClick("/om/atendimento")}
                className="c-pointer"
              >
                CONTATO
              </h1>
              <li>
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/central">
                  Central de Atendimento / Mesa de Operações</Link>
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                >
                  {" "}
                  <Link rel="canonical" to="/om/atendimento/loja">
                  Loja Virtual</Link>
                </button>
              </li>
              <li>
                {" "}
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/app">
                  Ourominas APP</Link>
                </button>{" "}
              </li><li>
                {" "}
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/ouro">
                  Ouro Joalheiros</Link>
                </button>{" "}
              </li>
              <li>
                {" "}
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/parceiro">Seja Nosso Parceiro</Link>
                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/assessoria">Assessoria de Imprensa e Lives</Link>

                </button>
              </li>
              <li>
                <button
                  className="btnFooter"
                >

                  <Link rel="canonical" to="/om/atendimento/marketing">Marketing</Link>
                </button>{" "}
              </li>
              <li>
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/trabalhe-conosco">
                  Trabalhe Conosco</Link>
                </button>{" "}
              </li>
              <li>
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/sugestoes">
                  Sugestões</Link>
                </button>{" "}
              </li>
              <li>
                <button
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/denuncia">
                  Denúncia</Link>
                </button>{" "}
              </li>
              <li>
                <button
                  className="btnFooter"
                >
                   <Link rel="canonical" to="/om/atendimento/reclamacao">
                   Reclame Aqui</Link>

                </button>{" "}
              </li>
              <li>
                <button
                  onClick={() => linkClick("/om/atendimento/ouvidoria")}
                  className="btnFooter"
                >
                  <Link rel="canonical" to="/om/atendimento/ouvidoria">

                  Ouvidoria</Link>
                </button>{" "}
              </li>
            </div>
            <div>
              <h1>REDES SOCIAIS</h1>
              <div className="icons">
                <a
                  href="https://www.facebook.com/OurominasBrasil/"
                  target="_blank"
                  rel="noreferrer"
                  className="btnFooter aLink social-icons"
                >
                  <FacebookRoundedIcon />
                </a>
                <a
                  href="https://www.instagram.com/ourominasbrasil/"
                  target="_blank"
                  rel="noreferrer"
                  className="btnFooter aLink social-icons"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCK23P_Fp5PBpvFGgw38jPUQ?sttick=0"
                  target="_blank"
                  rel="noreferrer"
                  className="btnFooter aLink social-icons"
                >
                  <YouTubeIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/ourominasbrasil/"
                  target="_blank"
                  rel="noreferrer"
                  className="btnFooter aLink social-icons"
                >
                  <LinkedInIcon />
                </a>
                <a
                  href="https://br.pinterest.com/ourominasbrasil/"
                  target="_blank"
                  rel="noreferrer"
                  className="btnFooter aLink social-icons"
                >
                  <Pinterest />
                </a>
                <a
                  href="https://www.threads.net/@ourominasbrasil"
                  target="_blank"
                  rel="noreferrer"
                  className="btnFooter aLink social-icons"
                >
                  <img src={iconThreads}  id="icon-threads" alt="ícone do threads"/>
                </a>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Section5;
