export const religiao = [
    {
      name: "Adventista",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Bahai",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Budista",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Católica",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Cristã",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Espirita",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Evangelica",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Hindu",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Islamismo",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Testemunha de Jeová",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Judaica",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }, {
      name: "Mormon",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ]
    }
    
    
  ];