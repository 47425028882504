import beloHorizonteFrente1g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-1g-frente.png";
import beloHorizonteFrente2g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-2g-frente.png";
import beloHorizonteFrente5g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-5g-frente.png";
import beloHorizonteFrente10g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-10g-frente.png";
import beloHorizonteFrente25g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-25g-frente.png";
import beloHorizonteFrente31g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-31g-frente.png";
import beloHorizonteFrente50g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-50g-frente.png";
import beloHorizonteVerso1g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-1g-verso.png";
import beloHorizonteVerso2g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-2g-verso.png";
import beloHorizonteVerso5g from "../../../assets/newImages/mockups/brasil-turistico/belo-horizonte/belo-horizonte-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  beloHorizonteMockups = {
    nome: "Belo Horizonte",
    frente1g: beloHorizonteFrente1g,

    frente2g: beloHorizonteFrente2g,

    frente5g: beloHorizonteFrente5g,

    frente10g: beloHorizonteFrente10g,

    frente25g: beloHorizonteFrente25g,

    frente31g: beloHorizonteFrente31g,

    frente50g: beloHorizonteFrente50g,

    verso1g: beloHorizonteVerso1g,
    verso2g: beloHorizonteVerso2g,

    verso5g: beloHorizonteVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const beloHorizonteBR = [
    {
      name: "Belo Horizonte",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]