import madriFrente1g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-1g-frente.png";
import madriFrente2g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-2g-frente.png";
import madriFrente5g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-5g-frente.png";
import madriFrente10g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-10g-frente.png";
import madriFrente25g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-25g-frente.png";
import madriFrente31g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-31g-frente.png";
import madriFrente50g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-50g-frente.png";
import madriVerso1g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-1g-verso.png";
import madriVerso2g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-2g-verso.png";
import madriVerso5g from "../../../assets/newImages/mockups/mundos/madri/madri-ouro-5g-verso.png";

export const  madriMockups = {
    nome: "Madrí",
    frente1g: madriFrente1g,
  
    frente2g: madriFrente2g,
  
    frente5g: madriFrente5g,
  
    frente10g: madriFrente10g,
  
    frente25g: madriFrente25g,
  
    frente31g: madriFrente31g,
  
    frente50g: madriFrente50g,
  
    verso1g: madriVerso1g,
    verso2g: madriVerso2g,
  
    verso5g: madriVerso5g,
    verso10g: madriVerso5g,
    verso25g: madriVerso5g,
    verso31g: madriVerso5g,
    verso50g: madriVerso5g,
  };

 