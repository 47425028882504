import melhorasFrente1g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-1g-frente.png";
import melhorasFrente2g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-2g-frente.png";
import melhorasFrente5g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-5g-frente.png";
import melhorasFrente10g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-10g-frente.png";
import melhorasFrente25g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-25g-frente.png";
import melhorasFrente31g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-31g-frente.png";
import melhorasFrente50g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-50g-frente.png";
import melhorasVerso1g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-1g-verso.png";
import melhorasVerso2g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-2g-verso.png";
import melhorasVerso5g from "../../../assets/newImages/mockups/sentimentos/melhoras/melhoras-ouro-5g-verso.png";

export const  melhorasMockups = {
    nome: "Melhoras",
    frente1g: melhorasFrente1g,
  
    frente2g: melhorasFrente2g,
  
    frente5g: melhorasFrente5g, 
    frente10g: melhorasFrente10g,
    frente25g:melhorasFrente25g,
    frente31g: melhorasFrente31g,
    frente50g: melhorasFrente50g, 
  
    verso1g: melhorasVerso1g,
    verso2g: melhorasVerso2g,
  
    verso5g: melhorasVerso5g,
    verso10g: melhorasVerso5g,
    verso25g: melhorasVerso5g,
    verso31g: melhorasVerso5g,
    verso50g: melhorasVerso5g,
  };