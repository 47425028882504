import gemeosFrente1g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-1g-frente.png";
import gemeosFrente2g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-2g-frente.png";
import gemeosFrente5g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-5g-frente.png";
import gemeosFrente10g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-10g-frente.png";
import gemeosFrente25g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-25g-frente.png";
import gemeosFrente31g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-31g-frente.png";
import gemeosFrente50g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-50g-frente.png";
import gemeosVerso1g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-1g-verso.png";
import gemeosVerso2g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-2g-verso.png";
import gemeosVerso5g from "../../../assets/newImages/mockups/signos/gemeos/gemeos-ouro-5g-verso.png";

export const  gemeosMockups = {
    nome: "Gêmeos",
    frente1g: gemeosFrente1g,
  
    frente2g: gemeosFrente2g,
  
    frente5g: gemeosFrente5g, 
    frente10g: gemeosFrente10g,
    frente25g:gemeosFrente25g,
    frente31g: gemeosFrente31g,
    frente50g: gemeosFrente50g, 
  
    verso1g: gemeosVerso1g,
    verso2g: gemeosVerso2g,
  
    verso5g: gemeosVerso5g,
    verso10g: gemeosVerso5g,
    verso25g: gemeosVerso5g,
    verso31g: gemeosVerso5g,
    verso50g: gemeosVerso5g,
  };