import React, { createContext, useState } from "react";
import { ReturnName, ReturnNamePage } from "../../../utils/coins";
import { db } from "../../../services/dexie/stores";

export const CartContext = createContext();

export default function CartProvider({ children }) {
  const [productsCart, setProductsCart] = useState([]);
  const [textoWpp, setTextoWpp] = useState();

  function toogleCArt(open) {
    if (open === "open") {
       document
        .getElementById("shopContainer")
        .classList.remove("invisible");
    } else {
      document
        .getElementById("shopContainer")
        .classList.toggle("invisible");
    }
  }
  function addProductsGold(item) {
    clearCart()
    const copyProducts = [...productsCart];
    const searchItem = copyProducts.find(
      (product) => product.id === item.id && product.tipo === item.tipo
    );

    if (!searchItem && item.quantidade > 0) {
      copyProducts.push(item);
    }

    setProductsCart(copyProducts);
  }
  async function addProductosCoin(item) {
    let searchItem = [];
    let idPedido = item.Tipo + item.Opcao + item.cod;
    const copyProducts = [...productsCart];
    searchItem = copyProducts.filter((product) =>
      product.IdPedido.includes(idPedido)
    );
    copyProducts.forEach((it) => {
      if (it.IdPedido === item.IdPedido) {
      }
    });

    if (searchItem.length === 0 && item.Quantidade > 0) {
      await copyProducts.push(item);
      setProductsCart(copyProducts);
    } else {
    }
  }
  function addProducts(item) {
    clearCart();
    db.cleanCarrinho();
    db.setCarrinho(item);
    const copyProducts = [...productsCart];
    const searchItem = copyProducts.find(
      (product) => product.id === item.id && product.tipo === item.tipo
    );

    if (!searchItem && item.quantidade > 0) {
      copyProducts.push(item);
    }

    setProductsCart(copyProducts);
  }

  function sendProduct(item) {
    let idPedido = item.Tipo + item.Opcao + item.cod;
    const copyProducts = [...productsCart];
    const searchItem = copyProducts.find(
      (product) => product.IdPedido === idPedido
    );
    if (searchItem) {
      alert("produto já adicionado");
      return true;
    }
    return false;
  }
  function removeProducts(item) {
    db.deleteItemCarrinho(item.id);
    const copyProducts = [...productsCart];
    const searchItem = copyProducts.find((product) => product.id === item.id);
    if (searchItem) {
      const arrayFiltered = copyProducts.filter(
        (product) => searchItem.id !== product.id
      );
      setProductsCart(arrayFiltered);
    }
  }
  function removeProductsGold(item) {
    const copyProducts = [...productsCart];
    const searchItem = copyProducts.find((product) => product.id === item.id);
    if (searchItem) {
      const arrayFiltered = copyProducts.filter(
        (product) => searchItem.id !== product.id
      );
      setProductsCart(arrayFiltered);
    }
  }

  function clearCart() {
    setProductsCart([]);
  }

  function sendWhatsapp() {
    let texto = "Solicito atendimento humanizado:";
    let total = 0;
    let moeda = "";
    productsCart.forEach((item) => {
      moeda +=
        "%0A%0AProduto: " +
        (ReturnName(item.codIso) || item.nome) +
        "%0ACotação: " +
         (ReturnNamePage(item.tipo) || item.tipo) +
        "%0AQuantidade: " +
        item.quantidade
        moeda += item.modelo? "%0ACartão OuroFácil: " + item.modelo : ""
      moeda +=
        "              Valor: " +
        (item.quantidade * item.valor).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      total += item.quantidade * item.valor;
    });
    texto +=
      moeda +
      "%0A%0ATotal: " +
      total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    setTextoWpp(texto);
  }

  return (
    <CartContext.Provider
      value={{
        productsCart,
        textoWpp,
        addProducts,
        removeProducts,
        clearCart,
        sendWhatsapp,
        addProductosCoin,
        toogleCArt,
        addProductsGold,
        sendProduct,
        removeProductsGold,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
