/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,  useState } from "react";
import checkIcon from "../../../../assets/check.png";
import editIcon from "../../../../assets/edit.png";
import load from "../../../../assets/spinner.gif";
import "./remessa.css";
import { getRemessasMore, putRemessasMore } from "../../../../services/cotacoes";
import styled from "styled-components";
import { ReturnName } from "../../../../utils/coins";
import { maskDecimal} from "../../../../utils/regex";
import CreateMoeda from "./createMoeda/createMoeda";

export default function RemessasInternacionaisMore() {
  const [clickEdit, setClickEdit] = useState("");
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [final, setFinal] = useState(false);
  const [coinSCP, setCoinSCP] = useState("");
  const [coinSVD, setCoinSVD] = useState("");
  const [coinICP, setCoinICP] = useState("");
  const [coinIVD, setCoinIVD] = useState("");


  useEffect(() => {
    getMoedas();
  },[]);

  function filter(moedas) {
    let filter = moedas.sort((a, b) => {
      return a["nomeMoeda"] < b["nomeMoeda"] ? -1 : 1;
    });

    setData(filter);
  }

  useEffect(() =>{
    const intervalId = setInterval(() =>{
      getMoedas();

    }, 100000)

    return () => {
      clearInterval(intervalId);
    };
  },[])

  async function getMoedas() {
    await getRemessasMore()
      .then((resp) => filter(resp))
      .catch((err) => setError(err))
      .finally(() => setFinal(true));
  }

  function edit(cod) {
    if (clickEdit === "") {
      document.getElementById("svd" + cod).disabled = false;
      document.getElementById("ivd" + cod).disabled = false;
      document.getElementById("btn" + cod).classList.remove("d-none");
      setClickEdit(cod);
    } else if (clickEdit === cod) {

      document.getElementById("svd" + cod).disabled = true;

      document.getElementById("ivd" + cod).disabled = true;
      document.getElementById("btn" + cod).classList.add("d-none");
      setClickEdit("");
    }
  }

  function salvar(moeda) {
    let newArr = moeda;
    if (coinSCP) {
      newArr.scp = maskDecimal(coinSCP);
    }
    if (coinSVD) {
      newArr.svd = maskDecimal(coinSVD);
    }
    if (coinICP) {
      newArr.icp = maskDecimal(coinICP);
    }
    if (coinIVD) {
      newArr.ivd = maskDecimal(coinIVD);
    }
    putRemessasMore(newArr).then(() => getMoedas());
    setCoinSCP("");
    setCoinSVD("");
    setCoinICP("");
    setCoinIVD("");

    document.getElementById("scp" + moeda.codIso).disabled = true;
    document.getElementById("svd" + moeda.codIso).disabled = true;
    document.getElementById("icp" + moeda.codIso).disabled = true;
    document.getElementById("ivd" + moeda.codIso).disabled = true;
    document.getElementById("btn" + moeda.codIso).classList.add("d-none");
    setClickEdit("");
  }

  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="adminTable">
        <h1 className="text-center">More Payment</h1>
        <CreateMoeda sync={getMoedas} />
        <div className="d-flex justify-content-center my-2"></div>

        <LineLoading>
          {!final && <Loading src={load} />}
          {error && <Error>Dados não disponíveis no momento.</Error>}
        </LineLoading>
        <table className="table table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">Moeda</th>
              <th className="text-center" scope="col">
                Venda
              </th>
              <th className="text-center" scope="col">
                Venda Sugerida
              </th>
              <th className="text-center" scope="col">
                VENDA <br />
                Sugerida %
              </th>
              <th className="text-center" scope="col">
                IOF <br />
                VENDA %
              </th>
              <th className="text-center" scope="col">
                Editar
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((moeda, index) => (
              <tr key={index++}>
                <th scope="row" className="text-capitalize fs-6">
                  {moeda.codIso === "EUSCOM" ? "USD" : moeda.codIso} -{" "}
                  {ReturnName(moeda.codIso)}
                </th>
                <td className="text-center">
                  {parseFloat(moeda.ovd).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}
                </td>
                <td className="text-center">
                  {parseFloat(moeda.calculoVenda).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}
                </td>

                <td className="text-center">
                  <input
                    className="form-control inputEdit margin-auto"
                    id={"svd" + moeda.codIso}
                    placeholder="moeda"
                    defaultValue={moeda.svd}
                    onChange={(e) => setCoinSVD((e.target.value))}
                    disabled
                  />
                </td>

                <td className="text-center">
                  <input
                    className="form-control inputEdit margin-auto"
                    id={"ivd" + moeda.codIso}
                    placeholder="moeda"
                    defaultValue={moeda.ivd}
                    onChange={(e) => setCoinIVD((e.target.value))}
                    disabled
                  />
                </td>
                <td className="text-center">
                  <button className="btn" onClick={() => edit(moeda.codIso)}>
                    <img src={editIcon} width="25px" alt="ícone de edição"/>
                  </button>
                  <button
                    className="btn d-none"
                    id={"btn" + moeda.codIso}
                    onClick={() => salvar(moeda)}
                  >
                    <img src={checkIcon} width="25px" alt="ícone de salvar"/>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const Loading = styled.img`
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
`;

const LineLoading = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
const Error = styled.span`
  font-size: 10px;
`;
