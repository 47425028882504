import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import usa from "../assets/estados-unidos-da-america.png";
import eu2 from "../assets/uniao-europeia.png";
import eng1 from "../assets/united-kingdom.png";
import ouro from "../assets/barras-de-ouro.png";

import "../styles/ouroninas.css";
import Charts from "./homepage/chart/chart";

const USA_ICON = () => {
  return <img src={usa} className="icon-img" alt="usa" width="67px" />;
};
const EU2_ICON = () => {
  return <img src={eu2} className="icon-img" alt="eua" width="67px" />;
};
const ENG1_ICON = () => {
  return <img src={eng1} className="icon-img" alt="eng" width="67px" />;
};
const OURO_ICON = () => {
  return <img src={ouro} className="icon-img" alt="ouro" width="67px" />;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", background: "#F7F7F7" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          id="tabCoins"
        >
          <Tab
            icon={<OURO_ICON />}
            iconPosition="start"
            label="Ouro"
            {...a11yProps(0)}
            className="tabCoins__button"
          />
          <Tab
            icon={<USA_ICON />}
            iconPosition="start"
            label="Dólar Americano"
            {...a11yProps(1)}
            className="tabCoins__button"
          />
          <Tab
            icon={<EU2_ICON />}
            iconPosition="start"
            label="Euro"
            {...a11yProps(2)}
            className="tabCoins__button"
          />
          <Tab
            icon={<ENG1_ICON />}
            iconPosition="start"
            label="Libra Esterlina"
            {...a11yProps(3)}
            className="tabCoins__button"
          />
          -
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Charts nome="Cotação da Onça Troy - Bolsa NY" moeda="xauusd" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Charts nome="Cotação do Dólar - USD" moeda="EUSCOM" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Charts nome="Cotação do Euro - EUR" moeda="EUR" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Charts nome="Cotação da Libra - GBP" moeda="GBP" />
      </TabPanel>
    </Box>
  );
}

/*
const GraphView = () => {
  return (
    <div className="graph-view">
      <img src={graphImg} alt="graph" />
    </div>
  );
};
*/