import cristaFrente1g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-1g-frente.png";
import cristaFrente2g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-2g-frente.png";
import cristaFrente5g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-5g-frente.png";
import cristaFrente10g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-10g-frente.png";
import cristaFrente25g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-25g-frente.png";
import cristaFrente31g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-31g-frente.png";
import cristaFrente50g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-50g-frente.png";
import cristaVerso1g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-1g-verso.png";
import cristaVerso2g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-2g-verso.png";
import cristaVerso5g from "../../../assets/newImages/mockups/religiao/crista/crista-ouro-5g-verso.png";

export const  cristaMockups = {
    nome: "Cristã",
    frente1g: cristaFrente1g,
  
    frente2g: cristaFrente2g,
  
    frente5g: cristaFrente5g, 
    frente10g: cristaFrente10g,
    frente25g:cristaFrente25g,
    frente31g: cristaFrente31g,
    frente50g: cristaFrente50g, 
  
    verso1g: cristaVerso1g,
    verso2g: cristaVerso2g,
  
    verso5g: cristaVerso5g,
    verso10g: cristaVerso5g,
    verso25g: cristaVerso5g,
    verso31g: cristaVerso5g,
    verso50g: cristaVerso5g,
  };