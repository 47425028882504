import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "@mui/material/Box";
import "../styles/ouroninas.css";
import GraphTabs from "./tab3";
import TabTable from "./homepage/tables/tabTable/tabTable";
import BoxButtons from "./homepage/boxButtons/boxButtons";

export default function Section3() {
  return (
    <section className="section3">
      <Container>
        <Row className="row3 pt-0 mt-5 pb-4">
          <Col xs={12} sm={12} md={12} className="col-3 pt-2">
            
      <BoxButtons/>

            <h1 className="htitle mb-1">COTAÇÃO AGORA</h1>
            <p className="subTitle">
              Valores exclusivos para o site. Cotações com IOF incluso.
            </p>
            <TabTable />
            <h1 className="htitle mt-5 mb-1">
              ACOMPANHE O MERCADO EM TEMPO REAL
            </h1>
            <Box
              sx={{
                border: "1px dotted grey",
                height: 500,
              }}
            >
              <GraphTabs />
            </Box>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
