import React from 'react';
import linha from "../../assets/linha.png";
import img from "../../assets/logo-b3.png";
import "../pages.css";

export default function Investidor() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <h5 className="fw-bolder mb-0">Investidores B3</h5>
            <hr></hr>
            <p>
              Em outubro de 2023, foi anunciado pela B3 (Brasil, Bolsa e Balcão)
              que as negociações dos contratos de ouro à vista seriam
              descontinuadas após o retorno do Carnaval. Considerado o ativo
              mais rentável do mercado financeiro no Brasil em janeiro de 2024,
              foi alegado que o metal não estava atraindo os investidores. Entre
              os argumentos, outros ativos relacionados ao ouro, como ETFs e
              BDRs de ETFs, trariam mais liquidez e facilidade operacional —
              aumentando os olhares dos investidores.
            </p>
            <p>
              Com a decisão, a B3 deu o prazo de quatro meses aos investidores
              para se organizarem diante do encerramento. No entanto, a medida
              desagradou muitos, que não esperavam a notícia. Com o término das
              negociações de Ouro Spot [compra e venda de Ouro físico], os
              investidores que têm seus investimentos em Custódia ficaram
              prejudicados e sem saber onde investir e, principalmente, para
              quem vender.
            </p>
            <p>
              Em 2020, houve uma demanda recorde devido ao cenário pandêmico de
              covid-19, levando os investidores a buscarem por segurança. Além
              disso, as guerras e conflitos mundiais envolvendo Rússia X
              Ucrânia, e, Israel X Hamas, também contribuíram para essa
              elevação. A alta procura por reserva de valores — oferecida pelo
              ouro — também rendeu alguns períodos de volatilidade, que deram
              espaço para a chegada de outros ativos atrelados ao metal
              precioso.
            </p>
          <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="c-init__image col-6">
            <img src={img} className="image" alt="grupo-om"></img>
        </div>
          </div>
        <p className="fw-bolder mt-4">
          Qual é a recomendação para os investidores?
        </p>
        <p>
          Nesse momento de incertezas e dúvidas, é importante entender qual é a
          melhor solução e procurar por opções que possam afastar o risco de
          prejuízos. Nesse cenário, a OM está ajudando esses investidores,
          oferecendo liquidez, tanto na venda quanto na compra desse ouro.
        </p>
        <p>
          A empresa quer auxiliar os investidores neste momento e atua com
          tabela de preço justo e liquidez rápida. Entre em contato com a Mesa
          de Operações, através do WhatsApp{' '}
          <a
            className=""
            href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
            rel="noopene noreferrer"
            target="_blank"
          >
            {' '}
            (11) 2050-1646
          </a>.
        </p>
      </section>
    </article>
  );
}
