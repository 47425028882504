import espanhaFrente1g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-1g-frente.png";
import espanhaFrente2g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-2g-frente.png";
import espanhaFrente5g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-5g-frente.png";

import espanhaVerso1g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-1g-verso.png";
import espanhaVerso2g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-2g-verso.png";
import espanhaVerso5g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-5g-verso.png";
import espanhaVerso10g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-10g-verso.png";
import espanhaVerso25g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-25g-verso.png";
import espanhaVerso31g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-31g-verso.png";
import espanhaVerso50g from "../../../assets/newImages/mockups/golden-cup/espanha/espanha-ouro-50g-verso.png";

export const  espanhaMockups = {
    nome: "Espanha",
    frente1g: espanhaFrente1g,
  
    frente2g: espanhaFrente2g,
  
    frente5g: espanhaFrente5g,  

    frente10g: espanhaFrente5g,
    frente25g:espanhaFrente5g,
    frente31g: espanhaFrente5g,
    frente50g: espanhaFrente5g,
  
    verso1g: espanhaVerso1g,
    verso2g: espanhaVerso2g,
  
    verso5g: espanhaVerso5g,

    verso10g: espanhaVerso10g,
    verso25g: espanhaVerso25g,
    verso31g: espanhaVerso31g,
    verso50g: espanhaVerso50g
  };

