import React from "react";
import img from "../../../assets/newImages/curiosidades-cambio.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";

export default function CuriosidadesCambio() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">CURIOSIDADES SOBRE O CÂMBIO</p>
            <p>
            O dinheiro é um instrumento de pagamento usado nas trocas,
            geralmente materializado sob a forma de notas e moedas, que é aceite
            por uma sociedade para pagar bens, serviços e todo o tipo de
            obrigações. A sua origem etimológica remete-nos para o vocábulo
            latim denarius, que era o nome da moeda que utilizavam os romanos.
          </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>


        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS AS SIGLAS DAS MOEDAS ESTRANGEIRAS?
          </summary>
          <ul className="mt-4">
            <li>
              Escudo cabo-verdiano, peso argentino, peso chileno, peso
              dominicano, dólar da Guiana, dólar da Jamaica, boliviano.
            </li>
            <li>€ Euro</li>
            <li>¥ Iene, iuane</li>
            <li>£ Libra esterlina</li>
            <li>₩ Won</li>
            <li>₪ Novo shekel israelense</li>
            <li>₤ Lira Turca</li>
            <li>Q Quetzal</li>
            <li>L Lempira</li>
            <li>₡ Colón</li>
            <li>₦ Naira</li>
            <li>₲ Guaraní</li>
            <li>Ø Libra Argentina</li>
          </ul>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS SÃO AS PRINCIPAIS MOEDAS DO MUNDO?
          </summary>
          <ul className="mt-4">
            <li>USD – United States Dollars (Dólar Americano)</li>
            <li>GBP – Great Britain Pound (Libra Esterlina)</li>
            <li>EUR – Euro</li>
            <li>JPY – Japanese Yen (Ien Japonês)</li>
            <li>CHF – Swiss Franc (Franco Suíço)</li>
            <li>AUD – Australian Dollar (Dólar Australiano)</li>
            <li>CAD – Canadian Dollar (Dólar Canadense)</li>
          </ul>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">QUAL MOEDA LEVAR PARA LONDRES?</summary>
          <p className="mt-4">
            O Reino Unido – que engloba a Inglaterra, a Escócia, o País de Gales
            e a Irlanda do Norte – optou por não adotar o Euro e manteve a Libra esterlina
            (em inglês pound sterling, símbolo £) como moeda.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            POR QUE O DÓLAR DE TORNOU A MOEDA MAIS FORTE DO MUNDO?
          </summary>
          <p className="mt-4">
            O dólar tem um impacto grande na economia mundial porque as reservas
            dos países estão nesta moeda. Além disso ela é usada como moeda de
            transações internacionais em todo o mundo. O dólar é regulado pelo
            próprio mercado, ou seja, por quem compra e vende a moeda.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAL A DIFERENÇA ENTRE LIBRA E POUND?
          </summary>
          <p className="mt-4">
            A libra esterlina ou simplesmente libra (em inglês, pound, plural
            pounds, informal. Pound Sterling, ou pounds sterling, formal) é a
            moeda oficial do Reino Unido. A palavra libra (pound) era um termo
            utilizado pelas unidades monetárias de várias regiões da Europa.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUANDO SURGIU O DÓLAR AMERICANO?
          </summary>
          <p className="mt-4">
            A história do dólar se inicia em 1776, com a necessidade de criação
            de uma moeda capaz de financiar a Guerra de Independência dos
            Estados Unidos. O dólar americano é uma das moedas mais importantes
            no mundo. O nome dollar vem da palavra thaler, uma conhecida moeda
            de prata que circulava na Europa durante o século XV.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">O QUE É MOEDA NACIONAL?</summary>
          <p className="mt-4">
            Moeda é o meio pelo qual são efetuadas as transações monetárias. É
            todo ativo que constitua forma imediata de solver débitos, com
            aceitabilidade geral e disponibilidade imediata, e que confere ao
            seu titular um direito de saque sobre o produto social.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">QUAL A ORIGEM DO DÓLAR?</summary>
          <p className="mt-4">
            A palavra dólar vem da palavra alemã “Thaler”, que significa vale.
            Esse termo foi cunhado numa moeda alemã chamada Guldengroschen e
            utilizada por volta de 1519. Os primeiros dólares eram feitos em
            moedas de prata na região da Boêmia.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUEM É A PESSOA NA NOTA DE 100 DÓLARES?
          </summary>
          <p className="mt-4">Os homenageados:</p>
          <p>
            As notas de dólar trazem estampadas os retratos de George Washington
            (US$ 1), Thomas Jefferson (US$ 2), Abraham Lincoln (US$ 5),
            Alexander Hamilton (US$ 10), Andrew Jackson (US$ 20), Ulysses Grant
            (US$ 50) e Benjamim Franklin (US$ 100).
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">QUANTO É O IOF DO DÓLAR?</summary>
          <p className="mt-4">
            A primeira coisa é ter em mente as diferenças nas alíquotas de IOF
            (Imposto Sobre Operações Financeiras) cobradas nas operações de
            câmbio. O imposto para aquisição de moeda em espécie é de 1,1%,
            enquanto a carga no cartão pré-pago e as compras no cartão de
            crédito estão sujeitas a IOF de 6,38%.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAL A DIFERENÇA ENTRE O DÓLAR COMERCIAL E O DÓLAR TURISMO?
          </summary>
          <p className="mt-4">
            O valor do dólar comercial é usado para definir as taxas do mercado.
            A moeda é utilizada no pagamento ou recebimento de recursos das
            importações e exportações de bens e serviços do Brasil com o mundo.
            Já o dólar turismo é a moeda física, negociada nas casas de câmbio e
            correspondentes cambiais.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAL A DIFERENÇA ENTRE TAXA DE COMPRA E TAXA DE VENDA?
          </summary>
          <p className="mt-4">
            A taxa de venda é o preço cobrado pelo banco para vender a moeda
            estrangeira, enquanto a taxa de compra reflete o preço que o banco
            paga pela divisa estrangeira que é ofertada. Ou seja, apesar de
            parecer estranho, você compra a moeda pela cotação de venda, mais
            alta, e vende pela de compra.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">POR QUE O EURO FOI CRIADO?</summary>
          <p className="mt-4">
            Uma conturbada sessão noturna em Bruxelas decidiu, no dia 2 de maio
            de 1998 (na realidade já era o dia seguinte, pois passava da
            meia-noite), que a União Europeia teria uma moeda comum. Entre 1994
            e 1999 foi criado o Instituto Monetário Europeu e aprovado o nome
            euro para a moeda única.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            POR QUE A DINAMARCA NÃO ADOTOU O EURO?
          </summary>
          <p className="mt-4">
            Os países que fazem parte da EU e não adotaram o euro como moeda
            são: Bulgária, Chipre, Dinamarca, Eslováquia, Estônia, Hungria,
            Letônia, Lituânia, Malta, Polônia, República Checa, Reino Unido,
            Romênia e Suécia, pois temiam que a moeda não tivesse estabilidade e
            pudesse desvalorizar.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUANDO FOI CRIADA A LIBRA ESTERLINA?
          </summary>
          <p className="mt-4">
            A libra esterlina foi criada e entrou em circulação em 1561, durante
            o reinado de Isabel I. A palavra libra (pound) tem origem no latim.
            Era no passado o nome que se dava a várias moedas que circulavam na
            Europa.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">QUAL A MOEDA DOS PAÍSES?</summary>
          <table >
            <tbody>
              <tr>
                <td >
                  <p>
                     Afegani –
                    Afeganistão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Ariary –
                    Madagascar
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Baht –
                    Tailândia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Balboa
                    (moeda), Dólar norte-americano (papel-moeda) – Panamá
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Birr –
                    Etiópia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Bolívar
                    Forte venezuelano – Venezuela
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Boliviano
                    – Bolívia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Cedi –
                    Gana
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Colón
                    costa-riquenho – Costa Rica
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Córdoba
                    Ouro – Nicarágua
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa da
                    República Checa – República Checa
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    dinamarquesa – Dinamarca
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    dinamarquesa – Groenlândia (território dinamarquês)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    estoniana – Estônia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    feroesa – Ilhas Feröe (território da Dinamarca)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    norueguesa – Noruega
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    norueguesa – Svalbard (território norueguês)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Coroa
                    sueca – Suécia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dalasi –
                    Gâmbia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar –
                    Kuwait
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar –
                    Sérvia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    argelino – Argélia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar do
                    Bahrein – Bahrein
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    iraquiano – Iraque
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    jordaniano – Jordânia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    jordaniano, Novo Sheqel israelense – Palestina
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    líbio – Líbia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    sudanês – Sudão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dinar
                    tunisino – Tunísia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dirham dos
                    Emirados – Emirados Árabes Unidos
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dirham
                    marroquino – Marrocos
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dirham
                    marroquino – Saara Ocidental
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dobra –
                    São Tomé e Príncipe
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    americano, Colón salvadorenho – El Salvador
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    australiano – Austrália
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    australiano – Ilha Christmas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    australiano – Ilhas Cocos
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    australiano – Kiribati
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    australiano – Nauru
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    australiano – Norfolk
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    canadense – Canadá
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar da
                    Nova Zelândia – Nova Zelândia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar das
                    Bahamas – Bahamas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar das
                    Bermudas – Bermudas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Barbados – Barbados
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Belize – Belize
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Brunei – Brunei
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Cingapura – Cingapura
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Fiji – Fiji
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Hong Kong – Hong Kong
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Pitcairn – Pitcairn
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Singapura – Singapura
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Trinidad e Tobago – Trinidad e Tobago
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar de
                    Tuvalu, Dólar australiano – Tuvalu
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – Anguilla (território britânico)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – Antígua e Barbuda
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – Dominica
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – Granada
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – Montserrat (território britânico)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – Santa Lúcia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – São Cristóvão e Névis
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Caribe Oriental – São Vicente e Granadinas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar do
                    Suriname, Florim surinamês – Suriname
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    guianense – Guiana
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    jamaicano – Jamaica
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    liberiano – Libéria
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    namibiano – Namíbia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    neozelandês – Ilhas Cook
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    neozelandês – Niue
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    neozelandês – Tokelau
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Equador
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Estados Federados da Micronésia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Estados Unidos da América
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Guam
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Ilhas Marianas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Ilhas Marshall
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Ilhas Virgens Britânicas (território
                    britânico)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Palau
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Porto Rico
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Samoa Americana
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    norte-americano – Timor-Leste
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    salomônico – Ilhas Salomão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dólar
                    zimbabuano – Zimbábue
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dong –
                    Vietnã
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dram –
                    Arménia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Dram
                    armênio – Nagorno Karabakh
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Escudo
                    cabo-verdiano – Cabo Verde
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Alemanha
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Andorra
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Áustria Áustria
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Bélgica
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Chipre
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Eslováquia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Eslovénia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Espanha
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Finlândia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    França
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Grécia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Irlanda
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Itália
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Kosovo
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Luxemburgo
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Malta
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Mayotte (território francês)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Mônaco
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Montenegro
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Países Baixos
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Portugal
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Reunião (território francês)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro – San
                    Marino
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Euro –
                    Vaticano
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Florim das
                    Antilhas Neerlandesas – Antilhas Holandesas (território
                    neerlandês)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Florim de
                    Aruba – Aruba
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Florim
                    húngaro – Hungria
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Benim
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Burkina Faso
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Camarões
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Chade
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Congo, República do
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Costa do Marfim
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Gabão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Guiné Equatorial
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Guiné-Bissau
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Mali
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Níger
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – República Centro-Africana
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Senegal
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco CFA
                    – Togo
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco
                    comorense – Comores
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco
                    congolês – Congo, República Democrática do
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco do
                    Burundi – Burundi
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco do
                    Djibouti – Djibouti
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco do
                    Pacífico – Nova Caledônia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco do
                    Pacífico – Polinésia Francesa
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco do
                    Pacífico – Wallis e Futuna
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco
                    guineense – Guiné
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco
                    ruandês – Ruanda
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco
                    suíço – Liechtenstein
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Franco
                    suíço – Suíça
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Gourde –
                    Haiti
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Guarani –
                    Paraguai
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Hryvna –
                    Ucrânia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Iene (ou
                    Yen) – Japão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Iuan
                    Renmimbi – República Popular da China
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Kina –
                    Papua-Nova Guiné
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Kuna –
                    Croácia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Kwacha
                    malawiana – Malawi
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Kwacha
                    zambiana – Zâmbia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Kwanza –
                    Angola
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Lari –
                    Geórgia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Lats –
                    Letônia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Lek novo –
                    Albânia Albânia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Lempira –
                    Honduras
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Leone –
                    Serra Leoa
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Leu –
                    Moldávia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Leu romeno
                    – Romênia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Lev novo –
                    Bulgária
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Libra de
                    Santa Helena – Santa Helena (território britânico)
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Libra
                    egípcia – Egito
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Libra
                    esterlina – Ilhas Malvinas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Libra
                    esterlina – Reino Unido
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Libra
                    síria – Síria
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Lilangeni
                    – Suazilândia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Litas –
                    Lituânia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Loti –
                    Lesoto
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Manat –
                    Azerbaijão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Manat
                    turcomano – Turcomenistão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Marco
                    conversível – Bósnia e Herzegovina
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Metical –
                    Moçambique
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Naira –
                    Nigéria
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Nakfa –
                    Eritreia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Ngultrum –
                    Butão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Nova Coroa
                    islandesa – Islândia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Nova Lira
                    turca – Chipre do Norte
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Nova lira
                    turca – Turquia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Novo denar
                    macedônio – Macedônia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Novo
                    Sheqel israelense – Israel
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Novo xelim
                    do Uganda – Uganda
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Ouguiya –
                    Mauritânia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Pa’anga –
                    Tonga
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Pataca –
                    Macau
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    argentino – Argentina
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    chileno – Chile
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    colombiano – Colômbia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    cubano, Peso Conversível – Cuba
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    dominicano – República Dominicana
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    mexicano – México
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Peso
                    uruguaio – Uruguai
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Pula –
                    Botsuana
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Quetzal –
                    Guatemala
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Quiat –
                    Mianmar
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rand –
                    África do Sul
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Real –
                    Brasil
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rial do
                    Catar – Qatar
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rial
                    iemenita – Iémen
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rial omani
                    – Omã
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Riel –
                    Camboja
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Riel – Irã
                    / Irão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Riyal –
                    Arábia Saudita
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rublo –
                    Abecásia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rublo –
                    Rússia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rublo
                    bielo-russo – Bielorrússia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rublo da
                    Transnístria – Transnístria
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rublo,
                    Lari – Ossétia do Sul
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rupia das
                    Maldivas – Maldivas
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rupia das
                    Seychelles – Seychelles
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rupia do
                    Sri Lanka – Sri Lanka
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rupia
                    indiana – Índia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rupia
                    mauriciana – Maurícia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Rupia
                    Nepalesa – Nepal
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Sol novo –
                    Peru
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Som do
                    Quirguistão – Quirguistão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Som do
                    Tadjiquistão – Tadjiquistão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Som
                    uzbeque – Uzbequistão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Taka –
                    Bangladesh
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Tala –
                    Samoa Ocidental
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Tenge –
                    Cazaquistão
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Togrog (ou
                    Tugrik) – Mongólia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Vatu –
                    Vanuatu
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Won
                    norte-coreano – Coreia do Norte
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Won
                    sul-coreano – Coreia do Sul
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Xelim da
                    Somalilândia – Somalilândia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Xelim
                    queniano – Quênia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Xelim
                    somaliano – Somália
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Xelim
                    tanzaniano – Tanzânia
                  </p>
                </td>
              </tr>
              <tr>
                <td >
                  <p>
                     Zloty –
                    Polônia
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </details>
        </section>
      </section>
    </article>
  );
}
