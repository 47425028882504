import afilhadoFrente1g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-1g-frente.png";
import afilhadoFrente2g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-2g-frente.png";
import afilhadoFrente5g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-5g-frente.png";
import afilhadoFrente10g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-10g-frente.png";
import afilhadoFrente25g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-25g-frente.png";
import afilhadoFrente31g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-31g-frente.png";
import afilhadoFrente50g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-50g-frente.png";
import afilhadoVerso1g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-1g-verso.png";
import afilhadoVerso2g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-2g-verso.png";
import afilhadoVerso5g from "../../../assets/newImages/mockups/familia/afilhado/afilhado-ouro-5g-verso.png";

export const  afilhadoMockups = {
    nome: "Afilhado de Ouro",
    frente1g: afilhadoFrente1g,
  
    frente2g: afilhadoFrente2g,
  
    frente5g: afilhadoFrente5g, 
    frente10g: afilhadoFrente10g,
    frente25g:afilhadoFrente25g,
    frente31g: afilhadoFrente31g,
    frente50g: afilhadoFrente50g, 
  
    verso1g: afilhadoVerso1g,
    verso2g: afilhadoVerso2g,
  
    verso5g: afilhadoVerso5g,
    verso10g: afilhadoVerso5g,
    verso25g: afilhadoVerso5g,
    verso31g: afilhadoVerso5g,
    verso50g: afilhadoVerso5g,
  };