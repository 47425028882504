import filhoFrente1g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-1g-frente.png";
import filhoFrente2g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-2g-frente.png";
import filhoFrente5g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-5g-frente.png";
import filhoFrente10g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-10g-frente.png";
import filhoFrente25g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-25g-frente.png";
import filhoFrente31g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-31g-frente.png";
import filhoFrente50g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-50g-frente.png";
import filhoVerso1g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-1g-verso.png";
import filhoVerso2g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-2g-verso.png";
import filhoVerso5g from "../../../assets/newImages/mockups/familia/filho/filho-ouro-5g-verso.png";

export const  filhoMockups = {
    nome: "Filho de Ouro",
    frente1g: filhoFrente1g,
  
    frente2g: filhoFrente2g,
  
    frente5g: filhoFrente5g, 
    frente10g: filhoFrente10g,
    frente25g:filhoFrente25g,
    frente31g: filhoFrente31g,
    frente50g: filhoFrente50g, 
  
    verso1g: filhoVerso1g,
    verso2g: filhoVerso2g,
  
    verso5g: filhoVerso5g,
    verso10g: filhoVerso5g,
    verso25g: filhoVerso5g,
    verso31g: filhoVerso5g,
    verso50g: filhoVerso5g,
  };