import enfermeiroFrente1g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-1g-frente.png";
import enfermeiroFrente2g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-2g-frente.png";
import enfermeiroFrente5g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-5g-frente.png";
import enfermeiroFrente10g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-10g-frente.png";
import enfermeiroFrente25g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-25g-frente.png";
import enfermeiroFrente31g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-31g-frente.png";
import enfermeiroFrente50g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-50g-frente.png";
import enfermeiroVerso1g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-1g-verso.png";
import enfermeiroVerso2g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-2g-verso.png";
import enfermeiroVerso5g from "../../../assets/newImages/mockups/profissoes/enfermeiro/enfermeiro-ouro-5g-verso.png";

export const  enfermeiroMockups = {
    nome: "Enfermeiro de Ouro",
    frente1g: enfermeiroFrente1g,
  
    frente2g: enfermeiroFrente2g,
  
    frente5g: enfermeiroFrente5g,
  
    frente10g: enfermeiroFrente10g,
  
    frente25g: enfermeiroFrente25g,
  
    frente31g: enfermeiroFrente31g,
  
    frente50g: enfermeiroFrente50g,
  
    verso1g: enfermeiroVerso1g,
    verso2g: enfermeiroVerso2g,
  
    verso5g: enfermeiroVerso5g,
    verso10g: enfermeiroVerso5g,
    verso25g: enfermeiroVerso5g,
    verso31g: enfermeiroVerso5g,
    verso50g: enfermeiroVerso5g,
  };

 