import primaFrente1g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-1g-frente.png";
import primaFrente2g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-2g-frente.png";
import primaFrente5g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-5g-frente.png";
import primaFrente10g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-10g-frente.png";
import primaFrente25g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-25g-frente.png";
import primaFrente31g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-31g-frente.png";
import primaFrente50g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-50g-frente.png";
import primaVerso1g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-1g-verso.png";
import primaVerso2g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-2g-verso.png";
import primaVerso5g from "../../../assets/newImages/mockups/familia/prima/prima-ouro-5g-verso.png";

export const  primaMockups = {
    nome: "Prima de Ouro",
    frente1g: primaFrente1g,
  
    frente2g: primaFrente2g,
  
    frente5g: primaFrente5g, 
    frente10g: primaFrente10g,
    frente25g:primaFrente25g,
    frente31g: primaFrente31g,
    frente50g: primaFrente50g, 
  
    verso1g: primaVerso1g,
    verso2g: primaVerso2g,
  
    verso5g: primaVerso5g,
    verso10g: primaVerso5g,
    verso25g: primaVerso5g,
    verso31g: primaVerso5g,
    verso50g: primaVerso5g,
  };