import React from "react";
import img from "../../../assets/vender-ouro.png";
import "../../pages.css";

import linha from "../../../assets/linha.png";
export default function VenderOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              VENDER MEU OURO
            </p>
            <p>
              Na Ourominas (OM) o cliente pode contar com uma equipe formada por
              profissionais com foco na análise e avaliação do ouro com
              segurança e a melhor valorização do mercado. 
            </p>
            <p>
              A OM efetua a compra de ouro em todos os teores, maciços, ocos ou
              ocados, barras (que podem ou não ser da OM), moedas, joias,
              relógios e fragmentos, ou seja, se você possui uma aliança,
              corrente, colar, pulseira ou qualquer objeto em ouro que queira se
              desfazer e vender com segurança e sem qualquer preocupação, a OM é
              a empresa mais qualificada.
            </p>
            <p>
              Além de um atendimento personalizado, a OM cobre qualquer oferta
              real da concorrência (anunciada e comprovada) e após a avaliação e
              negociação do ouro o pagamento e feito à vista.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <p className="mt-4">
          <b>Entenda como é calculado o ouro contido na peça:</b>
        </p>
        <p>
          O valor pago no ouro é correspondente ao peso e teor do metal, ou
          seja, o ouro de melhor qualidade, tem liga 750 (ouro 18k) ou superior,
          750 corresponde a uma joia com 75% ouro e 25% liga (prata e cobre),
          quanto maior for esse número, maior é a porcentagem de ouro na
          composição da peça, logo seu valor será superior.
        </p>

        <p>
          Muito cuidado com os compradores desconhecidos, evite correr riscos de
          ter o metal avaliado por um valor inferior e fazer um péssimo negócio.
          Vendendo para a OM, o cliente pode ter a certeza de que estará fazendo
          um excelente negócio, com a garantia de sempre receber o valor correto
          pelo metal. 
        </p>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O QUE A OUROMINAS COMPRA? </summary>
            <ul className="mt-2">
              <li>Barra de ouro;</li>
              <li>Ouro Laminado;</li>
              <li>Joias (mesmo quebradas ou partidas);</li>
              <li>Moedas de Ouro</li>
            </ul>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE VENDER OURO PARA A OURMINAS? </summary>
            <p className="mt-2">
              Além de sermos uma empresa com mais de 40 anos de experiência,
              somos extremamente idôneos. Nosso objetivo com a compra do ouro é
              trazer mais tranquilidade e segurança para os interessados em
              vender.
            </p>
            <p>
              Contamos com profissionais qualificados para avaliar o seu
              material dentro de minutos, e com total transparência. Não corra
              riscos desnecessários em vender seu ouro em locais desconhecidos.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>
              POR QUE NÃO COMPRAMOS TODAS AS JOIAS EM OURO BRANCO?{" "}
            </summary>
            <p className="mt-2">
              Se você tem uma joia em ouro branco e pretende vender, ou já veio
              até nós com interesse de vendê-la, saiba que não são todas as
              joias de ouro branco que compramos.
            </p>
            <p>
              A nossa avaliação para o ouro branco visa uma liga (uma mistura)
              específica, composta de ouro amarelo e paládio.
            </p>
            <p>
              Muitas das joalherias pelo Brasil utilizam liga italiana, mais
              focada na cor, do que na qualidade do material. Saiba que a liga
              italiana possui prata e outros metais de baixo valor em sua
              composição, e para nós não tem nenhum valor comercial.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO VENDER OURO USADO PARA A OUROMINAS?</summary>

            <ol className="mt-2">
              <li>
                A empresa negocia o ouro com spreads baixos e seguindo
                parâmetros de cotação tanto do mercado interno quanto do
                internacional, desta forma, propiciar ao vendedor a
                possibilidade de vender o ouro sempre no melhor preço do
                mercado.
              </li>
              <li>Vender ouro na OM é mais ágil e mais simples.</li>
              <li>
                A OM é uma empresa de tradição, possui mais de 40 anos de
                experiência no setor, conta com uma equipe bastante eficiente e
                profissional.
              </li>
              <li>
                A OM possui autorização do Banco Central e é credenciada na
                Comissão de Valores Mobiliários (CVM) para operar com o ouro,
                como toda DTVM.
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary> CADASTRO.</summary>
            <p className="mt-2">
              A OM, como instituição financeira e com fins de prevenção contra a
              lavagem de dinheiro e financiamento ao terrorismo (PLD/FT) é
              obrigada a seguir certos procedimentos cadastrais. A empresa
              protege as informações de seus clientes e não repassa a terceiros,
              salvo sob determinação do Banco Central do Brasil.
            </p>
            <ol>
              <li>
                Cadastro Simplificado: consiste no preenchimento da Ficha
                Cadastral com os dados básicos necessários e cópia simples dos
                documentos: RG / CPF / Comprovante de Endereço.
              </li>
              <li>
                Cadastro Completo: é o procedimento padrão de cadastro da
                instituição. Consiste no preenchimento da Ficha Cadastral, cópia
                simples dos documentos: RG / CPF / Comprovante de Endereço e
                documentação complementar para atribuição de limite operacional.
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>HORÁRIO DE NEGOCIAÇÃO. </summary>
            <p className="mt-2">
              Nosso horário de negociação: Segunda à Sexta, das 8:30 às 18:00h
              (Horário de Brasília). Temos horários diferenciados em alguns
              pontos de atendimento. Consulte quais lojas possuem avaliação do
              ouro na hora.
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
