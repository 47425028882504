import salvadorFrente1g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-1g-frente.png";
import salvadorFrente2g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-2g-frente.png";
import salvadorFrente5g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-5g-frente.png";
import salvadorFrente10g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-10g-frente.png";
import salvadorFrente25g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-25g-frente.png";
import salvadorFrente31g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-31g-frente.png";
import salvadorFrente50g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-50g-frente.png";

import salvadorVerso1g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-1g-verso.png";
import salvadorVerso2g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-2g-verso.png";
import salvadorVerso5g from "../../../assets/newImages/mockups/brasil-turistico/salvador/salvador-5g-verso.png";

import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  salvadorMockups = {
    nome: "Salvador",
    frente1g: salvadorFrente1g,

    frente2g: salvadorFrente2g,

    frente5g: salvadorFrente5g,

    frente10g: salvadorFrente10g,

    frente25g: salvadorFrente25g,

    frente31g: salvadorFrente31g,

    frente50g: salvadorFrente50g,

    verso1g: salvadorVerso1g,
    verso2g: salvadorVerso2g,

    verso5g: salvadorVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const salvadorBR = [
    {
      name: "Salvador",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]