import { useEffect, useState } from "react";
import { getCotacaoAtual } from "../../../../services/api";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import loading from "../../../../assets/spinner.gif";
import Button from "@mui/material/Button";
import {
  postMoedasEstrangeirasGuarulhos,
} from "../../../../services/cotacoes";

export default function CreateMoedaGuarulhos({ sync }) {
  const [moedas, setMoedas] = useState([]);
  const [selectedMoeda, setSelectedMoeda] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    getCotacaoAtual()
      .then(setMoedas)
      .finally(() => setLoad(false));
  }, []);

  const addCoin = () => {
    if (selectedMoeda) {
      const moeda = moedas.filter((moeda) => moeda.nome === selectedMoeda)[0];
      setLoad(true);
      postMoedasEstrangeirasGuarulhos(moeda).finally(() => {
        setLoad(false);
        sync();
      });
    }
  };
  return (
    <Container>
      <BoxLoading>{load && <Loading src={loading} />}</BoxLoading>

      <BoxAutoComplete>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedMoeda}
          onInputChange={(event, value) => {
            setSelectedMoeda(value);
          }}
          options={moedas.map((item) => item.nome)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Moeda" />}
        />
        <Button variant="contained" onClick={addCoin}>
          Adicionar
        </Button>
      </BoxAutoComplete>
    </Container>
  );
}
const Container = styled.div``;
const BoxAutoComplete = styled.div`
  display: flex;
  > div {
    margin-right: 10px;
  }
`;

const BoxLoading = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const Loading = styled.img`
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto;
`;
