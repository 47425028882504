import leaoFrente1g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-1g-frente.png";
import leaoFrente2g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-2g-frente.png";
import leaoFrente5g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-5g-frente.png";
import leaoFrente10g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-10g-frente.png";
import leaoFrente25g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-25g-frente.png";
import leaoFrente31g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-31g-frente.png";
import leaoFrente50g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-50g-frente.png";
import leaoVerso1g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-1g-verso.png";
import leaoVerso2g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-2g-verso.png";
import leaoVerso5g from "../../../assets/newImages/mockups/signos/leao/leao-ouro-5g-verso.png";

export const  leaoMockups = {
    nome: "Leão",
    frente1g: leaoFrente1g,
  
    frente2g: leaoFrente2g,
  
    frente5g: leaoFrente5g, 
    frente10g: leaoFrente10g,
    frente25g:leaoFrente25g,
    frente31g: leaoFrente31g,
    frente50g: leaoFrente50g, 
  
    verso1g: leaoVerso1g,
    verso2g: leaoVerso2g,
  
    verso5g: leaoVerso5g,
    verso10g: leaoVerso5g,
    verso25g: leaoVerso5g,
    verso31g: leaoVerso5g,
    verso50g: leaoVerso5g,
  };