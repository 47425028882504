import manausFrente1g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-1g-frente.png";
import manausFrente2g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-2g-frente.png";
import manausFrente5g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-5g-frente.png";
import manausFrente10g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-10g-frente.png";
import manausFrente25g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-25g-frente.png";
import manausFrente31g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-31g-frente.png";
import manausFrente50g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-50g-frente.png";
import manausVerso1g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-1g-verso.png";
import manausVerso2g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-2g-verso.png";
import manausVerso5g from "../../../assets/newImages/mockups/brasil-turistico/manaus/manaus-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  manausMockups = {
    nome: "Manaus",
    frente1g: manausFrente1g,

    frente2g: manausFrente2g,

    frente5g: manausFrente5g,

    frente10g: manausFrente10g,

    frente25g: manausFrente25g,

    frente31g: manausFrente31g,

    frente50g: manausFrente50g,

    verso1g: manausVerso1g,
    verso2g: manausVerso2g,

    verso5g: manausVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const manausBR = [
    {
      name: "Manaus",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]