import planteFrente1g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-1g-frente.png";
import planteFrente2g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-2g-frente.png";
import planteFrente5g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-5g-frente.png";
import planteFrente10g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-10g-frente.png";
import planteFrente25g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-25g-frente.png";
import planteFrente31g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-31g-frente.png";
import planteFrente50g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-50g-frente.png";
import planteVerso1g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-1g-verso.png";
import planteVerso2g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-2g-verso.png";
import planteVerso5g from "../../../assets/newImages/mockups/plante-arvore/plante-arvore-5g-verso.png";

export const  planteMockups = {
    nome: "Plante Árvore",
    frente1g: planteFrente1g,
  
    frente2g: planteFrente2g,
  
    frente5g: planteFrente5g,
  
    frente10g: planteFrente10g,
  
    frente25g: planteFrente25g,
  
    frente31g: planteFrente31g,
  
    frente50g: planteFrente50g,
  
    verso1g: planteVerso1g,
    verso2g: planteVerso2g,
  
    verso5g: planteVerso5g,
  };

  export const planteArvore = [
    {
      name: "Plante Árvore",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]