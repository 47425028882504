import arquitetoFrente1g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-1g-frente.png";
import arquitetoFrente2g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-2g-frente.png";
import arquitetoFrente5g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-5g-frente.png";
import arquitetoFrente10g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-10g-frente.png";
import arquitetoFrente25g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-25g-frente.png";
import arquitetoFrente31g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-31g-frente.png";
import arquitetoFrente50g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-50g-frente.png";
import arquitetoVerso1g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-1g-verso.png";
import arquitetoVerso2g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-2g-verso.png";
import arquitetoVerso5g from "../../../assets/newImages/mockups/profissoes/arquiteto/arquiteto-ouro-5g-verso.png";

export const  arquitetoMockups = {
    nome: "Arquiteto de Ouro",
    frente1g: arquitetoFrente1g,
  
    frente2g: arquitetoFrente2g,
  
    frente5g: arquitetoFrente5g,
  
    frente10g: arquitetoFrente10g,
  
    frente25g: arquitetoFrente25g,
  
    frente31g: arquitetoFrente31g,
  
    frente50g: arquitetoFrente50g,
  
    verso1g: arquitetoVerso1g,
    verso2g: arquitetoVerso2g,
  
    verso5g: arquitetoVerso5g,
    verso10g: arquitetoVerso5g,
    verso25g: arquitetoVerso5g,
    verso31g: arquitetoVerso5g,
    verso50g: arquitetoVerso5g,
  };

 