import { getLimiteOperacaoByMoeda } from '../modules/cliente/moedasService';

const { default: Dexie } = require('dexie');

class LocalDBDexie extends Dexie {
  constructor() {
    super('developDB');
    this.version(2).stores({
      carrinho:
        '++id, tipo, nome, quantidade , valor, toleranciaMin, toleranciaMax, liberado, liberadoBR, embalagemProtetora',
    });

    this.open();
    this.carrinho = this.table('carrinho');
  }

  async setCarrinho(item, cupom = null) {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        const itemExistente = await this.carrinho
          .where({ id: String(item.id) })
          .first();

        if (cupom) {
          const cupomValido = await this.validarCupom(cupom);

          if (cupomValido) {
            item.valor -= item.valor * (cupomValido.desconto / 100);
          } else {
            console.log('Cupom inválido, prosseguir sem desconto.');
          }
        }

        if (itemExistente) {
          await this.carrinho.where({ id: String(item.id) }).modify((value) => {
            value.quantidade += item.quantidade;
            value.nome = item.nome;
            value.valor = item.valor;
          });
        } else {
          await this.carrinho.add(item);
        }
      });
    } catch (error) {
      console.log('Erro ao salvar carrinho localmente: ', error);
    }
  }

  async deleteItemCarrinho(id) {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        await this.carrinho.delete(String(id));
      });
    } catch (error) {
      console.log('Erro ao salvar carrinho localmente: ', error);
    }
  }

  async cleanCarrinho() {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        await this.carrinho.clear();
      });
    } catch (error) {
      console.log('Erro ao limpar carrinho localmente: ', error);
    }
  }

  async getCarrinho() {
    const data = await db.carrinho.toArray();

    if (!(data.length > 0)) return [];

    for (const element of data) {
      try {
        const moedaData = await getLimiteOperacaoByMoeda(element.codIso);
        element.toleranciaMin = moedaData.toleranciaMin;
        element.toleranciaMax = moedaData.toleranciaMax;
        element.liberado = moedaData.liberado;
        element.liberadoBR = moedaData.liberadoBR;
      } catch (error) {
        console.log('Moeda Não encontrada!');
      }
    }

    return data;
  }

  async validarCupom(cupom) {
    try {
      const cupomValido = await verificarCupomDesconto(cupom);

      if (!cupomValido) {
        throw new Error('Cupom inválido ou expirado.');
      }

      return cupomValido;
    } catch (error) {
      console.log('Erro ao validar cupom: ', error);
      return null;
    }
  }
}

async function verificarCupomDesconto(cupom) {
  const cuponsValidos = {
    OMD10: {
      desconto: 10,
      descricao: 'Cupom de R$ 10,00 na compra de dólar em espécie',
      condicao: 'Válido nas compras acima de R$6.000,00',
      valorMinimo: 6000,
      observacao: 'Válido para qualquer tipo de dólar (USD, CAD, AUD, etc.)',
      moedasPermitidas: [
        'Dólar Americano',
        'Dólar Canadense',
        'Dólar Australiano',
        'Dólar Neozelandês',
      ],
    },
    OMAL44: {
      desconto: 44,
      descricao:
        'Cupom de R$ 44,00 na compra de moedas em espécie da América Latina',
      condicao: 'Válido para compras acima de R$2.000,00',
      valorMinimo: 2000,
      observacao:
        'Peso Chileno, Peso Argentino, Uruguaio, Novo Sol Peruano, etc',
      moedasPermitidas: [
        'Peso Chileno',
        'Peso Argentino',
        'Peso Uruguaio',
        'Sol Peruano',
        'Boliviano',
        'Peso Colombiano',
        'Peso Mexicano',
      ],
    },
    OME10: {
      desconto: 10,
      descricao: 'Cupom de R$ 10,00 na compra de euro em espécie',
      condicao: 'Válido para compras acima de R$6.000,00',
      valorMinimo: 6000,
      observacao: 'Válido apenas para euro (EUR)',
      moedasPermitidas: ['Euro'],
    },
  };

  return cuponsValidos[cupom] || null;
}

export async function updatedCarrinhoQuantidade(id, novaQuantidade) {
  try {
    await db.carrinho.where({ id: String(id) }).modify((item) => {
      item.quantidade = novaQuantidade;
    });
  } catch (error) {
    console.log('Erro ao atualizar a quantidade do carrinho: ', error);
  }
}

export const db = new LocalDBDexie();
