import axios from "axios";
import {
  getToken,
  getUserStorage,
  LogoutLoginStorage,
} from "../utils/localStorage";
import { env } from "./env";
import { postLogUser } from "./admin";
import { setLogModel } from "../models/Logs";

const url = env.linkAPI;
let token = getToken();

const returnUnauthorized = () => {
  LogoutLoginStorage();
  window.location.reload();
};

let AuthStr = `Bearer ${token}`;

function refreshToken() {
  token = getToken();
  AuthStr = `Bearer ${token}`;
  api = axios.create({
    baseURL: url,
    headers: {
      Authorization: AuthStr,
    },
  });
}

export let api = axios.create({
  baseURL: url,
  headers: {
    Authorization: AuthStr,
  },
});

export function getRemessasMore() {
  return api.get("/remessainternacionalmore").then((resp) => resp.data);
}

export function putRemessasMore(data) {
  refreshToken();
  return api
    .put("/remessainternacionalmore/" + data.id, {
      codIso: data.codIso,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Remessa Internacional More - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });

      alert("Remessa Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoin(err.response.status, data);
    });
}

export function postMoedasRemessaMore(data) {
  return api
    .post("/remessainternacionalmore", data)
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Moeda Estrangeira - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Criada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function postRemessaMore(data) {
  return api
    .post("/remessainternacionalmore", data)
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Criada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function getRemessas() {
  return api.get("/remessainternacional").then((resp) => resp.data);
}

export function putRemessas(data) {
  refreshToken();
  return api
    .put("/remessainternacional/" + data.id, {
      codIso: data.codIso,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Remessa Internacional - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });

      alert("Remessa Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoin(err.response.status, data);
    });
}

const ErrorCoins = (code) => {
  switch (parseInt(code)) {
    case 400:
      alert("Dados Inválidos!");
      break;
    case 401:
      alert("Sessão expirada!");
      returnUnauthorized();
      break;

      case 0:
        alert("Sessão expirada!");
        returnUnauthorized();
        break;

    default:
      alert("Erro interno, contacte a equipe de desenvolvimento.");
      break
  }
};
const ErrorCoin = async (code, data) => {
  switch (parseInt(code)) {
    case 400:
      alert("Dados Inválidos!");
      break;
    case 401:
      alert("Sessão expirada!");
      break;

    case 0:
      alert("Sessão expirada!");
      break;

    default:
      alert("Erro interno, contacte a equipe de desenvolvimento.");
      break
  }
};
export function getMoedasEstrangeira() {
  return api.get("/moedaestrangeira").then((resp) => resp.data);
}
export function getMoedasEstrangeiraGuarulhos() {
  return api.get("/moedaestrangeiraguarulhos").then((resp) => resp.data);
}

export function putMoedasEstrangeira(data) {
  return api
    .put("/moedaestrangeira/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
      valorMinimo: data.valorMinimo
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Moeda Estrangeira - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function putMoedasEstrangeirasGuarulhos(data) {
  return api
    .put("/moedaestrangeiraguarulhos/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
      valorMinimo: data.valorMinimo
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Moeda Estrangeira - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function postMoedasEstrangeirasGuarulhos(data) {
  return api
    .post("/moedaestrangeiraguarulhos", data)
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Moeda Estrangeira - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Criada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function getCartaoPrePago() {
  return api.get("/cartaoprepago").then((resp) => resp.data);
}

export function putCartaoPrePago(data) {
  return api
    .put("/cartaoprepago/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
      valorMinimo: data.valorMinimo
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Cartão Pré Pago - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });

      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function putCartaoPrePagoLojas(data) {
  return api
    .put("/cartaoprepagoloja/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Cartão Pré Pago Loja - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });

      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function putOuro(data) {
  return api
    .put("/ouroativo/" + data.id, {
      svd: data.svd,
      scp: data.scp,
      gramatura: data.gramatura,
      var: data.var,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Ouro Ativo - Edição de moeda: (${data.codIso}) - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function putNewsLetter(valor){
  return api.put("/newsletter"+ valor);
}

export function getMoedasEstrangeiraLojas() {
  return api.get("/moedaestrangeiraloja").then((resp) => resp.data);
}

export function getMoneygram() {
  return api.get("/moneygram").then((resp) => resp.data);
}
export function getCartaoPrePagoLojas() {
  return api.get("/cartaoprepagoloja").then((resp) => resp.data);
}

export function putMoedasEstrangeiraLoja(data) {
  refreshToken();
  return api
    .put("/moedaestrangeiraloja/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Moeda Estrangeira Loja - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function putMoneygram(data) {
  refreshToken();
  return api
    .put("/moneygram/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Moneygram - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}


export function putOuroLoja(data) {
  refreshToken();
  return api
    .put("/ouroativoloja/" + data.id, {
      svd: data.svd,
      scp: data.scp,
      gramatura: data.gramatura,
      var: data.var,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Ouro Ativo Loja - Edição de moeda: (${data.codIso}) - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      console.log("erro " +AuthStr)
      ErrorCoins(err.response.status);
    });
}


export async function getOuroLoja() {
  return await api.get("/ouroativoloja").then((resp) => resp.data);
}



export function putOuroLojaGuarulhos(data) {
  refreshToken();
  return api
    .put("/ouroativoGuarulhos/" + data.id, {
      svd: data.svd,
      scp: data.scp,
      gramatura: data.gramatura,
      var: data.var,
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Ouro Ativo Loja - Edição de moeda: (${data.codIso}) - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });
      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      console.log("erro " +AuthStr)
      ErrorCoins(err.response.status);
    });
}


export async function getOuroLojaGuarulhos() {
  return await api.get("/ouroativoGuarulhos").then((resp) => resp.data);
}

export function getCartaoPrePagoGuarulhosLojas() {
  return api.get("/cartaoprepagoGuarulhos").then((resp) => resp.data);
}
export function putCartaoPrePagoGuarulhosLojas(data) {
  refreshToken();
  return api
    .put("/cartaoprepagoGuarulhos/" + data.id, {
      codIso: data.codIso,
      cbc: data.cbc,
      cbv: data.cbv,
      svd: data.svd,
      scp: data.scp,
      icp: data.icp,
      ivd: data.ivd,
      valorMinimo: data.valorMinimo
    })
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Cartão Pré Pago - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
         postLogUser(model);
      });

      alert("Moeda Atualizada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}

export function postCartaoPrePagoGuarulhosLojas(data) {
  return api
    .post("/cartaoprepagoGuarulhos", data)
    .then(async () => {
      await getUserStorage().then((user) =>{
        const model = new setLogModel(user.id,`Cartão pré pago guarulhos - Edição de moeda: (${data.codIso}) ${data.nomeMoeda} - Usuário: ${user.username}`, "SUCCESS");
        postLogUser(model);

      })
      alert("Moeda Criada com sucesso!");
    })
    .catch((err) => {
      ErrorCoins(err.response.status);
    });
}