import fortalezaFrente1g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-1g-frente.png";
import fortalezaFrente2g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-2g-frente.png";
import fortalezaFrente5g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-5g-frente.png";
import fortalezaFrente10g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-10g-frente.png";
import fortalezaFrente25g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-25g-frente.png";
import fortalezaFrente31g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-31g-frente.png";
import fortalezaFrente50g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-50g-frente.png";
import fortalezaVerso1g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-1g-verso.png";
import fortalezaVerso2g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-2g-verso.png";
import fortalezaVerso5g from "../../../assets/newImages/mockups/brasil-turistico/fortaleza/fortaleza-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  fortalezaMockups = {
    nome: "Fortaleza",
    frente1g: fortalezaFrente1g,

    frente2g: fortalezaFrente2g,

    frente5g: fortalezaFrente5g,

    frente10g: fortalezaFrente10g,

    frente25g: fortalezaFrente25g,

    frente31g: fortalezaFrente31g,

    frente50g: fortalezaFrente50g,

    verso1g: fortalezaVerso1g,
    verso2g: fortalezaVerso2g,

    verso5g: fortalezaVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const fortalezaBR = [
    {
      name: "Fortaleza",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]