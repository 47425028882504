import francaFrente1g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-1g-frente.png";
import francaFrente2g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-2g-frente.png";
import francaFrente5g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-5g-frente.png";

import francaVerso1g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-1g-verso.png";
import francaVerso2g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-2g-verso.png";
import francaVerso5g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-5g-verso.png";
import francaVerso10g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-10g-verso.png";
import francaVerso25g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-25g-verso.png";
import francaVerso31g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-31g-verso.png";
import francaVerso50g from "../../../assets/newImages/mockups/golden-cup/franca/franca-ouro-50g-verso.png";

export const  francaMockups = {
    nome: "França",
    frente1g: francaFrente1g,
  
    frente2g: francaFrente2g,
  
    frente5g: francaFrente5g,  

    frente10g: francaFrente5g,
    frente25g:francaFrente5g,
    frente31g: francaFrente5g,
    frente50g: francaFrente5g,
  
    verso1g: francaVerso1g,
    verso2g: francaVerso2g,
  
    verso5g: francaVerso5g,

    verso10g: francaVerso10g,
    verso25g: francaVerso25g,
    verso31g: francaVerso31g,
    verso50g: francaVerso50g
  };
