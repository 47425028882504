export const goldenCup = [
  {
    name: "Argentina",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "Alemanha",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "Brasil",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "México",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "Espanha",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "EUA",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "França",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
  {
    name: "Catar",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },
    
    
  ];