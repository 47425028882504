import coordenadoraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-1g-frente.png";
import coordenadoraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-2g-frente.png";
import coordenadoraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-5g-frente.png";
import coordenadoraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-10g-frente.png";
import coordenadoraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-25g-frente.png";
import coordenadoraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-31g-frente.png";
import coordenadoraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-50g-frente.png";
import coordenadoraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-1g-verso.png";
import coordenadoraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-2g-verso.png";
import coordenadoraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/coordenadora/coordenadora-ouro-5g-verso.png";

export const  coordenadoraMockups = {
    nome: "Coordenadora",
    frente1g: coordenadoraFrente1g,
  
    frente2g: coordenadoraFrente2g,
  
    frente5g: coordenadoraFrente5g, 
    frente10g: coordenadoraFrente10g,
    frente25g:coordenadoraFrente25g,
    frente31g: coordenadoraFrente31g,
    frente50g: coordenadoraFrente50g, 
  
    verso1g: coordenadoraVerso1g,
    verso2g: coordenadoraVerso2g,
  
    verso5g: coordenadoraVerso5g
  };