import React, { useState } from 'react';
import './banner.css';
import { Carousel } from 'react-bootstrap';
import Home from './home';
import Global from "./global/global";

export default function Banner() {
  const [index, setIndex] = useState(0);
  const totalItems = 2;

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  }

  return (
    <>
      <Carousel className={`carouselBanner ${index === 0 ? 'disable-prev' : ''} ${index === totalItems - 1 ? 'disable-next' : ''}`} activeIndex={index} onSelect={handleSelect} controls={true}>
      <Carousel.Item interval={10000}>
          <section className="divBannerGlobal">
            <Global />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      <Carousel.Item interval={10000}>
          <section className="divBanner">
            <Home />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
