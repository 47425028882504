import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import NumberUtility from "../../../../utils/NumberUtility";
import CurrencyInput from "../../../inputs/CurrencyInputs";
import FormButton from "../../../forms/formButton/formButton";
import { QuoteInfoItemOuroDigital } from "../../../quoteInfo/quoteInfo";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../../context/shopp/cartShoppService";
import { maskNumberInt } from "../../../../utils/regex";
import "./simulator.css";
import { Colections, returnModel, ViewCarrouselGold } from "./utils";
import load from "../../../../assets/spinner.gif";
import { ReturnGrammageGold } from "../../../../utils/coins";
import { useMediaQuery } from "@material-ui/core";

export default function GoldCartSimulator({ data, error }) {
  const navigate = useNavigate();
  const [coin, setCoin] = useState(0);
  const [quant, setquant] = useState(1);
  const [valueRoyal, setValueRoyal] = useState(null);
  const [modelList, setModelList] = useState([]);
  const [colection, setColection] = useState();
  const { name, mockup } = useParams();
  const { toogleCArt, addProducts } = useContext(CartContext);
  const [type, setType] = useState("Comprar");
  const [modelUnique, setModelUnique] = useState("");
  const [dataModel, setDataModel] = useState([]);
  const [nameModel, setNameModel] = useState("");
  let cod = useRef(null);
  const text1g = {
    obsCompra:
      "Esta cotação é válida para compras online no site da Ourominas e Central de Vendas. Lojas próprias e correspondentes autorizados, podem praticar outros valores.",
    obsCompra1g:
      " A cotação das barras de 1 e 2 gramas possuem o valor mais elevado que as demais devido ser um produto exclusivo Ourominas, sendo a única DTVM a comercializar barras de 1 e 2g no formato de barra padrão, outras empresas comercializam estas gramaturas em formato laminado.",
    obsVenda:
      "Nesta página você conseguirá simular a venda de suas barras de ouro, porém não conseguirá finalizar a venda. Para efetuar a venda é necessária uma análise do ouro para comprovar a veracidade, por favor, se dirija a uma loja física, própria ou autorizada, para concluir a sua venda de forma presencial. Esta cotação pode sofrer alteração nas lojas físicas, sendo elas, próprias ou correspondentes autorizados.",
    obsDesconto: "Campanha Black Week: desconto de 5% para barras de ouro a partir de 1 grama até 1 quilo de ouro. Válido pelo período do dia 20/11/2023 até o dia 24/11/2023."
  };

  const [infoItems, setInfoItems] = useState({});

  useEffect(() => {
    if (coin) {
      selectCoin();
    }
      setValueRoyal(quant*coin);

  }, [quant, coin, valueRoyal]);

  useEffect(() => {
    setInfoItems({});
    setModelList([]);
    setCoin(0);
    setModelUnique("");
    setModelList([]);
    setColection(0);
  }, [type]);
  useEffect(() => {
    async function setColection() {
      await setModelList(() => returnModel(colection));
      setCoin(0);
      setModelUnique("");
      setInfoItems({});
      document.getElementById("selectModel").selectedIndex = "0";
    }
    setColection();
  }, [colection]);

  useEffect(() => {
    if (data) {
      if (modelUnique) {
        let arrModel = [];
        let arrData = [];
        arrModel.push(JSON.parse(modelUnique));
        arrModel.forEach((item) => {
          setNameModel(item.name);
          item.value.forEach((gramaturas) => {
            let a = data.filter((item) => {
              return item.codIso.includes(gramaturas);
            });
            a.forEach((item) => arrData.push(item));
          });
        });
        setDataModel(arrData);
      }
    }
    setCoin(0);
    setInfoItems({});
    cod = document.getElementById("selectG").selectedIndex = "0";
  }, [modelUnique]);

  function selectCoin(cod) {
    if (data) {
      data.forEach((item) => {
        if (type === "Comprar") {
          if (item.codIso === cod) {
            setInfoItems(item);
            setCoin(item.calculoGramaturaVenda);
          }
        }
        if (type === "Vender") {
          if (item.codIso === cod) {
            setInfoItems(item);
            setCoin(item.calculoGramaturaCompra);
          }
        }
      });
    }
  }

  async function selectCoin() {
    cod = document.getElementById("selectG");
    cod = cod.options[cod.selectedIndex].id;
    data.forEach((item) => {
      if (type === "Comprar") {
        if (item.codIso === cod) {
          setInfoItems(item);
        }
      }
      if (type === "Vender") {
        if (item.codIso === cod) {
          setInfoItems(item);
        }
      }
    });
  }
  async function AddItemsCoin() {
    let product = [];
    product = infoItems;
    product["tipo"] = name;
    product["opcao"] = type;
    product["quantidade"] = quant;
    product["nome"] = infoItems.codIso;
    product["valor"] = infoItems.calculoGramaturaVenda;
    product["colecao"] = colection;
    product["modelo"] = nameModel;
    if (coin) {
      await addProducts(product);
      toogleCArt("open");
    }
  }

  useEffect(() => {
    if (mockup === "plante-arvore") {
    }
  }, [mockup]);
  const matches = useMediaQuery("(max-width:960px)");
  return (
    <Container>
      <ShopDescription>Loja Online</ShopDescription>
      <ContainerInputs>
        {matches && (
          <BoxCart>
            {infoItems && modelUnique ? (
              <ViewCarrouselGold
                item={infoItems}
                name={name}
                type={type}
                model={nameModel}
              />
            ) : mockup === "plante-arvore" ? (
              <ViewCarrouselGold
                item={infoItems}
                name={name}
                type={type}
                model="Plante Árvore"
              />
            ) : mockup === "mae" ? (
              <ViewCarrouselGold
                item={infoItems}
                name={name}
                type={type}
                model="Mãe de Ouro"
              />
            ) : null}
          </BoxCart>
        )}

        <SelectCoin value="existentes">
          <LineLoading>
            {data?.length === 0 && !error && <Loading src={load} />}
            {error && <Error>Dados não disponíveis no momento. </Error>}
          </LineLoading>
          <ContentRow className="d-flex flex-column">
            <InputContainer type="mb">
              <InputLabel>Selecione a Coleção</InputLabel>
              <GrammageSelect
                onChange={(e) => setColection(e.target.value)}
                id="selectColection"
              >
                <option value={0}>Selecione</option>

                {Colections.map((item, i) => {
                  return (
                    <option key={i++} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </GrammageSelect>
            </InputContainer>
            <InputContainer type="mb">
              <InputLabel>Selecione o Modelo</InputLabel>
              <GrammageSelect
                onChange={(e) => setModelUnique(e.target.value)}
                id="selectModel"
              >
                <option value={""}>Selecione</option>
                {modelList &&
                  modelList?.map((item, i) => {
                    return (
                      <option key={i++} value={JSON.stringify(item)}>
                        {item.name}
                      </option>
                    );
                  })}
              </GrammageSelect>
            </InputContainer>

            <InputContainer type="mb">
              <InputLabel>Selecione a g</InputLabel>
              <GrammageSelect
                onChange={(e) => {
                  setCoin(e.target.value);
                }}
                id="selectG"
              >
                <option id="selectGold" value={0}>
                  Selecione
                </option>

                {type === "Comprar" &&
                  dataModel &&
                  dataModel?.map((item, i) => (
                    <option
                      id={item?.codIso}
                      key={i++}
                      value={item?.calculoGramaturaVenda}
                    >
                      {ReturnGrammageGold(item?.codIso)}
                    </option>
                  ))}
                {type === "Vender" &&
                  dataModel?.map((item, i) => (
                    <option
                      id={item?.codIso}
                      key={i++}
                      value={item?.calculoGramaturaCompra}
                    >
                      {ReturnGrammageGold(item?.codIso)}
                    </option>
                  ))}
              </GrammageSelect>
            </InputContainer>

            <InputContainerType>
              <GrammageSelect onChange={(e) => setType(e.target.value)}>
                <option value={"Comprar"}>Comprar</option>
                <option value={"Vender"}>Vender</option>
              </GrammageSelect>
            </InputContainerType>
            <InfoObs></InfoObs>
          </ContentRow>
        </SelectCoin>

        <ContentQuant>
          <Block>
            <ContentRow className="vw-20">
              <InputContainer>
                <InputLabel>Quantidade de barras</InputLabel>
                <InputQuant
                  value={quant}
                  onChange={(e) => setquant(maskNumberInt(e.target.value))}
                />
              </InputContainer>
            </ContentRow>

            {infoItems && (
              <QuoteInfoItemOuroDigital item={infoItems} type={type} />
            )}

            <ContentRow className="vw-20">
              <InputContainer>
                <InputLabel>Valor em reais</InputLabel>

                <CurrencyInput
                  type="tel"
                  disabled
                  id="inpValue"
                  value={"R$ " + NumberUtility.displayCurrencyValue(valueRoyal)}
                  maskOptions={{
                    prefix: "R$ ",
                    includeThousandsSeparator: true,
                    allowDecimal: true,
                    requireDecimal: true,
                  }}
                />
              </InputContainer>
            </ContentRow>
            <InfoCompra>
              {type === "Comprar" &&  <span>{text1g.obsCompra}</span>}
              {type === "Comprar" && name === "1g-2g" && <span>{text1g.obsCompra1g}</span>}
              {type === "Vender" && <span>{text1g.obsVenda}</span>}
            </InfoCompra>
          </Block>
        </ContentQuant>

        {!matches && (
          <BoxCart>
            {infoItems && modelUnique ? (
              <ViewCarrouselGold
                item={infoItems}
                name={name}
                type={type}
                model={nameModel}
              />
            ) : mockup === "plante-arvore" ? (
              <ViewCarrouselGold
                item={infoItems}
                name={name}
                type={type}
                model="Plante Árvore"
              />
            ) : mockup === "mae" ? (
              <ViewCarrouselGold
                item={infoItems}
                name={name}
                type={type}
                model="Mãe de Ouro"
              />
            ) : null}
          </BoxCart>
        )}
      </ContainerInputs>
      <ContainerButton>
        <ButtonRow>
          {type === "Comprar" ? (
            <FormButton
              className="btnAdd"
              variant="secondary"
              size="lg"
              onClick={() => {
                AddItemsCoin();
              }}
            >
              {type}
            </FormButton>
          ) : type === "Vender" ? (
            <FormButton
              className="btnAdd"
              variant="secondary"
              size="lg"
              onClick={() => navigate("/lojas")}
            >
              Loja mais próxima
            </FormButton>
          ) : null}
        </ButtonRow>
      </ContainerButton>
    </Container>
  );
}
const Container = styled.section`
background-color: white;
  flex-direction: column;
  @media (min-width: 320px) {
    padding: 20px 5px;
  }
  @media (min-width: 1000px) {
    border: 1px solid #e9ecef;
    padding: 50px 20px;
  }
  @media(min-width:1200px){
    padding: 50px 90px;
  }


`;
const ContainerInputs = styled.div`
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const ShopDescription = styled.div`
  display: flex;
  color: #677a8d;
  font-weight: bold;
`;

const ContentRow = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
  @media (min-width: 769px) {
    width: 250px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  padding: 10px 15px;
  height: 70px;
  width: 100%;
  margin-bottom: ${(props) => (props.type === "mb" ? " 10px" : null)};

  input[type="tel"] {
    border: none;
    border-radius: 6px;

    &:disabled {
      color: unset;
      background-color: unset;
    }
  }
`;
const InputContainerType = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #e9ecef;
  padding: 10px 15px;
  width: 100%;

  input[type="tel"] {
    border: none;
    border-radius: 6px;

    &:disabled {
      color: unset;
      background-color: unset;
    }
  }
`;
const ContentQuant = styled.div`
  width: 320px;
  //margin-right: 8vw;
  @media (max-width: 768px) {
    width: auto;
  }
  @media (min-width: 960px) {
    margin-left: 2vw;
  }
  @media (min-width: 1100px) {
    margin-left: 5vw;
  }
  @media (min-width: 1024px) {
    width: 620px;
  }
`;
const InputQuant = styled.input`
  border: 0 solid;
  width: 100%;
`;

const InputLabel = styled.div`
  font-weight: bold;
  color: #677a8d;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ButtonRow = styled.div`
  margin-top: 20px;
`;

const GrammageSelect = styled.select`
  border: none;
  background-color: white;

  border-radius: 0px;
`;
const ContainerButton = styled.div`
  padding: 0 10px;
  @media (min-width: 768px) {
    width: 516px;
    margin: 0 auto;
  }
`;
const InfoCompra = styled.p`
  text-align: justify;
  font-size: 12px;
  color: #677a8d;
  margin: 0;
  padding: 0;
  display: flex;
    flex-direction: column;

`;
const InfoObs = styled.span`
  text-align: start;
  font-size: 12px;
  color: #677a8d;
`;
const SelectCoin = styled.div`
  @media (min-width: 769px) {
    width: 250px;
  }
`;
const Block = styled.div`
  line-height: normal;
`;
export const LineLoading = styled.div`
  display: flex;
  justify-content: center;
`;
const Loading = styled.img`
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0 auto;
`;

export const Error = styled.span`
  font-size: 10px;
`;
export const BoxCart = styled.div`
  //width:600px;
`;