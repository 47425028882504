import advogadoFrente1g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-1g-frente.png";
import advogadoFrente2g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-2g-frente.png";
import advogadoFrente5g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-5g-frente.png";
import advogadoFrente10g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-10g-frente.png";
import advogadoFrente25g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-25g-frente.png";
import advogadoFrente31g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-31g-frente.png";
import advogadoFrente50g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-50g-frente.png";
import advogadoVerso1g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-1g-verso.png";
import advogadoVerso2g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-2g-verso.png";
import advogadoVerso5g from "../../../assets/newImages/mockups/profissoes/advogado/advogado-ouro-5g-verso.png";

export const  advogadoMockups = {
    nome: "Advogado de Ouro",
    frente1g: advogadoFrente1g,
  
    frente2g: advogadoFrente2g,
  
    frente5g: advogadoFrente5g,
  
    frente10g: advogadoFrente10g,
  
    frente25g: advogadoFrente25g,
  
    frente31g: advogadoFrente31g,
  
    frente50g: advogadoFrente50g,
  
    verso1g: advogadoVerso1g,
    verso2g: advogadoVerso2g,
  
    verso5g: advogadoVerso5g,
    verso10g: advogadoVerso5g,
    verso25g: advogadoVerso5g,
    verso31g: advogadoVerso5g,
    verso50g: advogadoVerso5g,
  };

 