import React, { useContext, useEffect} from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CartContext } from '../../../components/context/shopp/cartShoppService';
import { ReturnName } from '../../../utils/coins';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',

  '@media (min-width: 600px)': {
    width: '500px',
  },
};

export default function DetalhesPedido({item}) {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpen = (e, item) => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const { productsCart} = useContext(CartContext);
  let array = [productsCart];
  useEffect(() => {
    let value = 0;
    array[0].map((item) => {
      return (value += item.valor * item.quantidade);
    });
  }, [productsCart]);

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <div>
      <Button onClick={(e) => handleOpen(e, item)}>Ver detalhe do preço</Button>

        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {ReturnName(item?.codIso) || item?.nome} - Detalhamento do preço
              </Typography>
              <ButtonCloseModal variant="outline-danger" onClick={handleCloseModal}>
                X
              </ButtonCloseModal>
              <hr></hr>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TableDetalhe>
                <tbody>
                  <tr>
                    <TdTable>
                      Quantidade
                    </TdTable>
                    <TdTable>
                      {item.quantidade}
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      Valor do câmbio
                    </TdTable>
                    <TdTable>
                      {parseFloat(item?.calculoCompra
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      Embalagem protetora
                    </TdTable>
                    <TdTable>
                      R$ 0,00
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      Taxa administrativa
                    </TdTable>
                    <TdTable>
                    R$ 0,00
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      Taxa de corretagem
                    </TdTable>
                    <TdTable>
                    R$ 0,00
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      Tarifa bancária
                    </TdTable>
                    <TdTable>
                    R$ 0,00
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      IOF
                    </TdTable>
                    <TdTable>
                      {parseFloat(item?.calculoIOFVenda
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                        {" "}
                      ({item.ivd}%)
                    </TdTable>
                  </tr>
                  <tr>
                    <TdTable>
                      Total
                    </TdTable>
                    <TdTable>
                      {parseFloat(
                        item?.valor * item?.quantidade
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TdTable>
                  </tr>
                </tbody>
              </TableDetalhe>
            </Typography>
          </Box>
          </Modal>

    </div>
  );
}

const Button = styled.button `
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  color: #a9a9a9;
`;

const ButtonCloseModal = styled.button `
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 15px;
  font-weight: 700;
  color: #a9a9a9;
`;

const TableDetalhe = styled.table `
border-collapse: collapse;
width: 100%;
`

const TdTable = styled.td `
border: 1px solid #ddd;
 padding: 8px;
`
