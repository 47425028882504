import React from "react";
import img from "../../assets/whatsapp.png";
import { maskNumberInt } from "../../utils/regex";

export default function WhatsappIcon() {
  return (
    <div className="position-relative">
      <div className="position-fixed bottom-0 end-0 p-4 d-flex justify-content-end">
        <a
          className=""
          href="https://web.whatsapp.com/send?phone=551120501646"
          rel="noopene noreferrer"
          target="_blank"
        >
          <img src={img} width="50px" alt="ícone do Whatsapp"></img>
        </a>
      </div>
    </div>
  );
}
export function RedirectWhatsapp({ number }) {
  return (
    <>
      {number ? (
        <a
          className=""
          href={`https://api.whatsapp.com/send?phone=${maskNumberInt(
            number
          )}&type=phone_number&app_absent=0`}
          rel="noopene noreferrer"
          target="_blank"
        >
          {number}
        </a>
      ) : null}
    </>
  );
}
export function RedirectTel({ number }) {
  return (
    <>
      {number ? (
        <a
          className=""
          href={`tel:+55${maskNumberInt(number)}`}
          rel="noopene noreferrer"
          target="_blank"
        >
          {number}
        </a>
      ) : null}
    </>
  );
}

export function RedirectMail({ mail }) {
  return (
    <>
      {mail ? (
        <a
          className=""
          href={`mailto:${mail}`}
          rel="noopene noreferrer"
          target="_blank"
        >
          {mail}
        </a>
      ) : null}
    </>
  );
}
