import styled from "styled-components";

export const SendButton = styled.button`
background-color: var(--green);
  color: #fff;
  width: 260px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  border: 1px solid var(--green);
  border-radius: 4px;
  font-size: 12px;
  justify-self: center;

  &&:hover{
    background-color: #002c23;
  }
`