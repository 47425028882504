import sogroFrente1g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-1g-frente.png";
import sogroFrente2g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-2g-frente.png";
import sogroFrente5g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-5g-frente.png";
import sogroFrente10g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-10g-frente.png";
import sogroFrente25g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-25g-frente.png";
import sogroFrente31g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-31g-frente.png";
//import sogroFrente50g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-50g-frente.png";
import sogroVerso1g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-1g-verso.png";
import sogroVerso2g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-2g-verso.png";
import sogroVerso5g from "../../../assets/newImages/mockups/familia/sogro/sogro-ouro-5g-verso.png";

export const  sogroMockups = {
    nome: "Sogro de Ouro",
    frente1g: sogroFrente1g,
  
    frente2g: sogroFrente2g,
  
    frente5g: sogroFrente5g, 
    frente10g: sogroFrente10g,
    frente25g:sogroFrente25g,
    frente31g: sogroFrente31g,
    //frente50g: sogroFrente50g, 
  
    verso1g: sogroVerso1g,
    verso2g: sogroVerso2g,
  
    verso5g: sogroVerso5g,
    verso10g: sogroVerso5g,
    verso25g: sogroVerso5g,
    verso31g: sogroVerso5g,
    //verso50g: sogroVerso5g,
  };