import croaciaFrente1g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-1g-frente.png";
import croaciaFrente2g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-2g-frente.png";
import croaciaFrente5g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-5g-frente.png";
import croaciaFrente10g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-10g-frente.png";
import croaciaFrente25g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-25g-frente.png";
import croaciaFrente31g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-31g-frente.png";
import croaciaFrente50g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-50g-frente.png";
import croaciaVerso1g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-1g-verso.png";
import croaciaVerso2g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-2g-verso.png";
import croaciaVerso5g from "../../../assets/newImages/mockups/mundos/croacia/croacia-ouro-5g-verso.png";

export const  croaciaMockups = {
    nome: "Croácia",
    frente1g: croaciaFrente1g,
  
    frente2g: croaciaFrente2g,
  
    frente5g: croaciaFrente5g,
  
    frente10g: croaciaFrente10g,
  
    frente25g: croaciaFrente25g,
  
    frente31g: croaciaFrente31g,
  
    frente50g: croaciaFrente50g,
  
    verso1g: croaciaVerso1g,
    verso2g: croaciaVerso2g,
  
    verso5g: croaciaVerso5g,
    verso10g: croaciaVerso5g,
    verso25g: croaciaVerso5g,
    verso31g: croaciaVerso5g,
    verso50g: croaciaVerso5g,
  };

 