import vendedorFrente1g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-1g-frente.png";
import vendedorFrente2g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-2g-frente.png";
import vendedorFrente5g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-5g-frente.png";
import vendedorFrente10g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-10g-frente.png";
import vendedorFrente25g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-25g-frente.png";
import vendedorFrente31g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-31g-frente.png";
import vendedorFrente50g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-50g-frente.png";
import vendedorVerso1g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-1g-verso.png";
import vendedorVerso2g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-2g-verso.png";
import vendedorVerso5g from "../../../assets/newImages/mockups/empresa-ouro/vendedor/vendedor-ouro-5g-verso.png";

export const  vendedorMockups = {
    nome: "Vendedor",
    frente1g: vendedorFrente1g,
  
    frente2g: vendedorFrente2g,
  
    frente5g: vendedorFrente5g, 
    frente10g: vendedorFrente10g,
    frente25g:vendedorFrente25g,
    frente31g: vendedorFrente31g,
    frente50g: vendedorFrente50g, 
  
    verso1g: vendedorVerso1g,
    verso2g: vendedorVerso2g,
  
    verso5g: vendedorVerso5g
  };