import islamismoFrente1g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-1g-frente.png";
import islamismoFrente2g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-2g-frente.png";
import islamismoFrente5g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-5g-frente.png";
import islamismoFrente10g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-10g-frente.png";
import islamismoFrente25g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-25g-frente.png";
import islamismoFrente31g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-31g-frente.png";
import islamismoFrente50g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-50g-frente.png";
import islamismoVerso1g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-1g-verso.png";
import islamismoVerso2g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-2g-verso.png";
import islamismoVerso5g from "../../../assets/newImages/mockups/religiao/islamismo/islamismo-ouro-5g-verso.png";

export const  islamismoMockups = {
    nome: "Islamismo",
    frente1g: islamismoFrente1g,
  
    frente2g: islamismoFrente2g,
  
    frente5g: islamismoFrente5g, 
    frente10g: islamismoFrente10g,
    frente25g:islamismoFrente25g,
    frente31g: islamismoFrente31g,
    frente50g: islamismoFrente50g, 
  
    verso1g: islamismoVerso1g,
    verso2g: islamismoVerso2g,
  
    verso5g: islamismoVerso5g,
    verso10g: islamismoVerso5g,
    verso25g: islamismoVerso5g,
    verso31g: islamismoVerso5g,
    verso50g: islamismoVerso5g,
  };