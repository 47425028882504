import secretarioFrente1g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-1g-frente.png";
import secretarioFrente2g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-2g-frente.png";
import secretarioFrente5g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-5g-frente.png";
import secretarioFrente10g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-10g-frente.png";
import secretarioFrente25g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-25g-frente.png";
import secretarioFrente31g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-31g-frente.png";
import secretarioFrente50g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-50g-frente.png";
import secretarioVerso1g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-1g-verso.png";
import secretarioVerso2g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-2g-verso.png";
import secretarioVerso5g from "../../../assets/newImages/mockups/empresa-ouro/secretario/secretario-ouro-5g-verso.png";

export const secretarioMockups = {
  nome: "Secretário de Ouro",
  frente1g: secretarioFrente1g,

  frente2g: secretarioFrente2g,

  frente5g: secretarioFrente5g,

  frente10g: secretarioFrente10g,

  frente25g: secretarioFrente25g,

  frente31g: secretarioFrente31g,

  frente50g: secretarioFrente50g,

  verso1g: secretarioVerso1g,
  verso2g: secretarioVerso2g,

  verso5g: secretarioVerso5g,
  verso10g: secretarioVerso5g,
  verso25g: secretarioVerso5g,
  verso31g: secretarioVerso5g,
  verso50g: secretarioVerso5g,
};