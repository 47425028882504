import mexicoFrente1g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-1g-frente.png";
import mexicoFrente2g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-2g-frente.png";
import mexicoFrente5g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-5g-frente.png";

import mexicoVerso1g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-1g-verso.png";
import mexicoVerso2g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-2g-verso.png";
import mexicoVerso5g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-5g-verso.png";
import mexicoVerso10g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-10g-verso.png";
import mexicoVerso25g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-25g-verso.png";
import mexicoVerso31g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-31g-verso.png";
import mexicoVerso50g from "../../../assets/newImages/mockups/golden-cup/mexico/mexico-ouro-50g-verso.png";

export const  mexicoMockups = {
    nome: "México",
    frente1g: mexicoFrente1g,
  
    frente2g: mexicoFrente2g,
  
    frente5g: mexicoFrente5g,  

    frente10g: mexicoFrente5g,
    frente25g:mexicoFrente5g,
    frente31g: mexicoFrente5g,
    frente50g: mexicoFrente5g,
  
    verso1g: mexicoVerso1g,
    verso2g: mexicoVerso2g,
  
    verso5g: mexicoVerso5g,

    verso10g: mexicoVerso10g,
    verso25g: mexicoVerso25g,
    verso31g: mexicoVerso31g,
    verso50g: mexicoVerso50g
  };

 