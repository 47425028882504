import brasilFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/brasil-7g-frente.png";
import brasilVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/brasil-7g-verso.png";
import brasilEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-cristo.png";
import chinaFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/china-7g-frente.png";
import chinaVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/china-7g-verso.png";
import chinchenFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/chinchen-7g-frente.png";
import chinchenVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/chinchen-7g-verso.png";
import coliseuFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/coliseu-7g-frente.png";
import coliseuVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/coliseu-7g-verso.png";
import coliseuEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-coliseu.png";


import mahalFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/mahal-7g-frente.png";
import mahalVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/mahal-7g-verso.png";
import peruFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/peru-7g-frente.png";
import peruVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/peru-7g-verso.png";
import peruEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-machu.png";
import petraVerso7g from "../../../assets/newImages/mockups/maravilhas-mundo/petra-7g-verso.png";
import petraFrente7g from "../../../assets/newImages/mockups/maravilhas-mundo/petra-7g-frente.png";
import chinaEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-china.png";
import chinchenEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-chinchen.png";
import petraEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-petra.png";
import tajEspecificacao7g from "../../../assets/images/especificacoesOuro/ouro-7g-taj.png";

export const maravilhaMundo = [
    {
        name: "Chinchén Itza",
        value: ["OURO BARRA (7g)"],
      },{
        name: "Coliseu",
        value: ["OURO BARRA (7g)"],
      },{
        name: "Cristo Redentor",
        value: ["OURO BARRA (7g)"],
      },{
        name: "Machu Picchu",
        value: ["OURO BARRA (7g)"],
      },{
        name: "Muralha da China",
        value: ["OURO BARRA (7g)"],
      },{
        name: "Petra",
        value: ["OURO BARRA (7g)"],
      },{
        name: "Taj Mahal",
        value: ["OURO BARRA (7g)"],
      },
];

export const  chinchenMockups = {
        nome: "Chinchén Itza",  

        frente7g: chinchenFrente7g,
  
  
        verso7g: chinchenVerso7g,
        especificacao7g:  chinchenEspecificacao7g
  };

  
export const  cristoMockups = {
    nome: "Cristo Redentor",  

    frente7g: brasilFrente7g,


    verso7g: brasilVerso7g,
    especificacao7g:  brasilEspecificacao7g
};

export const  chinaMockups = {
    nome: "Muralha da China",  

    frente7g: chinaFrente7g,


    verso7g: chinaVerso7g,
    especificacao7g:  chinaEspecificacao7g
};

export const  coliseuMockups = {
    nome: "Coliseu",  

    frente7g: coliseuFrente7g,


    verso7g: coliseuVerso7g,
    especificacao7g: coliseuEspecificacao7g

};

export const  mahalMockups = {
    nome: "Taj Mahal",  

    frente7g: mahalFrente7g,


    verso7g: mahalVerso7g,
    especificacao7g:  tajEspecificacao7g
};

export const  machuMockups = {
  nome: "Machu Picchu",  

  frente7g: peruFrente7g,


  verso7g: peruVerso7g,
  especificacao7g:  peruEspecificacao7g
};

export const  petraMockups = {
  nome: "Petra",  

  frente7g: petraFrente7g,


  verso7g: petraVerso7g,
  especificacao7g:  petraEspecificacao7g
};
