import React from "react";
import styled from "styled-components";
import icoWork from "../../assets/images/professional/ico-home.png";
import icoPerson from "../../assets/images/professional/ico-people.png";
import icoGoldFoil from "../../assets/images/professional/ico-gold-foil.png";
import { Card } from "../../components/section/cards/cards";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export default function SectionCartaoOuro() {
  const name = useParams()

  useEffect(()=>{
    window.scrollTo(0,0)
  },[name])
  const itemsDescription = [
    {
      productName: "Cartões Existentes",
      href: "existentes",
      src: icoGoldFoil,
    },
    {
      productName: "Cartão Personalizado para Mim",
      href: "encaminhamento/para-mim",
      src: icoPerson,
    },
    {
      productName: "Cartão Personalizado para Minha Empresa",
      href: "encaminhamento/para-minha-empresa",
      src: icoWork,
    },
  ];
  return (
    <SectionContainer>
      <SectionIntroContainer>
        <h2 className="display-4">Cartão OuroFácil</h2>
      </SectionIntroContainer>

      <UL className="feature-list feature-list-sm row">
        {itemsDescription.map(({ href, src, productName }) =>
          Card(href, src, productName)
        )}
      </UL>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-color: #f8f9fa;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 50px;
`;
const UL = styled.ul`
  width: 100vw;
  justify-content: center;
  flex-wrap: wrap;

  && a {
    height: 168px;
  }
  @media (min-width: 320px) {
    && > li {
      width: 95%;
    }
  }
  @media (min-width: 520px) {
    && > li {
      width: 45%;
    }
  }
  @media (min-width: 1400px) {
    && > li {
      width: 33%;
    }
  }
`;
