import coordenadorFrente1g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-1g-frente.png";
import coordenadorFrente2g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-2g-frente.png";
import coordenadorFrente5g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-5g-frente.png";
import coordenadorFrente10g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-10g-frente.png";
import coordenadorFrente25g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-25g-frente.png";
import coordenadorFrente31g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-31g-frente.png";
import coordenadorFrente50g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-50g-frente.png";
import coordenadorVerso1g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-1g-verso.png";
import coordenadorVerso2g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-2g-verso.png";
import coordenadorVerso5g from "../../../assets/newImages/mockups/empresa-ouro/coordenador/coordenador-ouro-5g-verso.png";

export const  coordenadorMockups = {
    nome: "Coordenador",
    frente1g: coordenadorFrente1g,
  
    frente2g: coordenadorFrente2g,
  
    frente5g: coordenadorFrente5g, 
    frente10g: coordenadorFrente10g,
    frente25g:coordenadorFrente25g,
    frente31g: coordenadorFrente31g,
    frente50g: coordenadorFrente50g, 
  
    verso1g: coordenadorVerso1g,
    verso2g: coordenadorVerso2g,
  
    verso5g: coordenadorVerso5g
  };