import cunhadoFrente1g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-1g-frente.png";
import cunhadoFrente2g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-2g-frente.png";
import cunhadoFrente5g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-5g-frente.png";
import cunhadoFrente10g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-10g-frente.png";
import cunhadoFrente25g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-25g-frente.png";
import cunhadoFrente31g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-31g-frente.png";
import cunhadoFrente50g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-50g-frente.png";
import cunhadoVerso1g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-1g-verso.png";
import cunhadoVerso2g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-2g-verso.png";
import cunhadoVerso5g from "../../../assets/newImages/mockups/familia/cunhado/cunhado-ouro-5g-verso.png";

export const  cunhadoMockups = {
    nome: "Cunhado de Ouro",
    frente1g: cunhadoFrente1g,
  
    frente2g: cunhadoFrente2g,
  
    frente5g: cunhadoFrente5g, 
    frente10g: cunhadoFrente10g,
    frente25g:cunhadoFrente25g,
    frente31g: cunhadoFrente31g,
    frente50g: cunhadoFrente50g, 
  
    verso1g: cunhadoVerso1g,
    verso2g: cunhadoVerso2g,
  
    verso5g: cunhadoVerso5g,
    verso10g: cunhadoVerso5g,
    verso25g: cunhadoVerso5g,
    verso31g: cunhadoVerso5g,
    verso50g: cunhadoVerso5g,
  };