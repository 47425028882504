import novaDeliFrente1g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-1g-frente.png";
import novaDeliFrente2g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-2g-frente.png";
import novaDeliFrente5g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-5g-frente.png";
import novaDeliFrente10g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-10g-frente.png";
import novaDeliFrente25g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-25g-frente.png";
import novaDeliFrente31g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-31g-frente.png";
import novaDeliFrente50g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-50g-frente.png";
import novaDeliVerso1g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-1g-verso.png";
import novaDeliVerso2g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-2g-verso.png";
import novaDeliVerso5g from "../../../assets/newImages/mockups/mundos/nova-deli/nova-deli-ouro-5g-verso.png";

export const  novaDeliMockups = {
    nome: "Nova Deli",
    frente1g: novaDeliFrente1g,
  
    frente2g: novaDeliFrente2g,
  
    frente5g: novaDeliFrente5g,
  
    frente10g: novaDeliFrente10g,
  
    frente25g: novaDeliFrente25g,
  
    frente31g: novaDeliFrente31g,
  
    frente50g: novaDeliFrente50g,
  
    verso1g: novaDeliVerso1g,
    verso2g: novaDeliVerso2g,
  
    verso5g: novaDeliVerso5g,
    verso10g: novaDeliVerso5g,
    verso25g: novaDeliVerso5g,
    verso31g: novaDeliVerso5g,
    verso50g: novaDeliVerso5g,
  };

 