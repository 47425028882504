import React from 'react';
import Login from '../login/login';
import Cadastro from '../cadastro/cadastro';
import './dadosPessoais.css';

export default function DadosPessoais() {
  return (
    <div className="menuFinalizacao">
      <div className="menuFinaliza">
        <h1>Finalize seu pedido</h1>
        <p>Para finalizar a compra, faça seu login ou cadastre-se.</p>
      </div>
      <div className="formularios">
        <Login />
        <Cadastro />
      </div>
    </div>
  );
}
