import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Logo from "../../../assets/logo.png";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import {
  LogoutLoginStorage,
  getUserStorage,
} from "../../../utils/localStorage";
import { Button } from "react-bootstrap";

export default function NavBarAdmin() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  function exit() {
    LogoutLoginStorage();
    navigate("/admin/login");
  }

  useEffect(() => {
    async function fetchData() {
      await getUserStorage()
        .then((resp) => {
          if (!resp) {
            return exit();
          }
          setUser(resp);
        })
        .catch(() => exit());
    }

    if (!user) {
      fetchData();
    }
  });
  return (
    <Navbar expand="lg" sticky="top" className="header">
      <Container>
        <Navbar.Brand href="/">
          <Image src={Logo} className="logo" title="Ouro Minas" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse className="justify-content-between">
          <Nav className="my-2 my-lg-0">
            {(user?.role === "PRICING_COINS" || user?.role === "ADMIN") && (
              <NavDropdown
                className="links navDrop"
                title="Cotações"
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  href="/admin/cotacoes/cartao-pre-pago"
                  className="text-capitalize"
                >
                  Cartão Pré Pago
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="/admin/cotacoes/moedas-estrangeiras"
                  className="text-capitalize"
                >
                  Moedas Estrangeiras
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/cotacoes/moneygram"
                  className="text-capitalize"
                >
                  Moneygram
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/cotacoes/remessas-internacionais"
                  className="text-capitalize"
                >
                  Remessas Internacionais
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/cotacoes/remessas-more"
                  className="text-capitalize"
                >
                  More Payment
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/cotacoes/ouro"
                  className="text-capitalize"
                >
                  Ouro Ativo Financeiro
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {(user?.role === "MKT" || user?.role === "ADMIN") && (
              <NavDropdown
                className="links navDrop"
                title="Ações da Home"
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  href="/admin/home/alerta"
                  className="text-capitalize"
                >
                  Alerta
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/home/oferta"
                  className="text-capitalize"
                >
                  Oferta Câmbio
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/home/oferta-ouro"
                  className="text-capitalize"
                >
                  Oferta de Ouro
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/home/receba-cotacoes"
                  className="text-capitalize"
                >
                  Receba as Cotações
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/home/fique-por-dentro"
                  className="text-capitalize"
                >
                  Fique Por Dentro
                </NavDropdown.Item>
              </NavDropdown>
            )}

            {(user?.role === "PRICING_STORE" || user?.role === "ADMIN") && (
              <NavDropdown
                className="links navDrop"
                title="Cotações Lojas"
                id="navbarScrollingDropdown"
              >
                <NavDropdown
                  alignRight
                  title="Brás"
                  id="collasible-nav-dropdown"
                  className="text-capitalize navExport"
                >
                   <NavDropdown.Item
                  href="/admin/cotacoes/lojas/bras/moedas"
                  className="text-capitalize"
                >
                  Moedas Estrangeiras
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/cotacoes/lojas/bras/cartao"
                  className="text-capitalize"
                >
                  Cartão Pré Pago
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/admin/cotacoes/lojas/bras/ouro"
                  className="text-capitalize"
                >
                  Ouro Ativo Financeiro
                </NavDropdown.Item>
                </NavDropdown>


                <NavDropdown
                  alignRight
                  title="Guarulhos"
                  id="collasible-nav-dropdown"
                  className="text-capitalize navExport"
                >
                  <NavDropdown.Item
                    as={Link}
                    rel="canonical"
                    to="/admin/cotacoes/lojas/guarulhos/moeda"
                    className="text-capitalize border-bottom"
                  >
                    Moedas Estrangeiras
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    rel="canonical"
                    to="/admin/cotacoes/lojas/guarulhos/cartao"
                    className="text-capitalize border-bottom"
                  >
                    Cartão Pré Pago
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    rel="canonical"
                    to="/admin/cotacoes/lojas/guarulhos/ouro"
                    className="text-capitalize border-bottom"
                  >
                    Ouro
                  </NavDropdown.Item>
                </NavDropdown>


              </NavDropdown>
            )}

            {user?.role === "ADMIN" && (
              <NavDropdown
                className="links navDrop"
                title="PAINEL"
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  href="/admin/user/home"
                  className="text-capitalize"
                >
                  Usuários
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>

          <Nav className="my-2 my-lg-0">
            <Nav.Link href="#" className="links">
              <Button variant="outline-danger" onClick={() => exit()}>
                Sair
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
