import bahaiFrente1g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-1g-frente.png";
import bahaiFrente2g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-2g-frente.png";
import bahaiFrente5g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-5g-frente.png";
import bahaiFrente10g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-10g-frente.png";
import bahaiFrente25g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-25g-frente.png";
import bahaiFrente31g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-31g-frente.png";
import bahaiFrente50g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-50g-frente.png";
import bahaiVerso1g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-1g-verso.png";
import bahaiVerso2g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-2g-verso.png";
import bahaiVerso5g from "../../../assets/newImages/mockups/religiao/bahai/bahai-ouro-5g-verso.png";

export const  bahaiMockups = {
    nome: "Bahai",
    frente1g: bahaiFrente1g,
  
    frente2g: bahaiFrente2g,
  
    frente5g: bahaiFrente5g, 
    frente10g: bahaiFrente10g,
    frente25g:bahaiFrente25g,
    frente31g: bahaiFrente31g,
    frente50g: bahaiFrente50g, 
  
    verso1g: bahaiVerso1g,
    verso2g: bahaiVerso2g,
  
    verso5g: bahaiVerso5g,
    verso10g: bahaiVerso5g,
    verso25g: bahaiVerso5g,
    verso31g: bahaiVerso5g,
    verso50g: bahaiVerso5g,
  };