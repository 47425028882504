import React from "react";
import img from "../../../assets/newImages/indicador-financeiro.png";
import linha from "../../../assets/linha.png";
import "../../pages.css";
export default function IndicadorFinanceiro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              INDICADOR ATIVO FINANCEIRO
            </p>
            <p>
              Ser um Indicador Ativo Financeiro permite que seu cliente tenha a
              melhor estrutura de produtos e serviços em relação ao ouro ativo
              financeiro do país, da negociação ao fechamento da operação.
            </p>

            <p className=" mt-2  text-decoration-underline">
              Vantagens Indicador Ativo Financeiro
            </p>

            <ol>
              <li>
                <span>RECEITA</span>
                <p>
                  O indicador recebe uma comissão para cada operação concluída.
                </p>
              </li>
              <li>
                <span>ATENDIMENTO</span>
                <p>
                  Central de atendimento para suporte ao indicador, por meio da
                  plataforma Teams da Microsoft.
                </p>
              </li>
              <li>
                <span>DELIVERY</span>
                <p >
                  O indicador pode indicar clientes de todo Brasil, a OM faz
                  entrega em todo território nacional.
                </p>
              </li>
              <li>
                <span>EQUIPE ESPECIALIZADA</span>
                <p>
                  O indicador não necessariamente precisa entender de todo o
                  mercado, pois a OM faz o atendimento completo do consórcio, do
                  certificado em barra de ouro e da exportação.
                </p>
              </li>

              <li>
                <span> APLICATIVO</span>
                <p>
                  Facilidade na compra e venda de ouro com o app totalmente
                  online.
                </p>
              </li>
            </ol>

            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5 p-0">
          Ofereça a seus clientes todos os produtos e serviços relacionados ao{" "}
          <b>ouro, com ou sem um espaço físico.</b>
        </p>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Compra e venda:
              </li>
              <li className="list-unstyled">
                + de 10 tipos de gramaturas de barras de ouro.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Cartão OuroFácil:
              </li>
              <li className="list-unstyled">
                Um produto exclusivo e patenteado pela OM.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Exportação:
              </li>
              <li className="list-unstyled">
                Uma maneira rápida e segura de fazer exportação para empresas.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Consórcio:
              </li>
              <li className="list-unstyled">
                O primeiro e único consórcio de ouro do Brasil, através da
                Âncora Consórcios, empresa líder no segmento.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Certificados:
              </li>
              <li className="list-unstyled">
                Premiação com barras de ouro no formato de certificados.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Ouro digital:
              </li>
              <li className="list-unstyled">
                O cliente do indicador compra e vende ouro digital através do
                app.
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
/*
const Span = styled.p`
  font-size: 12px;
`;
*/