import React, { useEffect, useState } from 'react';
import InputsOM from '../components/inputs';
import styled from 'styled-components';
import {
  maskCPF,
  maskDDD,
  maskEmail,
  maskOnlyLetters,
  maskPhoneNotDDD,
  validEmail,
} from '../../utils/regex';
import { Cliente } from '../../models/modules/Cliente/Cliente';
import { getClienteCodigo } from '../../utils/localStorage';
import { getClienteByCodigo, putCliente } from '../../services/modules/cliente/clienteService';
import { PageLoading } from '../../components/Loading/Loading';
import DocumentosPage from './documentos';

export default function DadoPessoal() {
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [errNome, setErrNome] = useState('');
  const [errEmail, setErrEmail] = useState('');
  const [errDdd, setErrDdd] = useState('');
  const [errTelefone, setErrTelefone] = useState('');
  const [load, setLoad] = useState(false);
  const [data,setData] = useState<Cliente>();

  const validateFields = () => {
    setErrNome('');
    setErrEmail('');
    setErrDdd('');
    setErrTelefone('');

    let errorCount = 0;

    if (!nome) {
      setErrNome('Digite seu nome');
      errorCount++;
    }

    if (!email || !validEmail.test(email)) {
      setErrEmail('Digite um email válido');
      errorCount++;
    }

    if (!ddd || ddd.length !== 2) {
      setErrDdd('Digite um DDD válido');
      errorCount++;
    }

    if (!telefone || telefone.length !== 10) {
      setErrTelefone('Digite um número de telefone válido');
      errorCount++;
    }

    return errorCount === 0;
  };

  const handleDadosCadastrais = async (e) => {
    e.preventDefault();
    if(validateFields()){
      const payload: Cliente = {
        ...data,
        dddCelular: ddd,
        celular: telefone,
        email: email
      }
      setLoad(true)
     await putCliente(payload).then(() => {
        getCliente();
      }).catch(() => setLoad(false));
    }
  };

  useEffect(() =>{
    getCliente();
  },[])
  const getCliente = () => {
    const codigo = getClienteCodigo();
    setLoad(true);
    getClienteByCodigo(codigo).then(setData).catch(e => e).finally(() =>{
      setLoad(false);
    })
  }
  useEffect(() =>{
    if(data){
      setNome(data.nome);
      setEmail(data.email);
      setCpf(data.cpfCnpj);
      setDdd(data.dddCelular);
      setTelefone(data.celular);
    }
  }, [data])
  return (
    <MenuPrincipal>
      <SectionContainer>
        {load && <PageLoading/>}
        <SectionIntroContainer>
          <h2 className="display-4">Dados Pessoais</h2>
        </SectionIntroContainer>
        <DocumentosPage data={data} update={getCliente}/>
        <FormularioEntrega onSubmit={handleDadosCadastrais}>
          <InputsOM
            label="Nome Completo"
            type="text"
            disabled
            value={nome}
            onChange={(e) => setNome(maskOnlyLetters(e.target.value))}
          />
          <span className="text-danger">{errNome}</span>
          <br />
          <div className="dados">
            <InputsOM
              label="CPF"
              value={cpf}
              placeholder=""
              onChange={(e) => setCpf(maskCPF(e.target.value))}
              disabled
            />
            <br />
            <div className="emailCelular">
              <div className="divCelular">
                <Label>Celular</Label>
                <div className="inputCelular">
                  <input
                    className="ddd"
                    value={ddd}
                    onChange={(e) => setDdd(maskDDD(e.target.value))}
                  />
                  <span className="text-danger">{errDdd}</span>
                  <input
                    className="celular"
                    value={telefone}
                    onChange={(e) =>
                      setTelefone(maskPhoneNotDDD(e.target.value))
                    }
                  />
                  <span className="text-danger">{errTelefone}</span>
                </div>
              </div>
            </div>
            <br />
            <InputsOM
              label="E-mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(maskEmail(e.target.value))}
            />
            <span className="text-danger">{errEmail}</span>
          </div>
          <Button type="submit">Atualizar dados</Button>
        </FormularioEntrega>
      </SectionContainer>
    </MenuPrincipal>
  );
}

const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;
`;

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;

  h2 {
    text-transform: uppercase;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: var(--light);
`;

const FormularioEntrega = styled.form`
  background-color: #d8d8d8;
  border-radius: 8px;
  padding: 30px 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  .inputCelular {
    width: 100%;
    display: flex;
  }
  .celular {
    width: 100%;
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
  }

  .ddd {
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
    width: 50px;
    margin-right: 10px;
  }

  @media (min-width: 800px) {
    width: 760px;
  }

  @media (min-width: 1940px) {
    .dados {
      display: flex;
      justify-content: space-between;
    }
    .inputCelular {
      margin-left: 5px;
    }
  }
`;

const Button = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 45px;
  margin: 35px auto 0;
`;
