import moscowFrente1g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-1g-frente.png";
import moscowFrente2g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-2g-frente.png";
import moscowFrente5g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-5g-frente.png";
import moscowFrente10g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-10g-frente.png";
import moscowFrente25g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-25g-frente.png";
import moscowFrente31g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-31g-frente.png";
import moscowFrente50g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-50g-frente.png";
import moscowVerso1g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-1g-verso.png";
import moscowVerso2g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-2g-verso.png";
import moscowVerso5g from "../../../assets/newImages/mockups/mundos/moscow/moscow-ouro-5g-verso.png";

export const  moscowMockups = {
    nome: "Moscou",
    frente1g: moscowFrente1g,
  
    frente2g: moscowFrente2g,
  
    frente5g: moscowFrente5g,
  
    frente10g: moscowFrente10g,
  
    frente25g: moscowFrente25g,
  
    frente31g: moscowFrente31g,
  
    frente50g: moscowFrente50g,
  
    verso1g: moscowVerso1g,
    verso2g: moscowVerso2g,
  
    verso5g: moscowVerso5g,
    verso10g: moscowVerso5g,
    verso25g: moscowVerso5g,
    verso31g: moscowVerso5g,
    verso50g: moscowVerso5g,
  };

 