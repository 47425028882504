export const isEmptyObject = (obj): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  export const calcularDiaUtil = (diasParaSomar: number): Date => {
    // Obtém a data atual
    let novaData = new Date();

    // Adiciona 1 dia para começar a partir do próximo dia
    novaData.setDate(novaData.getDate() + 1);

    // Soma os dias informados (começando a partir do próximo dia)
    novaData.setDate(novaData.getDate() + diasParaSomar);

    // Verifica se a nova data cai em um sábado (6) ou domingo (0)
    if (novaData.getDay() === 6) { // Se for sábado
      novaData.setDate(novaData.getDate() + 2); // Ajusta para segunda-feira
    } else if (novaData.getDay() === 0) { // Se for domingo
      novaData.setDate(novaData.getDate() + 1); // Ajusta para segunda-feira
    }

    return novaData;
  };



export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.onerror = (error) => reject(error);
  });
};
