import React from "react";
import "../../pages.css";
import img from "../../../assets/remessa-internacional.png";
import linha from "../../../assets/linha.png";

export default function RemessaInternacional() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">REMESSA INTERNACIONAL</p>
            <p>
            Se você ou sua empresa precisa enviar ou receber dinheiro e realizar
            um pagamento em outro país, a Ourominas (OM) oferece um serviço ágil
            e seguro, que permite ao cliente realizar remessas e pagamentos
            internacionais do ou para o exterior, com processos muito
            simplificados.
          </p>
          <p>
            Com as remessas internacionais, você pode enviar ou receber dinheiro
            de/para familiares, pagar cursos em território estrangeiro, realizar
            transações financeiras internacionais, importação e exportação,
            entre outros. A tarifa é fixa para qualquer país e moeda.
          </p>
          <p>
            É possível também realizar conversões de moedas – de reais para a do
            país de destino – de maneira simples e enviar com toda segurança e
            rapidez. Em muitos casos, as remessas internacionais são creditadas
            de 1 (um) à 2 (dois) dias úteis (de acordo com o país, o fuso
            horário da transferência e também quantidade).
          </p>
          <p>
            A OM dispõe de equipe capacitada para executar, orientar e
            esclarecer as dúvidas dos nossos clientes que queiram realizar
            Remessas Internacionais.
          </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section className="mb-4 sect">      
      </section>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">O QUE É REMESSA INTERNACIONAL?</summary>
          <p className="mt-4">
            Remessa Internacional é uma solução para transferências
            internacionais. Você pode enviar dinheiro para o exterior ou receber
            valores de outros países de forma rápida, barata, segura e 100%
            digital.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS MOEDAS A OUROMINAS FAZ REMESSA?
          </summary>
          <ol className="mt-4">
            <li>
              A OM atualmente faz remessas com 9 (nove) moedas estrangeiras:
              Dólar Americano (USD), Euro (EUR), Libra Esterlina (GBP), Dólar
              Australiano (AUD), Dólar Canadense (CAD), Franco Suíço (CHF), IENE
              japonês (JPY), Peso Mexicano (MXN) e Coroa Sueca (SEK).
            </li>
          </ol>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS AS TAXAS E IMPOSTOS COBRADOS?
          </summary>
          <p className="mt-4">
            Na OM, é cobrada uma taxa administrativa fixa de 1.3% sobre as
            transferências. Esse custo é o menor do mercado, porque acreditamos
            que as pessoas não deveriam ficar presas às taxas altas dos bancos
            tradicionais.
          </p>
          <p>
            Também é cobrado o IOF (imposto sobre operações financeiras, cobrado
            pela Receita Federal) e uma tarifa das despesas das operações para
            realizar as transferências internacionais. Essa tarifa varia de
            acordo com a operação.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EM QUANTO TEMPO O DINHEIRO CHEGA NO DESTINO FINAL?
          </summary>
          <p className="mt-4">
            Em média, após a confirmação do pagamento e da documentação, quando
            necessária, o beneficiário ou favorecido recebe o dinheiro em até 1
            (um) dia útil (USD), outras moedas em até 03 (três) dias úteis. No
            caso de recebimentos de valores do exterior, o dinheiro pode ser
            identificado na plataforma em até 2 (dois) dias úteis e você pode
            solicitar o resgate quando quiser. Uma vez solicitado o fechamento
            de câmbio, creditamos o valor em 1 (um) dia útil.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS SÃO OS LIMITES OPERACIONAIS?
          </summary>
          <p className="mt-4">
            De acordo com a Resolução nº 4.811 de 30 de Abril de 2020 o cliente
            pode operar até US$300.000,00 (trezentos mil dólares dos Estados
            Unidos) ou o seu equivalente em outras moedas por operação.
            Ressaltamos que independente do valor estipulado por esta Resolução,
            o limite será estipulado de acordo com o documento apresentado como
            respaldo financeiro.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS OS DADOS NECESSÁRIOS PARA ENVIAR?
          </summary>
          <p className="mt-4">Para quem envia:</p>
          <ul>
            <li>
              Nome completo do favorecido, ou seja, a pessoa quem irá receber o
              dinheiro;
            </li>
            <li>Número da conta bancária do favorecido ou código IBAN;</li>
            <li>
              Código do banco do favorecido, sendo ele o código SWIFT ou ABA;
            </li>
            <li>
              Além disso, para facilitar a procura pela transferência, você pode
              incluir uma identificação de pagamento. Assim você ajuda o
              favorecido a identificar a transferência de valores.
            </li>
            <p>Para quem recebe:</p>
            <li>
              O favorecido precisa obrigatoriamente ter uma conta bancária,
              podendo ela ser uma conta pessoa física ou jurídica.
            </li>
          </ul>
          <p>
            *Os dados do favorecido podem variar dependendo do país escolhido.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            POSSO ENVIAR DINHEIRO PARA QUALQUER PAÍS?
          </summary>
          <p className="mt-4">
            A OM trabalha com uma lista de países reconhecidos pelo Banco
            Central e a partir dela são avaliados quais países podem ou não
            receber transferências internacionais. Veja abaixo uma lista de
            países em que somos proibidos de operar:
          </p>
          <ul className="mt-4">
            <li>Afeganistão;</li>
            <li>Cuba;</li>
            <li>Iémen;</li>
            <li>Irã;</li>
            <li>Iraque;</li>
            <li>Líbia;</li>
            <li>Mauritânia;</li>
            <li>Mianmar (Birmânia);</li>
            <li>Sudão do Sul (Rep. do Sudão do Sul);</li>
            <li>Paquistão;</li>
            <li>Coréia do Norte;</li>
            <li>República da África Central (Rep. Centro-Africana);</li>
            <li>Síria;</li>
            <li>Somália;</li>
            <li>Sudão (Rep. do Sudão);</li>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            EXEMPLOS DE PAGAMENTOS OU RECEBIMENTOS DE VALORES ESTRANGEIROS
          </summary>
          <ul className="mt-4">
            <li>Doações;</li>
            <li>Aposentadorias e pensões;</li>
            <li>Royalties;</li>
            <li>Importação;</li>
            <li>Exportação;</li>
            <li>Trânsito Internacional de Capitais;</li>
            <li>Cursos no Exterior;</li>
            <li>Manutenção de familiares no exterior;</li>
            <li>
              Recebimento de recursos de familiares domiciliados no exterior;
            </li>
            <li>Pagamentos de operadoras de turismo;</li>
            <li>Reserva de hotéis;</li>
            <li>Investimentos;</li>
            <li>Compra e venda de imóvel;</li>
            <li>ROF/RDE;</li>
            <li>Inscrição em congressos e feiras.</li>
          </ul>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            VANTAGENS DAS REMESSAS INTERNACIONAIS DO EXTERIOR
          </summary>
          <ul className="mt-4">
            <li>Custos reduzidos;</li>
            <li>
              Serviço personalizado, atendimento de acordo com a necessidade
              específica de cada cliente;
            </li>
            <li>Pouca burocracia;</li>
            <li>
              Correto enquadramento das operações, seguindo a legislação cambial
              vigente no Brasil;
            </li>
            <li>
              Ordens de Pagamento do Exterior – Um serviço que possibilita
              clientes pessoas físicas e/ou jurídicas, residentes no Brasil, a
              receberem recursos em moeda nacional provenientes do exterior.
            </li>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            VANTAGENS DAS REMESSAS PARA O EXTERIOR
          </summary>
          <ul className="mt-4">
            <li>Agilidade no processamento das operações;</li>
            <li>Equipe qualificada e uso de tecnologia de ponta;</li>
            <li>Flexibilidade e adequação às necessidades de cada cliente;</li>
            <li>Taxas de câmbio e tarifas competitivas;</li>
            <li>
              Ordens de Pagamento para o Exterior – Um serviço que possibilita
              pessoas físicas e jurídicas, residentes no Brasil a remeterem
              recursos em moeda estrangeira ao exterior.
            </li>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">POR QUE ESCOLHER A OUROMINAS?</summary>
          <ul className="mt-4">
            <li>
              A empresa negocia o câmbio com spreads baixos e seguindo
              parâmetros de cotação tanto do mercado interno quanto do
              internacional, de forma a propiciar a possibilidade de o comprador
              enviar ou receber a moeda estrangeira que deseja sempre no menor
              preço do mercado. Na OM o cliente encontra uma excelente cotação.
            </li>
            <li>
              As cotações são transparentes e o comprador não precisa pagar mais
              caro apenas porque está enviando ou recebendo uma pequena
              quantidade, o que normalmente acontece em muitas empresas.
            </li>
            <li>
              A OM é uma empresa de tradição, possui mais de 40 anos de
              experiência no setor e conta com uma equipe bastante eficiente e
              profissional.
            </li>
            <li>
              A OM possui autorização do Banco Central e é credenciada na
              Comissão de Valores Mobiliários (CVM) para operar com o ouro, como
              toda DTVM.
            </li>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">CADASTRO. </summary>
          <ol className="mt-4">
            <li>
              A OM, como instituição financeira e com fins de prevenção contra a
              lavagem de dinheiro e financiamento ao terrorismo (PLD/FT) é
              obrigada a seguir os procedimentos cadastrais. A empresa protege
              as informações de seus clientes e não repassa a terceiros, salvo
              sob determinação do Banco Central do Brasil.
            </li>
            <li>
              Cadastro Completo: é o procedimento padrão de cadastro da
              instituição. Consiste no preenchimento da Ficha Cadastral, cópia
              simples dos documentos: RG / CPF / Comprovante de Endereço e
              documentação complementar para atribuição de limite operacional. A
              este cliente é dado um limite operacional, para acompanhamento
              interno da empresa.
            </li>
          </ol>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">HORÁRIO DE NEGOCIAÇÃO.</summary>
          <p className="mt-4">
          Horário de negociação de Segunda-Feira à Sexta-Feira das 9h às 16:30
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            NÃO RECONHEÇO A TRANSAÇÃO EM MEU EXTRATO. O QUE DEVO FAZER?
          </summary>
          <p className="mt-4">
            Verifique se o valor não foi realmente utilizado por meio dos
            comprovantes de compras e saques.
          </p>
          <p>
            Acesse o extrato do seu cartão, vá até a caixa de seleção, ao lado
            da operação que você não reconhece, e clique no botão “Contestar
            Transações Selecionas”.
          </p>
        </details>
        </section>
      </section>
    </article>
  );
}
