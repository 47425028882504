export const OfertOuro = {
  nome: "",
  email: "",
  telefone: "",
  codIso: "",
  valor: 0,
  gramatura: 0,
  quantidade: "0",
  comentario: "",
};

export const OfertCoin = {
  nome: "",
  email: "",
  telefone: "",
  codIso: "",
  valor: 0,
  quantidade: "0",
  comentario: "",
};
