import dentistaFrente1g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-1g-frente.png";
import dentistaFrente2g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-2g-frente.png";
import dentistaFrente5g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-5g-frente.png";
import dentistaFrente10g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-10g-frente.png";
import dentistaFrente25g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-25g-frente.png";
import dentistaFrente31g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-31g-frente.png";
import dentistaFrente50g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-50g-frente.png";
import dentistaVerso1g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-1g-verso.png";
import dentistaVerso2g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-2g-verso.png";
import dentistaVerso5g from "../../../assets/newImages/mockups/profissoes/dentista/dentista-ouro-5g-verso.png";

export const  dentistaMockups = {
    nome: "Dentista de Ouro",
    frente1g: dentistaFrente1g,
  
    frente2g: dentistaFrente2g,
  
    frente5g: dentistaFrente5g,
  
    frente10g: dentistaFrente10g,
  
    frente25g: dentistaFrente25g,
  
    frente31g: dentistaFrente31g,
  
    frente50g: dentistaFrente50g,
  
    verso1g: dentistaVerso1g,
    verso2g: dentistaVerso2g,
  
    verso5g: dentistaVerso5g,
    verso10g: dentistaVerso5g,
    verso25g: dentistaVerso5g,
    verso31g: dentistaVerso5g,
    verso50g: dentistaVerso5g,
  };

 