import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReturnName } from '../../../src/utils/coins';
import { NavLink } from 'react-router-dom';
import FormButton from '../../../src/components/forms/formButton/formButton';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../services/dexie/stores';

export default function InformacoesPedido({
  linkButton,
  textButton,
  show,
  onClick,
  desconto,
}) {
  const [data, setData] = useState([]);
  const [soma, setSoma] = useState(0);

  const observable = useLiveQuery(() => db.carrinho.toArray());

  useEffect(() => {
    async function set() {
      const resp = await db.carrinho.toArray();
      let value = 0;
      resp.map((item) => {
        return (value += item.valor * item.quantidade);
      });
      setSoma(value);
      setData(resp);
    }
    set();
  }, [observable]);

  const totalComDesconto =
    desconto > 0
      ? desconto > 1
        ? soma - desconto
        : soma - (soma * desconto) / 100
      : soma;

  return (
    <SectionCarrinho>
      <Section>
        <Header>
          <h5 className="m-0">Informações sobre o pedido</h5>
        </Header>
        <hr></hr>
        {data &&
          data.map((item, i) => (
            <div key={i}>
              <TotalItems>
                <LineItem>
                  <p className="m-0 fw-bold">
                    {ReturnName(item?.codIso) || item?.nome}
                  </p>
                </LineItem>
                <ColumnOffer>
                  <p>
                    {parseFloat(item?.valor * item?.quantidade).toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </p>
                </ColumnOffer>
              </TotalItems>
              <hr></hr>
            </div>
          ))}

        <TotalItems>
          <p>Desconto</p>
          <p>
            {desconto > 1
              ? desconto.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : ((soma * desconto) / 100).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
          </p>
        </TotalItems>

        <TotalItems>
          <p>Total</p>
          <p>
            {totalComDesconto.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </p>
        </TotalItems>
        <hr></hr>
        {show && (
          <NavLink to={linkButton} onClick={onClick}>
            <ButtonRow className="d-flex justify-content-center">
              <FormButton
                style={{ display: 'block' }}
                className="formasEntrega btnAdd"
                variant="secondary"
                size="lg"
              >
                {textButton}
              </FormButton>
            </ButtonRow>
          </NavLink>
        )}
      </Section>
      <div className="contato">
        <p>Precisa de ajuda?</p>
        <a
          href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Chat agora
        </a>
      </div>
    </SectionCarrinho>
  );
}

const SectionCarrinho = styled.div`
  width: 100%;
  height: 100%;
  font-family: CenturyGothic;
  font-weight: 600;
`;

const Section = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 2em rgba(63, 61, 61, 0.11);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  a {
    text-decoration: none;
  }

  @media (min-width: 450px) {
    width: 400px;
  }

  @media (min-width: 750px) {
    height: 100%;
  }

  @media (min-width: 1000px) {
    width: 400px;
  }
`;

const Header = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0 10px;
  align-items: center;
  > h5 {
    font-weight: 600;
    color: #5b5b5f;
    font-size: 16px;
  }
`;

const ButtonRow = styled.div`
  margin: 20px 15px;

  .formasEntrega {
    text-transform: uppercase;
    margin: 0 auto;
    border-radius: 5px;
  }
`;

const TotalItems = styled.div`
  margin: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    margin: 0;
  }
`;

const LineItem = styled.div`
  display: flex;
  align-items: center;
`;

const ColumnOffer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > p {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  > span {
    font-size: 12px;
  }
`;
