import React from "react";
import styled from "@emotion/styled";
import CountryFlags from "../../utils/countryFlags";
import { useNavigate } from "react-router-dom";
import { ReturnName } from "../../utils/coins";

export function PedidoResumo(props: { item; button: boolean }) {
  const navigate = useNavigate();
  const redirect = (id) => {
    window.scrollTo(0, 0);
    navigate(
      `/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/pagamento/${id}`
    );
  };
  const item = props.item;
  return (
    <Container>
      <Pedidos>
        <CountryFlags cod={item.Tipo.split(" ")[0]} />
        <div className="tipoMoeda">
          <p className="moeda"> {ReturnName(item.Tipo.split(" ")[0])}</p>
          <p className="moeda">{parseFloat(item.Total).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}</p>
          <p style={{ margin: 0 }} className="moeda">Quantidade: {item.Qtde}</p>
          {item.status === "W" &&
            <a  className="ver-pedido" href={`/om/painel/perfil/meus-pedidos/${item.Ope_ID}`}>Ver Pedido</a>
          }
        </div>
      </Pedidos>
      <DivButton>
        {props.button && (
          <>
            {item.status === "W" ? (
              <Button onClick={() => redirect(item.Ope_ID)}>
                Realizar pagamento
              </Button>
            ) : (
              <Button type="submit" onClick={() => navigate(`/om/painel/perfil/meus-pedidos/${item.Ope_ID}`)}>Ver Pedido</Button>
            )}
          </>
        )}

{item.status === "W" ? (
              <p>Em Andamento</p>
            ) : item.status === "A" ? (
              <p>Concluído</p>
            ) : item.status === "N" ? (
              <p>Cancelado</p>
            ) : null}
      </DivButton>
      </Container>
  );
}



const Container = styled.div`
  font-family: var(--bs-body-font-family);
  border: 1px solid;
  border-radius: 8px;
  padding: 30px 20px;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  color: #a9a9a9;

  img {
    width: 120px;
  }

  @media (min-width: 780px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 760px;
  }
`;
const Pedidos = styled.div`
  display: flex;
  justify-content: space-evenly;

  .tipoMoeda {
    margin: 0 0 0 5px;
  }

  .tipoMoeda > .moeda {
    margin: 0;
    color: rgb(0, 65, 51);
    font-weight: 600;
    text-transform: uppercase;
  }
  .ver-pedido{

    color: rgb(0, 65, 51);
        text-decoration: none;
  }

  @media (min-width: 800px) {
    .tipoMoeda {
      margin: 0 30px 0 5px;
    }
  }
`;

const DivButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: rgb(0, 65, 51);
    margin-top: 5px;
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 45px;
  margin: 35px auto 0;

  @media (min-width: 780px) {
    margin: 0;
  }
`;
