import padrinhoFrente1g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-1g-frente.png";
import padrinhoFrente2g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-2g-frente.png";
import padrinhoFrente5g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-5g-frente.png";
import padrinhoFrente10g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-10g-frente.png";
import padrinhoFrente25g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-25g-frente.png";
import padrinhoFrente31g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-31g-frente.png";
import padrinhoFrente50g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-50g-frente.png";
import padrinhoVerso1g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-1g-verso.png";
import padrinhoVerso2g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-2g-verso.png";
import padrinhoVerso5g from "../../../assets/newImages/mockups/familia/padrinho/padrinho-ouro-5g-verso.png";

export const  padrinhoMockups = {
    nome: "Padrinho de Ouro",
    frente1g: padrinhoFrente1g,
  
    frente2g: padrinhoFrente2g,
  
    frente5g: padrinhoFrente5g, 
    frente10g: padrinhoFrente10g,
    frente25g:padrinhoFrente25g,
    frente31g: padrinhoFrente31g,
    frente50g: padrinhoFrente50g, 
  
    verso1g: padrinhoVerso1g,
    verso2g: padrinhoVerso2g,
  
    verso5g: padrinhoVerso5g,
    verso10g: padrinhoVerso5g,
    verso25g: padrinhoVerso5g,
    verso31g: padrinhoVerso5g,
    verso50g: padrinhoVerso5g,
  };