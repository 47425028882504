import safiraFrente1g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-1g-frente.png";
import safiraFrente2g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-2g-frente.png";
import safiraFrente5g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-5g-frente.png";
import safiraFrente10g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-10g-frente.png";
import safiraFrente25g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-25g-frente.png";
import safiraFrente31g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-31g-frente.png";
import safiraFrente50g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-50g-frente.png";
import safiraVerso1g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-1g-verso.png";
import safiraVerso2g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-2g-verso.png";
import safiraVerso5g from "../../../assets/newImages/mockups/pedras-preciosas/safira/safira-ouro-5g-verso.png";

export const  safiraMockups = {
    nome: "Safira",
    frente1g: safiraFrente1g,
  
    frente2g: safiraFrente2g,
  
    frente5g: safiraFrente5g,
  
    frente10g: safiraFrente10g,
  
    frente25g: safiraFrente25g,
  
    frente31g: safiraFrente31g,
  
    frente50g: safiraFrente50g,
  
    verso1g: safiraVerso1g,
    verso2g: safiraVerso2g,
  
    verso5g: safiraVerso5g,
    verso10g: safiraVerso5g,
    verso25g: safiraVerso5g,
    verso31g: safiraVerso5g,
    verso50g: safiraVerso5g,
  };

 