import tiaFrente1g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-1g-frente.png";
import tiaFrente2g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-2g-frente.png";
import tiaFrente5g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-5g-frente.png";
import tiaFrente10g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-10g-frente.png";
import tiaFrente25g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-25g-frente.png";
//import tiaFrente31g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-31g-frente.png";
import tiaFrente50g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-50g-frente.png";
import tiaVerso1g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-1g-verso.png";
import tiaVerso2g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-2g-verso.png";
import tiaVerso5g from "../../../assets/newImages/mockups/familia/tia/tia-ouro-5g-verso.png";

export const  tiaMockups = {
    nome: "Tia de Ouro",
    frente1g: tiaFrente1g,
  
    frente2g: tiaFrente2g,
  
    frente5g: tiaFrente5g, 
    frente10g: tiaFrente10g,
    frente25g:tiaFrente25g,
   // frente31g: tiaFrente31g,
    frente50g: tiaFrente50g, 
  
    verso1g: tiaVerso1g,
    verso2g: tiaVerso2g,
  
    verso5g: tiaVerso5g,
    verso10g: tiaVerso5g,
    verso25g: tiaVerso5g,
   // verso31g: tiaVerso5g,
    verso50g: tiaVerso5g,
  };