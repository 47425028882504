import arquitetaFrente1g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-1g-frente.png";
import arquitetaFrente2g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-2g-frente.png";
import arquitetaFrente5g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-5g-frente.png";
import arquitetaFrente10g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-10g-frente.png";
import arquitetaFrente25g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-25g-frente.png";
import arquitetaFrente31g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-31g-frente.png";
import arquitetaFrente50g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-50g-frente.png";
import arquitetaVerso1g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-1g-verso.png";
import arquitetaVerso2g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-2g-verso.png";
import arquitetaVerso5g from "../../../assets/newImages/mockups/profissoes/arquiteta/arquiteta-ouro-5g-verso.png";

export const  arquitetaMockups = {
    nome: "Arquiteta de Ouro",
    frente1g: arquitetaFrente1g,
  
    frente2g: arquitetaFrente2g,
  
    frente5g: arquitetaFrente5g,
  
    frente10g: arquitetaFrente10g,
  
    frente25g: arquitetaFrente25g,
  
    frente31g: arquitetaFrente31g,
  
    frente50g: arquitetaFrente50g,
  
    verso1g: arquitetaVerso1g,
    verso2g: arquitetaVerso2g,
  
    verso5g: arquitetaVerso5g,
    verso10g: arquitetaVerso5g,
    verso25g: arquitetaVerso5g,
    verso31g: arquitetaVerso5g,
    verso50g: arquitetaVerso5g,
  };

 