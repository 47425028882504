import preguicaFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-1g-frente.png";
import preguicaFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-2g-frente.png";
import preguicaFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-5g-frente.png";
import preguicaFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-10g-frente.png";
import preguicaFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-25g-frente.png";
import preguicaFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-31g-frente.png";
import preguicaFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-50g-frente.png";
import preguicaVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-1g-verso.png";
import preguicaVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-2g-verso.png";
import preguicaVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/preguica/preguica-ouro-5g-verso.png";

export const  preguicaMockups = {
    nome: "Preguiça",
    frente1g: preguicaFrente1g,
  
    frente2g: preguicaFrente2g,
  
    frente5g: preguicaFrente5g,
  
    frente10g: preguicaFrente10g,
  
    frente25g: preguicaFrente25g,
  
    frente31g: preguicaFrente31g,
  
    frente50g: preguicaFrente50g,
  
    verso1g: preguicaVerso1g,
    verso2g: preguicaVerso2g,
  
    verso5g: preguicaVerso5g,
  };

 