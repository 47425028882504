import festasFrente1g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-1g-frente.png";
import festasFrente2g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-2g-frente.png";
import festasFrente5g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-5g-frente.png";
import festasFrente10g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-10g-frente.png";
import festasFrente25g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-25g-frente.png";
import festasFrente31g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-31g-frente.png";
import festasFrente50g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-50g-frente.png";
import festasVerso1g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-1g-verso.png";
import festasVerso2g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-2g-verso.png";
import festasVerso5g from "../../../assets/newImages/mockups/empresa-ouro/boas-festas/boas-festas-5g-verso.png";

export const  boasFestasMockups = {
    nome: "Boas Festas",
    frente1g: festasFrente1g,
  
    frente2g: festasFrente2g,
  
    frente5g: festasFrente5g,  

    frente10g: festasFrente10g,
    frente25g:festasFrente25g,
    frente31g: festasFrente31g,
    frente50g: festasFrente50g,
  
    verso1g: festasVerso1g,
    verso2g: festasVerso2g,
  
    verso5g: festasVerso5g
  };

  export const boasfestas = [
    {
      name: "boas-festas",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]