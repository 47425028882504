import React from "react";
import img from "../../../assets/newImages/indicador-cambial.png";
import linha from "../../../assets/linha.png";
import "../../pages.css";
import styled from "styled-components";
export default function IndicadorCambial() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
            INDICADOR CAMBIAL
            </p>
            <p>
            Ser um Indicador Cambial permite que seu cliente tenha a melhor estrutura de produtos e serviços cambiais do país, da negociação ao fechamento da operação.
            </p>

            <p className=" mt-2  text-decoration-underline">
            Vantagens Indicador Cambial
            </p>

            <ol>
              <li>
                <span>RECEITA</span>
                <p>
                O indicador recebe uma comissão para cada operação concluída.

                </p>
              </li>
              <li>
                <span>ATENDIMENTO</span>
                <p>
                Central de atendimento para suporte ao indicador, por meio da plataforma Teams da Microsoft.

                </p>
              </li>
              <li>
                <span>DELIVERY</span>
                <p className="mb-0">
                O indicador fecha a operação turismo e a OM faz a entrega em SP Capital e em algumas regiões da Grande SP.
                </p>
                <Span>*Valor do frete para SP Capital: R$30,00. Demais regiões, é necessário calcular o frete.
</Span>
              </li>
              <li>
                <span>EQUIPE ESPECIALIZADA</span>
                <p>
                A OM fará o atendimento completo do cliente do indicador quando se tratar de cambio comercial, caso o indicador não possua a plataforma DUO.

                </p>
              </li>
              
            </ol>

            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5 p-0">
        Ofereça a seus clientes todos os produtos e serviços relacionados ao câmbio <b>sem a necessidade de ter um espaço físico.</b>


        </p>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Moedas em espécie:
              </li>
              <li className="list-unstyled">
                + de 20 tipos de moedas estrangeiras.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Cartão pré-pago Visa:
              </li>
              <li className="list-unstyled">
              Cartão pré-pago e recarregável, aceito em mais de 200 países e territórios e está disponível nas moedas Dólar Americano, Euro, Libra Esterlina, Dólar Australiano, Dólar Canadense e Peso Argentino.

              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
              Remessas financeiras:

              </li>
              <li className="list-unstyled">
              Envio de dinheiro totalmente online, através da plataforma digital personalizada DUO.

              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
              Câmbio comercial:

              </li>
              <li className="list-unstyled">
              Operações de câmbio referentes ao pagamento ou recebimento de produtos, ou seja, para atendimento ao mercado de comércio internacional.

              </li>
            </ul>
          </p>
        </section>
        
      </section>
    </article>
  );
}

const Span = styled.p`
font-size: 12px;
`