import medicoFrente1g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-1g-frente.png";
import medicoFrente2g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-2g-frente.png";
import medicoFrente5g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-5g-frente.png";
import medicoFrente10g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-10g-frente.png";
import medicoFrente25g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-25g-frente.png";
import medicoFrente31g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-31g-frente.png";
import medicoFrente50g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-50g-frente.png";
import medicoVerso1g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-1g-verso.png";
import medicoVerso2g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-2g-verso.png";
import medicoVerso5g from "../../../assets/newImages/mockups/profissoes/medico/medico-ouro-5g-verso.png";

export const  medicoMockups = {
    nome: "Médico de Ouro",
    frente1g: medicoFrente1g,
  
    frente2g: medicoFrente2g,
  
    frente5g: medicoFrente5g,
  
    frente10g: medicoFrente10g,
  
    frente25g: medicoFrente25g,
  
    frente31g: medicoFrente31g,
  
    frente50g: medicoFrente50g,
  
    verso1g: medicoVerso1g,
    verso2g: medicoVerso2g,
  
    verso5g: medicoVerso5g,
    verso10g: medicoVerso5g,
    verso25g: medicoVerso5g,
    verso31g: medicoVerso5g,
    verso50g: medicoVerso5g,
  };

 