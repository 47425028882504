import axios from "axios";
import { env } from "./env";

const url = env.linkAPI;
export const api = axios.create({
  baseURL: url,
});

export function getMoedas(moeda) {
  return api.get("/historicocotacao/" + moeda).then((resp) => resp.data);
}
export function getCotacaoAtual() {
  return api.get("/cotacaoatual").then((resp) => resp.data);
}
export function getCotacaoAtualMoeda(moeda) {
  return api.get(`/cotacaoatual/${moeda}`).then((resp) => resp.data);
}

export function postAlert(data) {
  return api
    .post("/alerta", data)
    .then(() => {
      alert("Alerta criado com sucesso!");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
    });
}

export function postEmailForOM(data) {
  api
    .post("/posts", {
      title: data.title,
      body: data.body,
      userId: data.userId,
    })
    .then((response) => {
      alert("Oferta enviada com sucesso!");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
    });
}

export function postLancamento(data) {
  return api
    .post("/lancamento", {
      nome: data.nome,
      email: data.email,
      telefone: data.telefone,
    })
    .then(() => {
      alert("Cadastro realizado com sucesso!");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
    });
}
export function postReceberCotacoes(data) {
  return api
    .post("/newsletter", data)
    .then(() => {
      alert("Cadastro realizado com sucesso!");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
      return err;
    });
}

export function postOfertaOuro(data) {
  return api
    .post("/ofertaouro", data)
    .then(() => {
      alert("Oferta enviada com sucesso!");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
      return err;
    });
}
export function postOferta(data) {
  return api
    .post("/oferta", data)
    .then(() => {
      alert("Oferta enviada com sucesso!");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
      return err;
    });
}

export function fetchMoedas() {
  return api.get("/moedas").then((resp) => resp.data);
}

export async function getOuro() {
  return await api.get("/ouroativo").then((resp) => resp.data);
}

export function postSendEmail(data) {
  return api
    .post("/envioemail", data)
    .then((resp) => resp.data)
    .catch((err) => {
      if (err.response.status === 400) {
        alert("Dados Inválidos");
      }
      return err;
    });
}
