import { apiTravel } from "../travel";
import { getClienteCodigo } from "../../../utils/localStorage";

export async function getLimiteOperacaoByMoeda(codigoMoeda: string): Promise<any> {
  const codigo = getClienteCodigo();
  try {
    const resp = await apiTravel.get(`compra-venda/obter-lista-moedas-compra-venda/${codigo}/Venda/${codigoMoeda}`);
    if (resp.data && resp.data.data) {
      const data = resp.data.data;

      if(data.length > 0){
        return data[0]
      }return Promise.reject("No data found");
    } else {
      return Promise.reject("No data found");
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}