import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./carousel.css";
import styled from "styled-components";

export function CarouselCoinIndex({
  frente,
  verso,
  especificacao,
  mockup,
  txt1,
  txt2,
  txt3,
  idx,
}) {
  const [index, setIndex] = useState(idx);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div id="carouselIndex">
      <Title>
        {index === 0 ? txt1 : index === 1 ? txt2 : index === 2 ? txt3 : null}
      </Title>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className="carouselCoin"
        slide={false}
      >
        <Carousel.Item interval={200000}>
          <ImageCarouselIndex
            className="d-block"
            src={frente}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <ImageCarouselIndex
            className="d-block"
            src={verso}
            alt="Second slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <ImageEspecificacaoIndex
            className="d-block px-5"
            src={especificacao}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        {mockup && (
          <Carousel.Item interval={200000}>
            <ImageMockupIndex
              className="d-block px-5"
              src={mockup}
              alt="Third slide"
            />

            <Carousel.Caption>
              {/* <h3></h3> */}
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  );
}

export default function CarouselCoin({
  frente,
  verso,
  especificacao,
  mockup,
  txt1,
  txt2,
  txt3,
}) {
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState("");
  const media = window.matchMedia("(min-width: 500px)");
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (media.matches === true) {
      if (image !== "") {
        setViewImage();
         function setViewImage() {
         const doc = document.getElementById("rowImg");

         if(doc) doc.classList.remove("invisible");
        }
      }
    }
  });


  return (
    <div>
      <Title>
        {index === 0 ? txt1 : index === 1 ? txt2 : index === 2 ? txt3 : null}
      </Title>
      <Carousel
        activeIndex={index}

        onSelect={handleSelect}
        className="carouselCoin"
        slide={false}
      >
        <Carousel.Item interval={200000}>
          <ImageCarousel
            className="d-block"
            src={frente}
            alt="First slide"
            onClick={() => setImage(frente)}
          />
          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <ImageCarousel
            className="d-block"
            src={verso}
            alt="Second slide"
            onClick={() => setImage(verso)}
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <ImageEspecificacao
            className="d-block px-5"
            src={especificacao}
            alt="Third slide"
            onClick={() => {
              setImage(especificacao);
            }}
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>

        {mockup && (
          <Carousel.Item interval={200000}>
            <ImageMockup
              className="d-block px-5"
              src={mockup}
              alt="Third slide"
              onClick={() => {
                setImage(mockup);
              }}
            />

            <Carousel.Caption>
              {/* <h3></h3> */}
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
      <RowImages>
        <button
          className="btn"
          onClick={() => {
            handleSelect(0);
          }}
        >
          <ImageButtons src={frente} />
        </button>
        <button
          className="btn"
          onClick={() => {
            handleSelect(1);
          }}
        >
          <ImageButtons src={verso} />
        </button>
        <button
          className="btn"
          onClick={() => {
            handleSelect(2);
          }}
        >
          <ImageButtons src={especificacao} />
        </button>
      </RowImages>

      {image && media.matches && (
        <div id="rowImg">
          <div className="position-relative" style={{ zIndex: 10000 }}>
            <div className="position-fixed top-50 start-50 translate-middle">
              <RowButtonClose>
                <ButtonClose onClick={() => setImage(null)}>X</ButtonClose>
              </RowButtonClose>
              <DivImage>
                <CarouselCoinIndex
                  frente={frente}
                  verso={verso}
                  especificacao={especificacao}
                  mockup={mockup}
                  txt1={txt1}
                  txt2={txt2}
                  txt3={txt3}
                  idx={index}
                />
              </DivImage>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


export function Caroussel({ images, title, className }) {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className={className}>
      <Title>{title}</Title>
      <Carousel
        activeIndex={index}

        onSelect={handleSelect}
        className="carouselCoin carrousel"
        slide={false}
      >
        {images?.map((item) => {
          return (
            <Carousel.Item interval={200000}>
              <ImageCarousel
                className="d-block"
                src={item}
                alt="First slide"
                type="any"
              />
              <Carousel.Caption>
                {/* <h3></h3> */}
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
const RowImages = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 375px) {
    display: none;
  }
`;
const ImageButtons = styled.img`
  width: 100px;
  height: 115px;
  border: 1px solid rgb(223, 231, 238);
  padding: 20px;
  border-radius: 10px;
`;
const Title = styled.h5`
  margin: 10px 0 20px 0;
  text-align: center;
  color: #004b3e;
`;
const ImageCarousel = styled.img`
  margin: 0 auto;
  width: ${(props) => (props.type === "any" ? null : "230px")};
  height: ${(props) => (props.type === "any" ? "100%" : null)};
  cursor: pointer;
`;

const ImageCarouselIndex = styled.img`
  @media(min-width:500px){
  width: 340px;

  margin: 0 50px;
  }
  @media(min-width:760px){
    width: 47vh;
    cursor: pointer;
    margin: 0 110px;
  }
`;
const ImageEspecificacao = styled.img`
  @media (min-width: 320px) {
    margin: 0 auto;
    width: 90vw;
  }
  @media (min-width: 720px) {
    width: 60vw;
  }

  @media (min-width: 890px) {
    width: 445px;
  }
  @media (min-width: 1024x) {
    width: 470px;
  }
  cursor: pointer;
`;
const ImageMockup = styled.img`
  @media (min-width: 320px) {
    margin: 0 auto;
    width: 90vw;
  }

  @media (min-width: 720px) {
    width: 70vw;
    padding-top: 80px;
  }

  @media (min-width: 890px) {
    width: 445px;
  }
  @media (min-width: 1024x) {
    width: 470px;
  }
  cursor: pointer;
`;
const ImageEspecificacaoIndex = styled.img`
  @media (min-width: 320px) {
    width: 90vw;
  }
  @media (min-width: 760px) {
    width: 620px;
  }
  @media (min-width: 960px) {
    width: 700px;
  }
  cursor: pointer;
`;

const ImageMockupIndex = styled.img`
  @media (min-width: 320px) {
    width: 90vw;
  }
  @media (min-width: 760px) {
    width: 620px;
  }
  @media (min-width: 960px) {
    margin: 0 30px;
    width: 700px;
  }
  cursor: pointer;
`;
const DivImage = styled.div`
  background: #fff;
  border-radius: 8px;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border: 6px solid #f1f1f1;
  padding: 20px;
  @media (min-width: 600px) {
    width: auto;
  }

 @media (min-width: 860px) {
  width: 800px;
  height: 90vh;
 }
`;

const RowButtonClose = styled.div`
  position: absolute;
  z-index: 1000;
  width: 90%;
  display: flex;
  justify-content: end;
  @media (min-width: 320px) {
    width: 99%;
    padding-top: 7px;
  }
  @media (min-width: 600px) {
    width: 99%;
    padding-top: 7px;
  }
`;
const ButtonClose = styled.button`
  background: red;
  border: red;
  padding: 10px 20px;
  border-radius: 0 0px 0px 5px;
`;
