import React, { useState } from "react";
import "../../../styles/ouroninas.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { postReceberCotacoes } from "../../../services/api";
import "./alertCot.css";
import { FormHelperText, InputLabel } from "@mui/material";
import {
  maskDDD,
  maskOnlyLetters,
  maskPhoneNotDDD,
  validEmail,
} from "../../../utils/regex";
import { RecebaCotModel } from "../../../models/RecebaCot";
import { TextSuccess } from "../../styled/text";

export default function AlertCot() {
  const [cotacao, setCotacao] = useState('');
  const [errCotacao, setErrCotacao] = useState();
  const [contato, setContato] = useState('');
  const [errContato, setErrContato] = useState();
  const [fullName, setFullName] = useState();
  const [errName, setErrName] = useState();
  const [email, setEmail] = useState("");
  const [errEmail, setErrEmail] = useState();
  const [ddd, setDdd] = useState("");
  const [telephone, setTelephone] = useState("");
  const [errTelephone, setErrTelephone] = useState();
  const [check, setCheck] = useState(false);
  const [errCheck, setErrCheck] = useState();
  const [success, setSuccess] = useState();
  let periodo;
  let count = 0;

  function validationInputs() {
    setSuccess("")
    setErrCheck("");
    setErrCotacao("");
    setErrContato("");
    setErrEmail("");
    setErrTelephone("");
    setErrName("")
    count = 0;
    if (
      cotacao === 0 ||
      cotacao === undefined ||
      cotacao === "" ||
      cotacao === null
    ) {
      count++;
      setErrCotacao("Selecione a cotação");
    } else {
      periodo =
        cotacao === 1
          ? "Diariamente"
          : cotacao === 2
          ? "Semanalmente"
          : cotacao === 3
          ? "Mensalmente"
          : null;
    }
  
    if (
      contato === 0 ||
      contato === undefined ||
      contato === "" ||
      contato === null
    ) {
      count++;
      setErrContato("Selecione o contato");
    }    if (fullName === "" || fullName == null || fullName === undefined) {
      count++;
      setErrName("Digite o seu nome!");
    }
    if (contato === 1) {
      setTelephone("");
      setDdd("");
      if (email === "") {
        count++;
        setErrEmail("Digite o seu email!");
      } else if (!validEmail.test(email)) {
        setErrEmail("Email inválido!");
        count++;
      }
    } else if (contato === 2) {
      setEmail("");
      if (!ddd || !telephone) {
        count++;
        setErrTelephone("Digite o telefone!");
      }
    }

    if (!check) {
      count++;
      setErrCheck("Marque o aceite!");
    }
  }
  function createPost() {


    let formaContato =
      contato === 1 ? "Email" : contato === 2 ? "Whatsapp" : null;
    validationInputs();
   
    if (count === 0) {
      let model = RecebaCotModel;
      model.nome = fullName;
      model.email = email;
      model.telefone = ddd + telephone;
      model.periodo = periodo;
      model.formaContato = formaContato;
      model.tipoCotacao = "Ouro e Câmbio";
      postReceberCotacoes(model).then(() =>{
        setSuccess("Cadastro realizado com sucesso!")
        setFullName("")
        setDdd("")
        setTelephone("")
        setCotacao('')
        setContato('')
        setErrCheck("");
        setErrCotacao("");
        setErrContato("");
        setErrEmail("");
        setErrTelephone("");
        setErrName("")
      });
    }
  }

  return (
    <section className="mt-5">
      <div className="Alert-cont-h p-0">
        <h3>RECEBA AS COTAÇÕES</h3>
        <p className="pb-0 m-0">
        Enviamos as cotações de câmbio e ouro diariamente, semanalmente ou mensalmente, para que fique informado sobre o mercado.
        </p>
      </div>
      <Box
        sx={{
          border: "1px dotted grey",
          background: "#f7f7f7",
        }}
      >
        <div className="sectAlert">
          <div className="col-12 w-100 divReceber">
            <div className="eachAlert my-3 ">
              <FormControl className="col-12 bg-white formCotacao">
                <InputLabel id="demo-simple-select-label">
                  Receber Cotação
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cotacao}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label="Receber Cotação"
                  placeholder="Receber Cotação"
                  onChange={(e) => setCotacao(e.target.value)}
                >
                  <MenuItem value={1}>Diariamente</MenuItem>
                  <MenuItem value={2}>Semanalmente</MenuItem>
                  <MenuItem value={3}>Mensalmente</MenuItem>
                </Select>
                <FormHelperText>{errCotacao}</FormHelperText>
              </FormControl>
            </div>

            
          </div>
          <div className="formaContato">
            <div className="eachAlert my-3 ">
              <FormControl className="col-12 bg-white formCotacao">
                <InputLabel id="demo-simple-select-label">
                  Forma de Contato
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={contato}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label="Forma de contato"
                  placeholder="Forma de Contato"
                  onChange={(e) => setContato(e.target.value)}
                >
                  <MenuItem value={1}>Email</MenuItem>
                  <MenuItem value={2}>Whatsapp</MenuItem> 
                </Select>
                <FormHelperText>{errContato}</FormHelperText>
              </FormControl>
            </div>

            <div className="eachAlert my-3 d-flex flex-column">
              <TextField
                helperText={errName}
                className="bg-white w-100"
                placeholder="NOME"
                label="Nome"
                value={fullName}
                onChange={(e) => setFullName(maskOnlyLetters(e.target.value))}
              />
            </div>

            {contato === 1 ? (
              <div className="eachAlert my-3 d-flex flex-column">
                <TextField
                  helperText={errEmail}
                  className="bg-white"
                  placeholder="EMAIL"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            ) : contato === 2 ? (
              <div className="eachAlert my-3 d-flex divCelDD">
                <div className="divDDD">
                  <TextField
                    className="bg-white"
                    placeholder="DDD"
                    label="DDD"
                    value={ddd}
                    onChange={(e) => setDdd(maskDDD(e.target.value))}
                  />
                </div>
                <div className="ms-2 col-8 divCel">
                  <TextField
                    className="bg-white w-100"
                    placeholder="Celular"
                    label="Celular"
                    helperText={errTelephone}
                    value={telephone}
                    onChange={(e) =>
                      setTelephone(maskPhoneNotDDD(e.target.value))
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className=" divCheck col-12">
            <input
              className="form-check-input checkInput"
              type="checkbox"
              value={check}
              id="checkCot"
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
            />
            <label className="form-check-label lblCheck">
            Autorizo que a OM me envie cotações de câmbio e ouro diariamente, semanalmente ou mensalmente.
            </label>
          </div>
          <div className="col-12">
            <span className="text-danger fs-6" value={errCheck}>
              {errCheck}
            </span>
          </div>
          <div className="py-2 divButton mb-2">
            <button
              type="submit"
              className="send-to btn-green-hover"
              onClick={createPost}
            >
              RECEBER COTAÇÃO
            </button>
          </div>
           
          <div className="divSuccess">
            <TextSuccess>{success}</TextSuccess>
          </div>
        </div>
      </Box>
    </section>
  );
}
