import avohFrente1g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-1g-frente.png";
import avohFrente2g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-2g-frente.png";
import avohFrente5g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-5g-frente.png";
import avohFrente10g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-10g-frente.png";
import avohFrente25g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-25g-frente.png";
import avohFrente31g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-31g-frente.png";
import avohFrente50g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-50g-frente.png";
import avohVerso1g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-1g-verso.png";
import avohVerso2g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-2g-verso.png";
import avohVerso5g from "../../../assets/newImages/mockups/familia/avoh/avoh-ouro-5g-verso.png";

export const  avohMockups = {
    nome: "Avó de Ouro",
    frente1g: avohFrente1g,
  
    frente2g: avohFrente2g,
  
    frente5g: avohFrente5g, 
    frente10g: avohFrente10g,
    frente25g:avohFrente25g,
    frente31g: avohFrente31g,
    frente50g: avohFrente50g, 
  
    verso1g: avohVerso1g,
    verso2g: avohVerso2g,
  
    verso5g: avohVerso5g,
    verso10g: avohVerso5g,
    verso25g: avohVerso5g,
    verso31g: avohVerso5g,
    verso50g: avohVerso5g,
  };