import peixesFrente1g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-1g-frente.png";
import peixesFrente2g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-2g-frente.png";
import peixesFrente5g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-5g-frente.png";
import peixesFrente10g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-10g-frente.png";
import peixesFrente25g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-25g-frente.png";
import peixesFrente31g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-31g-frente.png";
import peixesFrente50g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-50g-frente.png";
import peixesVerso1g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-1g-verso.png";
import peixesVerso2g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-2g-verso.png";
import peixesVerso5g from "../../../assets/newImages/mockups/signos/peixes/peixes-ouro-5g-verso.png";

export const  peixesMockups = {
    nome: "Peixes",
    frente1g: peixesFrente1g,
  
    frente2g: peixesFrente2g,
  
    frente5g: peixesFrente5g, 
    frente10g: peixesFrente10g,
    frente25g:peixesFrente25g,
    frente31g: peixesFrente31g,
    frente50g: peixesFrente50g, 
  
    verso1g: peixesVerso1g,
    verso2g: peixesVerso2g,
  
    verso5g: peixesVerso5g,
    verso10g: peixesVerso5g,
    verso25g: peixesVerso5g,
    verso31g: peixesVerso5g,
    verso50g: peixesVerso5g,
  };