import React from "react";
import "../../pages.css";
import img from "../../../assets/newImages/moeda-estrangeira.png";
import linha from "../../../assets/linha.png";
import { PoliticaEntregaCambio } from "../../../components/text/politcaEntrega";

export default function MoedasEstrangeiras() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              MOEDAS ESTRANGEIRAS
            </p>
            <p>
            Agora que você já escolheu o destino da sua viagem, definiu as datas
            e o tempo que vai ficar fora, chegou a hora de comprar as moedas que
            você vai usar em outro país, afinal, a compra da moeda é uma etapa
            muito importante.
          </p>
          <p>
            Chegar ao seu destino de viagem com a moeda local evita novas
            conversões e novos custos. E ainda é mais seguro, prático e
            econômico. Além disso, você aproveita mais o seu tempo de viagem,
            pois não vai preocupar-se de imediato com a procura por corretoras
            de câmbio.
          </p>
          <p>
            Escolher o melhor local para fazer o câmbio de uma moeda é,
            certamente, uma decisão importante e que pode fazer toda a diferença
            para os que estão marcando uma viagem. Afinal, quando estamos
            planejando ir para outro país, é sempre tudo muito corrido e o tempo
            passa voando, não é mesmo?
          </p>
          <p>
            Por isso, contar com um serviço rápido, prático e seguro como o da
            Ourominas (OM) é essencial para que tudo ocorra com simplicidade e
            tranquilidade.
          </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <section>
        <p>
          Na OM você conta com a máxima eficiência na compra de moedas
          estrangeiras, tanto para você, quanto para sua empresa. São mais de
          20* tipos de moedas estrangeiras em espécie disponíveis com taxas
          competitivas e com as melhores condições de compra. E você pode
          adquirir por WhatsApp, telefone, loja virtual, chat online, lojas
          próprias ou lojas autorizadas.
        </p>
        <p>
          *Quando se tratar de moedas exóticas, e caso a opção de compra seja em
          lojas próprias ou lojas autorizadas, verificar a disponibilidade das
          moedas.
        </p>
        <p>
          Para a sua comodidade e segurança, o delivery* da OM faz a entrega das
          moedas em espécie no endereço de sua preferência. A entrega pode ser
          feita apenas para o cliente titular da operação e não para terceiros.
          Para que outra pessoa receba a compra, é preciso fazer uma
          autorização. Por questões de segurança, a empresa não disponibiliza o
          seu itinerário, então não é possível informar o horário exato da
          entrega.
        </p>
        <p>
          *Consulte a disponibilidade na sua região e eventual taxa do serviço.
        </p>
        <p>
          Não existe quantidade limite definida para a compra, desde que seja
          apresentado comprovante de origem do valor em Reais e capacidade
          financeira. O comprovante é o contrato de câmbio e garantia de que a
          operação de compra de moeda estrangeira tem procedência. Leve-o
          consigo, pois poderá ser solicitado quando sair do Brasil ou ao entrar
          em outro país.
        </p>
        <p>
          Em operações de compra de moeda para pessoas que viajam ao exterior, o
          cálculo é feito com base no valor de venda do câmbio turismo e deve
          ser considerado o IOF (Imposto sobre Operações Financeiras) de 1,10%.
        </p>
      </section>



  <section className="mt-4 sect">
  <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            POR QUE A OUROMINAS É A MELHOR DTVM?
          </summary>
          <p className="my-4">
            Sabemos que a maioria das pessoas fazem pesquisa de preço e cotação
            entre diferentes casas de câmbio, agora, imagina só a vantagem de
            ver qualquer valor cobrado sendo coberto pela OM? Cobrimos o preço
            da concorrência, através de documento comprobatório. Oferecemos o
            custo-benefício mais positivo.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            POR QUE COMPRAR COM A OUROMINAS?
          </summary>
          <ol className="mt-4">
            <li>
            A empresa oferece serviços de Câmbio de qualidade, com spreads atrativos e seguindo
              parâmetros de cotação tanto do mercado interno quanto do
              internacional, de forma a propiciar a possibilidade de o comprador
              adquirir a moeda estrangeira que deseja sempre no menor preço do
              mercado. Na OM o cliente encontra uma excelente cotação.
            </li>
            <li>
              As cotações são transparentes e o comprador não precisa pagar mais
              caro apenas porque está comprando uma pequena quantidade, o que
              normalmente acontece em muitas empresas.
            </li>
            <li>
              A OM é uma empresa de tradição, possui mais de 40 anos de
              experiência no setor, conta com uma equipe bastante eficiente e
              profissional.
            </li>
            <li>
              A OM possui autorização do Banco Central e é credenciada na
              Comissão de Valores Mobiliários (CVM) para operar com o câmbio,
              como toda DTVM.
            </li>
          </ol>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAL A VANTAGEM DE COMPRAR ONLINE?
          </summary>
          <p className="mt-4">
            Além de economizar o tempo de visitar a casa de câmbio, a cotação
            on-line pode proporcionar preços ainda melhores em relação aos
            locais físicos, já que dispensam toda a sua estrutura. Outra
            vantagem é a praticidade, uma vez que é possível fazer todo o
            processo pelo WhatsApp, loja virtual ou chat online e sem sair de
            casa.
          </p>
          <p>
            Mais seguro. Com a possibilidade de retirar suas notas ou pedir por
            delivery, é possível optar pela forma que você possui mais
            confiança. Vale lembrar que, em caso de extravio ou problemas no
            recebimento do seu pedido, com a OM, você será ressarcido.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">COMO COMPRAR?</summary>
          <p className="mt-4">
            Após acordo de valores, realize o seu cadastro com a OM. Você poderá
            realizar suas operações com um cadastro simplificado (para operações
            de pequeno valor) ou com um cadastro completo (para os demais
            casos).
          </p>
          <p>
            O pagamento poderá ser através de transferência bancária (Pix, TED ou
            DOC) ou em espécie. Conforme exigência do Banco Central, o valor
            creditado na conta da OM deve sair da conta do titular do contrato
            de câmbio. A operação deverá ser liquidada no prazo combinado, pois
            do contrário será cancelada.
          </p>
          <p>
            Para realizar suas compras, basta entrar em contato com a OM através
            de seus canais de atendimento.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">CADASTRO.</summary>
          <p className="mt-4">
            A OM, como instituição financeira e com fins de prevenção contra a
            lavagem de dinheiro e financiamento ao terrorismo (PLD/FT) é
            obrigada a seguir os procedimentos cadastrais. A empresa protege as
            informações de seus clientes e não repassa a terceiros, salvo sob
            determinação do Banco Central do Brasil.
          </p>
          <ol >
            <li>
              Cadastro Simplificado: consiste no preenchimento da Ficha
              Cadastral com os dados básicos necessários e cópia simples dos
              documentos: RG / CPF / Comprovante de Endereço.
            </li>
            <li>
              Cadastro Completo: é o procedimento padrão de cadastro da
              instituição. Consiste no preenchimento da Ficha Cadastral, cópia
              simples dos documentos: RG / CPF / Comprovante de Endereço e
              documentação complementar para atribuição de limite operacional. A
              este cliente é dado um limite operacional, para acompanhamento
              interno da empresa.
            </li>
          </ol>
        </details>

        <PoliticaEntregaCambio/>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">HORÁRIO DE NEGOCIAÇÃO.</summary>
          <p className="my-4">
            Nosso horário de negociação: Segunda à Sexta, das 8:30 às 18:00h
            (Horário de Brasília). Temos horários diferenciados em alguns pontos
            de atendimento.
          </p>
        </details>
      </section>
      </section>
    </article>
  );
}
