import hinduFrente1g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-1g-frente.png";
import hinduFrente2g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-2g-frente.png";
import hinduFrente5g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-5g-frente.png";
import hinduFrente10g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-10g-frente.png";
import hinduFrente25g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-25g-frente.png";
import hinduFrente31g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-31g-frente.png";
import hinduFrente50g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-50g-frente.png";
import hinduVerso1g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-1g-verso.png";
import hinduVerso2g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-2g-verso.png";
import hinduVerso5g from "../../../assets/newImages/mockups/religiao/hindu/hindu-ouro-5g-verso.png";

export const  hinduMockups = {
    nome: "Hindu",
    frente1g: hinduFrente1g,
  
    frente2g: hinduFrente2g,
  
    frente5g: hinduFrente5g, 
    frente10g: hinduFrente10g,
    frente25g:hinduFrente25g,
    frente31g: hinduFrente31g,
    frente50g: hinduFrente50g, 
  
    verso1g: hinduVerso1g,
    verso2g: hinduVerso2g,
  
    verso5g: hinduVerso5g,
    verso10g: hinduVerso5g,
    verso25g: hinduVerso5g,
    verso31g: hinduVerso5g,
    verso50g: hinduVerso5g,
  };