import React from "react";
import img from "../../../assets/newImages/piramide.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";
import { TableGoldPiramide } from "../../../components/gold/tableGold";
import styled from "styled-components";
import { PoliticaEntrega } from "../../../components/text/politcaEntrega";
export default function PiramideOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              PIRÂMIDE DE OURO
            </p>
            <p>
              A Ourominas (OM), de forma pioneira e exclusiva no Brasil, lançou
              um novo produto, uma barra de ouro no formato piramidal. O
              produto, que também pode servir como investimento em ouro, foi
              criado com o propósito de agregar valor, uma vez que prosperidade
              e concentração de boas energias são qualidades associadas a
              pirâmide de ouro.
            </p>
            <p>
              O produto que simboliza a natureza, e os seus quatro ângulos
              representam a verdade, a inteligência, o silêncio e a
              profundidade, rapidamente tornou-se um sucesso, o que nos instigou
              a criar mais de um tamanho de pirâmide de 50, 100 e 250 gramas de
              ouro puro 24k.
            </p>
            <p>
              A OM criou a pirâmide de ouro, pois alguns especialistas acreditam
              que elas podem ser muito úteis para a vida cotidiana, e que seu
              poder pode funcionar de forma fiel a esses itens abaixo:
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section>
          <BoxBorder>
            <p>
              <ol>
                <li>
                  <b>Catalisar energia: </b>as estruturas piramidais de ouro
                  podem funcionar como um catalisador de energia, gerada a
                  partir dos seus cinco pontos;
                </li>
                <li>
                  <b>Conservação alimentícia:</b>se manter a comida perto de uma
                  pirâmide de ouro ela pode se manter fresca por mais tempo;
                </li>
                <li>
                  <b>Conservação de lâminas:</b>se colocar facas, tesouras e
                  lâminas de barbear perto de uma pirâmide de ouro em alguns
                  dias elas poderão estar mais afiadas;
                </li>
                <li>
                  <b>Sono contínuo:</b> se colocar uma pirâmide de ouro debaixo
                  da cama pode ajudar a evitar a insônia e a ter sonhos
                  agradáveis e duráveis.
                </li>
                <li>
                  <b>Mais concentração e memória: </b>a energia da pirâmide de
                  ouro pode melhorar a concentração e a memória das pessoas, por
                  isso, estudar perto de uma pirâmide de ouro pode melhorar o
                  desempenho no estudo e no trabalho;
                </li>
                <li>
                  <b>Controle do tabagismo: </b> as energias das pirâmides de
                  ouro são tão poderosas que podem ajudar as pessoas a pararem
                  de fumar ou a diminuir o vício, para isso, o fumante deve
                  armazenar seus cigarros perto de uma pirâmide de ouro, uns
                  vinte minutos por dia e com o tempo, ele irá notar que seu
                  hábito começa a desaparecer;
                </li>
                <li>
                  <b>Equilibrar os chacras:</b>deite-se e coloque uma pirâmide
                  de ouro sobre um ponto ou cada um dos pontos chacras, os
                  centros energéticos do organismo durante vinte minutos, por
                  sete dias seguidos;
                </li>
                <li>
                  <b>Favorecer alguém distante:</b> deixe por alguns dias fotos
                  da pessoa distante perto de uma pirâmide de ouro, isso pode
                  atrair bons fluidos para a pessoa e melhorar sua saúde.
                </li>
              </ol>
            </p>
          </BoxBorder>
        </section>
        <p className="mt-4">
          <b>Nossas Pirâmides</b>
        </p>
        <TableGoldPiramide />

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE COMPRAR COM A OUROMINAS? </summary>
            <ol className="mt-4">
              <li>
                Somente a OM possui pirâmide de ouro 24k de pureza 999, ou seja,
                o ouro mais puro que se pode encontrar, nas joalherias as
                pirâmides serão de 18k.
              </li>
              <li>
                A empresa negocia o ouro com spreads baixos e seguindo
                parâmetros de cotação tanto do mercado interno quanto do
                internacional, de forma a propiciar a possibilidade de o
                comprador adquirir o ouro sempre no menor preço do mercado.
              </li>
              <li>
                A OM é uma empresa de tradição, possui mais de 40 anos de
                experiência no setor, conta com uma equipe bastante eficiente e
                profissional.
              </li>
              <li>
                A OM possui autorização do Banco Central e é credenciada na
                Comissão de Valores Mobiliários (CVM) para operar com o ouro,
                como toda DTVM.
              </li>
            </ol>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO COMPRAR A PIRÂMIDE? </summary>
            <ol className="my-4">
              <li>
                Realize o seu cadastro com a OM. Você poderá realizar suas
                operações com um cadastro simplificado (para operações de
                pequeno valor) ou com um cadastro completo (para os demais
                casos).
              </li>
              <li>
                Para realizar suas compras, basta entrar em contato com a OM
                através de seus canais de atendimento.
              </li>
              <li>
                A operação deverá ser liquidada no prazo combinado, pois do
                contrário será cancelada.
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>A PIRÂMIDE POSSUI GARANTIA?</summary>
            <p>
              Todo ouro comercializado na OM possui teor de pureza 999, nota
              fiscal de negociação de ouro e tem sua recompra garantida. Guarde
              sempre as notas fiscais junto ao metal adquirido.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>CADASTRO.</summary>
            <p className="mt-4">
              A OM, como instituição financeira é obrigada a seguir certos
              procedimentos cadastrais. A empresa protege as informações de seus
              clientes e não repassa a terceiros, salvo sob determinação do
              Banco Central do Brasil.
            </p>
            <ol>
              <li>
                Cadastro Simplificado: consiste no preenchimento da Ficha
                Cadastral com os dados básicos necessários e cópia simples dos
                documentos: RG / CPF / Comprovante de Endereço.
              </li>
              <li>
                Cadastro Completo: é o procedimento padrão de cadastro da
                instituição. Consiste no preenchimento da Ficha Cadastral, cópia
                simples dos documentos: RG / CPF / Comprovante de Endereço e
                documentação complementar para atribuição de limite operacional.
                A este cliente é dado um limite operacional, para acompanhamento
                interno da empresa.
              </li>
            </ol>
          </details>

          <PoliticaEntrega/>
        </section>
      </section>
    </article>
  );
}

const BoxBorder = styled.div`
  margin: 70px 0 30px 0px;
  border: solid 4px #c3c1c1;
  padding: 40px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;
