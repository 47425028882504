import maeFrente1g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-1g-frente.png";
import maeFrente2g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-2g-frente.png";
import maeFrente5g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-5g-frente.png";
import maeFrente10g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-10g-frente.png";
import maeFrente25g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-25g-frente.png";
import maeFrente31g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-31g-frente.png";
import maeFrente50g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-50g-frente.png";
import maeVerso1g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-1g-verso.png";
import maeVerso2g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-2g-verso.png";
import maeVerso5g from "../../../assets/newImages/mockups/familia/mae/mae-ouro-5g-verso.png";

export const  maeMockups = {
    nome: "Mãe de Ouro",
    frente1g: maeFrente1g,
  
    frente2g: maeFrente2g,
  
    frente5g: maeFrente5g, 
    frente10g: maeFrente10g,
    frente25g:maeFrente25g,
    frente31g: maeFrente31g,
    frente50g: maeFrente50g, 
  
    verso1g: maeVerso1g,
    verso2g: maeVerso2g,
  
    verso5g: maeVerso5g,
    verso10g: maeVerso5g,
    verso25g: maeVerso5g,
    verso31g: maeVerso5g,
    verso50g: maeVerso5g,
  };