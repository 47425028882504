import React from "react";
import img from "../../../assets/newImages/correspondente-cambial.png";
import linha from "../../../assets/linha.png";
import "../../pages.css";

export default function CorrespondenteCambial() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              CORRESPONDENTE CAMBIAL
            </p>
            <p>
              Os Correspondentes Cambiais são habilitados para apresentar
              operações, realizar transações e fazer o atendimento dos produtos
              e serviços relacionados ao câmbio. Esses profissionais acabam
              sendo uma extensão da OM capilarizando o atendimento.
            </p>

            <p className=" mt-2  text-decoration-underline">
              Vantagens Correspondente Cambial
            </p>

            <ol>
              <li>
                <span>FLEXIBILIDADE</span>
                <p>
                  Liberdade na negociação, considerando a taxa mínima, entre o
                  correspondente e seu cliente.
                </p>
              </li>
              <li>
                <span>PRATICIDADE</span>
                <p>
                  Os cartões pré-pagos ficam alocados no correspondente, o que
                  permite ao cliente sair com tudo pronto para a viagem
                  internacional.
                </p>
              </li>
              <li>
                <span>RECEITA</span>
                <p>
                  Visualização de rentabilidade e emissão do próprio relatório
                  de comissão, com níveis hierárquicos de visualização.
                </p>
              </li>
              <li>
                <span>SEGURANÇA</span>
                <p>
                  Pagamentos via pix, transferência bancária ou boleto (sem riscos
                  para o correspondente).
                </p>
              </li>
              <li>
                <span>ATENDIMENTO</span>
                <p>
                  Central de atendimento para suporte ao correspondente, por
                  meio da plataforma Teams da Microsoft.
                </p>
              </li>
            </ol>

            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5 p-0">
          Ofereça a seus clientes todos os produtos e serviços abaixo
          relacionados ao câmbio <b>através de um espaço físico.</b>
        </p>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Moedas em espécie:
              </li>
              <li className="list-unstyled">
                + de 20 tipos de moedas estrangeiras.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Cartão pré-pago Visa:
              </li>
              <li className="list-unstyled">
                Cartão pré-pago e recarregável, aceito em mais de 200 países e
                territórios e está disponível nas moedas Dólar Americano, Euro,
                Libra Esterlina, Dólar Australiano, Dólar Canadense e Peso
                Argentino.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Câmbio comercial
              </li>
              <li className="list-unstyled">
                Operações de câmbio referentes ao pagamento ou recebimento de
                produtos, ou seja, para atendimento ao mercado de comércio
                internacional.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Transferência internacional de dinheiro:
              </li>
              <li className="list-unstyled">
                Através da MoneyGram, empresa líder no segmento.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Remessas financeiras:
              </li>
              <li className="list-unstyled">
                Envio de dinheiro totalmente online, através da plataforma
                digital personalizada DUO.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Seguro viagem:
              </li>
              <li className="list-unstyled">
                Através da GTA - Global Travel Assintance - empresa líder no
                segmento.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Chip internacional
              </li>
              <li className="list-unstyled">
                Através da MySim Travel, empresa líder no segmento.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Envio de encomendas:
              </li>
              <li className="list-unstyled">
                Através da DHL, empresa líder no segmento.
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
