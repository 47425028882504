import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../pages.css';
import linha from '../../../assets/linha.png';
import { LinkClean } from '../../../components/styled/links';
import prsac from '../../../assets/docs/acoes-com-vistas-efetividade.pdf';
import ouvidoria from '../../../assets/docs/ouvidoria.pdf';
import PoliticaPrivacidade from './politicaPrivacidade';
import socialAmbiental from '../../../assets/docs/politica-responsabilidade-social-ambiental-climatica.pdf';
import segurancaCibernetica from '../../../assets/docs/politica-seguranca-cibernetica.pdf';
import termoApp from '../../../assets/docs/termo-de-uso-app.pdf';
import PoliticaSegurancaCibernetica from './politicaSeguranca';
import PoliticaSocial from "./politicaSocial";
import Ouvidoria from './ouvidoria';
import PoliticaSgi from './politicaSgi';
import TermoOurominasApp from "./termoOurominasApp";

export default function PoliticasSeguranca() {
  const navigate = useNavigate();
  const linkClick = (link) => {
    window.scrollTo(0, 0);
    navigate(link);
  };
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <h2 className="fw-bolder text-decoration-underline">Políticas</h2>
          </div>
        </div>
        <p>
          <ul>
            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
                href={PoliticaSegurancaCibernetica}
                onClick={() =>
                  linkClick(
                    '/om/seguranca/politicas/politicaSegurancaCibernetica'
                  )
                }
              >
                Política de Segurança Cibernética
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
                href={PoliticaSocial}
                onClick={() =>
                  linkClick('/om/seguranca/politicas/politicaSocial')
                }
              >
                Política de Responsabilidade Sociaoambiental ("PRSAC")
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
                href={Ouvidoria}
                onClick={() => linkClick('/om/seguranca/politicas/ouvidoria')}
              >
                Ouvidoria
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
                href={PoliticaPrivacidade}
                onClick={() =>
                  linkClick('/om/seguranca/politicas/politicaPrivacidade')
                }
              >
                Política de Privacidade (LGPD)
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
                href={PoliticaSgi}
                onClick={() => linkClick('/om/seguranca/politicas/politicaSgi')}
              >
                Política de SGI
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
                href={TermoOurominasApp}
                onClick={() => linkClick('/om/seguranca/politicas/termoApp')}
              >
                OurominasApp: Ouro Digital
              </LinkClean>
            </li>
          </ul>
        </p>
        <div className="div-line">
          <img src={linha} alt="linha de separação"></img>
        </div>
      </section>
    </article>
  );
}
