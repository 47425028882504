export interface OperacaoVendaPost {
    codigoCliente: string;
    tipoOperacao: string;
    codigoMoeda: string;
    cartaoTransferenciaInternacional: string;
    taxaIOF: number;
    quantidade: number;
    tarifa: number;
    total: number;
    liberado: number;
    liberadoBR: number;
    toleranciaMin: number;
    toleranciaMax: number;
    observacoes: string;
    indicacao: string;
    tipoEntrega: string;
    tipoAssinatura: string;
    cupom: string | null;
    formaPagamento: string;
    codigoConta: string;
    codigoBancoPagamento: string | null;
    agenciaPagamento: string | null;
    contaPagamento: string | null;
    atende: number;
    documentoRecurso: string;
    nomeRecurso: string;
    origemRecurso: string;
}
export interface OperacaoVenda {
    codigoCliente: string;
    codigoMoeda: string;
    taxaIOF: number;
    quantidade: number;
    total: number;
    liberado: number;
    liberadoBR: number;
    toleranciaMin: number;
    toleranciaMax: number;
    documentoRecurso: string;
    nomeRecurso: string;
    tarifa: number;
    tipoEntrega: string;
    observacao: string;
}
export function mountOperacaoVenda(data: OperacaoVenda): OperacaoVendaPost{
    const operacaoVenda: OperacaoVendaPost = {
        codigoCliente: data.codigoCliente,
        tipoOperacao: "Venda",
        codigoMoeda: data.codigoMoeda,
        cartaoTransferenciaInternacional: "",
        taxaIOF: data.taxaIOF,
        quantidade: data.quantidade,
        tarifa: data.tarifa,
        total: data.total + data.tarifa,
        liberado: data.liberado,
        liberadoBR: data.liberadoBR,
        toleranciaMin: data.toleranciaMin,
        toleranciaMax: data.toleranciaMax,
        observacoes: `**Operação Câmbio**: Teste site - \n${data.observacao}`,
        indicacao: "MESAVAREJO 243",
        tipoEntrega: data.tipoEntrega,
        tipoAssinatura: "0",
        cupom: null,
        formaPagamento: "C/C 10400-4 OMDTVM",
        codigoConta: "C/C 10400-4 OMDTVM",
        codigoBancoPagamento: null,
        agenciaPagamento: null,
        contaPagamento: null,
        atende: 0,
        documentoRecurso: data.documentoRecurso,
        nomeRecurso: data.nomeRecurso,
        origemRecurso: "."
    };

    return operacaoVenda;

}


interface IDataItem {
    name: string;
    value: string | null;
}
export type StatusOperacao = 'S' | 'N' | 'A' | 'W' | null;
// Array de dados
export const dataFilterOperacao = (cpf: string, status: StatusOperacao): IDataItem[] =>[
    {
        name: "dc_tipo_interbancario",
        value: null
    },
    {
        name: "cd_cpf",
        value: cpf
    },
    {
        name: "dc_nome",
        value: null
    },
    {
        name: "dc_conta",
        value: null
    },
    {
        name: "dc_status",
        value: status
    },
    {
        name: "dc_parceiro",
        value: null
    },
    {
        name: "dt_inicio",
        value: null
    },
    {
        name: "dt_final",
        value: null
    },
    {
        name: "dc_moeda",
        value: null
    },
    {
        name: "dc_filtro3",
        value: null
    },
    {
        name: "dc_origem_operacao",
        value: null
    },
    {
        name: "dc_tipo_operacao",
        value: null
    },
    {
        name: "dc_tipo_remessa",
        value: "1"
    },
    {
        name: "dc_forma_pgto",
        value: "1"
    },
    {
        name: "dc_forma_entrega",
        value: "1"
    },
    {
        name: "dc_tipo_cliente",
        value: "1"
    },
    {
        name: "dc_indicacao",
        value: null
    }
];