import socioFrente1g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-1g-frente.png";
import socioFrente2g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-2g-frente.png";
import socioFrente5g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-5g-frente.png";
import socioFrente10g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-10g-frente.png";
import socioFrente25g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-25g-frente.png";
import socioFrente31g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-31g-frente.png";
import socioFrente50g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-50g-frente.png";
import socioVerso1g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-1g-verso.png";
import socioVerso2g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-2g-verso.png";
import socioVerso5g from "../../../assets/newImages/mockups/empresa-ouro/socio/socio-ouro-5g-verso.png";

export const  socioMockups = {
    nome: "Sócio",
    frente1g: socioFrente1g,
  
    frente2g: socioFrente2g,
  
    frente5g: socioFrente5g, 
    frente10g: socioFrente10g,
    frente25g:socioFrente25g,
    frente31g: socioFrente31g,
    frente50g: socioFrente50g, 
  
    verso1g: socioVerso1g,
    verso2g: socioVerso2g,
  
    verso5g: socioVerso5g
  };