import React from "react";
import styled from "styled-components";
import rande from "../assets/images/bandeirinhas/AFRICA-DO-SUL-RANDE.png";

import pesoArgentindo from "../assets/images/bandeirinhas/ARGENTINA-PESO-ARGENTINO.png";
import dolarAustraliano from "../assets/images/bandeirinhas/AUSTRALIA-DOLAR-AUSTRALIANO-DOLAR-NEOZELANDES.png";
import dolarCanadense from "../assets/images/bandeirinhas/CANÁDA-DOLAR-CANADENSE.png";
import pesoChileno from "../assets/images/bandeirinhas/CHILE-PESO-CHILENO.png";
import iuan from "../assets/images/bandeirinhas/CHINA-IUAN.png";
import pesoColombiano from "../assets/images/bandeirinhas/COLOMBIA-PESO-COLOMBIANO.png";
import coroaDinamarquesa from "../assets/images/bandeirinhas/DINAMARCA-COROA-DINAMARQUESA.png";
import emiradoArabes from "../assets/images/bandeirinhas/EMIRADOS-ARABES-DIRHAM.png";
import dolarAmericano from "../assets/images/bandeirinhas/ESTADOS-UNIDOS-DOLAR-AMERICANO.png";
import shekel from "../assets/images/bandeirinhas/ISRAEL-SHEKEL.png";
import iene from "../assets/images/bandeirinhas/JAPAO-LENE.png";
import CoroaNorueguesa from "../assets/images/bandeirinhas/NORUEGA-COROA-NORUEGUESA.png";
import peru from "../assets/images/bandeirinhas/PERU-NOVO-SOL.png";
import libra from "../assets/images/bandeirinhas/REINO-UNIDO-LIBRA.png";
import rubloRusso from "../assets/images/bandeirinhas/RUSSIA-RUBLO.png";
import coroaSueca from "../assets/images/bandeirinhas/SUECIA-COROA-SUECA.png";
import FrancoSuico from "../assets/images/bandeirinhas/SUICA-FRANCO-SUICO.png";
import euro from "../assets/images/bandeirinhas/UNIAO-EUROPEIA-EURO.png";
import pesoUruguaio from "../assets/images/bandeirinhas/URUGUAI-PESO-URUGUAIO.png";
import pesoMexicano from "../assets/images/bandeirinhas/MEXICO-PESO MEXICANO.png";
import novaZelandia from "../assets/images/bandeirinhas/NOVA-ZELANDIA.png";
import moneygram from "../assets/images/bandeirinhas/MONEYGRAM.png";
import ouro from "../assets/barras-de-ouro.png";
import more from "../assets/images/bandeirinhas/MORE.svg";

export default function CountryFlags({cod}) {
  return <>{cod === "DKK" ? <Icon src={coroaDinamarquesa}  id={cod}/> : cod === "NOK" ?  <Icon src={CoroaNorueguesa} id={cod}/> :
  cod === "SEK" ?  <Icon src={coroaSueca} id={cod}/> :
  cod === "AUD" ?  <Icon src={dolarAustraliano} id={cod}/> :
  cod === "CAD" ?  <Icon src={dolarCanadense} id={cod}/> :
  cod === "USD" ?  <Icon src={dolarAmericano} id={cod}/> :
  cod === "EUSCOM" ?  <Icon src={dolarAmericano} id={cod}/> :
  cod === "NZD" ?  <Icon src={novaZelandia} id={cod}/> :
  cod === "EUR" ?  <Icon src={euro} id={cod}/> :
  cod === "CHF" ?  <Icon src={FrancoSuico} id={cod}/> :
  cod === "JPY" ?  <Icon src={iene} id={cod}/> :
  cod === "CNY" ?  <Icon src={iuan} id={cod}/> :
  cod === "GBP" ?  <Icon src={libra} id={cod}/> :
  cod === "ARS" ?  <Icon src={pesoArgentindo} id={cod}/> :
  cod === "CLP" ?  <Icon src={pesoChileno} id={cod}/> :
  cod === "COP" ?  <Icon src={pesoColombiano} id={cod}/> :
  cod === "MXN" ?  <Icon src={pesoMexicano}id={cod}/> :
  cod === "UYU" ?  <Icon src={pesoUruguaio} id={cod}/> :
  cod === "ZAR" ?  <Icon src={rande} id={cod}/> :
  cod === "RUB" ?  <Icon src={rubloRusso} id={cod}/> :
  cod === "ILS" ?  <Icon src={shekel} id={cod}/> :
  cod === "PEN" ?  <Icon src={peru} id={cod}/> :
  cod === "AED" ?  <Icon src={emiradoArabes} id={cod}/> :
  cod === "OURO" ?  <Icon src={ouro} id={cod}/> :
  cod === "MONEYGRAM" ?  <Icon src={moneygram} id={cod}/> :
  cod === "MORE" ?  <img src={more} id={cod} style={{width: '40px', margin: '0 5px 3px 0'}} alt=""/> :  null}</>;
}

const Icon = styled.img`
  width: 20px;
  margin:0 5px 3px 0;

  .more{
    width: 40px !important;
  }
`;
