import tartarugaFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-1g-frente.png";
import tartarugaFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-2g-frente.png";
import tartarugaFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-5g-frente.png";
import tartarugaFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-10g-frente.png";
import tartarugaFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-25g-frente.png";
import tartarugaFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-31g-frente.png";
import tartarugaFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-50g-frente.png";
import tartarugaVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-1g-verso.png";
import tartarugaVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-2g-verso.png";
import tartarugaVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/tartaruga/tartaruga-ouro-5g-verso.png";

export const  tartarugaMockups = {
    nome: "Tartaruga",
    frente1g: tartarugaFrente1g,
  
    frente2g: tartarugaFrente2g,
  
    frente5g: tartarugaFrente5g,
  
    frente10g: tartarugaFrente10g,
  
    frente25g: tartarugaFrente25g,
  
    frente31g: tartarugaFrente31g,
  
    frente50g: tartarugaFrente50g,
  
    verso1g: tartarugaVerso1g,
    verso2g: tartarugaVerso2g,
  
    verso5g: tartarugaVerso5g,
  };

 