import irmaFrente1g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-1g-frente.png";
import irmaFrente2g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-2g-frente.png";
import irmaFrente5g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-5g-frente.png";
import irmaFrente10g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-10g-frente.png";
import irmaFrente25g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-25g-frente.png";
import irmaFrente31g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-31g-frente.png";
import irmaFrente50g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-50g-frente.png";
import irmaVerso1g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-1g-verso.png";
import irmaVerso2g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-2g-verso.png";
import irmaVerso5g from "../../../assets/newImages/mockups/familia/irma/irma-ouro-5g-verso.png";

export const  irmaMockups = {
    nome: "Irmã de Ouro",
    frente1g: irmaFrente1g,
  
    frente2g: irmaFrente2g,
  
    frente5g: irmaFrente5g, 
    frente10g: irmaFrente10g,
    frente25g:irmaFrente25g,
    frente31g: irmaFrente31g,
    frente50g: irmaFrente50g, 
  
    verso1g: irmaVerso1g,
    verso2g: irmaVerso2g,
  
    verso5g: irmaVerso5g,
    verso10g: irmaVerso5g,
    verso25g: irmaVerso5g,
    verso31g: irmaVerso5g,
    verso50g: irmaVerso5g,
  };