import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { CarrouselBootstrapOuro } from "../carousel/carousel";

export default function ClickReturnImg({ image, text }) {
  const [view, setView] = useState(false);
  return (
    <>
      <Button onClick={() => setView(!view)}> {text}</Button>

      {view && (
        <div className="position-relative" style={{ zIndex: 10000 }}>
          <div className="position-fixed top-50 start-50 translate-middle">
            <Box>
              <RowButtonClose>
                <ButtonClose onClick={() => setView(!view)}>X</ButtonClose>
              </RowButtonClose>
              <Image src={image} />{" "}
            </Box>
          </div>
        </div>
      )}
    </>
  );
}

export function ClickReturnImgGold({ image, text, props }) {
  const [view, setView] = useState(false);
  return (
    <>
      {view && (
        <RowImg>
          <RowButtonClose>
            <ButtonClose onClick={() => setView(!view)}>X</ButtonClose>
          </RowButtonClose>
          <CarrouselBootstrapOuro props={props} />
        </RowImg>
      )}
    </>
  );
}

export function ClickReturnCarousel({ array, text }) {
  const [view, setView] = useState(false);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <Button onClick={() => setView(!view)}> {text}</Button>

      {view && (
        <div className="position-relative" style={{ zIndex: 10000 }}>
          <div className="position-fixed top-50 start-50 translate-middle">
            <Box>
              <RowButtonClose type="carousel">
                <ButtonClose onClick={() => setView(!view)}>X</ButtonClose>
              </RowButtonClose>

              <Carousel
                activeIndex={index}

                onSelect={handleSelect}
                className=""
                slide={false}
              >
                {array.map((item) => {
                  return (
                    <Carousel.Item interval={200000}>
                      <Image className="d-block" src={item} alt="" type="carousel" />
                      <Carousel.Caption>
                        {/* <h3></h3> */}
                        <p></p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Box>
          </div>
        </div>
      )}
    </>
  );
}

const Button = styled.button`
  background: none;
  border: none;
`;
const RowButtonClose = styled.div`
  position: absolute;
  z-index: 1000;
  width: 90%;
  display: flex;
  justify-content: end;
  @media (min-width: 320px) {
    width: 280px;
  }
  @media (min-width: 375px) {
    width: 320px;
  }
  @media (min-width: 460px) {
    width: 400px;
  }
  @media (min-width: 540px) {
    width: 500px;
  }
  @media (min-width: 760px) {
    width: ${props => props.type === 'carousel' ? '99%': '650px'}
  }
`;
const ButtonClose = styled.button`
  background: red;
  border: red;
  padding: 10px 20px;
  border-radius: 0 0px 0px 5px;
`;
const RowImg = styled.div`
  margin: 0 auto;
  position: fixed;
  z-index: 1;
  background: #a49f9f;
  padding: 13px;
  @media (min-width: 320px) {
    left: 5%;
  }
  top: 20%;
  @media (min-width: 375px) {
    left: 10%;
  }
  @media (min-width: 1200px) {
    left: 18%;
  }
  @media (min-width: 1500px) {
    left: 22%;
  }
`;
const Image = styled.img`
  @media (min-width: 320px) {
    width: 280px;
  }
  @media (min-width: 375px) {
    width: 320px;
  }
  @media (min-width: 460px) {
    width: 400px;
  }
  @media (min-width: 540px) {
    width: 500px;
  }
  @media (min-width: 760px) {
    width: ${props => props.type === 'carousel' ? '480px': '650px'};
  }
`;
const Box = styled.div`
  border: 6px solid #f1f1f1;
  background: white;
  border-radius: 8px;
`;
