import React from 'react';
import '../../pages.css';
import img from '../../../assets/newImages/compliance.png';

export default function Compliance() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">COMPLIANCE</p>
            <p>
              A <strong>OM DTVM</strong> é uma Instituição Financeira (“IF”) e
              uma Distribuidora de Títulos e Valores Mobiliários (“DTVM”), com
              atuação voltada para a comercialização de ouro (I) ativo
              financeiro, (II) fino, (III) velho e (IV) oriundo de leilão, bem
              como compra e venda de moeda estrangeira, sob a égide da
              legislação vigente, normas do Banco Central do Brasil, Agência
              Nacional de Mineração e Comissão de Valores Mobiliários.
            </p>
            <p>
              Diante de tal cenário, a <strong>OM DTVM</strong> instituiu e
              estruturou um Departamento de Compliance, definido pelo Governo
              Federal do Brasil1 como “o cumprimento de leis e regras e, com a
              inserção do mecanismo da Integridade ao seu sistema, seu conceito
              se expande para alcançar a ética, estabelecendo uma cultura de se
              fazer o certo em toda a corporação” e prevenir sua exposição a
              quaisquer infrações regulatórias e/ou legais, bem como assegurar a
              sustentabilidade socioeconômica e ambiental das operações.
            </p>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="compliance"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
              ESTRUTURA ORGANIZACIONAL DO DEPARTAMENTO DE COMPLIANCE
              </li>
              <li className="list-unstyled">
              <p>
              Considerando que a atuação da <strong>OM DTVM</strong> alberga ramos distintos, igualmente foi instituído Corpo Diretivo para cada área, hábil a viabilizar o integral e regular funcionamento. 
              </p>
              </li>
              <li className="list-unstyled">
              <p>
              O Departamento de Compliance, composto por seu Diretor, Supervisor e Área de “Cadastro & Onboarding”, funciona como ente instrutor e fiscalizador da regularidade e legalidade da atuação das demais áreas, responsáveis pelas operações de ouro e câmbio. 
              </p>
              </li>
              <li className="list-unstyled">
              <p>
              Sobre a atuação da Área de “Cadastro & Onboarding”, destaca-se a recepção documental submetida pelo potencial cliente, a autenticação / validação, e a realização de análise reputacional, viabilizadas pela utilização de sistemas tecnológicos.  
              </p>
              </li>
              <li className="list-unstyled">
              <p>
              A atuação do Supervisor de Compliance, por sua vez, reside na elaboração de Políticas Institucionais para cada ramo negocial e operacional, além de ser responsável pela fiscalização da atuação das Áreas de “Cadastro & Onboarding” e operacionais de ouro e câmbio.  
              </p>
              </li>
              <li className="list-unstyled">
              <p>
              Por fim, o Diretor de Compliance atua conjuntamente aos demais na formulação e fiscalização da aplicação das Políticas Institucionais, no monitoramento da atuação do Departamento, na representação da <strong>OM DTVM</strong> junto às outras Diretorias e perante audiências com os Órgãos Reguladores e em demandas judiciais, quando necessário. 
              </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
              ATRIBUIÇÕES DO DEPARTAMENTO DE COMPLIANCE
              </li>
              <li className="list-unstyled">
                <p>
                Para viabilizar a prevenção da exposição da <strong>OM DTVM</strong> ao envolvimento de quaisquer operações que estejam em descompasso com a legislação e resoluções aplicáveis, o seu Departamento de Compliance é diretamente responsável por: 
                </p>
              </li>
              <p>
                <ul>
                  <li>Elaborar e divulgar Políticas de Conformidade, bem como controlar a adesão dos Colaboradores da <strong>OM DTVM</strong> à operacionalização em estrito cumprimento do dever legal e regulatório; </li>
                  <li>
                  Ininterrupta análise das atualizações legais e/ou regulatórias, para o fim de constante adequação do modelo operacional da <strong>OM DTVM</strong>.  
                  </li>
                  <li>
                  Viabilizar a aderência da <strong>OM DTVM</strong> ao arcabouço legal, à regulamentação infralegal, às recomendações dos órgãos de supervisão e aos códigos de ética e de conduta, bem como prestar suporte a Diretoria Executiva em assuntos relativos à Governança, Risco e Compliance (“GRC”); 
                  </li>
                  <li>Gerenciar o sistema de normativo interno relacionado à Compliance e Controles Internos, tais como: avaliação interna de risco (AIR), políticas, procedimentos, diretrizes, normas e manuais, instruções normativas e recomendações institucionais para conformidade do negócio, e,</li>
                  <li>
                  Atender as demandas e eventuais auditorias de órgãos reguladores. 
                  </li>
                </ul>
              </p>
              <li className="list-unstyled">
                <p>
                Através do estrito cumprimento de suas atribuições, o Departamento de Compliance da <strong>OM DTVM</strong> viabiliza que os demais Departamentos não exponham a Instituição ao envolvimento em quaisquer atividades minimamente suspeitas de estarem em desconformidade com as determinações dos Órgãos Reguladores e/ou da legislação aplicável.  
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONCLUSÃO
              </li>
              <li className="list-unstyled">
                <p>
                O escopo da estruturação de seu Departamento de Compliance, é de que a <strong>OM DTVM</strong> viabilize o estrito cumprimento da legislação e regulamentos aplicáveis aos seus ramos operacionais, através de políticas institucionais minunciosamente elaboradas. 
                </p>
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
