import papagaioFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-1g-frente.png";
import papagaioFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-2g-frente.png";
import papagaioFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-5g-frente.png";
import papagaioFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-10g-frente.png";
import papagaioFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-25g-frente.png";
import papagaioFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-31g-frente.png";
import papagaioFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-50g-frente.png";
import papagaioVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-1g-verso.png";
import papagaioVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-2g-verso.png";
import papagaioVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/papagaio/papagaio-ouro-5g-verso.png";

export const  papagaioMockups = {
    nome: "Papagaio",
    frente1g: papagaioFrente1g,
  
    frente2g: papagaioFrente2g,
  
    frente5g: papagaioFrente5g,
  
    frente10g: papagaioFrente10g,
  
    frente25g: papagaioFrente25g,
  
    frente31g: papagaioFrente31g,
  
    frente50g: papagaioFrente50g,
  
    verso1g: papagaioVerso1g,
    verso2g: papagaioVerso2g,
  
    verso5g: papagaioVerso5g,
  };

 