import React, {  useState } from 'react';
import styled from 'styled-components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Button } from '@mui/material';
import { convertFileToBase64 } from "../../../utils/utils";
import { useNavigate } from 'react-router-dom';
import { postDocumentoCliente } from '../../../services/modules/cliente/documentoService';
import { AlertNotify } from '../../../components/alerts/AlertNotify';
import { DocumentoPOST } from '../../../models/modules/Cliente/Documento';
import { PageLoading } from '../../../components/Loading/Loading';
function createData(name, cnpj = '', banco = '', banco1 = '', banco2 = '', agencia = '', conta = '') {
  return { name, cnpj, banco, banco1, banco2, agencia, conta };
}

const rows = [
  createData('Nome da empresa', 'Ouro Minas LTDA'),
  createData('CNPJ', '12.152.669/0001-88'),
  createData('Banco', 'Banco 01', 'Banco 02'),
  createData('Agência','6667-X'),
  createData('Conta Corrente', '000100-0'),
];

export default function PagamentoTed() {
  const [arquivo,setArquivo] = useState(null);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();


  const send = () => {
    function setArquivo(file){
      convertFileToBase64(file)
        .then((base64) => {
          const doc: DocumentoPOST = {
            codigoCliente: "props.data.codigoCliente",
            docComplementar: 'S',
            fileName: file.name,
            tipo: "tipo",
            dtVencimento: new Date().toISOString(),
            fileBase64: base64
          };
          setLoad(true);
          postDocumentoCliente(doc).then(resp => {
            "props.update();"
          }).catch(e => console.log(e)).finally(() => setLoad(false));

        })
        .catch((err) => {
          AlertNotify({message: 'Ocorreu algum erro ao importar o arquivo.\n\nTente novamente.', type: 'warning'});
        });

    }
  }

  function onSave(){
    AlertNotify({message: "Seu comprovante foi enviado! Aguarde enquanto processamos sua transação."})
    navigate("/om/painel/perfil/meus-pedidos");
  }

  return (
    <div className="my-4">
      <TituloPagamento>Dados para pagamento via TED</TituloPagamento>

      <PagamentoTransferencia>
        {load && <PageLoading/>}
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.cnpj}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.banco1}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.agencia}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.conta}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.cnpj}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.banco2}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.agencia}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px' }}>
                    {row.conta}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PagamentoTransferencia>
      <ContainerButtons>
        <div>
                <p>
                  Após realizar o pagamento, anexe o comprovante da transferência abaixo.
                </p>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<PhotoCameraIcon />}
                  onClick={() => GetImage()}
                >
                  {arquivo ? 'Alterar documento':'Anexar documento'}
                </Button>
              </div>
              </ContainerButtons>
      {arquivo && <ContainerButtonProsseguir type="submit">
        <button disabled={!arquivo} onClick={onSave}>Prosseguir</button>
      </ContainerButtonProsseguir>
}    </div>
  );

  function GetImage() {
    function openCameraOrImportImage() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*"; // Accept any image format

      // Add event listener to handle file selection
      input.addEventListener("change", handleFileSelection);

      // Trigger a click event on the input element
      input.click();
    }

    function handleFileSelection(event){
      const file = event.target.files[0];
      setArquivo(file);
    }

    return openCameraOrImportImage();
  }
}

const TituloPagamento = styled.h4`
  margin: 0 0 30px;
  color: var(--light);
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`;

const PagamentoTransferencia = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 830px) {
    flex-direction: row;

    div {
      margin: 0 15px 0 15px;
    }
  }
`;


const ContainerButtonProsseguir = styled.div`
display: flex;
justify-content: center;
 > button {
 color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 270px;
  height: 45px;
  margin: 40px auto;

  @media (min-width: 700px) {
    width: 550px;
  }
 }

`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  div:nth-child(n) {
    padding: 5px 0;
    border-top: 1px solid black;
        align-self: center;

    text-align: -webkit-center;

  }
`;