import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { CEP, CalculoFrete } from "../../../models/modules/Cliente/Cep";
import { apiTravel } from "../travel";

export function getCEP(cep: string): Promise<CEP> {
  return apiTravel.get(`/cliente/obter-endereco-cep/${cep}`).then((resp) => {
    const data = resp.data.data;
    if(data){
      return data;
    }
    AlertNotify({message: 'CEP não encontrado!', type: 'warning'})
    throw new Error('CEP não encontrado!');

  });
}

export function getCalculoCEP(cep: string): Promise<CalculoFrete> {
  return apiTravel.get(`/cliente/calculo-frete/${cep}`).then((resp) => {
    const data = resp.data.data;
    if(data){
      return data;
    }
    AlertNotify({message: 'CEP não encontrado!', type: 'warning'})
    throw new Error('CEP não encontrado!');

  });
}
