import React, { MouseEvent, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import styled from "styled-components";
import { Cliente } from "../../models/modules/Cliente/Cliente";
import { PageLoading } from "../../components/Loading/Loading";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { DocumentoPOST } from "../../models/modules/Cliente/Documento";
import { AlertNotify } from "../../components/alerts/AlertNotify";
import { postDocumentoCliente } from "../../services/modules/cliente/documentoService";
import { convertFileToBase64 } from "../../utils/utils";

export default function DocumentosPage(props: { data: Cliente, update: () => void }) {
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event: MouseEvent<Element, MouseEvent>,
    reason: string
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const isDocumento = (tipo: string) => {
    return props.data.documentos.some(item => item.tipoDocumento === tipo);
  };
  return (
    <>
      {load && <PageLoading />}
      <ButtonStyled color="primary" onClick={handleOpen}>
        Adicionar Documentos
      </ButtonStyled>

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <ResponsiveBox>
            <h2>Adicionar Documentos</h2>
            <p>
              Olá {props.data.nome.split(" ")[0]}, para continuar navegando
              precisamos confirmar sua identidade. Anexe os respectivos
              documentos abaixo.
            </p>

            <ContainerButtons>
              <div>
                <p>
                  Documento de Identificação (RG E CPF, CNH, RNE, OAB, CRM, ETC)
                </p>
                <Button
                  disabled={isDocumento('1008')}
                  component="label"
                  variant="contained"
                  startIcon={<PhotoCameraIcon />}
                  onClick={() => GetImage("1008")}
                >
                  {isDocumento('1008') ? 'Documento em análise': 'Adicionar'}
                </Button>
              </div>

              <div>
                <p>Foto Facial</p>
                <Button
                  disabled={isDocumento('1072')}
                  component="label"
                  variant="contained"
                  startIcon={<PhotoCameraIcon />}
                  onClick={() => GetImage("1072")}
                >
                  {isDocumento('1072') ? 'Documento em análise': 'Adicionar'}
                </Button>
              </div>

              <div>
                <p>Comprovante de Residência</p>
                <Button
                  disabled={isDocumento('1009')}
                  component="label"
                  variant="contained"
                  startIcon={<PhotoCameraIcon />}
                  onClick={() => GetImage("1009")}
                >
                  {isDocumento('1009') ? 'Documento em análise': 'Adicionar'}
                </Button>
              </div>
              <Button
                  component="label"
                  variant="contained"

                  onClick={() => setOpen(false)}
                >
                  Voltar
                </Button>
            </ContainerButtons>
          </ResponsiveBox>
        </Modal>
      )}
    </>
  );

  function GetImage(tipo: string) {
    function openCameraOrImportImage() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*"; // Accept any image format

      // Add event listener to handle file selection
      input.addEventListener("change", handleFileSelection);

      // Trigger a click event on the input element
      input.click();
    }

    function handleFileSelection(event: any){
      const file: File = event.target.files[0];
      setArquivo(file);
    }

    function setArquivo(file: File){
      convertFileToBase64(file)
        .then((base64) => {
          const doc: DocumentoPOST = {
            codigoCliente: props.data.codigoCliente,
            docComplementar: 'S',
            fileName: file.name,
            tipo: tipo,
            dtVencimento: new Date().toISOString(),
            fileBase64: base64
          };
          setLoad(true);
          postDocumentoCliente(doc).then(resp => {
            props.update();
          }).catch(e => console.log(e)).finally(() => setLoad(false));
        })
        .catch((err) => {
          AlertNotify({message: 'Ocorreu algum erro ao importar o arquivo.\n\nTente novamente.', type: 'warning'});
        });

    }
    return openCameraOrImportImage();
  }
}
const ButtonStyled = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 45px;
  margin: 35px auto 0;
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  div:nth-child(n) {
    padding: 5px 0;
    border-top: 1px solid black;
  }
`;
const ResponsiveBox = styled(Box)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 16px 8px;
  width: 600px;

  @media (max-width: 600px) {
    width: 90%;
    top: 50%
  }
`;
