import padrastoFrente1g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-1g-frente.png";
import padrastoFrente2g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-2g-frente.png";
import padrastoFrente5g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-5g-frente.png";
import padrastoFrente10g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-10g-frente.png";
import padrastoFrente25g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-25g-frente.png";
import padrastoFrente31g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-31g-frente.png";
import padrastoFrente50g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-50g-frente.png";
import padrastoVerso1g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-1g-verso.png";
import padrastoVerso2g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-2g-verso.png";
import padrastoVerso5g from "../../../assets/newImages/mockups/familia/padrasto/padrasto-ouro-5g-verso.png";

export const  padrastoMockups = {
    nome: "Padrasto de Ouro",
    frente1g: padrastoFrente1g,
  
    frente2g: padrastoFrente2g,
  
    frente5g: padrastoFrente5g, 
    frente10g: padrastoFrente10g,
    frente25g:padrastoFrente25g,
    frente31g: padrastoFrente31g,
    frente50g: padrastoFrente50g, 
  
    verso1g: padrastoVerso1g,
    verso2g: padrastoVerso2g,
  
    verso5g: padrastoVerso5g,
    verso10g: padrastoVerso5g,
    verso25g: padrastoVerso5g,
    verso31g: padrastoVerso5g,
    verso50g: padrastoVerso5g,
  };