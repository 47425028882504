import React, { useEffect, useRef, useState } from "react";
import {
  downloadExcel,
} from "react-export-table-to-excel";
import styled from "styled-components";
import { getAlerts } from "../../../../services/admin";
import { ReturnName } from "../../../../utils/coins";
import { toDate } from "../../../../utils/regex";

export default function AlertaADM() {
  const [data, setData] = useState();
  const [err, setErr] = useState();
  const tableRef = useRef(null);
  const header = [
    "ID",
    "NOME",
    "EMAIL",
    "TELEFONE",
    "MOEDA",
    "VALOR",
    "DATA MÁXIMA",
    "STATUS",
  ];
  useEffect(() => {
    getAlerts()
      .then(setData)
      .catch((resp) => setErr(resp.message));
  }, []);

  function onDownload() {
    downloadExcel({
      fileName: "Users table",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body: data,
      },
    });
  }

  return (
    <section>
      <Container>
        <Title>Histórico de Alertas criados</Title>
        {err && (
          <p className="text-center">
            Dados não disponíveis!
            <br />
            {err}
          </p>
        )}
        {data && (
          <>
            <div className="d-flex justify-content-end mb-2">
              {" "}
              <button
                className="btn btn-success text-align-center"
                onClick={() => onDownload()}
              >
                {" "}
                Exportar
              </button>{" "}
            </div>

            <table className="table table-hover table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col">Tipo da moeda</th>
                  <th className="text-center" scope="col">
                    Cotação desejada
                  </th>
                  <th className="text-center" scope="col">
                    Data Máxima
                  </th>
                  <th className="" scope="col">
                    Nome
                  </th>
                  <th className="" scope="col">
                    Email
                  </th>
                  <th className="" scope="col">
                    Telefone
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => {
                  return (
                    <tr key={i++}>
                      <td>{ReturnName(item?.codIso) || item?.codIso}</td>
                      <td className="text-center">
                        {parseFloat(item?.valor).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td className="text-center">{toDate(item?.dataLimite)}</td>
                      <td>{item?.nome}</td>
                      <td>{item?.email}</td>
                      <td>{item?.telefone}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </Container>
    </section>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 60px 100px;
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;
