export const empresaOuro = [
    {
      name: "Boas Festas",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    },{
      name: "Cliente",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    },{
      name: "Colaborador",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    },{
        name: "Colaboradora",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Consultor",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Consultora",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Coordenador",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Coordenadora",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Destaque do Ano",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Destaque do Mês",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Diretor",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Diretora",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Gerente",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Operador",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Operadora",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Parceira",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Parceiro",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      }, {
        name: "Secretária de Ouro",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ]
      }, {
        name: "Secretário de Ouro",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ]
      }, {
        name: "Sócia",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Sócio",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Vendedor",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      },{
        name: "Vendedora",
        value: [
          "OURO BARRA (1g)",
          "OURO BARRA (2g)",
          "OURO BARRA (5g)",
          "OURO BARRA (10g)",
          "OURO BARRA (25g)",
          "OURO BARRA (31,104g)",
          "OURO BARRA (50g)",
        ],
      }, 
    
  ];