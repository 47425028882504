import sorteFrente1g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-1g-frente.png";
import sorteFrente2g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-2g-frente.png";
import sorteFrente5g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-5g-frente.png";
import sorteFrente10g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-10g-frente.png";
import sorteFrente25g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-25g-frente.png";
import sorteFrente31g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-31g-frente.png";
import sorteFrente50g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-50g-frente.png";
import sorteVerso1g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-1g-verso.png";
import sorteVerso2g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-2g-verso.png";
import sorteVerso5g from "../../../assets/newImages/mockups/sentimentos/sorte/sorte-ouro-5g-verso.png";

export const  sorteMockups = {
    nome: "Boa Sorte",
    frente1g: sorteFrente1g,
  
    frente2g: sorteFrente2g,
  
    frente5g: sorteFrente5g, 
    frente10g: sorteFrente10g,
    frente25g:sorteFrente25g,
    frente31g: sorteFrente31g,
    frente50g: sorteFrente50g, 
  
    verso1g: sorteVerso1g,
    verso2g: sorteVerso2g,
  
    verso5g: sorteVerso5g,
    verso10g: sorteVerso5g,
    verso25g: sorteVerso5g,
    verso31g: sorteVerso5g,
    verso50g: sorteVerso5g,
  };