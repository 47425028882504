import dubaiFrente1g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-1g-frente.png";
import dubaiFrente2g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-2g-frente.png";
import dubaiFrente5g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-5g-frente.png";
import dubaiFrente10g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-10g-frente.png";
import dubaiFrente25g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-25g-frente.png";
import dubaiFrente31g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-31g-frente.png";
import dubaiFrente50g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-50g-frente.png";
import dubaiVerso1g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-1g-verso.png";
import dubaiVerso2g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-2g-verso.png";
import dubaiVerso5g from "../../../assets/newImages/mockups/mundos/dubai/dubai-ouro-5g-verso.png";

export const  dubaiMockups = {
    nome: "Dubai",
    frente1g: dubaiFrente1g,
  
    frente2g: dubaiFrente2g,
  
    frente5g: dubaiFrente5g,
  
    frente10g: dubaiFrente10g,
  
    frente25g: dubaiFrente25g,
  
    frente31g: dubaiFrente31g,
  
    frente50g: dubaiFrente50g,
  
    verso1g: dubaiVerso1g,
    verso2g: dubaiVerso2g,
  
    verso5g: dubaiVerso5g,
    verso10g: dubaiVerso5g,
    verso25g: dubaiVerso5g,
    verso31g: dubaiVerso5g,
    verso50g: dubaiVerso5g,
  };

 