import fotografoFrente1g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-1g-frente.png";
import fotografoFrente2g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-2g-frente.png";
import fotografoFrente5g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-5g-frente.png";
import fotografoFrente10g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-10g-frente.png";
import fotografoFrente25g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-25g-frente.png";
import fotografoFrente31g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-31g-frente.png";
import fotografoFrente50g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-50g-frente.png";
import fotografoVerso1g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-1g-verso.png";
import fotografoVerso2g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-2g-verso.png";
import fotografoVerso5g from "../../../assets/newImages/mockups/profissoes/fotografo/fotografo-ouro-5g-verso.png";

export const  fotografoMockups = {
    nome: "Fotógrafo de Ouro",
    frente1g: fotografoFrente1g,
  
    frente2g: fotografoFrente2g,
  
    frente5g: fotografoFrente5g,
  
    frente10g: fotografoFrente10g,
  
    frente25g: fotografoFrente25g,
  
    frente31g: fotografoFrente31g,
  
    frente50g: fotografoFrente50g,
  
    verso1g: fotografoVerso1g,
    verso2g: fotografoVerso2g,
  
    verso5g: fotografoVerso5g,
    verso10g: fotografoVerso5g,
    verso25g: fotografoVerso5g,
    verso31g: fotografoVerso5g,
    verso50g: fotografoVerso5g,
  };

 