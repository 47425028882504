import React from "react";
import img from "../../../assets/newImages/plante-arvore.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";
import planteArvore2021 from "../../../assets/docs/certificado-ibf-2021.pdf";
import planteArvore from "../../../assets/docs/certificado-plante-arvore.pdf";
import relatorio from "../../../assets/docs/relatorio-tecnico-fotografico.pdf";
import relatorio2022 from "../../../assets/docs/relatorio-tecnico-fotografico-2022.pdf";
import relatorio2023 from "../../../assets/docs/relatorio-tecnico-fotografico-2023.pdf";
import docIbf2023 from "../../../assets/docs/certificado-ibf-2023.pdf";
import docIbf2024 from "../../../assets/docs/certificado-ibf-2024.pdf";

export default function PlanteArvore() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              PROGRAMA PLANTE ÁRVORE
            </p>
            <p>
              O cliente que efetua a compra do Cartão OuroFácil Plante Árvore
              ajuda a OM a manter o Programa Plante Árvore ativo.
            </p>
            <p>
              O Programa Plante Árvore é uma ação do Instituto Brasileiro de
              Floresta (IBF), voltada à restauração florestal de mata nativa em
              áreas degradadas, dentro dos limites do bioma Mata Atlântica e
              Cerrado. O programa consiste no cadastro de proprietários rurais
              que tenham em suas propriedades, áreas a serem restauradas ou
              áreas de mata ciliar que devem ser refeitas.
            </p>
            <p>
              O IBF se encarrega da coleta das sementes, cultivo das mudas e
              escolha da área para o plantio, e ainda se compromete em oferecer
              toda a orientação necessária para o preparo do solo, plantio e
              manutenção das árvores durante o período de dois anos.
            </p>
            <p>
              O restauro florestal é projetado levando-se em conta critérios de
              máxima diversidade de espécies e respeitando as características do
              ecossistema local. Sempre é plantado um mix de diferentes espécies
              nativas, respeitando critérios da dinâmica de sucessão ecológica e
              condições específicas do local escolhido, visando restaurar a
              vegetação nativa da área ao mais próximo possível de sua condição
              original.
            </p>
            <p>
              Assim sendo, a OM torna-se responsável pelo plantio de uma
              floresta, com manutenção regular até seu pleno desenvolvimento.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO
              </li>
              <li>
                Recuperar trechos de mata degradados através do plantio de
                espécies florestais nativas em áreas de preservação permanente
                (APP), reservas legais (RL) e fragmentos de matas;
              </li>
              <li>
                Maximizar a biodiversidade das áreas restauradas por meio da
                criação de corredores ecológicos ao longo da floresta;
              </li>
              <li>
                Melhorar a capacidade hídrica dos rios e nascentes, controlando
                a erosão do solo e reduzindo o assoreamento dos rios;
              </li>
              <li>
                Auxiliar produtores rurais no restauro florestal de suas áreas,
                contribuindo para a regularização da propriedade junto ao órgão
                licenciador estadual e a averbação junto ao registro de imóveis
                competente;
              </li>
              <li>
                Auxiliar pessoas físicas e jurídicas na realização de projetos
                voluntários de reflorestamento, voltados também na compensação
                de emissões de gases de efeito estufa;
              </li>
              <li>
                Certificar essas ações através da concessão do Selo e
                Certificado da Plante Árvore e IBF, atribuindo uma imagem
                positiva a OM.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5">
          <p className=" mt-5 fw-bolder"> PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O QUE O IBF CERTIFICA?</summary>
            <div className="my-4">
              <p>
                O IBF certifica empresas que realizam plantio de árvores nativas
                ou patrocina projetos socioambientais com o Selo “Plante Árvore”
                que está em conformidade com a legislação ambiental vigente e de
                acordo com o modelo proposto pela Norma ISO 14020 para concessão
                de selos ambientais. Este Selo indica que a empresa investiu no
                plantio de árvores e sequestrou Gases do Efeito Estufa (GEE).
              </p>
            </div>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>
              COMO FUNCIONA A PARCERIA NO PROGRAMA PLANTE ÁRVORE?
            </summary>
            <div className="my-4">
              <p>A parceria no Programa Plante Árvore:</p>
              <p>
                <ul>
                  <li>Promove da sustentabilidade ambiental;</li>
                  <li>
                    Desencadeia o processo de certificação e rastreabilidade de
                    Florestas georeferenciadas;
                  </li>
                  <li>
                    Garante a empresa o reconhecimento quanto ao uso de práticas
                    ambientais e sociais corretas tendo em vista o
                    desenvolvimento florestal;
                  </li>
                  <li>
                    Possibilita agregar valor a produtos e serviços oferecidos
                    pelas empresas parceiras;
                  </li>
                  <li>
                    Colabora com a conscientização e a educação ambiental;
                  </li>
                  <li>Move olhares para um país mais consciente.</li>
                </ul>
              </p>
              <p>
                Independentemente da finalidade do plantio das árvores nativas,
                o Selo Plante Árvore irá representar o restauro florestal. Sendo
                assim, por meio da obtenção do Selo, a empresa demonstra aos
                seus parceiros, fornecedores, clientes e colaboradores a
                política adotada quanto ao processo de sustentabilidade
                organizacional.
              </p>
            </div>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>CERTIFICADO</summary>
            <div className="my-4">
              <p>
                É cedido a empresa parceira o Certificado Empresa Amiga da
                Floresta. O Selo Plante Árvore e o certificado de Empresa Amiga
                da Floresta são disponibilizados à todas as empresas
                patrocinadoras.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={planteArvore2021}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2021.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={planteArvore}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2022.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2023}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2023.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2024}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2024.
                </a>
              </p>
            </div>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>RELATÓRIO TÉCNICO DE PLANTIO</summary>
            <div className="my-4">
              <p>
                O plantio tem como objetivo contribuir para o meio ambiente com
                a recuperação de áreas degradadas, conservando a biodiversidade,
                protegendo os recursos hídricos e combatendo o aquecimento
                global.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={relatorio}
                  target="_blank"
                >
                  Clique aqui para visualizar o relatório de 2021.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={relatorio2022}
                  target="_blank"
                >
                  Clique aqui para visualizar o relatório de 2022.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={relatorio2023}
                  target="_blank"
                >
                  Clique aqui para visualizar o relatório de 2023.
                </a>
              </p>
            </div>
          </details>
        </section>
      </section>
    </article>
  );
}
