import madrastaFrente1g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-1g-frente.png";
import madrastaFrente2g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-2g-frente.png";
import madrastaFrente5g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-5g-frente.png";
import madrastaFrente10g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-10g-frente.png";
import madrastaFrente25g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-25g-frente.png";
import madrastaFrente31g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-31g-frente.png";
import madrastaFrente50g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-50g-frente.png";
import madrastaVerso1g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-1g-verso.png";
import madrastaVerso2g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-2g-verso.png";
import madrastaVerso5g from "../../../assets/newImages/mockups/familia/madrasta/madrasta-ouro-5g-verso.png";

export const  madrastaMockups = {
    nome: "Madrasta de Ouro",
    frente1g: madrastaFrente1g,
  
    frente2g: madrastaFrente2g,
  
    frente5g: madrastaFrente5g, 
    frente10g: madrastaFrente10g,
    frente25g:madrastaFrente25g,
    frente31g: madrastaFrente31g,
    frente50g: madrastaFrente50g, 
  
    verso1g: madrastaVerso1g,
    verso2g: madrastaVerso2g,
  
    verso5g: madrastaVerso5g,
    verso10g: madrastaVerso5g,
    verso25g: madrastaVerso5g,
    verso31g: madrastaVerso5g,
    verso50g: madrastaVerso5g,
  };