import React, { useEffect, useState } from "react";
import InputsOM from "../../components/inputs";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import InformacoesPedido from "../../components/informacoesPedido";
import {
  maskCEP,
  maskCPF,
  maskDDD,
  maskEmail,
  maskNumberInt,
  maskOnlyLetters,
  maskPhoneNotDDD,
  validEmail,
} from "../../../utils/regex";
import { useParams } from "react-router-dom";
import createCliente from "../../../models/modules/Cliente/Cliente";
import { getCEP } from "../../../services/modules/viacep/viacep";
import {
  getConsultaClienteByCPF,
  postCliente,
} from "../../../services/modules/cliente/clienteService";
import { PageLoading } from "../../../components/Loading/Loading";
import createEnderecoCEP from "../../../models/modules/Cliente/Endereco";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import RecuperarSenha from "../recuperarSenha/recuperarSenha";
import { isEmptyObject } from "../../../utils/utils";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CriarConta() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [ddd, setDdd] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [cepEntrega, setCepEntrega] = useState("");
  const [numero, setNumero] = useState("");
  const [numeroEntrega, setNumeroEntrega] = useState("");
  const [rg, setRG] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [complemenento, setComplemento] = useState("");
  const [complemenentoEntrega, setComplementoEntrega] = useState("");
  const [load, setLoad] = useState(false);
  const [objEndereco, setObjEndereco] = useState({ undefined });
  const [objEnderecoEntrega, setObjEnderecoEntrega] = useState({ undefined });
  const [cpfModal, setCpfModal] = useState("");

  const [errCpf, setErrCpf] = useState("");
  const [errNome, setErrNome] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errDdd, setErrDdd] = useState("");
  const [errTelefone, setErrTelefone] = useState("");
  const [errCep, setErrCep] = useState("");
  const [errCepEntrega, setErrCepEntrega] = useState("");
  const [errnNumero, setErrNumero] = useState("");
  const [errnNumeroEntrega, setErrNumeroEntrega] = useState("");
  const [errRg, setErrRG] = useState("");
  const [errDataNascimento, setErrDataNascimento] = useState("");
  const [error, setError] = useState("");
  const { cpf } = useParams();

  const valideFields = () => {
    setErrCpf("");
    setErrNome("");
    setErrEmail("");
    setErrDdd("");
    setErrTelefone("");
    setErrCep("");
    setErrNumero("");
    setErrRG("");
    setErrDataNascimento("");
    setErrNumeroEntrega("");
    setError("");
    let errText = "";
    let errorCount = 0;
    const cpfNumerico = cpf.replace(/\D/g, "");

    if (cpfNumerico.length !== 11) {
      // setErrCpf('O CPF deve conter 11 dígitos.');
    }
    if (rg.length <= 0) {
      setErrRG("Informe o RG");
      errorCount++;
    }

    if (!dataNascimento) {
      setErrDataNascimento("Informe a data");
      errorCount++;
    }

    if (!nome) {
      setErrNome("Digite seu nome");
      errorCount++;
    }

    if (!email || !validEmail.test(email)) {
      setErrEmail("Digite um email válido");
      errorCount++;
    }

    if (!ddd || ddd.length !== 2) {
      setErrDdd("Digite um DDD válido");
      errorCount++;
    }

    if (!telefone || telefone.length !== 10) {
      setErrTelefone("Digite um número de telefone válido");
      errorCount++;
    }

    if (!cep || cep.length !== 9) {
      errText = "Verifique a aba Endereço de Cadastro";
      setErrCep("Digite um CEP válido");
      errorCount++;
    }

    if (!numero || numero.length < 1) {
      errText = "Verifique a aba Endereço de Cadastro";
      setErrNumero("Digite o número");
      errorCount++;
    }

    if (!numeroEntrega || numeroEntrega.length < 1) {
      errText = "Verifique a aba Endereço de Entrega";
      setErrNumeroEntrega("Digite o número");
      errorCount++;
    }
    if (!cepEntrega || cepEntrega.length !== 9) {
      errText = "Verifique a aba Endereço de Entrega";
      setErrCepEntrega("Digite um CEP válido");
      errorCount++;
    }

    console.log(errorCount);
    if (errorCount === 0) return true;
    setError(errText);
    AlertNotify({
      message: "Dados inválidos! Valide todos os campos.",
      type: "danger",
    });
  };

  const handleDadosCadastrais = (e) => {
    e.preventDefault();
    if (valideFields()) {
      let novoCliente = createCliente(
        nome,
        cpf,
        email,
        rg,
        new Date(dataNascimento).toISOString(),
        ddd,
        telefone,
        createEnderecoCEP(
          numero,
          complemenento,
          maskNumberInt(cep),
          objEndereco
        ),
        createEnderecoCEP(
          numeroEntrega,
          complemenentoEntrega,
          maskNumberInt(cepEntrega),
          objEnderecoEntrega
        )
      );

      setLoad(true);
      postCliente(novoCliente)
        .then(() => {
          setCpfModal(cpf);
        })
        .catch((e) => e)
        .finally(() => setLoad(false));
    }
  };

  const handleGetCep = (cep, setErr, setObjCEP) => {
    setErr("");
    if (!cep || cep.length !== 9) {
      setErr("Digite um CEP válido");
      return;
    }
    setLoad(true);
    getCEP(cep)
      .then(setObjCEP)
      .catch((e) => e)
      .finally(() => setLoad(false));
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (cpf) {
      setLoad(true);
      getConsultaClienteByCPF(cpf)
        .then(setResponse)
        .catch((e) =>
          AlertNotify({ message: e.response.data.message, type: "warning" })
        )
        .finally(() => setLoad(false));
    }
  }, [cpf]);

  const setResponse = (data) => {
    const date = new Date(
      "Sun Jul 13 1975 00:00:00 GMT-0300 (Brasilia Standard Time)"
    );
    const dateFormat = new Date(date).toISOString().split("T")[0];

    setNome(data.nome);
    setDataNascimento(dateFormat);
    setDdd(data.dddCelular);
    setTelefone(maskPhoneNotDDD(data.celular));
    setEmail(data.email);

    if (!isEmptyObject(data.enderecoResidencial)) {
      const endereco = data.enderecoResidencial;
      setCep(maskCEP(endereco.cep));
    }
  };

  return (
    <MenuPrincipal>
      {load && <PageLoading />}

      {cpfModal && <RecuperarSenha title="Crie sua senha" cpf={cpfModal} />}
      <BoxCadastros>
        <Formulario>
          <TituloPrincipal>
            <h1>Informações Cadastrais</h1>
            <p>Para finalizar a compra, preencha o formulário abaixo.</p>
          </TituloPrincipal>

          <InputsOM
            label="Nome Completo"
            value={nome}
            disabled
            onChange={(e) => setNome(maskOnlyLetters(e.target.value))}
            error={errNome}
          />

          <br />

          <BoxInputs>
            <InputsOM
              label="Data de Nascimento"
              value={dataNascimento}
              disabled
              onChange={(e) => setDataNascimento(e.target.value)}
              onBlur={() => setErrDataNascimento("")}
              error={errDataNascimento}
              type="date"
            />
            <InputsOM
              label="CPF"
              value={maskCPF(cpf)}
              disabled
              error={errCpf}
            />
          </BoxInputs>
          <br />
          <BoxInputs>
            <InputsOM
              label="RG"
              value={maskNumberInt(rg)}
              onChange={(e) => setRG(e.target.value)}
              placeholder="000000000"
              error={errRg}
            />
            <InputsOM
              label="DDD"
              value={ddd}
              onChange={(e) => setDdd(maskDDD(e.target.value))}
              error={errDdd}
            />
            <InputsOM
              label="Celular"
              value={telefone}
              onChange={(e) => setTelefone(maskPhoneNotDDD(e.target.value))}
              error={errTelefone}
            />
          </BoxInputs>
          <br />

          <BoxInputs>

            <InputsOM
              label="E-mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(maskEmail(e.target.value))}
              error={errEmail}
            />
          </BoxInputs>

          <br />
        </Formulario>
        <InformacoesPedido
          linkButton="#"
          textButton="Formas de entrega"
          show={false}
        />
      </BoxCadastros>
      <BoxFormularios>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="enderecos">
              <Tab
                label="Endereço de Cadastro"
                {...a11yProps(0)}
                className="titulo"
              />
              <Tab
                label="Endereço de Entrega"
                {...a11yProps(1)}
                className="titulo"
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Endereco>
              <FormularioEntrega>
                <div className="cep">
                  <InputsOM
                    label="CEP"
                    type="text"
                    value={cep}
                    onChange={(e) => setCep(maskCEP(e.target.value))}
                    error={errCep}
                  />
                  <button
                    onClick={() => handleGetCep(cep, setErrCep, setObjEndereco)}
                  >
                    Consultar CEP
                  </button>
                </div>
                <br />
                <div className="endereco">
                  <div className="complementos">
                    <InputsOM
                      label="Endereço"
                      type="text"
                      value={objEndereco?.logradouro}
                      disabled
                    />
                  </div>
                  <BoxNumeroComplemento>
                    <InputsOM
                      label="Nº"
                      type="text"
                      onChange={(e) => setNumero(maskNumberInt(e.target.value))}
                      value={numero}
                      error={errnNumero}
                    />
                    <InputsOM
                      label="Complemento"
                      type="text"
                      value={complemenento}
                      onChange={(e) => setComplemento(e.target.value)}
                    />
                  </BoxNumeroComplemento>
                </div>
                <br />
                <div className="enderecoEstado">
                  <InputsOM
                    label="Bairro"
                    type="text"
                    disabled
                    value={objEndereco?.bairro}
                  />
                  <br />
                  <InputsOM
                    label="Cidade"
                    type="text"
                    disabled
                    value={objEndereco?.cidade}
                  />
                  <br />
                  <InputsOM
                    label="Estado"
                    type="text"
                    disabled
                    value={objEndereco?.uf}
                  />
                </div>
                <br />
              </FormularioEntrega>
            </Endereco>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Endereco>
              <FormularioEntrega>
                <div className="cep">
                  <InputsOM
                    label="CEP"
                    type="text"
                    value={cepEntrega}
                    onChange={(e) => setCepEntrega(maskCEP(e.target.value))}
                    error={errCepEntrega}
                  />
                  <button
                    onClick={() =>
                      handleGetCep(
                        cepEntrega,
                        setErrCepEntrega,
                        setObjEnderecoEntrega
                      )
                    }
                  >
                    Consultar CEP
                  </button>
                </div>
                <br />
                <div className="endereco">
                  <div className="complementos">
                    <InputsOM
                      label="Endereço"
                      type="text"
                      value={objEnderecoEntrega?.logradouro}
                      disabled
                    />
                  </div>
                  <BoxNumeroComplemento>
                    <InputsOM
                      label="Nº"
                      type="text"
                      onChange={(e) =>
                        setNumeroEntrega(maskNumberInt(e.target.value))
                      }
                      value={numeroEntrega}
                      error={errnNumeroEntrega}
                    />
                    <InputsOM
                      label="Complemento"
                      type="text"
                      value={complemenentoEntrega}
                      onChange={(e) => setComplementoEntrega(e.target.value)}
                    />
                  </BoxNumeroComplemento>
                </div>
                <br />
                <div className="enderecoEstado">
                  <InputsOM
                    label="Bairro"
                    type="text"
                    disabled
                    value={objEnderecoEntrega?.bairro}
                  />
                  <br />
                  <InputsOM
                    label="Cidade"
                    type="text"
                    disabled
                    value={objEnderecoEntrega?.cidade}
                  />
                  <br />
                  <InputsOM
                    label="Estado"
                    type="text"
                    disabled
                    value={objEnderecoEntrega?.uf}
                  />
                </div>
                <br />
              </FormularioEntrega>
            </Endereco>
          </CustomTabPanel>

          <div>
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            <Confirmacao onClick={handleDadosCadastrais}>Confirmar</Confirmacao>
          </div>
        </Box>
      </BoxFormularios>
    </MenuPrincipal>
  );
}

const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;
  font-family: "Roboto", sans-serif;
`;

const BoxCadastros = styled.div`
  @media (min-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
  }
`;
const BoxInputs = styled.div`
  gap: 10px;
  display: flex;

  flex-direction: column;
  align-items: center;
  > div {
    width: 100%;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
  }
`;

const TituloPrincipal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin: 30px 0 10px;
    color: var(--green);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
  }

  p {
    margin: 0 0 30px;
    color: var(--light);
    font-weight: 500;
    font-size: 16px;
  }
`;

const BoxFormularios = styled.div`
  padding: 15px;

  .titulo {
    text-transform: capitalize;
  }

  @media (min-width: 1300px) {
    width: 50%;
    margin-left: 50px;
  }

  @media (min-width: 1600px) {
    margin-left: 74px;
  }

  @media (min-width: 2160px) {
    width: 55%;
  }
`;

const Formulario = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 5% 0% 5%;

  .dadosCadastrais {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3%;

    > div:nth-child(1) {
      width: 30%;
    }
    > div:nth-child(2) {
      width: 70%;
    }

    label {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      color: var(--light);
    }

    .cpf {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    .inputsCelular {
      display: flex;
      justify-content: space-between;
      gap: 2%;
    }

    .ddd {
      width: 50px;
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
    }

    .celular {
      width: 80%;
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      margin-top: 23px;
    }

    @media (min-width: 570px) {
      max-width: 100%;
    }

    @media (min-width: 1024px) {
      flex-direction: row;

      .celular {
        margin-top: 25px;
        margin-right: 5px;
      }
    }
  }

  .complemento {
    display: flex;
    .numero {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 90px;
      margin-right: 10px;
      margin-top: 27px;
      margin-left: -20px;
    }
  }

  .campoSenha input[type="password"]::-ms-reveal,
  .campoSenha input[type="password"]::-ms-clear,
  .campoSenha input[type="password"]::-webkit-contacts-auto-fill-button {
    display: none;
    visibility: hidden;
  }

  @media (min-width: 1450px) {
    .campoSenha {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      input {
        width: 400px;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    padding: 2% 0% 0% 5%;

    .dados {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 1320px) {
    width: 90%;
  }
`;

const FormularioEntrega = styled.div`
  margin: 30px 0 -22px;
  display: flex;
  flex-direction: column;
  width: 98%;

  .inputCelular {
    width: 100%;
    display: flex;
  }

  .celular {
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }

  .complementos {
    width: 100%;
    display: flex;
    flex-direction: column;

    .complementoEndereco {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    @media (min-width: 1000px) {
      width: 65%;
    }
  }
  .complementosEndereco {
    display: flex;
    flex-direction: column;

    .complementoEndereco {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    @media (min-width: 800px) {
      .complementoEndereco {
        width: 100%;
      }
    }
  }
`;

const Endereco = styled.div`
  background-color: #d8d8d8;
  width: 100%;
  border-radius: 8px;
  padding: 30px 20px;

  .cep {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 65%;

    div {
      width: 100%;
    }
  }

  .endereco,
  .enderecoEstado {
    text-transform: uppercase;
    width: 100%;
  }

  p {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--light);
  }

  button {
    background-color: var(--green);
    border-radius: 5px;
    border: none;
    color: var(--primary-white);
    font-size: 11px;
    text-transform: uppercase;
    width: 209px;
    height: 34px;
    padding: 3px 14px;
    margin-left: 10px;
    margin-top: 28px;
  }

  .complemento {
    display: flex;

    .numeroCasa {
      display: flex;
    }

    .numero {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 90px;
      margin-right: 10px;
      margin-top: 27px;
      margin-left: -20px;
    }
  }

  @media (min-width: 400px) {
    .cep {
      flex-direction: row;
    }
  }

  @media (min-width: 450px) {
    .cep {
      width: 100%;
    }
  }

  @media (min-width: 520px) {
    padding: 30px 50px;

    button {
      width: 150px;
    }
  }

  @media (min-width: 700px) {
    .endereco {
      gap: 10px;
      display: flex;
    }
    .numeroCasa {
      margin-left: 15px;
    }
  }

  @media (min-width: 1300px) {
    justify-content: space-between;
    .cep {
      width: 75%;
    }
  }

  @media (min-width: 1900px) {
    .cep {
      width: 50%;
    }
  }

  @media (min-width: 1980px) {
    .enderecoEstado {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const EntregaAlternativa = styled.div`
  @media (min-width: 1300px) {
    width: 70%;
  }

  @media (min-width: 2160px) {
    width: 60%;
  }
`;

const BoxNumeroComplemento = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  @media (min-width: 700px) {
    width: 50%;
    flex-direction: inherit;
    gap: 10px;

    > div:nth-child(1) {
      width: 30%;
    }
    > div:nth-child(2) {
      width: 70%;
    }
  }
`;
const Confirmacao = styled.button`
  display: flex;
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  padding: 3px 20px;
  margin: 0 auto;

  @media (min-width: 1300px) {
    margin: 0 auto;
  }
`;
