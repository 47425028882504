import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import NumberUtility from '../../../../utils/NumberUtility';
import CurrencyInput from '../../../inputs/CurrencyInputs';
import FormButton from '../../../forms/formButton/formButton';
import { QuoteInfoItemCambio } from '../../../quoteInfo/quoteInfo';
import { useNavigate, useParams } from 'react-router-dom';
import { CartContext } from '../../../context/shopp/cartShoppService';
import { maskNumberInt } from '../../../../utils/regex';
import './simulator.css';
import load from '../../../../assets/spinner.gif';
import imgCartao from '../../../../assets/newImages/prepaid-card.png';
import ImageCoin from './imageCoin';
import { ReturnName } from '../../../../utils/coins';
import { FormControl, MenuItem, Select } from '@mui/material';

import InputLabelMUI from '@mui/material/InputLabel';
import CountryFlags from '../../../../utils/countryFlags';

export default function CoinSimulator({ data, error, isLoading }) {
  const navigate = useNavigate();
  const [coin, setCoin] = useState('');
  const [quant, setQuant] = useState(100);
  const [valueRoyal, setValueRoyal] = useState(null);
  const [dataFetch, setDataFetch] = useState([data]);
  const { name } = useParams();
  const { toogleCArt, addProducts } = useContext(CartContext);
  const [type, setType] = useState('Comprar');
  const [codMoeda, setCodMoeda] = useState();
  const [minValueWarning, setMinValueWarning] = useState(false);

  const [isQuantityValid, setIsQuantityValid] = useState(true);

  const textMoeda = {
    compra:
      'Ao realizar a compra desta moeda estrangeira, você poderá receber esses tipos de notas ao lado, não é possível escolher qual nota deseja receber, a seleção é feita de acordo com o estoque da empresa.',
    venda:
      'Essas são as notas em circulação no mercado que a Ourominas efetua a compra.',
    obsCompra:
      'Esta cotação é válida para compras online no site da Ourominas.  Central de Vendas, Lojas Próprias e Correspondentes Autorizados, podem praticar outros valores.',
    obsVenda:
      'Nesta página você conseguirá simular a venda de suas moedas estrangeiras, porém não conseguirá finalizar a venda. Para efetuar a venda é necessário uma análise das notas para comprovar a veracidade, por favor, se dirija a uma loja física, própria ou autorizada, para concluir a sua venda de forma presencial. Esta cotação pode sofrer alteração nas lojas físicas, sendo elas, próprias ou correspondentes autorizados.',
    obsDesconto:
      'Campanha Black Week: descontos progressivos de até R$ 250,00 para operações acima de 5mil USD ou 5mil Euros ou 5mil Libras. Válido pelo período do dia 20/11/2023 até o dia 24/11/2023.',
  };
  const textCartao = {
    venda:
      'Ao realizar a venda do saldo do seu cartão pré-pago, o valor em reais será transferido para os seus dados bancários cadastrados via ted ou transferência bancária identificado como OM DTVM LTDA.',
  };

  const [nameCoin, setNameCoin] = useState();
  const [infoItems, setInfoItems] = useState({});

  useEffect(() => {
    if (coin) {
      selectCoin();
    }
    calculateRoyal(quant, coin);
  }, [quant, coin, valueRoyal]);

  useEffect(() => {
    setDataFetch(data);
  }, [data]);

  useEffect(() => {
    setInfoItems({});
    setCoin('');
    setNameCoin('');
    setCodMoeda('');
  }, [type]);

  async function selectCoin() {

    var div = document.getElementById('selectG');
    var imagem = div.querySelector('img');
    var codImage = imagem.id;
    setCodMoeda(codImage);
    dataFetch.forEach((item) => {
      if (type === 'Comprar' || type === 'Recarregar') {
        if (item.codIso === codImage) {
          setNameCoin(item.nomeMoeda);
          setInfoItems(item);
        }
      } else if (type === 'Vender') {
        if (item.codIso === codImage) {
          setNameCoin(item.nomeMoeda);
          setInfoItems(item);
        }
      }
    });
  }

  async function AddItemsCoin() {
    if (!isQuantityValid) return; 
  
    let product = [];
    product = infoItems;
    product['tipo'] = name;
    product['opcao'] = type;
    product['quantidade'] = quant;
    product['nome'] = infoItems.nomeMoeda;
    product['valor'] = valueRoyal / quant;
    if (coin) {
      await addProducts(product);
      toogleCArt('open');
    }
  }

  function calculateRoyal(quant, coin) {
    if (infoItems.valorMinimo === 0) {
      setIsQuantityValid(true);
    } else {
      setIsQuantityValid(quant >= infoItems.valorMinimo);
    }
    let value = quant * coin;
    setValueRoyal(value);
  }

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      if (quant < infoItems.valorMinimo) {
        setMinValueWarning(true);
      } else {
        setMinValueWarning(false);
      }
    }, 1000);

    return () => clearTimeout(typingTimer);
  }, [quant, infoItems.valorMinimo]);

  return (
    <Container>
      <ShopDescription>Loja Online</ShopDescription>
      <ContainerInputs>
        <ContainerCoinContent>
          <SelectCoin>
            <LineLoading>
              {(!dataFetch && !error) || (isLoading && <Loading src={load} />)}
              {error && (
                <Error>Dados não disponíveis no momento. {error}</Error>
              )}
            </LineLoading>
            <ContentRow className="d-flex flex-column">
              {/* <InputContainer> */}
              <FormControl className="bg-white form__select-coin">
                <InputLabelMUI id="demo-simple-select-label">
                  Selecione a moeda
                </InputLabelMUI>
                <Select
                  labelId="demo-simple-select-label"
                  onChange={(e) => {
                    setCoin(e.target.value);
                  }}
                  id="selectG"
                  value={coin}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  label="Selecione a moeda"
                  placeholder="Cotação"
                >
                  {dataFetch &&
                    Object.keys(dataFetch).length > 0 &&
                    type === 'Comprar' &&
                    dataFetch?.map((item, i) => (
                      <MenuItem
                        id={item?.codIso}
                        key={i++}
                        value={item?.calculoIOFVenda}
                      >
                        <CountryFlags cod={item?.codIso} id={item?.codIso} />{' '}
                        {ReturnName(item?.codIso)}
                      </MenuItem>
                    ))}
                  {dataFetch &&
                    Object.keys(dataFetch).length > 0 &&
                    type === 'Vender' &&
                    dataFetch?.map((item, i) => (
                      <MenuItem
                        id={item?.codIso}
                        key={i++}
                        value={item?.calculoIOFCompra}
                      >
                        <div>
                          <CountryFlags cod={item?.codIso} id={item?.codIso} />{' '}
                          {ReturnName(item?.codIso)}
                        </div>
                      </MenuItem>
                    ))}
                  {dataFetch &&
                    Object.keys(dataFetch).length > 0 &&
                    type === 'Recarregar' &&
                    dataFetch?.map((item, i) => (
                      <MenuItem
                        id={item?.codIso}
                        key={i++}
                        value={item?.calculoIOFVenda}
                      >
                        <CountryFlags cod={item?.codIso} id={item?.codIso} />{' '}
                        {ReturnName(item?.codIso)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* </InputContainer> */}
              <InputContainerType>
                <GrammageSelect onChange={(e) => setType(e.target.value)}>
                  <option value={'Comprar'}>Comprar</option>
                  <option value={'Vender'}>Vender</option>
                  {name === 'cartao-pre-pago' ? (
                    <option value={'Recarregar'}>Recarregar</option>
                  ) : null}
                </GrammageSelect>
              </InputContainerType>

              <InfoObs>
                {nameCoin &&
                  type === 'Vender' &&
                  name === 'cartao-pre-pago' &&
                  textCartao.venda}
                {nameCoin &&
                  type === 'Comprar' &&
                  name === 'moedas-estrangeiras' &&
                  textMoeda.compra}
                {nameCoin &&
                  type === 'Vender' &&
                  name === 'moedas-estrangeiras' &&
                  textMoeda.venda}
              </InfoObs>
            </ContentRow>
          </SelectCoin>

          <ContentQuant>
            <Block>
          {quant < infoItems?.valorMinimo && (
                    <ValueMin>
                      Valor mínimo de compra: R$ {infoItems.valorMinimo}
                    </ValueMin>
                  )}
              <ContentRow className="vw-20">
                <InputContainer>
                  <InputLabel>Valor em {nameCoin?.split(' ', 1)}</InputLabel>
                  <InputQuant
                    value={quant}
                    onChange={(e) => setQuant(maskNumberInt(e.target.value))}
                  />
                </InputContainer>
              </ContentRow>
              {infoItems && (
                <QuoteInfoItemCambio
                  item={infoItems}
                  type={type}
                  quant={quant}
                  name={name}
                />
              )}

              <ContentRow className="vw-20">
                <InputContainer>
                  <InputLabel>VALOR TOTAL (VET)</InputLabel>

                  <CurrencyInput
                    type="tel"
                    disabled
                    id="inpValue"
                    value={
                      'R$ ' + NumberUtility.displayCurrencyValue(valueRoyal)
                    }
                    maskOptions={{
                      prefix: 'R$ ',
                      includeThousandsSeparator: true,
                      allowDecimal: true,
                    }}
                  />
                </InputContainer>
              </ContentRow>
              <InfoCompra>
                {type === 'Vender' && name === 'moedas-estrangeiras' && (
                  <span>{textMoeda.obsVenda}</span>
                )}
                {type === 'Comprar' && name === 'moedas-estrangeiras' && (
                  <span>{textMoeda.obsCompra}</span>
                )}
                {name === 'cartao-pre-pago' && (
                  <span>{textMoeda.obsCompra}</span>
                )}
              </InfoCompra>
            </Block>
          </ContentQuant>
        </ContainerCoinContent>

        {name === 'cartao-pre-pago' &&
          (type === 'Comprar' || type === 'Recarregar') && (
            <ImageCartao src={imgCartao}></ImageCartao>
          )}
        {name === 'moedas-estrangeiras' && codMoeda && (
          <BoxImage>
            <ImageCoin cod={codMoeda} />
          </BoxImage>
        )}
      </ContainerInputs>
      <ContainerButton>
        <ButtonRow>
          {type === 'Comprar' || type === 'Recarregar' ? (
            <FormButton
            className="btnAdd"
            variant="secondary"
            size="lg"
            onClick={AddItemsCoin}
            disabled={!isQuantityValid} 
          >
            {type}
          </FormButton>
          ) : type === 'Vender' ? (
            <FormButton
              className="btnAdd"
              variant="secondary"
              size="lg"
              onClick={() => navigate('/om/lojas')}
            >
              Loja mais próxima
            </FormButton>
          ) : null}
        </ButtonRow>
      </ContainerButton>
    </Container>
  );
}
const Container = styled.section`
  background-color: white;
  flex-direction: column;
  border: 1px solid #e9ecef;

  @media (min-width: 360px) {
    padding: 20px;
  }
  @media (min-width: 1024px) {
    padding: 50px 90px;
  }
`;
const ContainerInputs = styled.div`
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
  @media (min-width: 1024px) {
    gap: 9%;
  }
  @media (min-width: 1400px) {
    justify-content: space-between;
  }
`;

const ContainerCoinContent = styled.div`
  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 10%;
  }
`;
const ShopDescription = styled.div`
  display: flex;
  color: #677a8d;
  font-weight: bold;
`;

const ContentRow = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  padding: 10px 15px;
  height: 70px;
  width: 100%;

  input[type='tel'] {
    border: none;
    border-radius: 6px;

    &:disabled {
      color: unset;
      background-color: unset;
    }
  }
`;
const InputContainerType = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #e9ecef;
  padding: 10px 15px;
  width: 100%;
  margin: 10px 0;

  input[type='tel'] {
    border: none;
    border-radius: 6px;

    &:disabled {
      color: unset;
      background-color: unset;
    }
  }
`;
const ContentQuant = styled.div`
  max-width: 700px;
  @media (max-width: 768px) {
    width: auto;
  }
`;
const InputQuant = styled.input`
  border: 0 solid;
  width: 100%;
`;

const InputLabel = styled.div`
  font-weight: bold;
  color: #677a8d;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ButtonRow = styled.div`
  margin-top: 20px;
`;

const GrammageSelect = styled.select`
  border: none;
  background-color: white;

  border-radius: 0px;
`;
const ContainerButton = styled.div`
  padding: 0 10px;
  @media (min-width: 768px) {
    width: 516px;
    margin: 0 auto;
  }
`;
const ImageCartao = styled.img`
  @media (min-width: 320px) {
    margin: 0 auto;
    width: 288px;
    padding: 20px 10px;
  }
  @media (min-width: 768px) {
    width: 395px;
    height: 200px;
    padding: 0px 10px 20px 55px;
  }
`;
const InfoCompra = styled.span`
  text-align: justify;
  font-size: 12px;
  color: #677a8d;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
const InfoObs = styled.span`
  font-size: 12px;
  color: #677a8d;
`;
const SelectCoin = styled.div`
  @media (min-width: 768px) {
    width: 200px;
  }

  @media (min-width: 1024px) {
    width: 270px;
  }
`;
const Block = styled.div`
  line-height: normal;
`;
export const Loading = styled.img`
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0 auto;
`;
export const LineLoading = styled.div`
  display: flex;
  justify-content: center;
`;
export const Error = styled.span`
  font-size: 10px;
`;
const BoxImage = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueMin = styled.span `
  color: red;
`
