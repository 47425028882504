import React from "react";
import "../../pages.css";
import img from "../../../assets/newImages/cartao-ouro.png";
import linha from "../../../assets/linha.png";
import TableGold from "../../../components/gold/tableGold";
import { PoliticaEntrega } from "../../../components/text/politcaEntrega";

export default function BarraLaminas() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">BARRA DE OURO</p>
            <p>
              O ouro resiste ao teste do tempo, repetidas vezes. É imune à
              inflação, riscos financeiros e bancários. Ele brilha em tempos
              incertos e é uma das maneiras mais seguras e inteligentes de
              investir e construir a riqueza de uma pessoa ao longo do tempo.
            </p>
            <p>
              <ul>
                <li>
                  O ouro é usado como protetor de riqueza há mais de 6.000 anos
                  e sempre reterá seu valor.
                </li>
                <li>
                  O ouro tem sido usado como moeda há milhares de anos e foi
                  universalmente aceito por todas as civilizações.
                </li>
                <li>
                  Os preços do ouro não são ditados pelos governos ou bancos
                  centrais.
                </li>
                <li>
                  O ouro em forma física pertence ao comprador, ao contrário dos
                  ativos digitais, não possui risco de contraparte.
                </li>
                <li>
                  O ouro demonstrou que, apesar da volatilidade, mantém seu
                  poder de compra constante ao longo do tempo.
                </li>
              </ul>
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5">
          Seja você um investidor especialista ou apenas começando, a Ourominas
          (OM) oferece acesso fácil a um mercado global, não importa se está
          comprando um grama ou uma tonelada, a empresa sempre oferece as
          melhores ofertas disponíveis no mercado de ouro e conta com uma equipe
          formada por profissionais graduados e mestres na área financeira para
          auxiliar o cliente da melhor maneira possível.
        </p>
        <p>
          O material vendido pela OM é adquirido no mercado Balcão e no mercado primário, extraído de área de produção
          mineral localizada no Brasil, oriundo de Permissões de Lavras
          Garimpeiras (PLG) autorizadas e conferidas pela Agência Nacional de
          Mineração (ANM) e pela Americas Gold Manufactures Association
          (AMAGOLD), certificação de qualidade que é dada às empresas que passam
          por uma rigorosa análise, averiguando inclusive sua forma de produção
          para adequação nos critérios mais rígidos de qualidade.
        </p>

        <p>
          Todo ouro comercializado na OM possui teor de pureza 999, são
          lacrados individualmente com selos holográficos tridimensionais que
          garantem a segurança e a qualidade do produto, acompanha Nota Fiscal Eletrônica (NF-e), Garantia de Recompra e
          Certificado de Origem do Metal.
        </p>

        <p className="mt-4">
          <b>Nossas Barras</b>
        </p>
        <TableGold />

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE INVESTIR EM OURO?</summary>
            <p className="mt-4">
              Ao longo da história humana, o ouro desempenhou um papel quase
              mítico nas relações de poder entre os povos.
            </p>
            <p>
              No caso do metal dourado mais cobiçado do mundo, essa valoração se
              deu antes mesmo da criação da moeda como meio de troca: os
              astrônomos da Antiguidade ensinavam ao povo a existência de uma
              íntima conexão entre ouro e Sol, prata e Lua. Essa ligação ajudou
              a desenvolver a crença no poder mágico desses metais.
            </p>
            <p>
              De 1875 à metade do século XX, o sistema monetário mundial foi
              integrado pelo ouro e usado como referência econômica
              internacional (“padrão-ouro”).
            </p>
            <p>
              Nesse modelo, estipulava-se a obrigatoriedade de cada país manter
              parte relevante de suas reservas internacionais na forma de ouro,
              e eram essas reservas que definiam as políticas de comércio
              exterior de cada nação.
            </p>
            <p>
              Engana-se quem achava que a fixação do dólar como referência
              mundial (no fim do século XX) tiraria o prestígio do ouro. Em
              2018, por exemplo, os Bancos Centrais pelo mundo compraram mais
              ouro do que em todos os anos anteriores, desde 1971.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE INVESTIR EM OURO COM A OUROMINAS?</summary>
            <p className="my-4">
              <ol>
                <li>
                  A empresa negocia o ouro com spreads baixos e seguindo
                  parâmetros de cotação tanto do mercado interno quanto do
                  internacional, de forma a propiciar a possibilidade de o
                  investidor adquirir o ouro sempre no menor preço do mercado.
                  Na OM tanto o pequeno investidor quanto o grande encontram uma
                  excelente cotação.
                </li>
                <li>
                  As cotações são transparentes e o investidor não precisa pagar
                  mais caro apenas porque está comprando uma pequena quantidade,
                  o que normalmente acontece em muitas empresas.
                </li>
                <li>
                  Diferentemente de colocar seu dinheiro em um fundo de
                  investimento, onde existem taxas diversas como: taxa
                  administração, taxa de performance, taxa de entrada/saída e
                  etc, na OM você pode efetuar compras diárias, semanais,
                  mensais, a qualquer tempo, engordando seu portfólio e vendendo
                  o ouro adquirido a qualquer momento, sem pagar essas taxas.
                </li>
                <li>
                  Comprar ouro na OM é mais ágil, mais simples e barato que na
                  bolsa, já que tem mais liquidez, porque opera com um volume
                  maior do que a própria bolsa, na maioria de seus pregões.
                </li>
                <li>
                  A OM é uma empresa de tradição, possui mais de 40 anos de
                  experiência no setor, conta com uma equipe bastante eficiente
                  e profissional.
                </li>
                <li>
                  A OM possui autorização do Banco Central e é credenciada na
                  Comissão de Valores Mobiliários (CVM) para operar com o ouro,
                  como toda DTVM.
                </li>
              </ol>
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>INVESTIR EM OURO VALE A PENA?</summary>
            <p className="my-4">
              <ol>
                <li>
                  É a única moeda que não está sob o controle de qualquer
                  governo e, portanto, não sofre ingerência governamental.
                </li>
                <li>
                  Os excessos de liquidez e de dinheiro na economia cria
                  inflação ou inflação potencial, desvalorizando ou criando
                  potencial para a desvalorização das moedas que circulam em
                  excesso.
                </li>
                <li>
                  O ouro é um valor seguro sempre, enquanto ações de empresas e
                  outras modalidades de investimentos sofrem bastante em
                  cenários de crise.
                </li>
                <li>
                  A descoberta do ouro como investimento em economias emergentes
                  vem aumentando.
                </li>
                <li>
                  O ouro é um investimento de altíssima liquidez. Existem muito
                  poucos outros investimentos que podem reverter-se em dinheiro
                  de maneira tão rápida como o ouro: sem penalizações, sem
                  espera de tempo, sem condições adicionais.
                </li>
                <li>
                  O ouro adquirido de instituições financeiras no Brasil é
                  definido na Constituição como ativo financeiro, estando
                  sujeito à tributação incidente sobre a renda variável.
                </li>
              </ol>
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE TANTA GENTE INVESTE EM OURO?</summary>
            <p className="mt-4">
              O ouro costuma reluzir ainda mais aos olhos dos investidores em
              momentos de crise e pressão inflacionária.
            </p>
            <p>
              Consolidado no imaginário popular como símbolo de luxo e
              ostentação de reis, o metal dourado resistiu a guerras e
              catástrofes naturais porque é menos suscetível à especulação dos
              bancos e do mercado.
            </p>
            <p>
              Pode ser vendido em qualquer momento e em qualquer lugar do
              planeta, por isso é chamado de “dinheiro real”. Na prática, o ouro
              preserva patrimônio e compensa o risco de outras aplicações
              financeiras tradicionais.
            </p>
            <p>
              A lógica do ouro é a seguinte: quanto mais a bolsa cai e o dólar
              se desvaloriza, mais a cotação do ouro aumenta, por pura lei de
              oferta e demanda.
            </p>
            <p>
              O que acontece nessa situação é que simplesmente os investidores
              retiram seu capital da bolsa de valores e do dólar durante as
              crises para alocar na segurança do metal dourado.
            </p>
            <p>
              Assim, se você perceber tendência de queda do dólar e perda de
              fôlego nos gráficos da bolsa (sinais de crise econômica), é
              praticamente certo que virá uma valorização do ouro em seguida.
            </p>
            <p>
              É preciso lembrar que o ouro, diferentemente de um papel-moeda, é
              de prospecção limitada. E, mais do que isso, um mineral já
              bastante explorado — há séculos — no Brasil e em outras nações
              colonizadas.
            </p>
            <p className="mb-4">
              Dessa forma, com a escassez das jazidas mundiais, o metal amarelo
              ainda circulante no mercado tende a se valorizar ainda mais.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO COMPRAR OURO?</summary>
            <p className="mt-4">
              Realize o seu cadastro com a OM. Você poderá realizar suas
              operações com um cadastro simplificado (para operações de pequeno
              valor) ou com um cadastro completo (para os demais casos).
            </p>
            <p>
              Para realizar suas compras, basta entrar em contato com a OM
              através de seus canais de atendimento.
            </p>
            <p className="mb-4">
              A operação deverá ser liquidada no prazo combinado, pois do
              contrário será cancelada.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O OURO POSSUI GARANTIA?</summary>
            <p className="my-4">
              Todo ouro comercializado na OM possui teor de pureza 999, nota
              fiscal de negociação de ouro, nota fiscal eletrônica e tem sua recompra garantida. Guarde
              sempre as notas fiscais junto ao metal adquirido.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>CADASTRO.</summary>
            <p className="mt-4">
              A OM, como instituição financeira e com fins de prevenção contra a
              lavagem de dinheiro e financiamento ao terrorismo (PLD/FT) é
              obrigada a seguir certos procedimentos cadastrais. A empresa
              protege as informações de seus clientes e não repassa a terceiros,
              salvo sob determinação do Banco Central do Brasil.
            </p>
            <p className="mb-4">
              <ol>
                <li>
                  Cadastro Simplificado: consiste no preenchimento da Ficha
                  Cadastral com os dados básicos necessários e cópia simples dos
                  documentos: RG / CPF / Comprovante de Endereço.
                </li>
                <li>
                  Cadastro Completo: é o procedimento padrão de cadastro da
                  instituição. Consiste no preenchimento da Ficha Cadastral,
                  cópia simples dos documentos: RG / CPF / Comprovante de
                  Endereço e documentação complementar para atribuição de limite
                  operacional. A este cliente é dado um limite operacional, para
                  acompanhamento interno da empresa.
                </li>
              </ol>
            </p>
          </details>
           <PoliticaEntrega/>
          <details className="mb-2 pb-2 border-bottom">
            <summary>HORÁRIO DE NEGOCIAÇÃO.</summary>
            <p className="my-4">
              Nosso horário de negociação: Segunda à Sexta, das 8:30 às 18:00h
              (Horário de Brasília). Temos horários diferenciados em alguns
              pontos de atendimento.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>TRIBUTAÇÃO DO OURO.</summary>
            <p className="mt-4">
              O investimento em Ouro Ativo Financeiro é tratado como aplicação
              em Renda Variável, sendo regulamentado pela lei Lei nº 11.033, de
              21 de dezembro de 2004 e regulamentado pela Instrução Normativa
              RFB nº 1.022, de 5 de abril de 2.010.
            </p>
            <p>
              Está prevista isenção do imposto de renda sobre os ganhos de
              capital auferidos em aplicações de renda variável, cujos valores
              de alienações mensais destes ativos de renda variável sejam de até
              R$ 20.000,00 (vinte mil reais) em cada mercado, ações e Ouro Ativo
              Financeiro, separadamente dentro do mesmo mês calendário. Nesse
              caso relacione os ganhos de capital auferidos em sua declaração de
              Imposto de Renda no campo “Rendimentos Isentos e Não Tributáveis”.
            </p>
            <p>
              No caso do valor das alienações dos ativos financeiros de renda
              variável em conjunto superar os R$ 20.000,00 (vinte mil reais)
              dentro do mesmo mês, aplica-se a alíquota de 15% (quinze por
              cento) sobre os ganhos de capital obtidos, sendo o recolhimento
              efetuado até o último dia útil do mês subsequente, via carne leão.
              Nas operações de Day-trade (compra e venda no mesmo dia) a
              alíquota é de 20% sobre o ganho.
            </p>
            <p className="mb-4">
              O ICMS não é aplicado na comercialização de ouro ativo financeiro.
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
