import React, { useEffect, useState } from "react";
import { getUsersPortal } from "../../../../services/admin";
import spinner from "../../../../assets/spinner.gif";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import MailLockIcon from '@mui/icons-material/MailLock';
import styled from "styled-components";

export default function UserHomeADM() {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);
    const [error, setError] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        function fetchData() {
            getUsersPortal()
                .then(users => {
                    setData(users);
                    setDataLoaded(true);
                })
                .catch((err) => {
                    console.error(err);
                    setError(err)
                })
                .finally(() => setLoad(false));
        }
        fetchData();
    }, []);

    return (
        <div className="w-100 d-flex justify-content-center">
            <div className="adminTable">
                <h1 className="text-center">Usuários</h1>
                <div className="d-flex justify-content-center my-2"></div>

                <LineLoading>
                    {load && <Loading src={spinner} />}
                    {error && <Error>Dados não disponíveis no momento. </Error>}
                </LineLoading>
                {dataLoaded && !error && (
                    <table className="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th className="text-center" scope="col" id="">
                                    Usuário
                                </th>
                                <th className="text-center" scope="col" id="">
                                    Email
                                </th>
                                <th className="text-center" scope="col">
                                    Função
                                </th>
                                <th className="text-center" scope="col">
                                    Situação
                                </th>
                                <th className="text-center" scope="col">
                                    Logs
                                </th>
                                <th className="text-center" scope="col">
                                    Editar
                                </th>
                                <th className="text-center" scope="col">
                                    Gerar nova senha
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((user, index) => (
                                <tr key={index++}>
                                    <td className=" fs-6">{user.name}</td>
                                    <td className="text-center">
                                        {user.username}
                                    </td>
                                    <td className="fs-6 text-center">
                                        {user.email}
                                    </td>
                                    <td className="text-center">
                                        {user.role}
                                    </td>
                                    <td className=" fs-6 text-center">
                                        {user.active ? "Ativo" : "Inativo"}
                                    </td>
                                    <td className="text-center">
                                        <IconButton color="primary"><a href={`/admin/user/home/log/${user.id}`}><SearchIcon /></a></IconButton>
                                    </td>
                                    <td className="text-center">
                                        <IconButton color="primary"><EditIcon /></IconButton>
                                    </td>
                                    <td className="text-center">
                                        <IconButton color="primary"><MailLockIcon /></IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

const Loading = styled.img`
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
`;

const LineLoading = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
const Error = styled.span`
  font-size: 10px;
`;
