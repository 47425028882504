import React from "react";
import img from "../../../assets/newImages/indicador-digital.png";
import linha from "../../../assets/linha.png";
import "../../pages.css";
export default function IndicadorDigital() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              INDICADOR DIGITAL
            </p>
            <p>
              Ser um Indicador Digital permite que seu cliente tenha a melhor
              estrutura de produtos e serviços de ouro e câmbio do país, da
              negociação ao fechamento da operação.
            </p>

            <p className=" mt-2  text-decoration-underline">
              Vantagens Indicador Ativo Financeiro
            </p>

            <ol>
              <li>
                <span>RECEITA</span>
                <p>
                  O indicador recebe uma comissão por cada indicação (com venda
                  concluída).
                </p>
              </li>
              <li>
                <span>ATENDIMENTO</span>
                <p>
                  Central de atendimento para suporte ao indicador, por meio da
                  plataforma Teams da Microsoft.
                </p>
              </li>
              <li>
                <span>DELIVERY</span>
                <p>
                  O indicador pode indicar clientes de todo Brasil, a OM faz
                  entrega em todo território nacional.
                </p>
              </li>
              <li>
                <span>EQUIPE ESPECIALIZADA</span>
                <p>
                  O indicador não necessariamente precisa entender do mercado,
                  pois a OM fará o atendimento completo.
                </p>
              </li>

              <li>
                <span> DIVERSIDADE</span>
                <p>
                  O indicador pode indicar qualquer produto da carteira de
                  produtos da OM.
                </p>
              </li>
            </ol>

            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5 p-0">
          Neste modelo o indicador pode indicar qualquer produto da OM, seja de
          ouro ou de câmbio.
        </p>

        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                Indicador Digital:
              </li>
              <li className="list-unstyled">
                <p>
                  Ofereça a seus amigos, familiares e colegas todos os produtos
                  e serviços da OM relacionados ao ouro e ao câmbio através de
                  um cupom personalizado.
                </p>
                <p>
                  Divulgue em suas redes sociais também para aumentar a sua
                  receita.
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-2 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
              Cupom personalizado:

              </li>
              <li className="list-unstyled">
                <p>
                Divulgue o cupom personalizado + o número da central de vendas (WhatsApp) da OM para o seu cliente e peça para que ele informe, no momento da compra, o código do cupom para o operador, para que dessa forma você seja comissionado sobre a venda.
                </p>
                <p>
                Em breve o cupom poderá ser inserido numa compra online no site da OM.


                </p>
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
/*
const Span = styled.p`
  font-size: 12px;
`;
*/