import paiFrente1g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-1g-frente.png";
import paiFrente2g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-2g-frente.png";
import paiFrente5g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-5g-frente.png";
import paiFrente10g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-10g-frente.png";
import paiFrente25g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-25g-frente.png";
import paiFrente31g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-31g-frente.png";
import paiFrente50g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-50g-frente.png";
import paiVerso1g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-1g-verso.png";
import paiVerso2g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-2g-verso.png";
import paiVerso5g from "../../../assets/newImages/mockups/familia/pai/pai-ouro-5g-verso.png";

export const  paiMockups = {
    nome: "Pai de Ouro",
    frente1g: paiFrente1g,
  
    frente2g: paiFrente2g,
  
    frente5g: paiFrente5g, 
    frente10g: paiFrente10g,
    frente25g:paiFrente25g,
    frente31g: paiFrente31g,
    frente50g: paiFrente50g, 
  
    verso1g: paiVerso1g,
    verso2g: paiVerso2g,
  
    verso5g: paiVerso5g,
    verso10g: paiVerso5g,
    verso25g: paiVerso5g,
    verso31g: paiVerso5g,
    verso50g: paiVerso5g,
  };