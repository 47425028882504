import mormonFrente1g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-1g-frente.png";
import mormonFrente2g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-2g-frente.png";
import mormonFrente5g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-5g-frente.png";
import mormonFrente10g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-10g-frente.png";
import mormonFrente25g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-25g-frente.png";
import mormonFrente31g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-31g-frente.png";
import mormonFrente50g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-50g-frente.png";
import mormonVerso1g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-1g-verso.png";
import mormonVerso2g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-2g-verso.png";
import mormonVerso5g from "../../../assets/newImages/mockups/religiao/mormon/mormon-ouro-5g-verso.png";

export const  mormonMockups = {
    nome: "Mormon",
    frente1g: mormonFrente1g,
  
    frente2g: mormonFrente2g,
  
    frente5g: mormonFrente5g, 
    frente10g: mormonFrente10g,
    frente25g:mormonFrente25g,
    frente31g: mormonFrente31g,
    frente50g: mormonFrente50g, 
  
    verso1g: mormonVerso1g,
    verso2g: mormonVerso2g,
  
    verso5g: mormonVerso5g,
    verso10g: mormonVerso5g,
    verso25g: mormonVerso5g,
    verso31g: mormonVerso5g,
    verso50g: mormonVerso5g,
  };