import operadoraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-1g-frente.png";
import operadoraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-2g-frente.png";
import operadoraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-5g-frente.png";
import operadoraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-10g-frente.png";
import operadoraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-25g-frente.png";
import operadoraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-31g-frente.png";
import operadoraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-50g-frente.png";
import operadoraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-1g-verso.png";
import operadoraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-2g-verso.png";
import operadoraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/operadora/operadora-ouro-5g-verso.png";

export const  operadoraMockups = {
    nome: "Operadora",
    frente1g: operadoraFrente1g,
  
    frente2g: operadoraFrente2g,
  
    frente5g: operadoraFrente5g, 
    frente10g: operadoraFrente10g,
    frente25g:operadoraFrente25g,
    frente31g: operadoraFrente31g,
    frente50g: operadoraFrente50g, 
  
    verso1g: operadoraVerso1g,
    verso2g: operadoraVerso2g,
  
    verso5g: operadoraVerso5g
  };