import sagitarioFrente1g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-1g-frente.png";
import sagitarioFrente2g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-2g-frente.png";
import sagitarioFrente5g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-5g-frente.png";
import sagitarioFrente10g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-10g-frente.png";
import sagitarioFrente25g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-25g-frente.png";
import sagitarioFrente31g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-31g-frente.png";
import sagitarioFrente50g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-50g-frente.png";
import sagitarioVerso1g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-1g-verso.png";
import sagitarioVerso2g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-2g-verso.png";
import sagitarioVerso5g from "../../../assets/newImages/mockups/signos/sagitario/sagitario-ouro-5g-verso.png";

export const  sagitarioMockups = {
    nome: "Sagitário",
    frente1g: sagitarioFrente1g,
  
    frente2g: sagitarioFrente2g,
  
    frente5g: sagitarioFrente5g, 
    frente10g: sagitarioFrente10g,
    frente25g:sagitarioFrente25g,
    frente31g: sagitarioFrente31g,
    frente50g: sagitarioFrente50g, 
  
    verso1g: sagitarioVerso1g,
    verso2g: sagitarioVerso2g,
  
    verso5g: sagitarioVerso5g,
    verso10g: sagitarioVerso5g,
    verso25g: sagitarioVerso5g,
    verso31g: sagitarioVerso5g,
    verso50g: sagitarioVerso5g,
  };