import recifeFrente1g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-1g-frente.png";
import recifeFrente2g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-2g-frente.png";
import recifeFrente5g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-5g-frente.png";
import recifeFrente10g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-10g-frente.png";
import recifeFrente25g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-25g-frente.png";
import recifeFrente31g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-31g-frente.png";
import recifeFrente50g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-50g-frente.png";
import recifeVerso1g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-1g-verso.png";
import recifeVerso2g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-2g-verso.png";
import recifeVerso5g from "../../../assets/newImages/mockups/brasil-turistico/recife/recife-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  recifeMockups = {
    nome: "Recife",
    frente1g: recifeFrente1g,

    frente2g: recifeFrente2g,

    frente5g: recifeFrente5g,

    frente10g: recifeFrente10g,

    frente25g: recifeFrente25g,

    frente31g: recifeFrente31g,

    frente50g: recifeFrente50g,

    verso1g: recifeVerso1g,
    verso2g: recifeVerso2g,

    verso5g: recifeVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const recifeBR = [
    {
      name: "Recife",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]