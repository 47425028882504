import React from "react";
import img from "../../../assets/newImages/moneygram.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";

export default function MoneyGram() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">MONEYGRAM</p>
            <p>
            A Ourominas (OM) é parceira e representante oficial da MoneyGram no
            Brasil.
          </p>
          <p>
            Quando se trata de enviar dinheiro internacionalmente, sabemos que
            existem várias opções. Pensando nisso, a Ourominas, que só atua com
            parceiros confiáveis, escolheu a MoneyGram, que trabalha para
            garantir que as transferências internacionais sejam seguras.
          </p>
          <p>
            Ao escolher a MoneyGram, você tem total segurança de que está
            adquirindo um serviço confiável, utilizado por muitas outras pessoas
            em todo o mundo.
          </p>
          <p>
            O mundo globalizado faz com que seja cada vez mais frequente a
            necessidade de enviar dinheiro para o exterior. Em uma situação como
            essas, é importante pesquisar e conhecer as formas de transferência
            internacional, e uma das mais famosas é a MoneyGram.
          </p>
          <p>
            A MoneyGram International é uma empresa de transferência de dinheiro
            com sede nos Estados Unidos. Possui um centro de operações em St.
            Louis Park, Minnesota, e escritórios regionais e locais em todo o
            mundo.
          </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div> <section>
        <p>
          Não é preciso conta em banco para as remessas internacionais
          MoneyGram, o dinheiro fica disponível para sacar em mais de 350 mil
          pontos de atendimento, desde rede de supermercados, bancos ou até
          mesmo farmácias.
        </p>
        <p>
          Em 2014, a MoneyGram foi o segundo maior fornecedor de transferências
          de dinheiro do mundo. A empresa opera em 193 países e
          territórios com uma rede global de cerca de 347.000 escritórios de
          agentes.
        </p>
      </section>


        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            O QUE É MONEYGRAM E COMO FUNCIONA?
          </summary>
          <p className="my-4">
            A MoneyGram é uma empresa americana que atua em 193 países
            fazendo remessas internacionais de dinheiro. Os serviços prestados
            são basicamente dois: transferência em espécie ou transferência
            bancária. Assim, a pessoa que está no exterior poderá levantar o
            dinheiro físico, indo pessoalmente até algum agente MoneyGram, ou
            poderá recebê-lo na sua conta corrente.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUAIS OS PREÇOS E QUAL A TAXA DE CÂMBIO?
          </summary>
          <p className="mt-4">
            Para entender como funciona a Moneygram, é essencial entender como
            funciona o seu sistema de preços. Na hora de transferir dinheiro,
            existe o câmbio aplicado na transferência. Além disso, existe o
            valor da comissão pelo serviço prestado, já que o total pago no
            final será uma soma de todos estes custos. No caso da MoneyGram, a
            tarifa de envio de cada transferência dependerá do país para onde se
            está mandando dinheiro, bem como do montante enviado. A MoneyGram só
            trabalha com a remessa de dólares, mas o seu sistema faz a conversão
            para a moeda local do país destino.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUE DOCUMENTOS PRECISO PARA ENVIAR DINHEIRO DO BRASIL PARA O
            EXTERIOR?
          </summary>
          <p className="mt-4">
            Para enviar dinheiro para o exterior com a MoneyGram, você precisará
            fazer um cadastro com um agente MoneyGram, no caso a OM. O processo
            é feito de forma presencial ou através da mesa de operações e você
            deverá:
          </p>
          <ol>
            <li>
              Apresentar um documento de identificação com foto (carteira de
              habilitação ou RG) + CPF;
            </li>
            <li>Preencher o formulário de envio;</li>
            <li>
              Indicar o nome completo e o número do documento de identificação
              do destinatário, bem como o local em que ele se encontra;
            </li>
            <li>
              Pagar o valor a ser enviado e o montante relativo aos custos da
              transação;
            </li>
            <li>
              Ao final, anote a referência do formulário de envio, pois será
              necessário apresentá-lo quando da retirada do dinheiro.
            </li>
          </ol>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">
            QUANTO TEMPO DEMORA PARA RECEBER?
          </summary>
          <p className="mt-4">
            Via de regra, o envio é imediato e pode ser recebido dentro de
            alguns minutos. Entretanto, isso também depende do horário de
            funcionamento do agente MoneyGram. Se a remessa for feita, por
            exemplo, em um feriado, é possível que só seja recebida no dia útil
            seguinte.
          </p>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">ONDE E COMO RECEBER?</summary>
          <p className="mt-4">
            O procedimento para receber o dinheiro é simples:
          </p>
          <ol>
            <li>Dirija-se ao agente MoneyGram que lhe for mais conveniente;</li>
            <li>Apresente um documento oficial com foto;</li>
            <li>
              Indique a referência de transição (número referido no formulário
              de envio, que deverá ter sido repassado por quem enviou o
              dinheiro);
            </li>
            <li>
              Preencha o formulário de recebimento e receba o dinheiro em mãos,
              se estiver no Brasil. Em outros países será possível receber o
              dinheiro diretamente em conta bancária.
            </li>
          </ol>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary className="fw-bold">MONEYGRAM É SEGURO?</summary>
          <p className="mt-4">
            Sim, a MoneyGram é uma forma segura e lícita de envio de dinheiro
            para o exterior, pois age de acordo com as normas brasileiras. A
            empresa, nascida em 1940 nos Estados Unidos, atua por meio de
            parceiros que é o caso da OM.
          </p>
        </details>
        </section>
      </section>
    </article>
  );
}

