import engenheiroFrente1g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-1g-frente.png";
import engenheiroFrente2g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-2g-frente.png";
import engenheiroFrente5g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-5g-frente.png";
import engenheiroFrente10g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-10g-frente.png";
import engenheiroFrente25g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-25g-frente.png";
import engenheiroFrente31g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-31g-frente.png";
import engenheiroFrente50g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-50g-frente.png";
import engenheiroVerso1g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-1g-verso.png";
import engenheiroVerso2g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-2g-verso.png";
import engenheiroVerso5g from "../../../assets/newImages/mockups/profissoes/engenheiro/engenheiro-ouro-5g-verso.png";

export const  engenheiroMockups = {
    nome: "Engenheiro de Ouro",
    frente1g: engenheiroFrente1g,
  
    frente2g: engenheiroFrente2g,
  
    frente5g: engenheiroFrente5g,
  
    frente10g: engenheiroFrente10g,
  
    frente25g: engenheiroFrente25g,
  
    frente31g: engenheiroFrente31g,
  
    frente50g: engenheiroFrente50g,
  
    verso1g: engenheiroVerso1g,
    verso2g: engenheiroVerso2g,
  
    verso5g: engenheiroVerso5g,
    verso10g: engenheiroVerso5g,
    verso25g: engenheiroVerso5g,
    verso31g: engenheiroVerso5g,
    verso50g: engenheiroVerso5g,
  };

 