import React from "react";
import styled from "styled-components";
import icoDhl from "../../assets/images/professional/ico-dhl.png";
import icoFlyInsurance from "../../assets/images/professional/ico-fly-insurance.png";
import icoFlyTicket from "../../assets/images/professional/ico-fly-ticket.png";
import icoInternetChip from "../../assets//images/professional/ico-internet-chip.png";
import { Card } from "../../components/section/cards/cards";

export default function SectionOutros() {
  const itemsDescription = [
    {
      productName: "DHL",
      src: icoDhl,
      href: "encaminhamento/dhl",
    },
    {
      productName: "Seguro Viagem",
      src: icoFlyInsurance,
      href: "encaminhamento/seguro-viagem",
    },
    {
      productName: "Passagens Aéreas",
      src: icoFlyTicket,
      href: "encaminhamento/passagens-aereas",
    },
  ];
  return (
    <SectionContainer>
      <SectionIntroContainer>
        <h2 className="display-4">O QUE VOCÊ PRECISA HOJE?</h2>
      </SectionIntroContainer>

      <ul className="feature-list feature-list-sm row wv-100">
        <li className="card-li col-6 col-md-4 mb-4">
          <a
            href="https://mystore.mysimtravel.com/ourominas-jardimpaulistano"
            className="aCard card text-center "
            rel="noopene noreferrer"
            target="_blank"
          >
            <div className="card-body">
              <img
                src={icoInternetChip}
                style={{ height: "auto", width: "100px" }}
                alt="ícone do chip internacional"
              />
              <h6 className=" text-muted title-decorative mt-2">
                Chip Internacional
              </h6>
            </div>
          </a>
        </li>
        {itemsDescription.map(({ href, src, productName }) =>
          Card(href, src, productName)
        )}
      </ul>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-color: #f8f9fa;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 50px;
`;
