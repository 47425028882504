import React from "react";
import styled from "styled-components";

export default function TextButton({ children,  onClick }) {
  return <Button onClick={onClick}>{children}</Button>;
}
const Button = styled.button`
  background: none;
  border: none;
  color: #014b3e;
  &:hover {
    color: #5f6368;
  }
`;
