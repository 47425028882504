import yamanashiFrente1g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-1g-frente.png";
import yamanashiFrente2g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-2g-frente.png";
import yamanashiFrente5g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-5g-frente.png";
import yamanashiFrente10g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-10g-frente.png";
import yamanashiFrente25g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-25g-frente.png";
import yamanashiFrente31g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-31g-frente.png";
import yamanashiFrente50g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-50g-frente.png";
import yamanashiVerso1g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-1g-verso.png";
import yamanashiVerso2g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-2g-verso.png";
import yamanashiVerso5g from "../../../assets/newImages/mockups/mundos/yamanashi/yamanashi-ouro-5g-verso.png";

export const  yamanashiMockups = {
    nome: "Yamanashi",
    frente1g: yamanashiFrente1g,
  
    frente2g: yamanashiFrente2g,
  
    frente5g: yamanashiFrente5g,
  
    frente10g: yamanashiFrente10g,
  
    frente25g: yamanashiFrente25g,
  
    frente31g: yamanashiFrente31g,
  
    frente50g: yamanashiFrente50g,
  
    verso1g: yamanashiVerso1g,
    verso2g: yamanashiVerso2g,
  
    verso5g: yamanashiVerso5g,
    verso10g: yamanashiVerso5g,
    verso25g: yamanashiVerso5g,
    verso31g: yamanashiVerso5g,
    verso50g: yamanashiVerso5g,
  };

 