import evangelicaFrente1g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-1g-frente.png";
import evangelicaFrente2g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-2g-frente.png";
import evangelicaFrente5g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-5g-frente.png";
import evangelicaFrente10g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-10g-frente.png";
import evangelicaFrente25g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-25g-frente.png";
import evangelicaFrente31g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-31g-frente.png";
import evangelicaFrente50g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-50g-frente.png";
import evangelicaVerso1g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-1g-verso.png";
import evangelicaVerso2g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-2g-verso.png";
import evangelicaVerso5g from "../../../assets/newImages/mockups/religiao/evangelica/evangelica-ouro-5g-verso.png";

export const  evangelicaMockups = {
    nome: "Evangelica",
    frente1g: evangelicaFrente1g,
  
    frente2g: evangelicaFrente2g,
  
    frente5g: evangelicaFrente5g, 
    frente10g: evangelicaFrente10g,
    frente25g:evangelicaFrente25g,
    frente31g: evangelicaFrente31g,
    frente50g: evangelicaFrente50g, 
  
    verso1g: evangelicaVerso1g,
    verso2g: evangelicaVerso2g,
  
    verso5g: evangelicaVerso5g,
    verso10g: evangelicaVerso5g,
    verso25g: evangelicaVerso5g,
    verso31g: evangelicaVerso5g,
    verso50g: evangelicaVerso5g,
  };