import amigaFrente1g from "../../../assets/newImages/mockups/amigos/amiga-ouro-1g-frente.png";
import amigaFrente2g from "../../../assets/newImages/mockups/amigos/amiga-ouro-2g-frente.png";
import amigaFrente5g from "../../../assets/newImages/mockups/amigos/amiga-ouro-5g-frente.png";
import amigaFrente10g from "../../../assets/newImages/mockups/amigos/amiga-ouro-10g-frente.png";
import amigaFrente25g from "../../../assets/newImages/mockups/amigos/amiga-ouro-25g-frente.png";
import amigaFrente31g from "../../../assets/newImages/mockups/amigos/amiga-ouro-31g-frente.png";
import amigaFrente50g from "../../../assets/newImages/mockups/amigos/amiga-ouro-50g-frente.png";
import amigaVerso1g from "../../../assets/newImages/mockups/amigos/amiga-ouro-1g-verso.png";
import amigaVerso2g from "../../../assets/newImages/mockups/amigos/amiga-ouro-2g-verso.png";
import amigaVerso5g from "../../../assets/newImages/mockups/amigos/amiga-ouro-5g-verso.png";
import amigoFrente1g from "../../../assets/newImages/mockups/amigos/amigo-ouro-1g-frente.png";
import amigoFrente2g from "../../../assets/newImages/mockups/amigos/amigo-ouro-2g-frente.png";
import amigoFrente5g from "../../../assets/newImages/mockups/amigos/amigo-ouro-5g-frente.png";
import amigoFrente10g from "../../../assets/newImages/mockups/amigos/amigo-ouro-10g-frente.png";
import amigoFrente25g from "../../../assets/newImages/mockups/amigos/amigo-ouro-25g-frente.png";
import amigoFrente31g from "../../../assets/newImages/mockups/amigos/amigo-ouro-31g-frente.png";
import amigoFrente50g from "../../../assets/newImages/mockups/amigos/amigo-ouro-50g-frente.png";
import amigoVerso1g from "../../../assets/newImages/mockups/amigos/amigo-ouro-1g-verso.png";
import amigoVerso2g from "../../../assets/newImages/mockups/amigos/amigo-ouro-2g-verso.png";
import amigoVerso5g from "../../../assets/newImages/mockups/amigos/amigo-ouro-5g-verso.png";

import aniversarioFrente1g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-1g-frente.png";
import aniversarioFrente2g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-2g-frente.png";
import aniversarioFrente5g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-5g-frente.png";
import aniversarioFrente10g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-10g-frente.png";
import aniversarioFrente25g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-25g-frente.png";
import aniversarioFrente31g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-31g-frente.png";
import aniversarioFrente50g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-50g-frente.png";
import aniversarioVerso1g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-1g-verso.png";
import aniversarioVerso2g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-2g-verso.png";
import aniversarioVerso5g from "../../../assets/newImages/mockups/aniversario/aniversario-ouro-5g-verso.png";

import babyMeninaFrente1g from "../../../assets/newImages/mockups/baby/baby-menina-1g-frente.png";
import babyMeninaFrente2g from "../../../assets/newImages/mockups/baby/baby-menina-2g-frente.png";
import babyMeninaFrente5g from "../../../assets/newImages/mockups/baby/baby-menina-5g-frente.png";
import babyMeninaFrente10g from "../../../assets/newImages/mockups/baby/baby-menina-10g-frente.png";
import babyMeninaFrente25g from "../../../assets/newImages/mockups/baby/baby-menina-25g-frente.png";
import babyMeninaFrente31g from "../../../assets/newImages/mockups/baby/baby-menina-31g-frente.png";
import babyMeninaFrente50g from "../../../assets/newImages/mockups/baby/baby-menina-50g-frente.png";
import babyMeninaVerso1g from "../../../assets/newImages/mockups/baby/baby-menina-1g-verso.png";
import babyMeninaVerso2g from "../../../assets/newImages/mockups/baby/baby-menina-2g-verso.png";
import babyMeninaVerso5g from "../../../assets/newImages/mockups/baby/baby-menina-5g-verso.png";

import babyMeninoFrente1g from "../../../assets/newImages/mockups/baby/baby-menino-1g-frente.png";
import babyMeninoFrente2g from "../../../assets/newImages/mockups/baby/baby-menino-2g-frente.png";
import babyMeninoFrente5g from "../../../assets/newImages/mockups/baby/baby-menino-5g-frente.png";
import babyMeninoFrente10g from "../../../assets/newImages/mockups/baby/baby-menino-10g-frente.png";
import babyMeninoFrente25g from "../../../assets/newImages/mockups/baby/baby-menino-25g-frente.png";
import babyMeninoFrente31g from "../../../assets/newImages/mockups/baby/baby-menino-31g-frente.png";
import babyMeninoFrente50g from "../../../assets/newImages/mockups/baby/baby-menino-50g-frente.png";
import babyMeninoVerso1g from "../../../assets/newImages/mockups/baby/baby-menino-1g-verso.png";
import babyMeninoVerso2g from "../../../assets/newImages/mockups/baby/baby-menino-2g-verso.png";
import babyMeninoVerso5g from "../../../assets/newImages/mockups/baby/baby-menino-5g-verso.png";


import bemvindoFrente1g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-1g-frente.png";
import bemvindoFrente2g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-2g-frente.png";
import bemvindoFrente5g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-5g-frente.png";
import bemvindoFrente10g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-10g-frente.png";
import bemvindoFrente25g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-25g-frente.png";
import bemvindoFrente31g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-31g-frente.png";
import bemvindoFrente50g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-50g-frente.png";
import bemvindoVerso1g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-1g-verso.png";
import bemvindoVerso2g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-2g-verso.png";
import bemvindoVerso5g from "../../../assets/newImages/mockups/bemvindo/bem-vindo-5g-verso.png";

import bemvindaFrente1g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-1g-frente.png";
import bemvindaFrente2g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-2g-frente.png";
import bemvindaFrente5g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-5g-frente.png";
import bemvindaFrente10g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-10g-frente.png";
import bemvindaFrente25g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-25g-frente.png";
import bemvindaFrente31g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-31g-frente.png";
import bemvindaFrente50g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-50g-frente.png";
import bemvindaVerso1g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-1g-verso.png";
import bemvindaVerso2g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-2g-verso.png";
import bemvindaVerso5g from "../../../assets/newImages/mockups/bemvindo/bem-vinda-5g-verso.png";

import brasilFrente1g from "../../../assets/newImages/mockups/brasil/brasil-ouro-1g-frente.png";
import brasilFrente2g from "../../../assets/newImages/mockups/brasil/brasil-ouro-2g-frente.png";
import brasilFrente5g from "../../../assets/newImages/mockups/brasil/brasil-ouro-5g-frente.png";
import brasilFrente10g from "../../../assets/newImages/mockups/brasil/brasil-ouro-10g-frente.png";
import brasilFrente31g from "../../../assets/newImages/mockups/brasil/brasil-ouro-25g-frente.png";
import brasilFrente25g from "../../../assets/newImages/mockups/brasil/brasil-ouro-31g-frente.png";
import brasilFrente50g from "../../../assets/newImages/mockups/brasil/brasil-ouro-50g-frente.png";
import brasilVerso1g from "../../../assets/newImages/mockups/brasil/brasil-ouro-1g-verso.png";
import brasilVerso2g from "../../../assets/newImages/mockups/brasil/brasil-ouro-2g-verso.png";
import brasilVerso5g from "../../../assets/newImages/mockups/brasil/brasil-ouro-5g-verso.png";

import casamentoFrente1g from "../../../assets/newImages/mockups/casamento/casamento-ouro-1g-frente.png";
import casamentoFrente2g from "../../../assets/newImages/mockups/casamento/casamento-ouro-2g-frente.png";
import casamentoFrente5g from "../../../assets/newImages/mockups/casamento/casamento-ouro-5g-frente.png";
import casamentoFrente10g from "../../../assets/newImages/mockups/casamento/casamento-ouro-10g-frente.png";
import casamentoFrente25g from "../../../assets/newImages/mockups/casamento/casamento-ouro-25g-frente.png";
import casamentoFrente31g from "../../../assets/newImages/mockups/casamento/casamento-ouro-31g-frente.png";
import casamentoFrente50g from "../../../assets/newImages/mockups/casamento/casamento-ouro-50g-frente.png";
import casamentoVerso1g from "../../../assets/newImages/mockups/casamento/casamento-ouro-1g-verso.png";
import casamentoVerso2g from "../../../assets/newImages/mockups/casamento/casamento-ouro-2g-verso.png";
import casamentoVerso5g from "../../../assets/newImages/mockups/casamento/casamento-ouro-5g-verso.png";

import formandosFrente1g from "../../../assets/newImages/mockups/formandos/formandos-ouro-1g-frente.png";
import formandosFrente2g from "../../../assets/newImages/mockups/formandos/formandos-ouro-2g-frente.png";
import formandosFrente5g from "../../../assets/newImages/mockups/formandos/formandos-ouro-5g-frente.png";
import formandosFrente10g from "../../../assets/newImages/mockups/formandos/formandos-ouro-10g-frente.png";
import formandosFrente25g from "../../../assets/newImages/mockups/formandos/formandos-ouro-25g-frente.png";
import formandosFrente31g from "../../../assets/newImages/mockups/formandos/formandos-ouro-31g-frente.png";
import formandosFrente50g from "../../../assets/newImages/mockups/formandos/formandos-ouro-50g-frente.png";
import formandosVerso1g from "../../../assets/newImages/mockups/formandos/formandos-ouro-1g-verso.png";
import formandosVerso2g from "../../../assets/newImages/mockups/formandos/formandos-ouro-2g-verso.png";
import formandosVerso5g from "../../../assets/newImages/mockups/formandos/formandos-ouro-5g-verso.png";

import loveWinsFrente1g from "../../../assets/newImages/mockups/lovewins/lovewins-1g-frente.png";
import loveWinsFrente2g from "../../../assets/newImages/mockups/lovewins/lovewins-2g-frente.png";
import loveWinsFrente5g from "../../../assets/newImages/mockups/lovewins/lovewins-5g-frente.png";
import loveWinsFrente10g from "../../../assets/newImages/mockups/lovewins/lovewins-10g-frente.png";
import loveWinsFrente25g from "../../../assets/newImages/mockups/lovewins/lovewins-25g-frente.png";
import loveWinsFrente31g from "../../../assets/newImages/mockups/lovewins/lovewins-31g-frente.png";
import loveWinsFrente50g from "../../../assets/newImages/mockups/lovewins/lovewins-50g-frente.png";
import loveWinsVerso1g from "../../../assets/newImages/mockups/lovewins/lovewins-1g-verso.png";
import loveWinsVerso2g from "../../../assets/newImages/mockups/lovewins/lovewins-2g-verso.png";
import loveWinsVerso5g from "../../../assets/newImages/mockups/lovewins/lovewins-5g-verso.png";

import mazalFrente1g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-1g-frente.png";
import mazalFrente2g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-2g-frente.png";
import mazalFrente5g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-5g-frente.png";
import mazalFrente10g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-10g-frente.png";
import mazalFrente25g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-25g-frente.png";
import mazalFrente31g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-31g-frente.png";
import mazalFrente50g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-50g-frente.png";
import mazalVerso1g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-1g-verso.png";
import mazalVerso2g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-2g-verso.png";
import mazalVerso5g from "../../../assets/newImages/mockups/mazaltov/mazal-ouro-5g-verso.png";

import mulherFrente1g from "../../../assets/newImages/mockups/mulher/mulher-ouro-1g-frente.png";
import mulherFrente2g from "../../../assets/newImages/mockups/mulher/mulher-ouro-2g-frente.png";
import mulherFrente5g from "../../../assets/newImages/mockups/mulher/mulher-ouro-5g-frente.png";
import mulherFrente10g from "../../../assets/newImages/mockups/mulher/mulher-ouro-10g-frente.png";
import mulherFrente25g from "../../../assets/newImages/mockups/mulher/mulher-ouro-25g-frente.png";
import mulherFrente31g from "../../../assets/newImages/mockups/mulher/mulher-ouro-31g-frente.png";
import mulherFrente50g from "../../../assets/newImages/mockups/mulher/mulher-ouro-50g-frente.png";
import mulherVerso1g from "../../../assets/newImages/mockups/mulher/mulher-ouro-1g-verso.png";
import mulherVerso2g from "../../../assets/newImages/mockups/mulher/mulher-ouro-2g-verso.png";
import mulherVerso5g from "../../../assets/newImages/mockups/mulher/mulher-ouro-5g-verso.png";


import homemFrente1g from "../../../assets/newImages/mockups/homem/homem-ouro-1g-frente.png";
import homemFrente2g from "../../../assets/newImages/mockups/homem/homem-ouro-2g-frente.png";
import homemFrente5g from "../../../assets/newImages/mockups/homem/homem-ouro-5g-frente.png";
import homemFrente10g from "../../../assets/newImages/mockups/homem/homem-ouro-10g-frente.png";
import homemFrente25g from "../../../assets/newImages/mockups/homem/homem-ouro-25g-frente.png";
import homemFrente31g from "../../../assets/newImages/mockups/homem/homem-ouro-31g-frente.png";
import homemFrente50g from "../../../assets/newImages/mockups/homem/homem-ouro-50g-frente.png";
import homemVerso1g from "../../../assets/newImages/mockups/homem/homem-ouro-1g-verso.png";
import homemVerso2g from "../../../assets/newImages/mockups/homem/homem-ouro-2g-verso.png";
import homemVerso5g from "../../../assets/newImages/mockups/homem/homem-ouro-5g-verso.png";


import namoradaFrente1g from "../../../assets/newImages/mockups/namorados/namorada-ouro-1g-frente.png";
import namoradaFrente2g from "../../../assets/newImages/mockups/namorados/namorada-ouro-2g-frente.png";
import namoradaFrente5g from "../../../assets/newImages/mockups/namorados/namorada-ouro-5g-frente.png";
import namoradaFrente10g from "../../../assets/newImages/mockups/namorados/namorada-ouro-10g-frente.png";
import namoradaFrente25g from "../../../assets/newImages/mockups/namorados/namorada-ouro-25g-frente.png";
import namoradaFrente31g from "../../../assets/newImages/mockups/namorados/namorada-ouro-31g-frente.png";
import namoradaFrente50g from "../../../assets/newImages/mockups/namorados/namorada-ouro-50g-frente.png";
import namoradaVerso1g from "../../../assets/newImages/mockups/namorados/namorada-ouro-1g-verso.png";
import namoradaVerso2g from "../../../assets/newImages/mockups/namorados/namorada-ouro-2g-verso.png";
import namoradaVerso5g from "../../../assets/newImages/mockups/namorados/namorada-ouro-5g-verso.png";


import namoradoFrente1g from "../../../assets/newImages/mockups/namorados/namorado-ouro-1g-frente.png";
import namoradoFrente2g from "../../../assets/newImages/mockups/namorados/namorado-ouro-2g-frente.png";
import namoradoFrente5g from "../../../assets/newImages/mockups/namorados/namorado-ouro-5g-frente.png";
import namoradoFrente10g from "../../../assets/newImages/mockups/namorados/namorado-ouro-10g-frente.png";
import namoradoFrente25g from "../../../assets/newImages/mockups/namorados/namorado-ouro-25g-frente.png";
import namoradoFrente31g from "../../../assets/newImages/mockups/namorados/namorado-ouro-31g-frente.png";
import namoradoFrente50g from "../../../assets/newImages/mockups/namorados/namorado-ouro-50g-frente.png";
import namoradoVerso1g from "../../../assets/newImages/mockups/namorados/namorado-ouro-1g-verso.png";
import namoradoVerso2g from "../../../assets/newImages/mockups/namorados/namorado-ouro-2g-verso.png";
import namoradoVerso5g from "../../../assets/newImages/mockups/namorados/namorado-ouro-5g-verso.png";


import natalFrente1g from "../../../assets/newImages/mockups/natal/natal-ouro-1g-frente.png";
import natalFrente2g from "../../../assets/newImages/mockups/natal/natal-ouro-2g-frente.png";
import natalFrente5g from "../../../assets/newImages/mockups/natal/natal-ouro-5g-frente.png";
import natalFrente10g from "../../../assets/newImages/mockups/natal/natal-ouro-10g-frente.png";
import natalFrente25g from "../../../assets/newImages/mockups/natal/natal-ouro-25g-frente.png";
import natalFrente31g from "../../../assets/newImages/mockups/natal/natal-ouro-31g-frente.png";
import natalFrente50g from "../../../assets/newImages/mockups/natal/natal-ouro-50g-frente.png";
import natalVerso1g from "../../../assets/newImages/mockups/natal/natal-ouro-1g-verso.png";
import natalVerso2g from "../../../assets/newImages/mockups/natal/natal-ouro-2g-verso.png";
import natalVerso5g from "../../../assets/newImages/mockups/natal/natal-ouro-5g-verso.png";

import noivaFrente1g from "../../../assets/newImages/mockups/noivos/noiva-ouro-1g-frente.png";
import noivaFrente2g from "../../../assets/newImages/mockups/noivos/noiva-ouro-2g-frente.png";
import noivaFrente5g from "../../../assets/newImages/mockups/noivos/noiva-ouro-5g-frente.png";
import noivaFrente10g from "../../../assets/newImages/mockups/noivos/noiva-ouro-10g-frente.png";
import noivaFrente25g from "../../../assets/newImages/mockups/noivos/noiva-ouro-25g-frente.png";
import noivaFrente31g from "../../../assets/newImages/mockups/noivos/noiva-ouro-31g-frente.png";
import noivaFrente50g from "../../../assets/newImages/mockups/noivos/noiva-ouro-50g-frente.png";
import noivaVerso1g from "../../../assets/newImages/mockups/noivos/noiva-ouro-1g-verso.png";
import noivaVerso2g from "../../../assets/newImages/mockups/noivos/noiva-ouro-2g-verso.png";
import noivaVerso5g from "../../../assets/newImages/mockups/noivos/noiva-ouro-5g-verso.png";

import noivoFrente1g from "../../../assets/newImages/mockups/noivos/noivo-ouro-1g-frente.png";
import noivoFrente2g from "../../../assets/newImages/mockups/noivos/noivo-ouro-2g-frente.png";
import noivoFrente5g from "../../../assets/newImages/mockups/noivos/noivo-ouro-5g-frente.png";
import noivoFrente10g from "../../../assets/newImages/mockups/noivos/noivo-ouro-10g-frente.png";
import noivoFrente25g from "../../../assets/newImages/mockups/noivos/noivo-ouro-25g-frente.png";
import noivoFrente31g from "../../../assets/newImages/mockups/noivos/noivo-ouro-31g-frente.png";
import noivoFrente50g from "../../../assets/newImages/mockups/noivos/noivo-ouro-50g-frente.png";
import noivoVerso1g from "../../../assets/newImages/mockups/noivos/noivo-ouro-1g-verso.png";
import noivoVerso2g from "../../../assets/newImages/mockups/noivos/noivo-ouro-2g-verso.png";
import noivoVerso5g from "../../../assets/newImages/mockups/noivos/noivo-ouro-5g-verso.png";

import noivadoFrente1g from "../../../assets/newImages/mockups/noivado/noivado-ouro-1g-frente.png";
import noivadoFrente2g from "../../../assets/newImages/mockups/noivado/noivado-ouro-2g-frente.png";
import noivadoFrente5g from "../../../assets/newImages/mockups/noivado/noivado-ouro-5g-frente.png";
import noivadoFrente10g from "../../../assets/newImages/mockups/noivado/noivado-ouro-10g-frente.png";
import noivadoFrente25g from "../../../assets/newImages/mockups/noivado/noivado-ouro-25g-frente.png";
import noivadoFrente31g from "../../../assets/newImages/mockups/noivado/noivado-ouro-31g-frente.png";
import noivadoFrente50g from "../../../assets/newImages/mockups/noivado/noivado-ouro-50g-frente.png";
import noivadoVerso1g from "../../../assets/newImages/mockups/noivado/noivado-ouro-1g-verso.png";
import noivadoVerso2g from "../../../assets/newImages/mockups/noivado/noivado-ouro-2g-verso.png";
import noivadoVerso5g from "../../../assets/newImages/mockups/noivado/noivado-ouro-5g-verso.png";



export const datasComemorativas = [
    {
      name: 'Amiga',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Amigo',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Aniversário',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Baby Menina',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Baby Menino',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Brasil',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Bem Vinda',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Bem Vindo',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Casamento',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Formandos',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'LoveWins',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Mazal Tov',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Mulher',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Homem',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Namorada',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Namorado',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Natal',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Noivado',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Noiva',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
    {
      name: 'Noivo',
      value: [
        'OURO BARRA (1g)',
        'OURO BARRA (2g)',
        'OURO BARRA (5g)',
        'OURO BARRA (10g)',
        'OURO BARRA (25g)',
        'OURO BARRA (31,104g)',
        'OURO BARRA (50g)',
      ],
    },
  ];



  export const amigaMockups = {
    nome: "Amiga",
    frente1g: amigaFrente1g,
    frente2g: amigaFrente2g,
    frente5g: amigaFrente5g,
    frente10g: amigaFrente10g,
    frente25g: amigaFrente25g,
    frente31g: amigaFrente31g,
    frente50g: amigaFrente50g,
    verso1g: amigaVerso1g,
    verso2g: amigaVerso2g,
    verso5g: amigaVerso5g,
  };
  export const amigoMockups = {
    nome: "Amigo",
    frente1g: amigoFrente1g,
    frente2g: amigoFrente2g,
    frente5g: amigoFrente5g,
    frente10g: amigoFrente10g,
    frente25g: amigoFrente25g,
    frente31g: amigoFrente31g,
    frente50g: amigoFrente50g,
    verso1g: amigoVerso1g,
    verso2g: amigoVerso2g,
    verso5g: amigoVerso5g,
  };
  export const aniversarioMockups = {
    nome: "Aniversário",
    frente1g: aniversarioFrente1g,
    frente2g: aniversarioFrente2g,
    frente5g: aniversarioFrente5g,
    frente10g: aniversarioFrente10g,
    frente25g: aniversarioFrente25g,
    frente31g: aniversarioFrente31g,
    frente50g: aniversarioFrente50g,
    verso1g: aniversarioVerso1g,
    verso2g: aniversarioVerso2g,
    verso5g: aniversarioVerso5g,
  };
  export const babyMeninaMockups = {
    nome: "Baby Menina",
    frente1g: babyMeninaFrente1g,
    frente2g: babyMeninaFrente2g,
    frente5g: babyMeninaFrente5g,
    frente10g: babyMeninaFrente10g,
    frente25g: babyMeninaFrente25g,
    frente31g: babyMeninaFrente31g,
    frente50g: babyMeninaFrente50g,
    verso1g: babyMeninaVerso1g,
    verso2g: babyMeninaVerso2g,
    verso5g: babyMeninaVerso5g,
  };
  export const babyMeninoMockups = {
    nome: "Baby Menino",
    frente1g: babyMeninoFrente1g,
    frente2g: babyMeninoFrente2g,
    frente5g: babyMeninoFrente5g,
    frente10g: babyMeninoFrente10g,
    frente25g: babyMeninoFrente25g,
    frente31g: babyMeninoFrente31g,
    frente50g: babyMeninoFrente50g,
    verso1g: babyMeninoVerso1g,
    verso2g: babyMeninoVerso2g,
    verso5g: babyMeninoVerso5g,
  };
  export const brasilMockups = {
    nome: "Brasil",
    frente1g: brasilFrente1g,
    frente2g: brasilFrente2g,
    frente5g: brasilFrente5g,
    frente10g: brasilFrente10g,
    frente25g: brasilFrente25g,
    frente31g: brasilFrente31g,
    frente50g: brasilFrente50g,
    verso1g: brasilVerso1g,
    verso2g: brasilVerso2g,
    verso5g: brasilVerso5g,
  };
  export const casamentoMockups = {
    nome: "Casamento",
    frente1g: casamentoFrente1g,
    frente2g: casamentoFrente2g,
    frente5g: casamentoFrente5g,
    frente10g: casamentoFrente10g,
    frente25g: casamentoFrente25g,
    frente31g: casamentoFrente31g,
    frente50g: casamentoFrente50g,
    verso1g: casamentoVerso1g,
    verso2g: casamentoVerso2g,
    verso5g: casamentoVerso5g,
  };
  export const formandosMockups = {
    nome: "Formandos",
    frente1g: formandosFrente1g,
    frente2g: formandosFrente2g,
    frente5g: formandosFrente5g,
    frente10g: formandosFrente10g,
    frente25g: formandosFrente25g,
    frente31g: formandosFrente31g,
    frente50g: formandosFrente50g,
    verso1g: formandosVerso1g,
    verso2g: formandosVerso2g,
    verso5g: formandosVerso5g,
  };

  export const loveWinsMockups = {
    nome: "LoveWins",
    frente1g: loveWinsFrente1g,
    frente2g: loveWinsFrente2g,
    frente5g: loveWinsFrente5g,
    frente10g: loveWinsFrente10g,
    frente25g: loveWinsFrente25g,
    frente31g: loveWinsFrente31g,
    frente50g: loveWinsFrente50g,
    verso1g: loveWinsVerso1g,
    verso2g: loveWinsVerso2g,
    verso5g: loveWinsVerso5g,
  };

  export const mazalMockups = {
    nome: "Mazal Tov",
    frente1g: mazalFrente1g,
    frente2g: mazalFrente2g,
    frente5g: mazalFrente5g,
    frente10g: mazalFrente10g,
    frente25g: mazalFrente25g,
    frente31g: mazalFrente31g,
    frente50g: mazalFrente50g,
    verso1g: mazalVerso1g,
    verso2g: mazalVerso2g,
    verso5g: mazalVerso5g,
  };
  export const mulherMockups = {
    nome: "Mulher",
    frente1g: mulherFrente1g,
    frente2g: mulherFrente2g,
    frente5g: mulherFrente5g,
    frente10g: mulherFrente10g,
    frente25g: mulherFrente25g,
    frente31g: mulherFrente31g,
    frente50g: mulherFrente50g,
    verso1g: mulherVerso1g,
    verso2g: mulherVerso2g,
    verso5g: mulherVerso5g,
  };
  export const homemMockups = {
    nome: "Homem",
    frente1g: homemFrente1g,
    frente2g: homemFrente2g,
    frente5g: homemFrente5g,
    frente10g: homemFrente10g,
    frente25g: homemFrente25g,
    frente31g: homemFrente31g,
    frente50g: homemFrente50g,
    verso1g: homemVerso1g,
    verso2g: homemVerso2g,
    verso5g: homemVerso5g,
  };
  export const namoradaMockups = {
    nome: "Namorada",
    frente1g: namoradaFrente1g,
    frente2g: namoradaFrente2g,
    frente5g: namoradaFrente5g,
    frente10g: namoradaFrente10g,
    frente25g: namoradaFrente25g,
    frente31g: namoradaFrente31g,
    frente50g: namoradaFrente50g,
    verso1g: namoradaVerso1g,
    verso2g: namoradaVerso2g,
    verso5g: namoradaVerso5g,
  };
  export const namoradoMockups = {
    nome: "Namorado",
    frente1g: namoradoFrente1g,
    frente2g: namoradoFrente2g,
    frente5g: namoradoFrente5g,
    frente10g: namoradoFrente10g,
    frente25g: namoradoFrente25g,
    frente31g: namoradoFrente31g,
    frente50g: namoradoFrente50g,
    verso1g: namoradoVerso1g,
    verso2g: namoradoVerso2g,
    verso5g: namoradoVerso5g,
  };
  export const natalMockups = {
    nome: "Natal",
    frente1g: natalFrente1g,
    frente2g: natalFrente2g,
    frente5g: natalFrente5g,
    frente10g: natalFrente10g,
    frente25g: natalFrente25g,
    frente31g: natalFrente31g,
    frente50g: natalFrente50g,
    verso1g: natalVerso1g,
    verso2g: natalVerso2g,
    verso5g: natalVerso5g,
  };
  export const noivadoMockups = {
    nome: "Noivado",
    frente1g: noivadoFrente1g,
    frente2g: noivadoFrente2g,
    frente5g: noivadoFrente5g,
    frente10g: noivadoFrente10g,
    frente25g: noivadoFrente25g,
    frente31g: noivadoFrente31g,
    frente50g: noivadoFrente50g,
    verso1g: noivadoVerso1g,
    verso2g: noivadoVerso2g,
    verso5g: noivadoVerso5g,
  };
  export const bemvindoMockups = {
    nome: "Bem Vindo",
    frente1g: bemvindoFrente1g,
    frente2g: bemvindoFrente2g,
    frente5g: bemvindoFrente5g,
    frente10g: bemvindoFrente10g,
    frente25g: bemvindoFrente25g,
    frente31g: bemvindoFrente31g,
    frente50g: bemvindoFrente50g,
    verso1g: bemvindoVerso1g,
    verso2g: bemvindoVerso2g,
    verso5g: bemvindoVerso5g,
  };
  export const bemvindaMockups = {
    nome: "Bem Vinda",
    frente1g: bemvindaFrente1g,
    frente2g: bemvindaFrente2g,
    frente5g: bemvindaFrente5g,
    frente10g: bemvindaFrente10g,
    frente25g: bemvindaFrente25g,
    frente31g: bemvindaFrente31g,
    frente50g: bemvindaFrente50g,
    verso1g: bemvindaVerso1g,
    verso2g: bemvindaVerso2g,
    verso5g: bemvindaVerso5g,
  };

  export const noivaMockups = {
    nome: "Noiva",
    frente1g: noivaFrente1g,

    frente2g: noivaFrente2g,

    frente5g: noivaFrente5g,

    frente10g: noivaFrente10g,

    frente25g: noivaFrente25g,

    frente31g: noivaFrente31g,

    frente50g: noivaFrente50g,

    verso1g: noivaVerso1g,
    verso2g: noivaVerso2g,

    verso5g: noivaVerso5g,
  };

  export const noivoMockups = {
    nome: "Noivo",
    frente1g: noivoFrente1g,

    frente2g: noivoFrente2g,

    frente5g: noivoFrente5g,

    frente10g: noivoFrente10g,

    frente25g: noivoFrente25g,

    frente31g: noivoFrente31g,

    frente50g: noivoFrente50g,

    verso1g: noivoVerso1g,
    verso2g: noivoVerso2g,

    verso5g: noivoVerso5g,
  };
