import catarFrente1g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-1g-frente.png";
import catarFrente2g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-2g-frente.png";
import catarFrente5g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-5g-frente.png";

import catarVerso1g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-1g-verso.png";
import catarVerso2g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-2g-verso.png";
import catarVerso5g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-5g-verso.png";
import catarVerso10g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-10g-verso.png";
import catarVerso25g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-25g-verso.png";
import catarVerso31g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-31g-verso.png";
import catarVerso50g from "../../../assets/newImages/mockups/golden-cup/catar/catar-ouro-50g-verso.png";

export const  catarMockups = {
    nome: "Catar",
    frente1g: catarFrente1g,
  
    frente2g: catarFrente2g,
  
    frente5g: catarFrente5g,  

    frente10g: catarFrente5g,
    frente25g:catarFrente5g,
    frente31g: catarFrente5g,
    frente50g: catarFrente5g,
  
    verso1g: catarVerso1g,
    verso2g: catarVerso2g,
  
    verso5g: catarVerso5g,

    verso10g: catarVerso10g,
    verso25g: catarVerso25g,
    verso31g: catarVerso31g,
    verso50g: catarVerso50g
  };
