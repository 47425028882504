import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import banner from "../../assets/banner.png";
import "./banner.css";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const linkClick = (link) => {
    window.scrollTo(0, 0);
    navigate(link);
  };
  return (
       <Container style={{ height: "100%" }}>
        <Row className="row">
          <Col xs={12} md={6} lg={6}>
            <div className="divImg">
              <img src={banner} alt="imagem contendo cartão ouro fácil, barra de ouro, cédula de dólar, onça troy e uma imagem de um celular com o ícone da ourominas no centro "></img>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="colText">
              <h2>
                Ourominas,
                <br /> você pode confiar.
              </h2>
              <p className="sub">
              Ouro ativo financeiro para exportação, investimento, consumo industrial, câmbio de moedas estrangeiras para turismo e negócios internacionais.
              </p>
              <p className="bolder my-4">
                Para cotação, simulação e compra, <br /> clique nos botões
                abaixo.
              </p>
            </div>

            <div className="d-flex flex-wrap">
              <button
                className=" btnBanner"
                onClick={() => linkClick("/om/servicos-ouro")}
              >
                Ouro
              </button>
              <button
                id="cambio"
                className=" btnBanner mx-4"
                onClick={() => linkClick("/om/servicos-cambio")}
              >
                Câmbio
              </button>
              <button
                className=" btnBanner px-3"
                onClick={() => linkClick("/om/outros-servicos")}
              >
                Outros Serviços
              </button>
            </div>
          </Col>
        </Row>
      </Container> 
  );
}
