import desculpaFrente1g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-1g-frente.png";
import desculpaFrente2g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-2g-frente.png";
import desculpaFrente5g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-5g-frente.png";
import desculpaFrente10g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-10g-frente.png";
import desculpaFrente25g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-25g-frente.png";
import desculpaFrente31g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-31g-frente.png";
import desculpaFrente50g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-50g-frente.png";
import desculpaVerso1g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-1g-verso.png";
import desculpaVerso2g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-2g-verso.png";
import desculpaVerso5g from "../../../assets/newImages/mockups/sentimentos/desculpa/desculpa-ouro-5g-verso.png";

export const  desculpaMockups = {
    nome: "Desculpas",
    frente1g: desculpaFrente1g,
  
    frente2g: desculpaFrente2g,
  
    frente5g: desculpaFrente5g, 
    frente10g: desculpaFrente10g,
    frente25g:desculpaFrente25g,
    frente31g: desculpaFrente31g,
    frente50g: desculpaFrente50g, 
  
    verso1g: desculpaVerso1g,
    verso2g: desculpaVerso2g,
  
    verso5g: desculpaVerso5g,
    verso10g: desculpaVerso5g,
    verso25g: desculpaVerso5g,
    verso31g: desculpaVerso5g,
    verso50g: desculpaVerso5g,
  };