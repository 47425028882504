import jeovaFrente1g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-1g-frente.png";
import jeovaFrente2g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-2g-frente.png";
import jeovaFrente5g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-5g-frente.png";
import jeovaFrente10g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-10g-frente.png";
import jeovaFrente25g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-25g-frente.png";
import jeovaFrente31g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-31g-frente.png";
import jeovaFrente50g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-50g-frente.png";
import jeovaVerso1g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-1g-verso.png";
import jeovaVerso2g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-2g-verso.png";
import jeovaVerso5g from "../../../assets/newImages/mockups/religiao/jeova/jeova-ouro-5g-verso.png";

export const  jeovaMockups = {
    nome: "Testemunha de Jeová",
    frente1g: jeovaFrente1g,
  
    frente2g: jeovaFrente2g,
  
    frente5g: jeovaFrente5g, 
    frente10g: jeovaFrente10g,
    frente25g:jeovaFrente25g,
    frente31g: jeovaFrente31g,
    frente50g: jeovaFrente50g, 
  
    verso1g: jeovaVerso1g,
    verso2g: jeovaVerso2g,
  
    verso5g: jeovaVerso5g,
    verso10g: jeovaVerso5g,
    verso25g: jeovaVerso5g,
    verso31g: jeovaVerso5g,
    verso50g: jeovaVerso5g,
  };