import React, { useEffect, useState } from "react";
import "./login.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/Visibility";
import { maskCPF } from "../../../utils/regex";
import { useLocation, useNavigate } from "react-router-dom";
import { PageLoading } from "../../../components/Loading/Loading";
import { getClienteByCPF, loginCliente } from "../../../services/modules/cliente/clienteService";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { postTokenEmail } from "../../../services/modules/cliente/senhaService";
import RecuperarSenha from "../recuperarSenha/recuperarSenha";

export default function Login() {
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [errCPF, setErrCPF] = useState("");
  const [errSenha, setErrSenha] = useState("");
  const navigateTo = useNavigate();
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState("");
  const [openModal, setModal] = useState(false);
  
  const handleRecuperarSenhaClick = () => {
    const cpfNumerico = cpf.replace(/\D/g, "");
    setErrCPF("");
    if (cpfNumerico.length !== 11) {
      setErrCPF("O CPF deve conter 11 dígitos.");
      AlertNotify({message: "Informe o CPF para continuar!", type: 'warning'})
      return;
    }

    setLoad(true);
    getClienteByCPF(cpf).then(async (resp) => {
      await postTokenEmail(resp.codigoCliente).then(() => {
        setModal(true);
      }).catch(() => AlertNotify({message: "Ocorreu um erro ao processar a sua solicitação. Tente novamente mais tarde.",  type: 'warning'}))
    }).catch(()=> AlertNotify({message: "CPF não encontrado! Crie uma conta para continuar.", type: 'warning'})).finally(() => setLoad(false))
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validarCPFESenha()) {
      setLoad(true);
      loginCliente(cpf, senha)
        .then(() => {
          navigateTo(currentRoute.length > 0 ? currentRoute : "/om/painel/perfil");
          window.scrollTo(0, 0);
        })
        .catch((e) => e)
        .finally(() => setLoad(false));
    }
  };

  const validarCPFESenha = () => {
    setErrCPF("");
    setErrSenha("");
    let bool = true;

    const cpfNumerico = cpf.replace(/\D/g, "");

    if (cpfNumerico.length !== 11) {
      setErrCPF("O CPF deve conter 11 dígitos.");
      bool = false;
    }

    const isSenhaValid = senha.length >= 8;

    if (!isSenhaValid) {
      setErrSenha("A senha deve ter pelo menos 8 caracteres.");
      bool = false;
    }
    return bool;
  };

  const handleTogglePasswordVisibility = () => {
    setMostrarSenha(!mostrarSenha);
  };


  useEffect(() => {
    function getParameters() {
      const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
      };

      const currentRoute = getQueryParam('current-route');

      if(currentRoute){
        setCurrentRoute(currentRoute)
      }
    }

    getParameters();


  }, [location.search])


  return (
    <>
      {load && <PageLoading />}
      <div className="login">
        <h2>Login</h2>
        <p>Se já possui cadastro, faça seu login abaixo</p>
        <form onSubmit={handleLogin}>
          <p className="formulario">CPF</p>
          <input
            className="inputs"
            type="text"
            placeholder="CPF"
            value={cpf}
            onChange={(e) => setCpf(maskCPF(e.target.value))}
          />
          <span className="text-danger">{errCPF}</span>
          <br />
          <p className="formulario">Senha</p>
          <div className="senhaInputContainer">
            <input
              className="inputs"
              type={mostrarSenha ? "text" : "password"}
              placeholder="Insira sua senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
            <div
              className="olhoSenha"
              onClick={handleTogglePasswordVisibility}
              aria-label={mostrarSenha ? "Ocultar senha" : "Mostrar senha"}
            >
              {mostrarSenha ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </div>
          </div>
          <span className="text-danger">{errSenha}</span>
          <br />
            <p className="recuperar">
              Esqueceu a senha?
              <span onClick={handleRecuperarSenhaClick}>Recuperar senha</span>
            </p>
          <div className="botaoLog">
            <button type="submit" className="botaoLogin" onClick={handleLogin}>
              Login
            </button>
          </div>
        </form>
        {openModal && <RecuperarSenha cpf={cpf} />}
      </div>
    </>
  );
}
