import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { getMoedas } from "../../../services/api";
import "./chart.css";
import spin from "../../../assets/spinner.gif";

export default function Charts(props) {
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState();
  const [isMediaDesktop, setIsMediaDesktop] = useState(false);
   function fetchMoedas() {
    setLoad(true);
     getMoedas(props.moeda)
      .then(resp => makeChart(resp))
      .catch((err) => setError(err.message))
      .finally(() => setLoad(false));
  }
  useEffect(() => {
    fetchMoedas();
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    setIsMediaDesktop(mediaQuery.matches)
  },[]);


  function makeChart(array) {
    setX(x, []);
    setY(y, []);
    array.forEach((item) => {
      setX((x) => [...x, item.hor]);
      setY((y) => [...y, item.ovd]);
    });
  }
  return (
    <div>
      {!error && load && (
        <div className="col-12 d-flex justify-content-center h-100 pt-2" style={{backgroundColor: "#F7F7F7"}}>
          <img src={spin} alt="" width="100px"></img>
        </div>
      )}
      {x && y && (
        <Plot
          data={[
            {
              x: x,
              y: y,
              mode: "lines",
              type: "scatter",
              marker: { color: "gold" },
            },
          ]}
          style={{ width: "100%", height: "100%" }}
          useResizeHandler={true}
          layout={{
            autosize: true,
            paper_bgcolor: "#F7F7F7",
            plot_bgcolor: "#F7F7F7",
            title: {
              text: props.nome,
              font: {
                color: "#cda153",
                size: isMediaDesktop ? 23 : 16,
              },
            },
            colorway: "blue",
          }}
          config={{ displayModeBar: false }}
        />
      )}
      {error && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          Dados não disponíveis no momento. {error}
        </div>
      )}
    </div>
  );
}
