import filhaFrente1g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-1g-frente.png";
import filhaFrente2g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-2g-frente.png";
import filhaFrente5g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-5g-frente.png";
import filhaFrente10g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-10g-frente.png";
import filhaFrente25g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-25g-frente.png";
import filhaFrente31g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-31g-frente.png";
import filhaFrente50g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-50g-frente.png";
import filhaVerso1g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-1g-verso.png";
import filhaVerso2g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-2g-verso.png";
import filhaVerso5g from "../../../assets/newImages/mockups/familia/filha/filha-ouro-5g-verso.png";

export const  filhaMockups = {
    nome: "Filha de Ouro",
    frente1g: filhaFrente1g,
  
    frente2g: filhaFrente2g,
  
    frente5g: filhaFrente5g, 
    frente10g: filhaFrente10g,
    frente25g:filhaFrente25g,
    frente31g: filhaFrente31g,
    frente50g: filhaFrente50g, 
  
    verso1g: filhaVerso1g,
    verso2g: filhaVerso2g,
  
    verso5g: filhaVerso5g,
    verso10g: filhaVerso5g,
    verso25g: filhaVerso5g,
    verso31g: filhaVerso5g,
    verso50g: filhaVerso5g,
  };